import { AddressFilter } from 'Components/Filters/Fields/AddressFilter/AddressFilter';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { usePermissions, useUser } from 'Pages/Login/reducers';
import { ReactNode } from 'react';
import { useShouldHideMyCompsForExchangePlus } from './FilterFields';
import { FilterSpacer } from 'Components/Filters/Base/Filter/FilterSpacer';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { PropertyTypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertyTypeFilter';
import { PropertySubtypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertySubtypeFilter';
import { BuyerFilter } from 'Components/Filters/Fields/BuyerFilter/BuyerFilter';
import { CountyAPNFilter } from 'Components/Filters/Fields/CountyAPNFilter/CountyAPNFilter';
import { SellerFilter } from 'Components/Filters/Fields/SellerFilter/SellerFilter';
import { CityFilter } from 'Components/Filters/Fields/CityFilter/CityFilter';
import { ZipFilter } from 'Components/Filters/Fields/ZipFilter/ZipFilter';
import { BuildingClassFilter } from 'Components/Filters/Fields/BuildingClassFilter/BuildingClassFilter';
import { BuildingSizeFilter } from 'Components/Filters/Fields/BuildingSizeFilter/BuildingSizeFilter';
import { BuildingFloorsCount } from 'Components/Filters/Fields/BuildingFloorsCount/BuildingFloorsCount';
import { YearBuilt } from 'Components/Filters/Fields/YearBuilt/YearBuilt';
import { BuildingNameFilter } from 'Components/Filters/Fields/BuildingNameFilter/BuildingNameFilter';
import { DateCreatedFilter } from 'Components/Filters/Fields/DateCreatedFilter/DateCreatedFilter';
import { HiddenFilter } from 'Components/Filters/Fields/HiddenFilter/HiddenFilter';
import { PartialFilter } from 'Components/Filters/Fields/PartialFilter/PartialFilter';
import { MyCompsFilter } from 'Components/Filters/Fields/MyCompsFilter/MyCompsFilter';
import { MarketStartingRentFilter } from 'Components/Filters/Fields/MarketStartingRentFilter/MarketStartingRentFilter';
import { AverageTransactionSizeFilter } from 'Components/Filters/Fields/AverageTransactionSizeFilter/AverageTransactionSizeFilter';
import { PortfolioFilter } from 'Components/Filters/Fields/PortfolioFilter/PortfolioFilter';
import { SalePricePsfFilter } from 'Components/Filters/Fields/SalePricePsfFilter/SalePricePsfFilter';
import { SalePriceFilter } from 'Components/Filters/Fields/SalePriceFilter/SalePriceFilter';
import { SaleDateFilter } from 'Components/Filters/Fields/SaleDateFilter/SaleDateFilter';
import { InterestTypeFilter } from 'Components/Filters/Fields/InterestTypeFilter/InterestTypeFilter';
import { TransactionSizeFilter } from 'Components/Filters/Fields/TransactionSizeFilter/TransactionSizeFilter';
import { PropertyMarketEffectiveRentFilter } from 'Components/Filters/Fields/PropertyMarketEffectiveRentFilter/PropertyMarketEffectiveRentFilter';
import { DataSourceFilter } from 'Components/Filters/Fields/DataSourceFilter/DataSourceFilter';

export const SaleFilterFields = () => {
	const permissions = usePermissions();
	const user = useUser();
	const { filters } = useFilterFieldContext();
	const shouldHideMyCompsFilter = useShouldHideMyCompsForExchangePlus();

	let i = 0,
		fields: ReactNode[] = [];

	const normalFields = [
		<AddressFilter key={++i} />,
		<CityFilter key={++i} />,
		<ZipFilter key={++i} />,
		<CountyAPNFilter key={++i} />,
		<FilterSpacer key={++i} />,
		<BuildingNameFilter key={++i} />,

		<YearBuilt key={++i} />,

		<PropertyTypeFilter key={++i}>
			<PropertySubtypeFilter />
		</PropertyTypeFilter>,

		<BuildingClassFilter key={++i} />,
		<BuildingSizeFilter key={++i} />,
		<BuildingFloorsCount key={++i} />,

		<FilterSpacer key={++i} />,

		<MarketStartingRentFilter key={++i} />,
		<PropertyMarketEffectiveRentFilter key={++i} />,
		<AverageTransactionSizeFilter key={++i} />,

		<FilterSpacer key={++i} />,

		<PortfolioFilter key={++i} />,
		<DataSourceFilter key={++i} />,
		<SaleDateFilter key={++i} />,
		<TransactionSizeFilter key={++i} />,
		<SalePriceFilter key={++i} />,
		<SalePricePsfFilter key={++i} />,
		<BuyerFilter key={++i} />,
		<SellerFilter key={++i} />,
		<InterestTypeFilter key={++i} />,
		<DateCreatedFilter key={++i} />,
	].filter(Boolean);

	if (
		!shouldHideMyCompsFilter &&
		getFiltersMarkets(filters).some(
			(market) => !permissions[market.id]?.ownsAllSalesComps
		)
	) {
		fields = fields.concat(normalFields, [<MyCompsFilter key={++i} />]);
	} else {
		fields = fields.concat(normalFields);
	}

	if (user?.userType === 'admin') {
		const adminFields = [
			<HiddenFilter key={++i} />,
			<PartialFilter key={++i} />,
		];

		fields = fields.concat(adminFields);
	}

	return <>{fields}</>;
};
