import { GoogleMapSwitcher } from '@compstak/maps';
import { useIsMufaProperty, usePropertyById } from 'api';
import { PropertyAttributes } from './PropertyAttributes';
import { routes } from 'router';
import { useMediaQuery } from 'react-responsive';
import { MQB } from 'constants/mediaQueryBreakpoints';
import styled from 'styled-components';

export const MainInfo = () => {
	const params = routes.propertyById.useParams();
	const { data: property } = usePropertyById({ propertyId: params.propertyId });
	const { data: isMufa } = useIsMufaProperty({ propertyId: params.propertyId });

	const showSmallerMap = useMediaQuery({ maxWidth: MQB.D_1536 });

	if (!property) return null;

	return (
		<Container>
			<InfoContainer>
				<MapContainer>
					<GoogleMapSwitcher
						key={params.propertyId}
						lat={property.geoPoint.lat}
						lon={property.geoPoint.lon}
						googleStreetViewId={PROPERTY_STREET_VIEW_ID}
						width={showSmallerMap ? MAP_WIDTH_SMALLER : MAP_WIDTH}
						height={MAP_HEIGHT}
					/>
				</MapContainer>
				<PropertyAttributes property={property} isMufa={isMufa} />
			</InfoContainer>
		</Container>
	);
};

const PROPERTY_STREET_VIEW_ID = 'PropertyStreetViewId';

const MAP_WIDTH = 450;
const MAP_WIDTH_SMALLER = 384;
const MAP_HEIGHT = 320;

const Container = styled.div`
	overflow: hidden;
`;

const InfoContainer = styled.div`
	display: flex;
	gap: 1.5rem;
	@media (min-width: ${MQB.D_1920}px) {
		gap: 2rem;
	}
`;

const MapContainer = styled.div`
	padding-top: 8px;
	flex-shrink: 0;
`;
