import { ProfileMenuHeader } from 'Components/ProfileMenu/ProfileMenuHeader';
import {
	IconPromotions,
	ProfileMenu,
	ProfileMenuAnchor,
	ProfileMenuButton,
	ProfileMenuLink,
} from 'Components/ProfileMenu/UI';
import { shouldRewardsLinkBeShown } from 'Pages/Settings/Components/Rewards';
import { useUserQuery } from 'api';
import { useUserCanSeePromotions } from 'api/promotions/promotionQueries';
import { logout } from 'auth/auth';
import { routes } from 'router/routes';
import IconAccount from '../../../ui/svg_icons/account.svg';
import IconEmailText from '../../../ui/svg_icons/email_text.svg';
import IconHelp from '../../../ui/svg_icons/help_v2.svg';
import IconLogout from '../../../ui/svg_icons/logout.svg';
import IconRewards from '../../../ui/svg_icons/rewards.svg';
import IconUploadOutlined from '../../../ui/svg_icons/upload_outlined.svg';

type UserMenuProps = {
	closeMenu: () => void;
};

export const ExchangeUserMenu = ({ closeMenu }: UserMenuProps) => {
	const { data: user } = useUserQuery();
	const isRewardsLinkShown = shouldRewardsLinkBeShown(user);
	const canSeePromos = useUserCanSeePromotions();

	return (
		<ProfileMenu>
			<ProfileMenuHeader close={closeMenu} />
			<ProfileMenuLink
				to={routes.settingsPage.toHref({ page: 'account' })}
				onClick={closeMenu}
			>
				Account
				<IconAccount width="20px" />
			</ProfileMenuLink>
			<ProfileMenuLink
				to={routes.settingsPage.toHref({ page: 'email' })}
				onClick={closeMenu}
			>
				Email
				<IconEmailText width="20px" />
			</ProfileMenuLink>
			{canSeePromos && (
				<ProfileMenuLink to={routes.promotions.toHref()} onClick={closeMenu}>
					Promotions
					<IconPromotions width="20px" />
				</ProfileMenuLink>
			)}
			<ProfileMenuLink to={routes.credits.toHref()} onClick={closeMenu}>
				Credits
				<IconUploadOutlined width="20px" />
			</ProfileMenuLink>
			{isRewardsLinkShown && (
				<ProfileMenuLink to={routes.rewards.toHref()} onClick={closeMenu}>
					Rewards
					<IconRewards width="20px" />
				</ProfileMenuLink>
			)}
			<ProfileMenuAnchor
				href="https://help.compstak.com"
				target="_blank"
				rel="noreferrer"
				onClick={closeMenu}
				data-qa-id="help_link"
			>
				Help
				<IconHelp width="20px" />
			</ProfileMenuAnchor>
			<ProfileMenuButton
				onClick={() => {
					closeMenu();
					logout();
				}}
			>
				Logout
				<IconLogout width="20px" />
			</ProfileMenuButton>
		</ProfileMenu>
	);
};
