import { LOCATION_CHANGE } from 'redux-first-history';
import {
	BOOTSTRAP,
	BootstrapAction,
	LOGOUT,
	LogoutAction,
} from 'Pages/Login/actions';
import { AppState } from 'reducers/root';
import { Middleware } from 'redux';
import {
	ChurnAction,
	initializeChurnZero,
	postChurnZeroEvent,
} from './churnZero';
import { LocationChangeRouterAction } from 'middleware/filtersFromRoutes';
import { getPromiseActionType } from 'types/redux-promise-middleware';
import {
	initializeSegment,
	trackSegmentIdentify,
	trackSegmentPage,
} from './segment';

export const reduxTrackingRetrievalWrapper = new WeakMap();

// @ts-expect-error ts-migrate(2339) FIXME: Property 'selectReduxTrackingRetrievalWrapper' doe... Remove this comment to see the full error message
window.selectReduxTrackingRetrievalWrapper = function (z) {
	// @ts-expect-error ts-migrate(2339) FIXME: Property 'reduxTrackingRetrievalCalledOnce' does n... Remove this comment to see the full error message
	if (window.reduxTrackingRetrievalCalledOnce) {
		throw new Error(
			'Tried to select Redux Tracking Retrieval Wrapper more than once!'
		);
	}
	return reduxTrackingRetrievalWrapper.get(z);
};

type ProcessedAction =
	| LocationChangeRouterAction
	| BootstrapAction
	| LogoutAction
	| ChurnAction;

export default function trackingMiddleware(): Middleware<{}, AppState> {
	return (store) => (next) => (action: ProcessedAction) => {
		const trackingAllowed = (() => {
			const env = window._env_.REACT_APP_DEPLOYMENT_ENV;
			if (env === 'prod') {
				return true;
			}
			if (env === 'local') {
				return false;
			}
			try {
				const userEmail =
					action.type === 'BOOTSTRAP_FULFILLED'
						? action.payload.user.email
						: store.getState().user?.email;
				// Tracking on all envs is allowed only for specific accounts.
				// https://compstak.atlassian.net/browse/AP-16743
				// This should be removed in the future, once no longer needed for testing.
				if (!userEmail) {
					return false;
				}
				// You can add emails here to enable tracking for them on all environments.
				const enabledEmails = [
					'qa_admin@compstak.com',
					'liza@compstak.com', // TODO Matija: Remove Liza & me.
					'matija.milicevic@compstak.com',
				];
				return enabledEmails.includes(userEmail);
			} catch (_err) {
				return false;
			}
		})();
		if (!trackingAllowed) {
			return next(action);
		}

		switch (action.type) {
			case LOCATION_CHANGE:
				if (action.payload.location.pathname.includes('/property/')) {
					const churnData = {
						name: 'Property Page Viewed',
						description: 'User viewed a property page',
						count: 1,
						extraData: {},
					};
					postChurnZeroEvent(churnData);
				}

				trackSegmentPage(action.payload.location.pathname);
				break;

			case getPromiseActionType(BOOTSTRAP, 'FULFILLED'):
				!window.analytics && initializeSegment();
				trackSegmentIdentify(action.payload.user, action.payload.markets);
				initializeChurnZero(action.payload.user);
				break;
			case LOGOUT:
				if (window.analytics) {
					window.analytics.reset?.();
				}
				break;
			default:
				// Churn Zero tracking events
				if (
					'meta' in action &&
					action.meta &&
					action.meta.churnData &&
					action.type.includes('_FULFILLED')
				) {
					postChurnZeroEvent(action.meta.churnData);
				}
				break;
		}

		return next(action);
	};
}
