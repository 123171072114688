import {
	CenterOnBounds,
	ClusterLayer,
	Map,
	MapInteractivity,
	MapStyle,
} from '@compstak/maps';
import { featureCollection, point } from '@turf/helpers';
import { usePortfolioPropertiesQuery } from 'api';
import { MapStyleButton } from 'maps';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { PortfolioPopup } from './PortfolioPopup';

type PortfolioMapProps = {
	isMapView?: boolean;
	onClickPinClusterIds?: (propertyIds: number[]) => void;
	portfolioId: number;
};

export const PortfolioMap = ({
	isMapView,
	onClickPinClusterIds,
	portfolioId,
}: PortfolioMapProps) => {
	const [mapStyle, setMapStyle] = useState(MapStyle.DEFAULT);
	const { filters } = usePortfolioFilters();

	const { data: properties } = usePortfolioPropertiesQuery({
		portfolioId,
		...filters,
	});

	const propertyPoints = useMemo(() => {
		if (!properties) return [];
		return properties.map((p) => {
			const { lon, lat } = p.geoPoint;
			return point([lon, lat], p);
		});
	}, [properties]);

	const propertyPointsFeatureCollection = useMemo(() => {
		return featureCollection(propertyPoints);
	}, [propertyPoints]);

	return (
		<Map
			interactivity={
				isMapView
					? MapInteractivity.INTERACTIVE
					: MapInteractivity.INTERACTIVE_NO_SCROLL_ZOOM
			}
			mapStyle={mapStyle}
			logoPosition="top-left"
		>
			<CenterOnBounds geoJSON={propertyPointsFeatureCollection} />
			<ClusterLayer
				data={propertyPoints}
				circlesLayerProps={{ billboard: true }}
				pinsLayerProps={{
					getTooltipContent: (p) => `${p.properties.buildingAddressAndCity}`,
					getPopupContent(p, closePopup) {
						return (
							<PortfolioPopup
								closePopup={closePopup}
								isMinified={!isMapView}
								property={p.properties}
							/>
						);
					},
				}}
				onClickCluster={(_, leaves) => {
					if (typeof onClickPinClusterIds === 'function') {
						onClickPinClusterIds(leaves.map((pin) => pin.properties?.id));
					}
				}}
			/>
			<MapButtonsContainer>
				<MapStyleButton
					mapStyle={mapStyle}
					onClick={() => {
						setMapStyle(
							mapStyle === MapStyle.DEFAULT
								? MapStyle.SATELLITE
								: MapStyle.DEFAULT
						);
					}}
				/>
			</MapButtonsContainer>
		</Map>
	);
};

export const MapButtonsContainer = styled.div`
	bottom: 0;
	display: flex;
	height: 40px;
	left: 0;
	margin: 0 15px 15px 15px;
	position: absolute;
	max-width: 550px;
`;
