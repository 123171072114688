import { CreatePortfolioParams, useCreatePortfolioMutation } from 'api';
import { useNavigate } from 'router';
import { routes } from 'router/routes/routes';
import { PortfolioDialog } from '../Components';

type Props = {
	closeDialog: () => void;
};

export const NewPortfolioDialog = ({ closeDialog }: Props) => {
	const { mutateAsync: createPorfolio, isLoading: isLoadingCreatePortfolio } =
		useCreatePortfolioMutation();

	const navigate = useNavigate();

	return (
		<PortfolioDialog
			title="New Portfolio"
			closeDialog={closeDialog}
			isLoadingMutation={isLoadingCreatePortfolio}
			onSubmit={async (values) => {
				const params: CreatePortfolioParams = {
					name: values.name,
				};
				if (values.description.length > 0) {
					params.description = values.description;
				}
				const newPortfolio = await createPorfolio(params);
				closeDialog();
				navigate(
					routes.portfolioByIdView.toHref({
						portfolioId: newPortfolio.id,
						viewType: 'dashboard',
					})
				);
			}}
		/>
	);
};
