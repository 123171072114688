import {
	CompsetType,
	useCompsetType,
	useMufaPropertyByIdQuery,
	useMufaSubmarketPolygonsQuery,
	usePropertyByIdQuery,
} from 'api';
import { useSubmarketPolygons } from 'api/submarketPolygons/useSubmarketPolygons';
import { useMemo } from 'react';

type Props = {
	propertyId: number;
};

export const useCompsetSubmarketPolygon = ({ propertyId }: Props) => {
	const compsetType = useCompsetType({ propertyId });

	const { data: property } = usePropertyByIdQuery({ id: propertyId });

	const { data: polygons } = useSubmarketPolygons(
		{ id: property?.marketId },
		{
			enabled:
				property?.marketId != null && compsetType === CompsetType.COMMERCIAL,
		}
	);

	const { data: mufaProperty } = useMufaPropertyByIdQuery(
		{
			propertyId: property?.id,
		},
		{
			enabled: compsetType === CompsetType.REALPAGE,
		}
	);

	const { data: mufaPolygons } = useMufaSubmarketPolygonsQuery(
		{
			rpMarketId: mufaProperty?.rpMarketId,
		},
		{
			enabled: compsetType === CompsetType.REALPAGE,
		}
	);

	const submarketPolygon = useMemo(() => {
		if (!polygons || !property) return;

		return polygons.features.find(
			(p) => p.properties.id === property.submarketId
		);
	}, [polygons, property]);

	const mufaSubmarketPolygon = useMemo(() => {
		if (!mufaPolygons || !mufaProperty) return;

		return mufaPolygons.features.find(
			(p) => p.properties.regioncode === mufaProperty.rpSubmarketCode
		);
	}, [mufaProperty, mufaPolygons]);

	return {
		data:
			compsetType === CompsetType.REALPAGE
				? mufaSubmarketPolygon
				: submarketPolygon,
	};
};
