import { useSearchLeasesQuery } from 'api';
import { ServerFilterItem } from 'types/serverFilters';

type Props = {
	addresses: string[];
	market?: string;
	sortBy: string;
};

export const useLeaseSnapshotData = ({ addresses, market, sortBy }: Props) => {
	const filters: ServerFilterItem[] = [{ property: 'hidden', value: false }];

	filters.push({
		property: 'address',
		value: addresses,
	});

	if (market) {
		filters.push({
			property: 'marketName',
			value: market,
		});
	}

	return useSearchLeasesQuery(
		{
			filter: filters,
			limit: 999999999,
			offset: 0,
			sort: sortBy,
			order: 'desc',
		},
		{
			enabled: filters.length > 0,
		}
	);
};
