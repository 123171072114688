import { BetaChip as PremierListBetaChip } from 'Pages/PremierList/styles';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

// TODO: Should be in ui-kit
export const LinkishButton = styled.button`
	background-color: transparent;
	border: 0;
	color: #303441;
	font-family: inherit;
	font-size: 100%;
	padding: 0;
	text-decoration: underline;
`;
LinkishButton.displayName = 'LinkishButton';

export const Triangle = styled.div<{
	size: number;
	color: string;
}>`
	width: 0;
	height: 0;
	border-right: ${(p) => p.size / 2}px solid transparent;
	border-left: ${(p) => p.size / 2}px solid transparent;
	border-top: 0;
	border-bottom: ${(p) => p.size * 0.866}px solid ${(p) => p.color};
`;

export const DialogFormError = styled.div`
	color: ${(p) => p.theme.colors.red.red500};
	text-align: left;
`;

export const TableCellGapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.375rem;
`;

export const defaultDropdownContentCss = css`
	min-width: 160px;
	background-color: ${(p) => p.theme.colors.white.white};
	z-index: ${(p) => p.theme.zIndex.dropdown};
	border-radius: 0.125rem;
	box-shadow:
		0 4px 6px -1px rgb(0 0 0 / 0.1),
		0 2px 4px -2px rgb(0 0 0 / 0.1);
`;

export const defaultDropdownItemCheckedCss = css`
	font-weight: 500;
`;

export const defaultDropdownItemCss = css`
	width: 100%;
	font-size: 0.875rem;
	font-weight: 400;
	padding: 0.5rem 1rem;
	cursor: pointer;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	display: flex;
	justify-content: space-between;
	align-items: center;
	user-select: none;
	&:hover {
		background-color: ${(p) => p.theme.colors.neutral.n20};
	}
	&[data-highlighted] {
		background-color: ${(p) => p.theme.colors.neutral.n20};
	}
	&[data-state='checked'] {
		${defaultDropdownItemCheckedCss};
	}
`;

export const BlueLink = styled(Link)`
	text-decoration: none;
	transition: color 0.3s ease-in-out;
	user-select: none;
	text-transform: uppercase;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 1.4px;
`;

export const TableHeaderTitle = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.5;
`;

// TODO: Remove this style after PPV2 merge
export const TabContentTitle = styled.h3`
	font-size: 1.125rem;
	font-weight: 300;
	line-height: 1;
`;

export const BetaChip = styled(PremierListBetaChip)`
	font-size: 0.75rem;
	height: 20px;
	align-items: center;
	justify-content: center;
	padding: 0 0.5rem;
	font-weight: 400;
	text-transform: uppercase;
	align-self: unset;
`;
