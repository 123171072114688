import { PROPERTY_TYPE_ID_TO_NAME } from 'api';
import { usePortfolioFilters } from '../../PortfolioFiltersProvider';
import { CheckboxFilter } from './CheckboxFilter';
import { FilterOption } from './types';

export const PropertyTypeFilter = () => {
	const { filters, setFilters } = usePortfolioFilters();

	return (
		<CheckboxFilter
			label="Property Type"
			emptyValueLabel="All Types"
			options={OPTIONS}
			value={filters.propertyTypeIds}
			onChange={(value) => {
				setFilters((s) => ({
					...s,
					propertyTypeIds: value,
				}));
			}}
			buttonProps={{
				style: {
					width: 140,
				},
			}}
		/>
	);
};

const propertyTypeOptions = Object.entries(PROPERTY_TYPE_ID_TO_NAME).reduce(
	(o, [id, name]) => {
		o.push({
			label: name,
			value: String(id),
		});
		return o;
	},
	[] as FilterOption[]
);

const OPTIONS = [
	...propertyTypeOptions,
	{
		label: 'Unknown',
		value: '-1',
	},
];
