import {
	AddToPortfolioButton,
	CompPopupActions,
	CompPopupRoot,
	PropertyDetails,
	PropertyPageLink,
	PropertyPopupHeader,
} from 'maps';
import { PropertyComp } from 'types/comp';

type PortfolioPopupProps = {
	closePopup: () => void;
	isMinified?: boolean;
	property: PropertyComp;
};

export const PortfolioPopup = ({
	isMinified,
	closePopup,
	property,
}: PortfolioPopupProps) => {
	return (
		<CompPopupRoot {...(isMinified && { width: 'auto' })}>
			<PropertyPopupHeader property={property} closePopup={closePopup} />
			{!isMinified && <PropertyDetails property={property} />}
			<CompPopupActions>
				<PropertyPageLink propertyId={property.id} className="single-child">
					View Property Report
				</PropertyPageLink>
				<AddToPortfolioButton property={property} />
			</CompPopupActions>
		</CompPopupRoot>
	);
};
