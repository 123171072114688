import { usePortfolioLeasesQuery } from 'api';
import {
	EXPIRATION_YEARS,
	PROPERTY_TYPE_IDS,
	TENANT_INDUSTRIES,
	TENANT_NAMES,
	SPACE_TYPE_NAMES,
} from 'PortfolioAnalytics/constants';
import { RouterLocation } from 'types';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { getFilterQueryParam } from './getFilterQueryParam';

type Props = {
	portfolioId: number;
	location: RouterLocation;
};

export const useListViewLeases = ({ portfolioId, location }: Props) => {
	const { filters } = usePortfolioFilters();

	return usePortfolioLeasesQuery({
		portfolioId,
		...filters,
		propertyTypeIds:
			getFilterQueryParam(location, PROPERTY_TYPE_IDS) ??
			filters.propertyTypeIds,
		tenantNames: getFilterQueryParam(location, TENANT_NAMES),
		tenantIndustry: getFilterQueryParam(location, TENANT_INDUSTRIES),
		expirationYears: getFilterQueryParam(location, EXPIRATION_YEARS),
		spaceTypes: getFilterQueryParam(location, SPACE_TYPE_NAMES),
	});
};
