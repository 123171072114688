import React, { useMemo } from 'react';

import { appendStateCodes, MarketSelectorProps } from './MarketSelector';

import '../styles/marketSelector.nomodule.less';
import { MarketListV2 } from './MarketListV2/MarketList';
import { User } from '@compstak/common';
import { MultiMarketUpgradeModal } from 'Components/Modals/UpgradeModal/MultiMarketUpgradeModal';
import { isMarketAccessible } from 'util/marketAccessUtils';
import isEqual from 'lodash/isEqual';
import { useListSearchInput } from '../../../../Components/ListSearchInput/ListSearchInput';
import { useDispatch } from 'react-redux';
import { closeMarketSelector, openMarketSelector } from 'actions/home';
import { usePrevious } from 'util/hooks';
import { useMarketsBuffer } from 'hooks/useMarketsBuffer';
import { useMarkets } from 'hooks/useMarkets';
import { useChangeMarkets } from 'hooks/useChangeMarkets';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Modal } from 'Components/Modals/common/UI';
interface MultiMarketSelectorProps
	extends Omit<MarketSelectorProps, 'changeMarket' | 'appConfig'> {
	user: User;
	resetFiltersOnApply?: boolean;
}

export default function MultiMarketSelector(props: MultiMarketSelectorProps) {
	const filtersMarkets = props.filters.markets;
	const changeMarkets = useChangeMarkets();
	const { marketsBuffer, setMarketsBuffer, resetMarketsBuffer } =
		useMarketsBuffer();
	const prevSelectedMarkets = usePrevious(marketsBuffer);
	const dispatch = useDispatch();
	const hasChanged = useMemo(() => {
		const marketsBufferIds = marketsBuffer.map(({ id }) => id);
		const filtersMarketsIds = filtersMarkets.map(({ id }) => id);
		return !isEqual(marketsBufferIds.sort(), filtersMarketsIds.sort());
	}, [marketsBuffer, filtersMarkets]);
	const marketCount = marketsBuffer.length;
	const { sidebarRevampFF } = useFeatureFlags();
	const markets = useMarkets();
	const totalMarketsCount = markets.list.length;

	const {
		searchTerm,
		reset: clearSearchInput,
		element,
	} = useListSearchInput({
		inputPlaceholder: 'Search by state or market',
		inputDataQaId: multiMarketSelectorSearchInputTestId,
	});

	const handleCancel = () => {
		dispatch(closeMarketSelector());
		clearSearchInput();
	};

	const handleApply = () => {
		changeMarkets(marketsBuffer, props.resetFiltersOnApply);
		clearSearchInput();
	};

	const toggleMarketList = () => {
		if (props.marketSelectorExpanded) {
			dispatch(closeMarketSelector());
			clearSearchInput();
		} else {
			dispatch(openMarketSelector());
		}
	};

	const getTooltip = () => {
		if (marketCount === 0) return 'Select a market to continue';
		if (hasChanged) return 'Please apply changes first';
		return undefined;
	};

	const getMarketsSelectorLabel = () => {
		if (marketCount === 0) return 'No Markets Selected';
		if (marketCount === 1) {
			if (props.marketSelectorExpanded) return '1 Market Selected';
			const singleMarket = marketsBuffer[0];
			const stateCode = appendStateCodes(singleMarket.states);
			return `${singleMarket.displayName} ${stateCode}`;
		}
		if (sidebarRevampFF) {
			if (marketCount > 1 && marketCount < totalMarketsCount)
				return `${marketCount} Markets Selected`;
			if (marketCount === totalMarketsCount) return 'National';
		}
		if (marketCount > 1) return `${marketCount} Markets Selected`;
	};

	const currentMarket = () => {
		let className = '';
		if (props.marketSelectorExpanded) {
			className = 'opened ';
		}
		if (hasChanged) {
			className += 'disabled ';
		}

		return (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events
			<div
				onClick={!hasChanged ? toggleMarketList : undefined}
				className={className + 'market-toggle multiple-items'}
				data-tooltip={getTooltip()}
			>
				{getMarketsSelectorLabel()}
			</div>
		);
	};

	const initialExpandedState = useMemo(() => {
		return filtersMarkets.reduce<Record<string, boolean>>((acc, market) => {
			for (const state of market.states) {
				const name = state.name.toLowerCase();
				acc[name] = true;
			}
			return acc;
		}, {});
	}, [filtersMarkets]);

	const selectedMarketsWithoutAccess = useMemo(
		() =>
			marketsBuffer.filter(
				(market) =>
					!isMarketAccessible(market.id, props.compType, props.permissions)
			),
		[marketsBuffer, props.compType, props.permissions]
	);

	const onUpgradeModalClose = () => {
		const accessiblePrevSelectedMarkets = (prevSelectedMarkets ?? []).filter(
			({ id }) => isMarketAccessible(id, props.compType, props.permissions)
		);
		setMarketsBuffer(accessiblePrevSelectedMarkets);
	};

	return (
		<div className="market-selector">
			{selectedMarketsWithoutAccess.length !== 0 && (
				<Modal onClose={onUpgradeModalClose}>
					<MultiMarketUpgradeModal
						onClose={onUpgradeModalClose}
						compType={props.compType}
						selectedMarkets={selectedMarketsWithoutAccess.map(({ id }) => id)}
					/>
				</Modal>
			)}
			{currentMarket()}
			<MarketListV2
				marketSelectorExpanded={props.marketSelectorExpanded}
				searchElement={element}
				searchTerm={searchTerm}
				selectedMarkets={marketsBuffer}
				changeSelectedMarkets={setMarketsBuffer}
				onClearAll={resetMarketsBuffer}
				onCancel={handleCancel}
				onApply={handleApply}
				isSubmitDisabled={marketCount === 0}
				initialExpandedState={initialExpandedState}
				markets={markets}
			/>
		</div>
	);
}

export const multiMarketSelectorSearchInputTestId = 'market-list-search-input';
