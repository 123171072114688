import { Flex } from '@compstak/ui-kit';
import dompurify from 'dompurify';
import styled from 'styled-components';
import { Promotion } from './promotionTypes';
import {
	PromoDetailsSection,
	PromoDetailsSectionV2,
	PromoTitleAndDate,
} from './promotionUtilComponents';

export function SelectedPromotion({
	promotion,
}: {
	promotion: Promotion | null;
}) {
	if (!promotion) {
		return <div>There is no selected promotion</div>;
	}
	const sanitizedDescription = dompurify.sanitize(promotion.description);
	return (
		<Card>
			{/* TODO: Image */}
			{/* <img /> */}
			<PromoTitleAndDate promotion={promotion} />
			<hr />
			<Flex direction="column" justifyContent="space-between" gap="16px">
				<DescriptionStyled
					dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
				></DescriptionStyled>
				<PromoDetailsSection promotion={promotion} />
			</Flex>
		</Card>
	);
}

export const SelectedPromotionV2 = ({
	promotion,
}: {
	promotion: Promotion | null;
}) => {
	if (!promotion) {
		return <div>There is no selected promotion</div>;
	}
	const sanitizedDescription = dompurify.sanitize(promotion.description);
	return (
		<Card>
			{/* TODO: Image */}
			{/* <img /> */}
			<PromoTitleAndDate promotion={promotion} />
			<hr />
			<Flex direction="column" justifyContent="space-between" gap="16px">
				<DescriptionStyled
					dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
				></DescriptionStyled>
				<PromoDetailsSectionV2 promotion={promotion} />
			</Flex>
		</Card>
	);
};

const DescriptionStyled = styled.div`
	width: 100%;
	min-height: 120px;
	& * {
		/*
			All descendants of this element shouldn't overflow their parent in width.
			This is important since CMS content will be shown in this element.
		*/
		max-width: 100%;
	}
`;

const Card = styled.div`
	padding: 16px;
	border: 3px solid ${(p) => p.theme.colors.neutral.n90};
`;
