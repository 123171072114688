import { Market, MarketPermissions } from '@compstak/common';
import {
	MultiselectListItem,
	SelectListItem,
	SelectOnChangeParams,
	SpaceTypeSelectListItem,
	useSimpleForm,
} from '@compstak/ui-kit';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MarketsState, PermissionsState } from '../../../../Login/reducers';
import { Chart, DataSetType } from '../../../analytics';
import { attributeToDataSetType } from '../../chartBuilderConstants';
import {
	MUFA_OPTION,
	SPACE_TYPE_COMMERCIAL_ITEMS,
	SPACE_TYPE_MUFA_ITEMS,
} from '../Modals/DataSets/CreateNewDataSetV2/spaceTypeItems';
import {
	MarketItem,
	attributeToPlotOptions,
	getAttrOptionsForCreateDSForm,
	getMarketOptions,
} from '../Modals/DataSets/CreateNewDataSetV2/utils';

export interface UseDataSetFormProps {
	markets: MarketsState;
	permissions: Record<number, MarketPermissions>;
	initialState?: State;
	/** Flag signaling, that MufaSpaceType options must be
	 * displayed if there is Mufa access for at least one market*/
	isMufaSpaceTypesIfAtLeastOneMarketAccess?: boolean;
	chartDraft: Chart;
	dataSetType?: DataSetType;
}

interface State {
	title?: string;
	market?: Market;
	spaceTypes?: SpaceTypeSelectListItem[];
	propertyTypes?: MultiselectListItem[];
	attributeToPlot?: string;
	dataSetType?: DataSetType;
	attributeToPlotItems: SelectListItem[];
}

const defaultState: State = {
	title: '',
	market: undefined,
	spaceTypes: undefined,
	propertyTypes: undefined,
	attributeToPlot: undefined,
	dataSetType: DataSetType.COMMERCIAL,
	attributeToPlotItems: [],
};

function getInitialSpaceTypeOptions(
	permissions: PermissionsState,
	marketItems: { id: number }[]
): SpaceTypeSelectListItem[] {
	const hasAtLeastOneMufaAccess = marketItems.some(
		(market) => permissions[market.id]?.multifamilyAccess
	);
	return hasAtLeastOneMufaAccess
		? SPACE_TYPE_MUFA_ITEMS
		: SPACE_TYPE_COMMERCIAL_ITEMS;
}

const isValidTitle = (
	title: string
): { success: boolean; errorMsg: string } => {
	if (!title?.trim()) {
		return { success: false, errorMsg: 'Field is empty' };
	}

	return { success: true, errorMsg: '' };
};

export const useDataSetForm = ({
	markets,
	permissions,
	initialState = defaultState,
	isMufaSpaceTypesIfAtLeastOneMarketAccess = false,
	dataSetType,
}: UseDataSetFormProps) => {
	// @ts-expect-error TS2344: Type 'State' does not satisfy ...
	const { state, change, changeBulk } = useSimpleForm<State>(initialState);
	const { salesCompsAnalytics2 } = useFeatureFlags();
	const marketItems = useMemo(() => getMarketOptions(markets), [markets]);

	const getSpaceTypeOptions = useCallback(
		// @ts-expect-error TS7006: Parameter 'marketIdx' implicit...
		(marketIdx) => {
			const market = marketIdx !== undefined && markets[marketIdx];
			const hasMufaAccess =
				market && permissions?.[market.id].multifamilyAccess;
			return hasMufaAccess
				? SPACE_TYPE_MUFA_ITEMS
				: SPACE_TYPE_COMMERCIAL_ITEMS;
		},
		[markets, permissions]
	);

	const initialSpaceTypeOptions = isMufaSpaceTypesIfAtLeastOneMarketAccess
		? getInitialSpaceTypeOptions(permissions, marketItems)
		: getSpaceTypeOptions(initialState?.market?.id);

	const [spaceTypeOptions, setSpaceTypeOptions] = useState(
		initialSpaceTypeOptions
	);

	const hasMufaAccess = marketItems.some(
		(market) => permissions[market.id].multifamilyAccess
	);

	const isFormNotComplete = useMemo(() => {
		return Object.keys(state).some((key) => {
			if (
				(state.dataSetType === DataSetType.MUFA &&
					(key === 'spaceTypes' || key === 'propertyTypes')) ||
				(state.dataSetType === DataSetType.SALES && key === 'spaceTypes') ||
				((state.dataSetType === DataSetType.LEASES ||
					state.dataSetType === DataSetType.COMMERCIAL) &&
					key === 'propertyTypes')
			) {
				return false;
			}
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			if (Array.isArray(state[key])) {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				return state[key].length === 0;
			}
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			return !state[key];
		});
	}, [state, dataSetType]);

	let attributeToPlotItems: SelectListItem[] = [];

	const isMufaSpaceType = dataSetType === DataSetType.MUFA;
	attributeToPlotItems =
		attributeToPlotOptions[isMufaSpaceType ? 'mufa' : 'commercial'];

	const onChangeMarket = useCallback(
		({ selectedItem }: SelectOnChangeParams<MarketItem>) => {
			if (!selectedItem) return;
			const market = markets[selectedItem.value];
			setSpaceTypeOptions(getSpaceTypeOptions(selectedItem.value));
			change('market', market);

			if (!permissions?.[market.id].multifamilyAccess) {
				change('spaceTypes', undefined);
				if (state.dataSetType === DataSetType.MUFA) {
					change('attributeToPlot', undefined);
					change('dataSetType', DataSetType.COMMERCIAL);
				}
			}
			change(
				'attributeToPlotItems',
				getAttrOptionsForCreateDSForm(
					permissions?.[market.id].multifamilyAccess,
					salesCompsAnalytics2
				)
			);
		},
		[
			change,
			getSpaceTypeOptions,
			markets,
			permissions,
			state.dataSetType,
			salesCompsAnalytics2,
		]
	);

	const onChangeSpaceType = useCallback(
		// @ts-expect-error TS7006: Parameter 'newValue' implicitl...
		(newValue) => {
			const isSwitchFromCommercialToMufa =
				newValue[0]?.value === MUFA_OPTION.value &&
				(state.spaceTypes === undefined ||
					state.spaceTypes?.[0]?.value !== MUFA_OPTION.value);

			if (isSwitchFromCommercialToMufa) {
				change('dataSetType', DataSetType.MUFA);
			}

			change('spaceTypes', newValue.length === 0 ? [] : newValue);
		},
		[change, state.spaceTypes]
	);

	const onChangePropertyType = useCallback(
		// @ts-expect-error TS7006: Parameter 'newValue' implicitl...
		(newValue) => {
			change('propertyTypes', newValue.length === 0 ? [] : newValue);
		},
		[change]
	);

	const onChangeAttributeToPlot = useCallback(
		({ selectedItem }: SelectOnChangeParams) => {
			if (!selectedItem) return;
			change('attributeToPlot', String(selectedItem.value));

			// @ts-expect-error TS7053: Element implicitly has an 'any...
			change('dataSetType', attributeToDataSetType[selectedItem.value]);
		},
		[change]
	);

	const [titleFieldError, setTitleFieldError] = useState('');

	const onChangeTitle = useCallback(
		// @ts-expect-error TS7006: Parameter 'value' implicitly h...
		(value) => {
			change('title', value);

			const isValidObj = isValidTitle(value);
			setTitleFieldError(isValidObj.errorMsg);
		},
		[change]
	);

	// @ts-expect-error TS7006: Parameter 'e' implicitly has a...
	const onBlurTitle = (e) => {
		const isValidObj = isValidTitle(e.target.value);
		setTitleFieldError(isValidObj.errorMsg);
	};

	useEffect(() => {
		changeBulk(initialState);
	}, [changeBulk, initialState]);

	return {
		state,
		options: {
			spaceType: spaceTypeOptions,
			market: marketItems,
			attributeToPlot: attributeToPlotItems,
			titleFieldError: titleFieldError,
			salesAttributeToPlot: attributeToPlotOptions.sales,
		},
		handlers: {
			onChangeMarket,
			onChangeSpaceType,
			onChangePropertyType,
			onChangeAttributeToPlot,
			onChangeTitle,
			onBlurTitle,
		},
		meta: {
			formComplete: !isFormNotComplete,
			hasMufaAccess,
		},
	};
};
