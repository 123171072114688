import { AddToPortfolioDialog } from 'Components';
import { MapFilter } from 'Components/Filters/Fields/MapFilter/MapFilter';
import { ExportButtons } from 'Components/sidebarWidgets';
import { useMarketSelectorExpanded } from 'hooks/useMarketSelectorExpanded';
import 'Pages/SavedSearches/Components/SaveSearchMenu';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import MultiMarketSelector from '../../../Home/Sidebar/Components/MultiMarketSelector';
import '../styles/sidebar.nomodule.less';
import { FilterFields } from './FilterFields';
import { SearchSidebarProps } from '..';
import { useDispatch } from 'react-redux';
import { showSaveSearchMenu } from 'Pages/SavedSearches/actions';
import { usePermissions } from 'Pages/Login/reducers';

export default function Sidebar(props: SearchSidebarProps) {
	const { params, filters, compType, selection, onNewSearchClick } = props;
	const [isAddToPortfolioDialogOpen, setIsAddToPortfolioDialogOpen] =
		useState(false);
	const marketSelectorExpanded = useMarketSelectorExpanded();
	const permissions = usePermissions();

	const dispatch = useDispatch();

	const onShowSaveSearchMenu = useCallback(
		(event: React.MouseEvent) => {
			dispatch(showSaveSearchMenu(filters, event.target, true, compType));
		},
		[dispatch, filters, compType]
	);

	let href = '/search/leases/home';
	if (compType === 'sale') {
		href = '/search/sales/home';
	} else if (compType === 'property') {
		href = '/search/properties/home';
	}

	const addToPortfolioPropertyIds = useMemo(() => {
		return selection.map((s) => s.id);
	}, [selection]);

	return (
		<div>
			<MultiMarketWrap>
				<MultiMarketSelector
					compType={compType}
					filters={filters}
					permissions={permissions}
					user={props.user}
					marketSelectorExpanded={marketSelectorExpanded}
					resetFiltersOnApply={false}
				/>
			</MultiMarketWrap>
			<div style={{ display: marketSelectorExpanded ? 'none' : 'block' }}>
				<div className="search-sidebar-container">
					<a
						href={href}
						onClick={onNewSearchClick}
						className="button_button button_blue button_block button_large"
					>
						New Search
					</a>
				</div>
				<MapFilter key={props.compType} params={params} />
				<FilterFields />
				<ExportButtons
					data-qa-id="ExportButtons-2"
					compType={compType}
					filters={filters}
					selection={selection}
					showAddToPortfolioModal={() => {
						setIsAddToPortfolioDialogOpen(true);
					}}
					showSaveSearchMenu={onShowSaveSearchMenu}
				/>
				{isAddToPortfolioDialogOpen && (
					<AddToPortfolioDialog
						propertyIds={addToPortfolioPropertyIds}
						closeDialog={() => setIsAddToPortfolioDialogOpen(false)}
					/>
				)}
			</div>
		</div>
	);
}

const MultiMarketWrap = styled.div`
	.market-selector {
		margin: 20px 20px 0px 20px;
	}
`;
