import { usePropertyCompsetQuery } from 'api';
import { useMemo } from 'react';
import { Link } from 'router';
import { PropertyComp } from 'types';
import { createSearchLeasesUrl } from '../../../utils';
import { CompPopupActionButton } from './UI';
import { useMarketByNameOrId } from 'Pages/Login/reducers';
import { Spinner } from '@compstak/ui-kit';
import styled from 'styled-components';

type Props = {
	property: PropertyComp;
	closePopup: () => void;
};

export const GenerateCompSetButton = ({ property, closePopup }: Props) => {
	const { data, isFetching } = usePropertyCompsetQuery({ id: property.id });
	const addresses = data?.propertyCompSet.map(
		(comp) => comp.property.buildingAddressAndCity
	);

	const market = useMarketByNameOrId(property.marketId);

	const url = useMemo(() => {
		return createSearchLeasesUrl({
			market,
			address: addresses,
		});
	}, [market, addresses]);

	return (
		<Link to={url} onClick={closePopup}>
			<CompPopupActionButton
				data-qa-id="generate-related-comps-btn"
				className="blue single-child"
				disabled={isFetching}
			>
				{isFetching && (
					<SpinnerContainer>
						<Spinner size="s" />
					</SpinnerContainer>
				)}
				Generate Related Comps
			</CompPopupActionButton>
		</Link>
	);
};

const SpinnerContainer = styled.span`
	margin-right: 10px;
`;
