import React from 'react';

import styles from '../../repository.less';

// import SearchIcon from 'ui/Icons/Search';

import EmptyFolder from './EmptyFolder';
import saveBeforeOpening from '../Modals/SaveBeforeOpening';
import ChartRow from './ChartRow';
import { Project, Chart } from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';

type Props = {
	chartDraft: Chart;
	chart: Chart;
	redirect: (url: string) => void;
	markets: MarketsState;
	project: Project;
};

type State = {
	searchQuery: string;
	toggledChartId: number | null;
	sortByAttribute: string;
	reversed: boolean;
};

export default class ChartRepository extends React.Component<Props, State> {
	state = {
		searchQuery: '',
		toggledChartId: null,
		sortByAttribute: 'lastUpdated',
		reversed: false,
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleInput = (event) => {
		this.setState({ searchQuery: event.target.value });
	};

	handleChartToggle = (chartId: number) => {
		let toggledChartId;
		if (this.state.toggledChartId !== chartId) {
			toggledChartId = chartId;
		}
		// @ts-expect-error TS2322: Type 'number | undefined' is n...
		this.setState({ toggledChartId });
	};

	openNewChart = (newChart: Chart) => {
		if (this.props.chartDraft && this.props.chartDraft.dataSets.length) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.modalActions.showModal(saveBeforeOpening, {
				...this.props,
				newChart,
			});
		} else {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'chartBuilderActions' does not exist on t... Remove this comment to see the full error message
			this.props.chartBuilderActions.resetChartBuilder(
				newChart,
				this.props.markets
			);
			this.props.redirect('/analytics');
		}
	};

	// @ts-expect-error TS7006: Parameter 'attribute' implicit...
	changeSort = (attribute) => (event) => {
		event.preventDefault();
		if (this.state.sortByAttribute === attribute) {
			this.setState({ reversed: !this.state.reversed });
		} else {
			this.setState({
				sortByAttribute: attribute,
				reversed: false,
			});
		}
	};

	// @ts-expect-error TS7006: Parameter 'attribute' implicit...
	sortBy = (attribute, reversed) => (chart1: Chart, chart2: Chart) => {
		const reverse = reversed ? -1 : 1;
		if (attribute === 'title') {
			// @ts-expect-error TS18048: 'chart1.title' is possibly 'un...
			return chart1.title.localeCompare(chart2.title) * reverse;
		} else {
			return (
				// @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
				(new Date(chart2[attribute]) - new Date(chart1[attribute])) * reverse
			);
		}
	};

	render() {
		if (!this.props.project) {
			return (
				<div className={styles.emptyState}>
					<div className={styles.message}>
						Please select a project from the sidebar to view its contents
					</div>
				</div>
			);
		}

		const fieldNameMap = {
			name: 'title',
			dateCreated: 'dateCreated',
			dateModified: 'lastUpdated',
		};

		const fieldHeaders = ['name', 'dateCreated', 'dateModified'].map(
			(field, idx) => {
				const displayName = field
					.replace(/([A-Z])/g, ' $1')
					.replace(/^./, function (str) {
						return str.toUpperCase();
					});

				// @ts-expect-error TS7053: Element implicitly has an 'any...
				if (fieldNameMap[field] === this.state.sortByAttribute) {
					const arrowClass = this.state.reversed ? styles.up : styles.down;
					return (
						<span
							className={`${styles[field]} ${styles.sortingField}`}
							// @ts-expect-error TS7053: Element implicitly has an 'any...
							onClick={this.changeSort(fieldNameMap[field])}
							key={idx}
						>
							<span className={styles.fieldName}>{displayName}</span>
							<span className={`${styles.arrow} ${arrowClass}`} />
						</span>
					);
				} else {
					return (
						<span
							className={styles[field]}
							// @ts-expect-error TS7053: Element implicitly has an 'any...
							onClick={this.changeSort(fieldNameMap[field])}
							key={idx}
						>
							<span className={styles.fieldName}>{displayName}</span>
							<span className={`${styles.arrow} ${styles.down}`} />
						</span>
					);
				}
			}
		);

		const chartRows = this.props.project.charts
			.sort(this.sortBy(this.state.sortByAttribute, this.state.reversed))
			.map((chart, idx) => (
				<ChartRow
					chart={chart}
					project={this.props.project}
					colorClass={idx % 2 ? styles.darkRow : ''}
					key={chart.id}
					handleToggle={this.handleChartToggle}
					toggled={chart.id === this.state.toggledChartId}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'referenceData' does not exist on type 'R... Remove this comment to see the full error message
					referenceData={this.props.referenceData}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'chartBuilderActions' does not exist on t... Remove this comment to see the full error message
					resetChartBuilder={this.props.chartBuilderActions.resetChartBuilder}
					openNewChart={this.openNewChart}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjectActions' does not exist ... Remove this comment to see the full error message
					analyticsProjectActions={this.props.analyticsProjectActions}
					markets={this.props.markets}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsLastUpdated' does not exist on ... Remove this comment to see the full error message
					analyticsLastUpdated={this.props.analyticsLastUpdated}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
					user={this.props.user}
				/>
			));

		return (
			<div className={styles.table}>
				<div className={styles.header}>
					<span className={styles.title}>
						Projects {'>'} {this.props.project.name}
					</span>
					{/* <span className={styles.search}>
                    <SearchIcon width="16px" height="16px" />
                    <input
                        value={this.state.searchQuery}
                        onChange={this.handleInput}
                        placeholder="Search Charts"
                    />
                </span> */}
				</div>
				<div className={styles.fields}>
					<span className={styles.arrowBoxHeader} />
					{fieldHeaders}
				</div>
				{chartRows.length > 0 ? chartRows : <EmptyFolder />}
			</div>
		);
	}
}
