import React, { useCallback } from 'react';
import Modal from 'Singletons/Modal';
import { createFilters } from 'models/filters';
import styles from '../../styles/analytics.less';
import { Chart, DataSetType } from 'Pages/Analytics/analytics';
import { ChartBuilderActions } from 'Pages/Analytics/Builder/actions';
import { useReferenceData } from '../../../../../Login/reducers';
import { CreateNewDataSetV2 } from './CreateNewDataSetV2';
import { ModalActions } from 'Singletons/Modal/actions';
import { useMarkets } from 'hooks/useMarkets';

export const CreateNewDataSet = ({
	chartDraft,
	...props
}: {
	chartDraft: Chart;
	chartBuilderActions: ChartBuilderActions;
	modalActions: ModalActions;
}) => {
	const spaceTypes = useReferenceData().filterableSpaceTypes;
	const markets = useMarkets();

	const handleSubmit = useCallback(
		// @ts-expect-error TS7006: Parameter 'name' implicitly ha...
		(name, market, spacePropertyType, dataSetType, series) => {
			const dataSetFilters = (() => {
				if (dataSetType === DataSetType.MUFA) {
					return createFilters('mufa', {
						market,
					});
				}

				if (dataSetType === DataSetType.SALES) {
					return createFilters('sale', {
						market,
						buildingPropertyTypeId: spacePropertyType.value.ids || [
							spacePropertyType.value.id,
						],
					});
				}

				return createFilters('lease', {
					market,
					// The "Other" spacetype has two ids, one for "Other" and one for "Unknown"
					// All other spacetypes will only have one id
					spaceTypeId: spacePropertyType.value.ids || [
						spacePropertyType.value.id,
					],
				});
			})();

			props.chartBuilderActions.addDataSet(
				chartDraft,
				name,
				dataSetFilters,
				markets,
				series,
				{ dataSetType }
			);
			props.modalActions.hideModal();
		},
		[chartDraft, markets, props.chartBuilderActions, props.modalActions]
	);

	const handleBack = useCallback(() => {
		props.modalActions.popModal();
	}, [props.modalActions]);

	return (
		<CreateNewDataSetV2
			{...props}
			chartDraft={chartDraft}
			markets={markets}
			// @ts-expect-error TS2322: Type '{ chartDraft: Chart; mar...
			spaceTypes={spaceTypes}
			onSubmit={handleSubmit}
			onBack={handleBack}
		/>
	);
};

Modal.addComponent({
	id: 'analytics-create-new-dataset',
	Component: CreateNewDataSet,
	className: styles.analyticsModal,
});

export default 'analytics-create-new-dataset';
