import styled, { css } from 'styled-components';
import IconThumbUp from '../../ui/svg_icons/thumb_up.svg';
import IconThumbDown from '../../ui/svg_icons/thumb_down.svg';
import IconDownArrow from '../../ui/svg_icons/arrow_bottom.svg';

import { Button, Chip, Flex, Spinner } from '@compstak/ui-kit';
import { ThumbStatus } from 'types/marketSummary';
import { SectionContainer as SectionContainerOriginal } from 'ExchangeDashboard/UI';

export const SectionContainer = styled(SectionContainerOriginal)`
	margin-top: 1.5rem;
`;

export const HeaderFlex = styled(Flex)`
	flex-wrap: wrap;
	flex-direction: column;
	align-items: flex-start;

	h6 {
		color: ${({ theme }) => theme.colors.gray.n100};
		font-size: 0.875em;
		line-height: 24px;
		font-weight: 350;
		text-transform: none;
	}
`;

export const StyledAnalysis = styled.div`
	margin: 1.5rem 0;
`;

export const StyledChip = styled(Chip)`
	position: relative;
	bottom: 10px;

	font-size: 10px;
	color: ${({ theme }) => theme.colors.white.white};

	padding: 4px 8px;
	border-radius: 20px;
	background-color: ${({ theme }) => theme.colors.blue.blue400};

	font-weight: 350;
`;

const sharedThumbStyle = css`
	cursor: pointer;
`;

export const StyledIconThumbUp = styled(IconThumbUp)<{
	thumbStatus: ThumbStatus;
}>`
	${sharedThumbStyle}

	path {
		fill: ${({ thumbStatus, theme }) =>
			thumbStatus ? theme.colors.blue.blue400 : 'none'};
		stroke: ${({ thumbStatus, theme }) =>
			thumbStatus ? theme.colors.blue.blue400 : theme.colors.gray.n100};
	}
`;

export const StyledIconThumbDown = styled(IconThumbDown)<{
	thumbStatus: ThumbStatus;
}>`
	${sharedThumbStyle}

	path {
		fill: ${({ thumbStatus, theme }) =>
			thumbStatus === false ? theme.colors.blue.blue400 : 'none'};
		stroke: ${({ thumbStatus, theme }) =>
			thumbStatus === false
				? theme.colors.blue.blue400
				: theme.colors.gray.n100};
	}
`;

export const ReadMoreButton = styled.button`
	user-select: none;

	background: unset;
	border: unset;

	font-weight: 325;

	color: ${({ theme }) => theme.colors.blue.blue400};

	div {
		min-width: 92px;
	}

	svg {
		width: 11px;
		height: 7px;

		fill: ${({ theme }) => theme.colors.blue.blue400};
	}
`;

export const SpaceTypesButtonsContainer = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.gray.gray300};
	display: flex;
	place-self: flex-start;
`;

export const SpaceTypeButton = styled(Button)`
	border: unset;
	border-radius: unset;
	width: 90px;
	height: 30px;
	font-weight: 400;
`;

export const StyledIconDownArrow = styled(IconDownArrow)<{
	isCollapsed: boolean;
}>`
	transition: 0.5s;
	transform: ${({ isCollapsed }) =>
		isCollapsed ? 'rotateZ(180deg)' : 'rotateZ(0)'};
`;

export const ModalContainer = styled.div`
	.textarea-label {
		margin-top: 16px;
		font-weight: 400;
	}

	label {
		cursor: pointer;
	}
`;

export const FooterSpan = styled.span`
	cursor: pointer;

	color: ${({ theme }) => theme.colors.blue.blue400};
	text-decoration: underline;
`;

export const StyledSpinner = styled(Spinner)`
	z-index: 0;
`;
