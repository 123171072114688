import { useState } from 'react';
import {
	CompstakEstimate,
	useCompstakEstimatesData,
} from 'Pages/PropertyPageV3/hooks/useCompstakEstimatesData';

export const useEditableCompstakEstimates = () => {
	const compstakEstimatesData = useCompstakEstimatesData();

	const [editableCompstakEstimates, setEditableCompstakEstimates] =
		useState<EditableCompstakEstimates>({
			visibleFields: {
				startingRent: true,
				effectiveRent: true,
			},
			...compstakEstimatesData,
		});

	return {
		editableCompstakEstimates,
		setEditableCompstakEstimates,
	};
};

type EditableCompstakEstimates = {
	visibleFields: Record<CompstakEstimate, boolean>;
} & ReturnType<typeof useCompstakEstimatesData>;
