import { FormSelect, FormSelectProps } from '@compstak/ui-kit';
import { ModalParagraph } from 'Components/Modals/common/UI';
import styled from 'styled-components';

type ChartBuilderMarketFormSelectProps = FormSelectProps;

export const ChartBuilderMarketFormSelect = (
	props: ChartBuilderMarketFormSelectProps
) => {
	return (
		<Container>
			<ModalParagraph>
				Chartbuilder currently only supports data from a single market per
				dataset. Please select a market.
			</ModalParagraph>
			<FormSelect
				data-qa-id="select-market"
				label="Market"
				placeholder="Select Market"
				{...props}
			/>
		</Container>
	);
};

const Container = styled.div`
	margin-bottom: 0.75rem;
`;
