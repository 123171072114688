import React from 'react';
import { FilterCategory } from './FilterCategory';
import { useUserQuery } from 'api';
import { useShouldHideMyCompsForExchangePlus } from '../FilterFields';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { usePermissions } from 'Pages/Login/reducers';
import { DateCreatedFilter } from 'Components/Filters/Fields/DateCreatedFilter/DateCreatedFilter';
import { HiddenFilter } from 'Components/Filters/Fields/HiddenFilter/HiddenFilter';
import { PartialFilter } from 'Components/Filters/Fields/PartialFilter/PartialFilter';
import { MyCompsFilter } from 'Components/Filters/Fields/MyCompsFilter/MyCompsFilter';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { UndesirableResults } from 'Pages/Analytics/Builder/Components/ExpandedFilters';

export const CompFilterCategory = () => {
	const { data: user } = useUserQuery();
	const shouldHideMyCompsFilter = useShouldHideMyCompsForExchangePlus();
	const permissions = usePermissions();
	const { context, compType, filters } = useFilterFieldContext();

	const compPermission =
		compType === 'sale' ? 'ownsAllSalesComps' : 'ownsAllLeaseComps';

	return (
		<FilterCategory category="Comp">
			<DateCreatedFilter>
				{context === 'analytics' && <UndesirableResults />}
			</DateCreatedFilter>

			{!shouldHideMyCompsFilter &&
				getFiltersMarkets(filters).some(
					(market) => !permissions[market.id]?.[compPermission]
				) && <MyCompsFilter />}

			{user.userType === 'admin' && (
				<>
					<HiddenFilter />
					<PartialFilter />
				</>
			)}
		</FilterCategory>
	);
};
