import { MapImage, useGoogleStaticStreetViewURL } from '@compstak/maps';
import { Flex, Spinner } from '@compstak/ui-kit';
import { point } from '@turf/helpers';
import {
	KeysToDisplay,
	MufaKeysToDisplay,
	PropertyTitle,
	getPropertyAttributesKeys,
} from 'Pages/PropertyPageV3/MainInfo';
import { PropertyLease, useIsMufaProperty } from 'api';
import styled from 'styled-components';
import { PropertyComp } from 'types';
import { RealPage } from '../../RealPage';
import { PropertyEditPage } from '../PropertyEditPage';
import { EditableCompstakEstimates } from './EditableCompstakEstimates';
import { EditableKeyInfoItems } from './EditableKeyInfoItems';
import { EditableLeaseSnapshot } from './EditableLeaseSnapshot';
import { EditablePropertyAttributes } from './EditablePropertyAttributes';
import { useEditableCompstakEstimates } from './useEditableCompstakEstimates';
import { useEditableOverview } from './useEditableOverview';

export const EditOverviewPage = ({
	editableOverviewFields,
	setEditableOverviewFields,
	editableCompstakEstimates,
	setEditableCompstakEstimates,
	property,
	isStreetViewEnabled,
	isLoadingStreetView,
	oneLockedActiveLease,
}: ReturnType<typeof useEditableOverview> &
	ReturnType<typeof useEditableCompstakEstimates> & {
		property: PropertyComp;
		isStreetViewEnabled: boolean;
		isLoadingStreetView: boolean;
		oneLockedActiveLease: undefined | boolean | PropertyLease;
	}) => {
	const url = useGoogleStaticStreetViewURL({
		width: MAP_SIZE.width,
		height: MAP_SIZE.height,
		lat: property?.geoPoint.lat,
		lon: property?.geoPoint.lon,
		pitch: 30,
		source: 'outdoor',
	});

	const { data: isMufa } = useIsMufaProperty({ propertyId: property.id });

	const propertyAttributes = getPropertyAttributesKeys(
		isMufa,
		property.buildingPropertyType
	);

	const setIsVisible = (key: KeysToDisplay | MufaKeysToDisplay) => () => {
		setEditableOverviewFields((s) => ({
			...s,
			visibleFields: {
				...s.visibleFields,
				[key]: !s.visibleFields[key],
			},
		}));
	};
	const getIsVisible = (key: KeysToDisplay | MufaKeysToDisplay) => {
		return editableOverviewFields.visibleFields[key];
	};

	return (
		<PropertyEditPage pageKey="overview">
			<PropertyTitle property={property} />
			<Flex direction="row" gap="24px" alignItems="start">
				<ImagesContainer>
					{isLoadingStreetView ? (
						<Spinner />
					) : (
						isStreetViewEnabled && (
							<img
								width={MAP_SIZE.width}
								height={MAP_SIZE.height}
								src={url.href}
								alt="Google street view"
							/>
						)
					)}

					<MapImage
						width={MAP_SIZE.width}
						height={MAP_SIZE.height}
						position={{
							type: 'center',
							latitude: property.geoPoint.lat,
							longitude: property.geoPoint.lon,
						}}
						pinPoints={[point([property.geoPoint.lon, property.geoPoint.lat])]}
					/>
				</ImagesContainer>
				<Flex direction="column" gap="24px" alignItems="flex-start">
					<EditableKeyInfoItems
						editableOverviewFields={editableOverviewFields}
						setEditableOverviewFields={setEditableOverviewFields}
						setIsVisible={setIsVisible}
						getIsVisible={getIsVisible}
						isMufa={isMufa}
					/>
					<EditablePropertyAttributes
						propertyAttributes={propertyAttributes}
						editableOverviewFields={editableOverviewFields}
						setEditableOverviewFields={setEditableOverviewFields}
						setIsVisible={setIsVisible}
						getIsVisible={getIsVisible}
						isMufa={isMufa}
					/>
					{isMufa && (
						<Flex justifyContent="flex-end" style={{ width: '100%' }}>
							<RealPage />
						</Flex>
					)}
					{!!oneLockedActiveLease === false && <EditableLeaseSnapshot />}
					<EditableCompstakEstimates
						editableCompstakEstimates={editableCompstakEstimates}
						setEditableCompstakEstimates={setEditableCompstakEstimates}
					/>
				</Flex>
			</Flex>
		</PropertyEditPage>
	);
};

const MAP_SIZE = {
	width: 416,
	height: 272,
};

const ImagesContainer = styled.div`
	width: min-content;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-top: 8px;
`;
