import { validateEmail, validatePhone } from 'util/validate';
import { checkStringLength } from 'util/checkStringLength';
import produce from 'immer';
import React from 'react';
import styled from 'styled-components';
import { useReferColleguesV2Mutation } from 'api/referrals/useReferColleguesV2Mutation';
import { useModal } from 'providers/ModalProvider';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { Button } from '@compstak/ui-kit';

type FormData = {
	firstNameReferralForm: string;
	lastNameReferralForm: string;
	emailReferralForm: string;
	phoneReferralForm?: string;
	firmReferralForm?: string;
}[];

const initialFormData = [
	{
		firstNameReferralForm: '',
		lastNameReferralForm: '',
		emailReferralForm: '',
	},
];

export const ReferColleguesModal = () => {
	const [modalState, setModalState] = React.useState<
		'form' | 'thankYou' | 'error'
	>('form');
	const [formData, setFormData] = React.useState<FormData>(initialFormData);
	const {
		mutate: referCollegues,
		isLoading,
		error,
	} = useReferColleguesV2Mutation({
		onSuccess: () => {
			setModalState('thankYou');
		},
	});
	const { closeModal } = useModal();

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		event.preventDefault();
		const { name, value } = event.target;
		setFormData((prevState) =>
			produce(prevState, (draftState) => {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				draftState[index][name] = value;
			})
		);
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	const handleAddReferral = (event) => {
		event.preventDefault();
		setFormData((prevData) => prevData.concat(initialFormData));
		return false;
	};
	const handleRemove = (index: number) => {
		setFormData((prevData) =>
			produce(prevData, (draft) => {
				draft.splice(index, 1);
				return;
			})
		);
	};
	const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
		ev.preventDefault();
		referCollegues(
			formData.map((field) => ({
				firstName: field.firstNameReferralForm,
				lastName: field.lastNameReferralForm,
				email: field.emailReferralForm,
				...(field.phoneReferralForm && { phone: field.phoneReferralForm }),
				...(field.firmReferralForm && { firm: field.firmReferralForm }),
			}))
		);
	};

	if (error) {
		return (
			<>
				<ModalTitle>Server Error</ModalTitle>
				<ModalParagraph>
					Request cannot be processed at the moment.
				</ModalParagraph>
				<ModalButtons>
					<ModalButton
						data-qa-id="refer-close-button"
						onClick={() => closeModal()}
						type="button"
					>
						Close
					</ModalButton>
				</ModalButtons>
			</>
		);
	}

	switch (modalState) {
		case 'form':
			return (
				<>
					<FormTitle>
						Introduce a colleague to CompStak and receive 150 free credits per
						referral!
					</FormTitle>
					<FormParagraph>
						Please provide your colleague’s contact information.
					</FormParagraph>
					<form onSubmit={handleSubmit}>
						{formData.map((fields, index) => (
							<>
								<Input>
									<label>
										<div>First Name</div>
										<input
											name="firstNameReferralForm"
											onChange={(event) => handleChange(event, index)}
											placeholder="First Name"
											value={fields.firstNameReferralForm}
										/>
									</label>
									<label>
										<div>Last Name</div>
										<input
											name="lastNameReferralForm"
											onChange={(event) => handleChange(event, index)}
											placeholder="Last Name"
											value={fields.lastNameReferralForm}
										/>
									</label>
									<label>
										<div>Email Address</div>
										<input
											name="emailReferralForm"
											onChange={(event) => handleChange(event, index)}
											placeholder="Email"
											value={fields.emailReferralForm}
										/>
									</label>
									<label>
										<div>
											Phone <span>(optional)</span>
										</div>
										<input
											name="phoneReferralForm"
											type="tel"
											onChange={(event) => handleChange(event, index)}
											placeholder="Phone (optional)"
											value={fields.phoneReferralForm ?? ''}
										/>
									</label>
									<label>
										<div>
											Firm <span>(optional)</span>
										</div>
										<input
											name="firmReferralForm"
											onChange={(event) => handleChange(event, index)}
											placeholder="Firm (optional)"
											value={fields.firmReferralForm ?? ''}
										/>
										{index !== 0 && (
											<RemoveButton
												onClick={() => handleRemove(index)}
												variant="ghost"
												type="button"
											>
												Remove
											</RemoveButton>
										)}
									</label>
								</Input>
							</>
						))}
						<AddMemberButton
							onClick={handleAddReferral}
							data-qa-id="team-invite-add-another"
							variant="ghost"
							type="button"
						>
							<span>+&nbsp;</span>
							Add another team member
						</AddMemberButton>
						<ModalButtons>
							<ModalButton
								data-qa-id="refer-cancel-button"
								onClick={() => closeModal()}
								variant="secondary"
								type="button"
							>
								Cancel
							</ModalButton>
							<ModalButton
								type="submit"
								data-qa-id="refer-submit-button"
								disabled={formData.some((field) => {
									return (
										!checkStringLength(field.firstNameReferralForm) ||
										!checkStringLength(field.lastNameReferralForm) ||
										!checkStringLength(field.emailReferralForm, 256) ||
										!validateEmail(field.emailReferralForm) ||
										(!!field.firmReferralForm &&
											!checkStringLength(field.firmReferralForm, 128)) ||
										(!!field.phoneReferralForm &&
											!validatePhone(field.phoneReferralForm) &&
											!checkStringLength(field.phoneReferralForm, 128))
									);
								})}
								isLoading={isLoading}
							>
								Submit
							</ModalButton>
						</ModalButtons>
					</form>
					<FootNote>
						*Credits applied to your account when member account confirmed.
						Referrals do not apply to anyone with an existing member account.
					</FootNote>
				</>
			);
		case 'thankYou':
			return (
				<>
					<ModalTitle>Thank you for the referral!</ModalTitle>
					<ModalParagraph>
						You'll receive your bonus credits once the member you've referred
						signs up for CompStak.
					</ModalParagraph>
					<ModalButtons>
						<ModalButton
							data-qa-id="refer-close-button"
							type="button"
							onClick={() => closeModal()}
						>
							Close
						</ModalButton>
					</ModalButtons>
				</>
			);
		default:
			return null;
	}
};

const FormTitle = styled(ModalTitle)`
	font-size: 1.5rem;
`;

const FormParagraph = styled(ModalParagraph)`
	margin-bottom: 0;
	padding-left: 1rem;
`;

const Input = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 1rem;

	&:not(:first-of-type) {
		background-color: #f1f2f5;
	}

	label {
		display: flex;
		flex-direction: column;
		font-size: 1rem;
		font-weight: 500;

		input {
			border-radius: 3px;
			border: 1px solid #dfe1e7;
			padding: 0.75rem;
			width: 100%;
			margin: 0;
		}
	}
`;

const RemoveButton = styled(Button)`
	color: ${({ theme }) => theme.colors.red.red500};
	font-weight: 400;
	margin-top: 0.5rem;
`;

const AddMemberButton = styled(Button)`
	font-weight: 400;
	margin-top: 0.5rem;
`;

const FootNote = styled.div`
	font-size: 0.6875rem;
	margin-top: 1rem;
`;
