import React, { useCallback } from 'react';
import {
	MarketTrendFilters,
	MarketTrendMetricFilter,
	MarketTrendTimespanFilter,
	MarketTrendMovingAverageFilter,
} from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { Dropdown, DropdownItem } from './Dropdown';
import { Styled } from './styled';

export const metricDropdownData = [
	{ id: 'effectiveRent', value: 'Effective Rent' },
	{ id: 'startingRent', value: 'Starting Rent' },
	{ id: 'freeMonths', value: 'Free Rent' },
	{ id: 'ti', value: 'Tenant Improvement' },
	{ id: 'leaseTerm', value: 'Lease Term' },
];
const timeSpanDropdownData = [
	{ id: 15, value: '15 years' },
	{ id: 10, value: '10 years' },
	{ id: 5, value: '5 years' },
	{ id: 3, value: '3 years' },
	{ id: 1, value: '1 year' },
];
const movingAverageDropdownData = [
	{ id: 3, value: '3 month' },
	{ id: 6, value: '6 month' },
	{ id: 12, value: '12 month' },
];

type MarketTrendFiltersProps = {
	marketDropdownData: DropdownItem[];
	filters: MarketTrendFilters;
	setMarketTrendFilters: React.Dispatch<
		React.SetStateAction<MarketTrendFilters>
	>;
};

export const MarketTrendFiltersComponent = ({
	marketDropdownData,
	filters,
	setMarketTrendFilters,
}: MarketTrendFiltersProps) => {
	const onChangeMarket = useCallback(
		(marketId: number) =>
			setMarketTrendFilters((state) => ({ ...state, marketId })),
		[setMarketTrendFilters]
	);
	const onChangeMetric = useCallback(
		(metric: MarketTrendMetricFilter) =>
			setMarketTrendFilters((state) => ({ ...state, metric })),
		[setMarketTrendFilters]
	);
	const onChangeTimeSpan = useCallback(
		(timeSpan: MarketTrendTimespanFilter) =>
			setMarketTrendFilters((state) => ({ ...state, timeSpan })),
		[setMarketTrendFilters]
	);
	const onChangeMovingAverage = useCallback(
		(movAverage: MarketTrendMovingAverageFilter) =>
			setMarketTrendFilters((state) => ({ ...state, movAverage })),
		[setMarketTrendFilters]
	);

	return (
		<Styled.Container data-html2canvas-ignore>
			{marketDropdownData.length > 1 && (
				<Dropdown
					title="market"
					items={marketDropdownData}
					// @ts-expect-error TS2322: Type '(marketId: number) => vo...
					onSelect={onChangeMarket}
					activeItemId={filters.marketId}
					defaultLabel="Select Market"
				/>
			)}
			<Dropdown
				title="metric"
				items={metricDropdownData}
				// @ts-expect-error TS2322: Type '(metric: MarketTrendMetr...
				onSelect={onChangeMetric}
				activeItemId={filters.metric}
			/>
			<Dropdown
				title="timespan"
				items={timeSpanDropdownData}
				// @ts-expect-error TS2322: Type '(timespan: MarketTrendTi...
				onSelect={onChangeTimeSpan}
				activeItemId={filters.timeSpan}
			/>
			<Dropdown
				title="mov. average"
				items={movingAverageDropdownData}
				// @ts-expect-error TS2322: Type '(movAverage: MarketTrend...
				onSelect={onChangeMovingAverage}
				activeItemId={filters.movAverage}
			/>
		</Styled.Container>
	);
};
