import { RowGridContainer } from '@compstak/ui-kit';
import { SubmarketRentDistribution } from 'Components/CommercialSubmarketTrends/SubmarketRentDistribution';
import { SubmarketRentTrend } from 'Components/CommercialSubmarketTrends/SubmarketRentTrend';
import {
	SubmarketLeaseTradeOut,
	SubmarketMarketTrend,
	SubmarketOccupancy,
	SubmarketSupply,
} from 'Components/MufaSubmarket/blocks';
import { RealPage } from 'Pages/PropertyPageV3/RealPage';
import { useSubmarketTrendsContext } from 'Pages/PropertyPageV3/SubmarketTrendsTab/SubmarketTrendsProvider';
import { CommonChartProps } from 'types/propertyTrendCharts';
import { UseSelectionStateReturn } from 'utils';
import { PropertyEditPage } from '../PropertyEditPage';
import { EditPageSectionTitle } from '../UI';
import { ExportPdfData } from '../useExportPdfData';
import { EditableSubmarketRecentLeasesTable } from './EditableSubmarketRecentLeases';
import { useEditableSubmarketRecentLeases } from './useEditableSubmarketRecentLeases';

type Props = {
	recentLeasesSelection: UseSelectionStateReturn;
	data: ExportPdfData;
} & ReturnType<typeof useEditableSubmarketRecentLeases>;

const pdfChartOptions: CommonChartProps = {
	showSaveDataMenu: false,
	showAnimation: false,
	shouldShowTooltip: false,
};

export const commercialTrendChartsId = 'commercialTrendCharts';
export const mufaTrendChartsId1 = 'mufaTrendChartsId1';
export const mufaTrendChartsId2 = 'mufaTrendChartsId2';

export const EditSubmarketTrends = ({
	editableSubmarketRecenetLeasesColumns,
	editableSubmarketRecenetLeasesRow,
	setEditableSubmarketRecenetLeasesColumns,
	setEditableSubmarketRecenetLeasesRow,
	recentLeasesSelection,
	data,
}: Props) => {
	const {
		commercialTrendsFilters,
		setCommercialTrendsFilters,
		commercialTrendsData,
		mufaTrendsData,
		mufaTrendsFilters,
		setMufaTrendsFilters,
		trendsToShow,
	} = useSubmarketTrendsContext();

	const property = data.property;
	const address = property.buildingAddressAndCity;

	return (
		<PropertyEditPage pageKey="submarketTrends">
			{trendsToShow.mufaTrends && (
				<>
					<EditPageSectionTitle>
						Multifamily Submarket Trends - {property.submarket}
					</EditPageSectionTitle>
					<div id={mufaTrendChartsId1}>
						<RowGridContainer>
							<SubmarketMarketTrend
								address={address}
								filters={mufaTrendsFilters}
								handleFilterChange={setMufaTrendsFilters}
								submarketMarketTrend={mufaTrendsData.submarketMarketTrend}
								{...pdfChartOptions}
							/>
							<SubmarketLeaseTradeOut
								address={address}
								filters={mufaTrendsFilters}
								handleFilterChange={setMufaTrendsFilters}
								submarketLeaseTradeOut={mufaTrendsData.submarketLeaseTradeOut}
								{...pdfChartOptions}
							/>
						</RowGridContainer>
					</div>
					<div id={mufaTrendChartsId2}>
						<RowGridContainer>
							<SubmarketOccupancy
								address={address}
								filters={mufaTrendsFilters}
								handleFilterChange={setMufaTrendsFilters}
								submarketOccupancy={mufaTrendsData.submarketOccupancy}
								{...pdfChartOptions}
							/>
							<SubmarketSupply
								address={address}
								filters={mufaTrendsFilters}
								handleFilterChange={setMufaTrendsFilters}
								submarketSupply={mufaTrendsData.submarketSupply}
								{...pdfChartOptions}
							/>
						</RowGridContainer>
					</div>
					<RealPage />
				</>
			)}
			{trendsToShow.commercialTrends && (
				<>
					<EditPageSectionTitle>
						Submarket Trends - {property.submarket}
					</EditPageSectionTitle>
					<div id={commercialTrendChartsId}>
						<RowGridContainer>
							<SubmarketRentTrend
								address={property.buildingAddress ?? ''}
								rentTrend={commercialTrendsData.rentTrend}
								filters={commercialTrendsFilters}
								handleFilterChange={setCommercialTrendsFilters}
								{...pdfChartOptions}
							/>
							<SubmarketRentDistribution
								address={property.buildingAddress ?? ''}
								marketId={property.marketId}
								rentDistribution={commercialTrendsData.rentDistribution}
								filters={commercialTrendsFilters}
								handleFilterChange={setCommercialTrendsFilters}
								{...pdfChartOptions}
							/>
						</RowGridContainer>
					</div>
				</>
			)}
			<EditableSubmarketRecentLeasesTable
				submarket={property.submarket}
				editableSubmarketRecenetLeasesColumns={
					editableSubmarketRecenetLeasesColumns
				}
				editableSubmarketRecenetLeasesRow={editableSubmarketRecenetLeasesRow}
				setEditableSubmarketRecenetLeasesColumns={
					setEditableSubmarketRecenetLeasesColumns
				}
				setEditableSubmarketRecenetLeasesRow={
					setEditableSubmarketRecenetLeasesRow
				}
				recentLeasesSelection={recentLeasesSelection}
				data={data}
			/>
		</PropertyEditPage>
	);
};
