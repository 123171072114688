import { generatePath } from 'react-router';
import { useParams } from './migration';

export const route = <Params = void, Data = Record<string, any>>({
	path,
	toHref,
	isModal = false,
	isLegacyModal = true,
	data,
}: RouteConfigOptions<Params, Data>) => {
	return {
		path,
		isModal,
		isLegacyModal,
		data: data ?? {},
		toHref: toHref ?? ((params: Params) => generatePath(path, params ?? {})),
		useParams: () => {
			const params = useParams();
			const result = { ...params };

			// convert numeric params to numbers automatically
			Object.entries(result).forEach(([key, value]) => {
				if (isNumeric(value ?? '')) {
					// @ts-expect-error please shut up
					result[key] = Number(value);
				}
			});
			return result as Params;
		},
	};
};

const isNumeric = (str: string) => {
	if (typeof str != 'string') return false;
	return !isNaN(+str) && !isNaN(parseFloat(str));
};

type RouteConfigOptions<Params, Data = Record<string, any>> = {
	path: string;
	toHref?: (params: Params) => string;
	/** @default false */
	isModal?: boolean;
	/** @default true */
	isLegacyModal?: boolean;
	data?: Data;
};
