import { Button, defaultTheme } from '@compstak/ui-kit';
import { Document } from '@react-pdf/renderer';
import { ModalCloseButton, ModalCloseIcon } from 'Components/Modals/common/UI';
import { useFilteredTenantIndustries } from 'ExchangeDashboard/TenantIndustryMap';
import { useExchangeDashboardTenantIndustryDistribution } from 'ExchangeDashboard/hooks/tenantIndustryDistribution/useExchangeDashboardTenantIndustryDistribution';
import { useUserQuery } from 'api';
import { EditContent } from 'pdf/UI';
import styled, { ThemeProvider } from 'styled-components';
import { useDashboardExportContext } from './DashboardExportProvider';
import { DynamicEditPages } from './DynamicEditPages';
import { DynamicPdfPages } from './DynamicPdfPages';
import { ExportPdfButton } from './ExportPdfButton';
import { useExportPdfState } from './ExportPdfProvider';
import { EditHeaderPage } from './HeaderPage/EditHeaderPage';
import { HeaderPage } from './HeaderPage/HeaderPage';
import { useMarketRentSectionText } from './MarketRentSection/useMarketRentSectionText';
import { useTenantIndustryMapDescription } from './TenantIndustryMap/useTenantIndustryMapDescription';

type Props = {
	closeDialog: () => void;
	onStepBack: () => void;
};

export const StepTwoV2 = ({ closeDialog, onStepBack }: Props) => {
	const {
		exportPdfState,
		marketText,
		submarketText,
		propertyTypesText,
		createdOnText,
		imageUrls,
	} = useExportPdfState();
	const { data: user } = useUserQuery();
	const { selectedComponents } = useDashboardExportContext();

	const { title: marketRentTitle, description: marketRentDescription } =
		useMarketRentSectionText();

	const { data: tenantIndustryDistributionData } =
		useExchangeDashboardTenantIndustryDistribution();

	const tenantIndustryMapData = useFilteredTenantIndustries();

	const tenantIndustryMapDescription = useTenantIndustryMapDescription();

	return (
		<div style={{ textAlign: 'left' }}>
			<Toolbar>
				<Button variant="ghost" onClick={onStepBack} data-qa-id="back">
					Back
				</Button>
				<RightContainer>
					<ExportPdfButton
						pdfDocument={
							<ThemeProvider theme={defaultTheme}>
								<Document>
									<HeaderPage
										exportPdfState={exportPdfState}
										user={user}
										marketText={marketText}
										submarketText={submarketText}
										propertyTypesText={propertyTypesText}
										createdOnText={createdOnText}
									/>
									<DynamicPdfPages
										selectedComponents={selectedComponents}
										user={user}
										exportPdfState={exportPdfState}
										pdfSectionProps={{
											marketRentHeatMap: {
												title: marketRentTitle,
												description: marketRentDescription,
												url: imageUrls.marketRentHeatMap,
											},
											tenantIndustryDistribution: {
												url: imageUrls.tenantIndustryDistribution,
												data: tenantIndustryDistributionData,
											},
											tenantIndustryMap: {
												url: imageUrls.tenantIndustryMap,
												data: tenantIndustryMapData,
												description: tenantIndustryMapDescription,
											},
										}}
									/>
								</Document>
							</ThemeProvider>
						}
					/>
					<ModalCloseButton onClick={closeDialog} style={{ position: 'unset' }}>
						<ModalCloseIcon />
					</ModalCloseButton>
				</RightContainer>
			</Toolbar>
			<EditContent>
				<EditHeaderPage
					marketText={marketText}
					submarketText={submarketText}
					propertyTypesText={propertyTypesText}
					createdOnText={createdOnText}
				/>
				<DynamicEditPages />
			</EditContent>
		</div>
	);
};

const Toolbar = styled.div`
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: ${(p) => p.theme.colors.white.white};
	padding: 1rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-bottom: 0.5rem;
	display: flex;
	align-items: center;
`;

const RightContainer = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 0.5rem;
	margin-left: auto;
`;
