import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Spinner } from '@compstak/ui-kit';
import CompPanel from './CompPanel';
import { CompNotFound } from '@compstak/ui-kit';
import { PrevNextLeaseHeader } from './PrevNextLeaseHeader';
import { BackToContainer, UnderlinedLink } from 'ui/PrevNextHeaderUI';
import ArrowRightIcon from '../../../../ui/svg_icons/arrow_right.svg';
import ArrowLeftIcon from '../../../../ui/svg_icons/arrow_left.svg';

import modalStyles from 'Singletons/Modal/modal.less';
import spinner from 'ui/styles/spinner.less';
import styled from 'styled-components';
import { Link, useParams, useRouteContext } from 'router';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { mapDispatchToProps, mapStoreToProps } from '..';
import { useMarkets } from 'hooks/useMarkets';

type LeaseWrapperProps = ReturnType<typeof mapStoreToProps> &
	ReturnType<typeof mapDispatchToProps>;

const LeaseWrapper = (_props: LeaseWrapperProps) => {
	const [buttonsDisabled, setButtonsDisabled] = useState(false);
	const [transitionType, setTransitionType] = useState('modal-transition');
	const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
	const prevRef = useRef<HTMLAnchorElement>();
	const nextRef = useRef<HTMLAnchorElement>();

	const featureFlags = useFeatureFlags();
	const markets = useMarkets();
	const market = markets[_props.lease?.marketId];

	const props = { ..._props, markets, market, featureFlags };

	const { router, location } = useRouteContext();
	const params = useParams<{ id: string }>() as { id: string };

	const handleButtonClick = () => {
		setButtonsDisabled(true);
		const newTimer = setTimeout(() => {
			setButtonsDisabled(false);
		}, 300);
		setTimer(newTimer);
	};

	const index = location.state ? parseInt(location.state.index) : 0;

	useEffect(() => {
		const keyListener = (event: KeyboardEvent) => {
			if (
				event.target instanceof HTMLInputElement ||
				event.target instanceof HTMLTextAreaElement
			) {
				return;
			}
			switch (event.keyCode) {
				case 39:
					if (index === props.search?.total - 1) {
						return;
					}
					next();
					break;
				case 37:
					if (index === 0) {
						return;
					}
					prev();
					break;
				case 27:
					event.stopPropagation();
					location.state ? router.goBack() : router.push('/search/leases/home');
					break;
			}
		};

		document.body.addEventListener('keydown', keyListener, true);

		return () => {
			props.pageActions.clear();
			document.body.removeEventListener('keydown', keyListener, true);
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [index]);

	useEffect(() => {
		props.pageActions.loadLease(parseInt(params.id));

		if (location.state?.queryString && !props.search && !props.searchLoading) {
			props.pageActions.createSearch(props.markets, location.state.queryString);
		} else if (props.search) {
			props.pageActions.fetch(props.search, index, props.lowestLoaded);
		}
	}, [params.id, location.state?.queryString, props.searchLoading]);

	const next = () => {
		setNextClass();
		nextRef.current?.click();
	};

	const prev = () => {
		setPrevClass();
		prevRef.current?.click();
	};

	const setPrevClass = (event?: React.MouseEvent) => {
		if (event && (event.metaKey || event.button !== 0)) {
			return;
		}
		setTransitionType('modal-backwards');
	};

	const setNextClass = (event?: React.MouseEvent) => {
		if (event && (event.metaKey || event.button !== 0)) {
			return;
		}
		setTransitionType('modal-forwards');
	};

	const getPrevButton = () => {
		if (index > 0 && props.search) {
			//@ts-expect-error No index signature with a parameter of type 'number' was found on type '{}
			const prev = props.leases[index - 1];
			if (prev && !buttonsDisabled) {
				return (
					<ArrowIconContainer
						to={`/comps/leases/${prev.id}`}
						replace={true}
						state={{
							index: index - 1,
							queryString: location.state?.queryString,
						}}
						className={modalStyles.prevButton}
						onMouseOver={setPrevClass}
						onClick={handleButtonClick}
						// @ts-expect-error The expected type comes from property 'ref' which is declared here on type
						ref={prevRef}
					>
						<ArrowLeftIcon width={9} height={15} />
					</ArrowIconContainer>
				);
			} else {
				return (
					<div className={modalStyles.prevButton}>
						<div className={spinner.spinner} />
					</div>
				);
			}
		}
	};

	const getNextButton = () => {
		if (!props.search) {
			return null;
		}
		const length = props.search.total || props.search.length;
		if (index !== length - 1) {
			//@ts-expect-error No index signature with a parameter of type 'number' was found on type '{}
			const next = props.leases[index + 1];
			if (next && !buttonsDisabled) {
				return (
					<ArrowIconContainer
						to={`/comps/leases/${next.id}`}
						replace={true}
						state={{
							modal: true,
							index: index + 1,
							queryString: location.state?.queryString,
						}}
						data-index={index + 1}
						data-query-string={location.state?.queryString}
						className={modalStyles.nextButton}
						onMouseOver={setNextClass}
						onClick={handleButtonClick}
						// @ts-expect-error The expected type comes from property 'ref' which is declared here on type
						ref={nextRef}
					>
						<ArrowRightIcon width={9} height={15} />
					</ArrowIconContainer>
				);
			} else {
				return (
					<div className={modalStyles.nextButton}>
						<div className={spinner.spinner} />
					</div>
				);
			}
		}
	};

	const numberOfLeaseComps = props.search?.total ?? props.totalLeases;

	if (props.did404) {
		return (
			<CompNotFound
				onNewSearch={() => {
					router.replace('/search/leases/home');
				}}
			/>
		);
	}

	return props.featureFlags.LeaseCompUX23Q2 ? (
		props.lease ? (
			<div>
				{location.state?.fromNewPropertyPage ? (
					<BackToContainer>
						<UnderlinedLink
							onClick={() => {
								location.state
									? router.goBack()
									: router.push(`/property/${props.lease.propertyId}`);
							}}
						>
							Back to properties
						</UnderlinedLink>
					</BackToContainer>
				) : location.state?.fromNewSalePage ? (
					<BackToContainer>
						<UnderlinedLink
							onClick={() => {
								router.goBack();
							}}
						>
							Back to sales comp
						</UnderlinedLink>
					</BackToContainer>
				) : (
					<PrevNextLeaseHeader
						index={index}
						numberOfComps={numberOfLeaseComps}
						location={location}
						router={router}
						//@ts-expect-error No index signature with a parameter of type 'number' was found on type '{}
						prevCompId={index > 0 ? props.leases[index - 1]?.id : null}
						nextCompId={
							numberOfLeaseComps && index !== numberOfLeaseComps - 1
								? //@ts-expect-error No index signature with a parameter of type 'number' was found on type '{}
									props.leases[index + 1]?.id
								: null
						}
					/>
				)}
				<CompPanel
					// @ts-expect-error setNextClass prop is missing
					setNextClass={setNextClass}
					comp={props.lease}
					comps={props.leases}
					{...props}
				/>
			</div>
		) : (
			<StyledSpinner size="l" />
		)
	) : (
		<div>
			<TransitionGroup>
				<CSSTransition key={index} classNames={transitionType} timeout={500}>
					<CompPanel
						// @ts-expect-error setNextClass prop is missing
						setNextClass={setNextClass}
						comp={props.lease}
						comps={props.leases}
						{...props}
					/>
				</CSSTransition>
			</TransitionGroup>
			<div className={modalStyles.pagination}>
				{getPrevButton()}
				{getNextButton()}
			</div>
		</div>
	);
};

export default LeaseWrapper;

const ArrowIconContainer = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledSpinner = styled(Spinner)`
	margin-top: 100px;
`;
