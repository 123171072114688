import { Spinner } from '@compstak/ui-kit';
import { usePropertyByIdQuery, usePropertySalesQuery } from 'api';
import { Link } from 'router';
import styled from 'styled-components';
import MapRadiusIcon from 'ui/svg_icons/map-radius-icon.svg';
import ReportIcon from 'ui/svg_icons/report.svg';
import { CompPopupHeader } from './CompPopupHeader';
import { GenerateCompSetButton } from './GenerateCompSetButton';
import { NearbyCompsButton } from './NearbyCompsButton';
import { PropertyPageLink } from './PropertyPageLink';
import {
	CompPopupActions,
	CompPopupRoot,
	CompPopupRootLoading,
	StyledArrowRightIcon,
} from './UI';
import { useFiltersWithAddressString } from './useFiltersWithAddressString';

type Props = {
	propertyId: number;
	closePopup: () => void;
};

export const SalePopup = ({ propertyId, closePopup }: Props) => {
	const { data: property } = usePropertyByIdQuery({
		id: propertyId,
	});

	const { data: propertySales } = usePropertySalesQuery({
		propertyId,
	});

	const filtersWithAddressString = useFiltersWithAddressString({
		property,
	});

	const listSaleHistoryUrl = `/search/sales/list?${filtersWithAddressString}`;

	const comps = propertySales?.comps;

	if (!property || !comps)
		return (
			<CompPopupRootLoading>
				<Spinner size="l" />
			</CompPopupRootLoading>
		);

	return (
		<CompPopupRoot>
			<CompPopupHeader property={property} closePopup={closePopup} />
			<CompPopupActions>
				<PropertyPageLink propertyId={property.id}>
					<StyledReportIcon />
					Property Report
					<StyledArrowRightIcon />
				</PropertyPageLink>
				<NearbyCompsButton
					property={property}
					closePopup={closePopup}
					compType="sale"
					data-qa-id="nearby-sale-comps-btn"
				>
					<StyledMapRadiusIcon />
					Nearby Properties
					<StyledArrowRightIcon />
				</NearbyCompsButton>
				<GenerateCompSetButton property={property} closePopup={closePopup} />
				<ListSaleHistoryLink to={listSaleHistoryUrl} onClick={closePopup}>
					List Sale History ({comps.length})
				</ListSaleHistoryLink>
			</CompPopupActions>
		</CompPopupRoot>
	);
};

const ListSaleHistoryLink = styled(Link)`
	color: white;
	margin: 10px;
	margin-left: 0;
	text-transform: uppercase;
	font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-size: 11px;
	letter-spacing: 0.06em;
	border-bottom: grey solid 1px;
	padding-bottom: 5px;
	width: fit-content;
	display: block;
	&:hover {
		color: white;
	}
`;

const StyledMapRadiusIcon = styled(MapRadiusIcon)`
	width: 24px;
	height: 24px;
	fill: ${({ theme }) => theme.colors.white.white};
	margin: -5px 0;
`;

const StyledReportIcon = styled(ReportIcon)`
	width: 23px;
	height: 25px;
	fill: ${({ theme }) => theme.colors.white.white};
	margin: -5px 0;
`;
