import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import 'Components/Filters/styles/filters.nomodule.less';
import { useListSearchInput } from 'Components/ListSearchInput/ListSearchInput';
import { useIsExchangePlusUser } from 'exchange/exchangePlus/exchangePlusStatus';
import { useEffect } from 'react';
import { LeaseFilterFields } from './LeaseFilterFields';
import { PropertyFilterFields } from './PropertyFilterFields';
import { SaleFilterFields } from './SaleFilterFields';
import { useLocation } from 'react-router';

export const FilterFields = () => {
	const {
		element: searchInput,
		searchTerm: searchInputSearchTerm,
		reset: resetSearch,
	} = useListSearchInput({
		inputDataQaId: 'filterFieldsSearchInputTestId',
		inputPlaceholder: 'Search for filters',
	});

	const { compType, setSearchTerm } = useFilterFieldContext();

	useEffect(() => {
		resetSearch();
	}, [compType, resetSearch]);

	useEffect(() => {
		setSearchTerm(searchInputSearchTerm);
	}, [searchInputSearchTerm, setSearchTerm]);

	return (
		<ul className="search_sidebar_filters">
			{searchInput}
			{compType === 'lease' && <LeaseFilterFields />}
			{compType === 'sale' && <SaleFilterFields />}
			{compType === 'property' && <PropertyFilterFields />}
		</ul>
	);
};

export function useShouldHideMyCompsForExchangePlus() {
	const isExchangePlusUser = useIsExchangePlusUser();

	const currentUrl = useLocation().pathname;
	if (!isExchangePlusUser) {
		return false;
	}
	return currentUrl.startsWith('/analytics/dashboard');
}
