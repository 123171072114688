import {
	NAV_KEY,
	SearchTable,
	SearchTableExportButton,
	useSearchPropertiesNavHeaderContext,
} from 'Components';
import {
	SearchPropertiesAverages,
	SearchPropertiesResponse,
	useSearchInfiniteParams,
	useSearchTableAverages,
} from 'api';
import { useSearchTableSelection } from 'hooks/useSearchTableSelection';
import { useCallback } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { routes } from 'router';
import { PropertyComp } from 'types';
import { usePropertiesAveragesFormatting } from '../usePropertiesAveragesFormatting';
import { useSearchTableRows } from '../useSearchTableRows';
import { useSearchTableSort } from '../useSearchTableSort';
import { useUnifiedPropertiesInfiniteQuery } from './useUnifiedPropertiesInfiniteQuery';

export const PropertiesSearchTable = () => {
	const [filters] = useFilters();

	const params = useSearchInfiniteParams({ filters });

	const {
		data: propertiesData,
		fetchNextPage,
		isFetching,
		hasNextPage,
	} = useUnifiedPropertiesInfiniteQuery(params);

	const totalCount = propertiesData?.pages[0].totalCount ?? 0;
	const searchAverages = propertiesData?.pages[0].averages;

	const rows = useSearchTableRows<PropertyComp>({
		data: propertiesData,
		getRows: useCallback(
			(page: SearchPropertiesResponse) => page.properties,
			[]
		),
		isFetching,
	});

	const { data: averages } = useSearchTableAverages({
		compType: 'property',
		searchAverages,
		filters,
	});

	const averagesFormatting = usePropertiesAveragesFormatting(
		averages as SearchPropertiesAverages
	);

	const { initNavHeader } = useSearchPropertiesNavHeaderContext();

	const selectionProps = useSearchTableSelection({
		compType: 'property',
		totalCount,
		filters,
	});

	const sortProps = useSearchTableSort();

	return (
		<SearchTable
			{...selectionProps}
			{...sortProps}
			rows={rows}
			averages={averagesFormatting}
			noData={!isFetching && totalCount === 0}
			getRowId={(row) => row.id}
			onLoadMore={() => {
				if (hasNextPage && !isFetching) {
					fetchNextPage();
				}
			}}
			columnPreferencesType="property"
			renderActionButton={(row) => (
				<SearchTableExportButton compType="property" row={row} />
			)}
			getBodyCellProps={(row, rowIndex) => ({
				onClick: () => {
					initNavHeader(rowIndex, { filters });
				},
				state: {
					[NAV_KEY]: true,
				},
				to: routes.propertyById.toHref({ propertyId: row.id }),
			})}
		/>
	);
};
