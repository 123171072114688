import { Market } from '@compstak/common';
import { radiusExpanderSearch, updateSuggestionId } from 'actions/search';
import { ResultsButton } from 'Components/ResultsButton';
import { useChangeMarkets } from 'hooks/useChangeMarkets';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { createFilters } from 'models/filters';
import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { isListFilterKey } from 'models/filters/util/isListFilterKey';
import { filtersToQueryString } from 'models/filters/util/urls';
import {
	getSuggestionItemTitle,
	isGlobalSuggestionItem,
	isPropertySubtypeSuggestionItem,
	isPropertyTypeSuggestionItem,
	isSpaceSubtypeSuggestionItem,
	isSpaceTypeSuggestionItem,
} from 'Pages/Home/Components/SearchBar/Suggestions/helpers';
import { focusProperty } from 'Pages/Search/Map/actions';
import { useMultiSelect } from 'Pages/Search/MultiSelectProvider';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
	PlaceSuggestionItem,
	Suggestion,
	SuggestionItem,
} from 'api/suggestions/suggestions';
import { CompType } from 'types';
import MultiSelectOptionsButton from '../../MultiSelectMode/Singletons/MultiSelectOptionsButton';
import { useNavigate } from 'router';
import { SearchBar } from './Components/SearchBar/SearchBar';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { useReferenceDataQuery } from 'api';
import { useMarkets } from 'hooks/useMarkets';
import styled from 'styled-components';
import { useFilters } from 'reducers/filtersReducer';
import { useCheckMarketAccess } from 'util/marketAccessUtils';

const SEARCH_URLS = {
	lease: '/search/leases/list',
	sale: '/search/sales/list',
	property: '/search/properties/list',
};

type HomeToolbarProps = {
	compType: CompType;
	params: SearchLayoutRouteParams;
	filters: FiltersObject;
};

// TODO to remove once sidebarRevampFF is on (don't forget about css)
export const HomeToolbar = (props: HomeToolbarProps) => {
	const navigate = useNavigate();
	const markets = useMarkets();
	const { data: referenceData } = useReferenceDataQuery();
	const [, , resetFilters] = useFilters();
	const { isMultiSelectOn } = useMultiSelect();

	const dispatch = useDispatch();
	const changeMarkets = useChangeMarkets();
	const { checkMarketAccess } = useCheckMarketAccess();

	const ref = useRef<HTMLDivElement>(null);

	const checkSelectionPermission = (
		suggestion: Suggestion,
		suggestionItem: SuggestionItem
	) => {
		return (
			!isGlobalSuggestionItem(suggestion, suggestionItem) ||
			checkMarketAccess({
				marketId: suggestionItem.marketId,
				compType: props.compType,
			})
		);
	};

	const onSuggestionSelection = (
		suggestion: Suggestion,
		suggestionItem: SuggestionItem,
		suggestionId: string
	) => {
		if (!checkSelectionPermission(suggestion, suggestionItem)) {
			return;
		}

		const suggestionItemTitle = getSuggestionItemTitle(suggestionItem);

		let filters = createFilters(props.compType, {
			market: props.filters.market,
			markets: props.filters.markets,
		});

		switch (suggestion.field) {
			case 'buildingName':
			case 'buildingAddressAndCity': {
				focusBuilding(suggestion, suggestionItem, suggestionId);
				return;
			}
			case 'places': {
				radiusSearch(suggestionItem as PlaceSuggestionItem);
				return;
			}
			case 'submarket': {
				filters = mergeFilters(filters, { submarket: [suggestionItemTitle] });
				resetFilters(filters);
				dispatch(updateSuggestionId(suggestionId));
				return;
			}
			case 'propertyTypeOrSubtype':
			case 'spaceTypeOrPropertySubtype':
			case 'spaceTypeOrSpaceSubtype': {
				if (isSpaceTypeSuggestionItem(suggestionItem)) {
					const spaceType = referenceData.spaceTypes.find(
						(type) => type.id === suggestionItem.spaceTypeId
					);
					if (spaceType) {
						filters = mergeFilters(filters, {
							spaceTypeId: [spaceType.id],
						});
					}
				} else if (isSpaceSubtypeSuggestionItem(suggestionItem)) {
					const spaceSubtype = referenceData.spaceSubtypes.find(
						(type) => type.id === suggestionItem.spaceSubtypeId
					);
					if (spaceSubtype) {
						filters = mergeFilters(filters, {
							spaceSubtypeId: [spaceSubtype.id],
						});
					}
				} else if (isPropertyTypeSuggestionItem(suggestionItem)) {
					const buildingPropertyType = referenceData.propertyTypes.find(
						(type) => type.id === suggestionItem.propertyTypeId
					);
					if (buildingPropertyType) {
						filters = mergeFilters(filters, {
							buildingPropertyTypeId: [buildingPropertyType.id],
						});
					}
				} else if (isPropertySubtypeSuggestionItem(suggestionItem)) {
					const buildingPropertySubtype = referenceData.propertySubtypes.find(
						(subtype) => subtype.id === suggestionItem.propertySubtypeId
					);
					if (buildingPropertySubtype) {
						filters = mergeFilters(filters, {
							buildingPropertySubtype: [buildingPropertySubtype.name],
						});
					}
				}

				navigate(
					`${SEARCH_URLS[props.compType]}?${filtersToQueryString(filters)}`,
					{ state: { suggestionId } }
				);
				return;
			}
			default: {
				const suggestionFilter = {
					[suggestion.field]: isListFilterKey(suggestion.field)
						? [suggestionItemTitle]
						: suggestionItemTitle,
				};
				filters = mergeFilters(filters, suggestionFilter);
				navigate(
					`${SEARCH_URLS[props.compType]}?${filtersToQueryString(filters)}`,
					{ state: { suggestionId } }
				);
				return;
			}
		}
	};

	const radiusSearch = (suggestionItem: PlaceSuggestionItem) => {
		dispatch(
			radiusExpanderSearch({
				compType: props.compType,
				lat: suggestionItem.lat,
				lon: suggestionItem.lon,
				filters: props.filters,
			})
		);
	};

	const focusBuilding = (
		suggestion: Suggestion,
		suggestionItem: SuggestionItem,
		suggestionId: string
	) => {
		const market = markets[suggestionItem.marketId];

		if (isGlobalSuggestionItem(suggestion, suggestionItem)) {
			handleGlobalSuggestionMarket(market);
		}

		dispatch(
			focusProperty({
				marketId: market.id,
				propertyAddress: getSuggestionItemTitle(suggestionItem),
				suggestionId,
			})
		);
	};

	const handleGlobalSuggestionMarket = (market: Market) => {
		const currentMarkets = getFiltersMarkets(props.filters);

		if (currentMarkets.some((currMarket) => currMarket.id === market.id)) {
			return;
		}

		changeMarkets([...currentMarkets, market]);
	};

	return (
		<SearchToolbarContainer ref={ref}>
			{!isMultiSelectOn ? (
				<>
					<SearchBar
						key={props.compType}
						filters={props.filters}
						compType={props.compType}
						onConfirmSelection={onSuggestionSelection}
					/>
					<ResultsButton compType={props.compType} />
				</>
			) : (
				<MultiSelectOptionsButton compType={props.compType} />
			)}
		</SearchToolbarContainer>
	);
};

const SearchToolbarContainer = styled.div`
	display: flex;
	padding: 20px;
	gap: 20px;
`;
