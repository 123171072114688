import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Chart, DataSet } from 'Pages/Analytics/analytics';
import { findDSIndexInDataArray } from 'Pages/Analytics/Builder/Components/helpers';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { filtersToServerJSON } from 'models/filters/util';
import { SEARCH_MAX_LIMIT } from 'api';
import { DEFAULT_SORT_DIRECTION } from 'Components/SearchTable/constants';
import { QUERY_KEYS } from 'api/constants';
import { fetchSearchLeases } from 'api/searchLeases/useSearchLeasesQuery';
import { FiltersObject } from 'models/filters/types';
import { isEqual } from 'lodash';
import { updateChartDraft } from 'Pages/Analytics/Builder/actions';
import { useMarkets } from 'hooks/useMarkets';
import { useDispatch } from 'react-redux';
import { SearchLeasesResponse } from 'api/searchLeases/types';
import { DataSetType } from 'Pages/Analytics/analytics';

export const useUpdateChartExcludedCompsMutation = ({
	chart,
	dataSet,
	filters,
}: {
	chart: Chart;
	dataSet: DataSet;
	filters: Partial<FiltersObject>;
}) => {
	const markets = useMarkets();
	const dispatch = useDispatch();
	const { chartBuilderCanExcludeComps } = useFeatureFlags();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async () => {
			if (
				!(
					chartBuilderCanExcludeComps &&
					dataSet.excludedCompIds &&
					dataSet.excludedCompIds?.length > 0 &&
					dataSet.type !== DataSetType.MUFA
				)
			) {
				return;
			}
			const paramsForExcludedCompIdsSearch = {
				filter: filtersToServerJSON({
					...filters,
					compId: { value: dataSet.excludedCompIds, exclude: false },
				}),
				offset: 0,
				limit: SEARCH_MAX_LIMIT,
				sort: 'executionDate',
				order: DEFAULT_SORT_DIRECTION,
			};
			const { comps } =
				queryClient.getQueryData<SearchLeasesResponse>([
					QUERY_KEYS.SEARCH_LEASES,
					paramsForExcludedCompIdsSearch,
				]) ??
				(await fetchSearchLeases(paramsForExcludedCompIdsSearch)({
					queryKey: [QUERY_KEYS.SEARCH_LEASES, paramsForExcludedCompIdsSearch],
					meta: undefined,
				}));
			const compsIds = comps?.map(({ id }: { id: number }) => id);
			if (
				!compsIds ||
				isEqual(
					[...compsIds].sort((a, b) => a - b),
					[...dataSet.excludedCompIds].sort((a, b) => a - b)
				)
			) {
				return;
			}
			const newExcludedComps = dataSet.excludedCompIds.filter(
				(excludedCompId) => compsIds.includes(excludedCompId)
			);
			const dataSetIndex = findDSIndexInDataArray(chart.dataSets, dataSet);
			const updatedDataSets = chart.dataSets.map((chartDataSet, index) => {
				return index === dataSetIndex
					? { ...chartDataSet, excludedCompIds: newExcludedComps }
					: chartDataSet;
			});
			const chartDraftWithUpdatedDataSet = {
				...chart,
				dataSets: updatedDataSets,
			};
			return updateChartDraft(chartDraftWithUpdatedDataSet, markets)(dispatch);
		},
	});
};
