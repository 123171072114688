import {
	CompsetType,
	PropertyInfoPropertyType,
	useCompsetType,
	usePropertyInfoQuery,
} from 'api';
import { useIsExchange } from 'hooks';
import { SalesCompPageTab } from '../types';

type Props = {
	propertyId: number;
	isPortfolio: boolean;
};

export const useTabVisibility = ({ propertyId, isPortfolio }: Props) => {
	const shouldShowCompset = useShouldShowCompset(propertyId);
	const shouldShowLeasesInCompset = useShouldShowLeasesInCompset(propertyId);

	return {
		[SalesCompPageTab.TRANSACTION]: true,
		[SalesCompPageTab.LEASE]: true,
		[SalesCompPageTab.SIMILAR]: !isPortfolio,
		[SalesCompPageTab.COMPSET]: shouldShowCompset && !isPortfolio,
		[SalesCompPageTab.LEASES_COMP_SET]:
			shouldShowLeasesInCompset && !isPortfolio,
	};
};

const useShouldShowCompset = (propertyId: number) => {
	const { data: propertyInfoData } = usePropertyInfoQuery({ propertyId });
	const isExchange = useIsExchange();

	if (isExchange) return true;

	if (!propertyInfoData) return false;

	const { propertyType, hasRpCompSetData } = propertyInfoData;

	if (
		propertyType === PropertyInfoPropertyType.MULTIFAMILY ||
		propertyType === PropertyInfoPropertyType.MIXED_USE
	) {
		return hasRpCompSetData;
	}

	return true;
};

const useShouldShowLeasesInCompset = (propertyId: number) => {
	const shouldShowCompset = useShouldShowCompset(propertyId);
	const compsetType = useCompsetType({ propertyId });

	return shouldShowCompset && compsetType === CompsetType.COMMERCIAL;
};
