import { Text, View } from '@react-pdf/renderer';
import { TenantIndustry, TopTenantIndustry } from 'api';
import { ComponentType } from 'react';
import { MarketRentMapPdfSection } from './MarketRentSection/MarketRentMapPdfSection';
import { MarketRentMapSection } from './MarketRentSection/MarketRentMapSection';
import { TenantIndustryDistributionPdfSection } from './TenantIndustryDistribution/TenantIndustryDistributionPdfSection';
import { TenantIndustryDistributionSection } from './TenantIndustryDistribution/TenantIndustryDistributionSection';
import { TenantIndustryMapPdfSection } from './TenantIndustryMap/TenantIndustryMapPdfSection';
import { TenantIndustryMapSection } from './TenantIndustryMap/TenantIndustryMapSection';

export const HALF_SECTIONS = [
	'marketRentHeatMap',
	'startingRent',
	'leaseExpirationPropertyType',
	'leaseExpirationSubmarket',
	'tenantIndustryMap',
	'tenantIndustryDistribution',
] as const;

export type HalfSectionKey = (typeof HALF_SECTIONS)[number];

export const DYNAMIC_SECTIONS = [...HALF_SECTIONS];

type SectionKey = (typeof DYNAMIC_SECTIONS)[number];

// temporary
const EditStub = () => <div>Component</div>;

export const EDIT_COMPONENT_MAP: Record<SectionKey, ComponentType> = {
	marketRentHeatMap: MarketRentMapSection,
	startingRent: EditStub,
	leaseExpirationPropertyType: EditStub,
	leaseExpirationSubmarket: EditStub,
	tenantIndustryMap: TenantIndustryMapSection,
	tenantIndustryDistribution: TenantIndustryDistributionSection,
} as const;

// temporary
const PdfStub = () => (
	<View>
		<Text>Component</Text>
	</View>
);

export const PDF_COMPONENT_MAP: Record<
	SectionKey,
	ComponentType<PdfSectionProps>
> = {
	marketRentHeatMap: MarketRentMapPdfSection,
	leaseExpirationPropertyType: PdfStub,
	leaseExpirationSubmarket: PdfStub,
	startingRent: PdfStub,
	tenantIndustryDistribution: TenantIndustryDistributionPdfSection,
	tenantIndustryMap: TenantIndustryMapPdfSection,
};

export const IMAGE_SECTIONS = [
	'marketRentHeatMap',
	'tenantIndustryDistribution',
	'tenantIndustryMap',
] as const;

export type ImageSectionKey = (typeof IMAGE_SECTIONS)[number];

export type PdfSectionProps = {
	marketRentHeatMap: {
		title: string;
		description: string;
		url: string | null;
	};
	tenantIndustryDistribution: {
		url: string | null;
		data: TopTenantIndustry[] | undefined;
	};
	tenantIndustryMap: {
		url: string | null;
		data: TenantIndustry[];
		description: string;
	};
};
