import { PortfolioPropertySummary } from 'api';
import { PropertyComp } from 'types';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { usePropertiesWithSummaries } from '../usePropertiesWithSummaries';
import { useMarketRentState } from './MarketRentProvider';

type Props = {
	portfolioId: number;
};

export const useMarketRentPropertiesWithSummaries = ({
	portfolioId,
}: Props) => {
	const [marketRentState] = useMarketRentState();

	const { filters } = usePortfolioFilters();

	const marketIds = marketRentState.selectedMarketId
		? [marketRentState.selectedMarketId]
		: undefined;

	const propertyTypeIds = marketRentState.selectedPropertyTypeId
		? [marketRentState.selectedPropertyTypeId]
		: undefined;

	const arePropertyRequestsEnabled =
		marketIds != null && propertyTypeIds != null;

	const { data } = usePropertiesWithSummaries({
		portfolioId,
		filters: { ...filters, marketIds, propertyTypeIds },
		enabled: arePropertyRequestsEnabled,
	});

	return data;
};

export type PropertyWithSummary = PropertyComp & PortfolioPropertySummary;
