import { useDeletePortfolioMutation } from 'api';
import {
	Modal,
	ModalButton,
	ModalButtons,
	ModalCenteredContent,
	ModalTitle,
} from 'Components/Modals/common/UI';
import styled from 'styled-components';

type Props = {
	portfolioId: number;
	closeDialog: () => void;
};

export const DeletePortfolioDialog = ({ portfolioId, closeDialog }: Props) => {
	const { mutateAsync: deletePortfolio, isLoading } =
		useDeletePortfolioMutation();

	return (
		<Modal onClose={closeDialog}>
			<ModalCenteredContent>
				<ModalTitle>Delete Portfolio</ModalTitle>
				<Body>
					Are you sure you want to delete the portfolio? All portfolio details
					will be permanently removed.
				</Body>
				<ModalButtons>
					<ModalButton variant="secondary" type="button" onClick={closeDialog}>
						Cancel
					</ModalButton>
					<ModalButton
						type="submit"
						variant="error"
						disabled={isLoading}
						isLoading={isLoading}
						style={{
							transition: 'none',
						}}
						onClick={async () => {
							await deletePortfolio({ id: portfolioId });
							closeDialog();
						}}
					>
						Delete Portfolio
					</ModalButton>
				</ModalButtons>
			</ModalCenteredContent>
		</Modal>
	);
};

const Body = styled.div`
	font-size: 1rem;
	padding-bottom: 1rem;
`;
