import { MAP_CONTROLS } from 'actions/search';
import { MapFilterV2Base } from './MapFilterV2Base';
import IconMapViewport from 'ui/svg_icons/map-viewport-icon.svg';

export const SearchWithinViewMapFilterV2 = () => {
	return (
		<MapFilterV2Base
			Icon={IconMapViewport}
			key={MAP_CONTROLS.SEARCH_WITHIN_VIEW}
			label="Search Within View"
			mapControl={MAP_CONTROLS.SEARCH_WITHIN_VIEW}
		/>
	);
};
