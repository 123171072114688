import { Flex } from '@compstak/ui-kit';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import styled from 'styled-components';
import { Promotion } from './promotionTypes';
import { PromotionsList } from './promotionsList';
import { SelectedPromotion, SelectedPromotionV2 } from './selectedPromotion';

export function PromotionsListAndFocusedPromo({
	promotions,
	selectedPromoId,
	onSelectPromo,
}: {
	promotions: Promotion[];
	selectedPromoId: number | null;
	onSelectPromo: (id: number | null) => void;
}) {
	const flags = useFeatureFlags();

	const selectedPromo =
		promotions.find((p) => p.id === selectedPromoId) ?? null;

	if (promotions.length === 0) {
		return (
			<LRColumnsContainer>
				{/* Left side: Empty promos list */}
				<LRColumn>
					<PromotionsList {...{ promotions, selectedPromoId, onSelectPromo }} />
				</LRColumn>
				{/* Right side: Empty */}
				<LRColumn></LRColumn>
			</LRColumnsContainer>
		);
	}

	if (promotions.length === 1) {
		return (
			<LRColumnsContainer>
				{/* Left side: Only (focused) promo */}
				<LRColumn>
					{flags.TargetedPromotionIterationsPhase1 ? (
						<SelectedPromotionV2 promotion={selectedPromo} />
					) : (
						<SelectedPromotion promotion={selectedPromo} />
					)}
				</LRColumn>
				{/* Right side: Empty */}
				<LRColumn></LRColumn>
			</LRColumnsContainer>
		);
	}

	return (
		<LRColumnsContainer>
			{/* Left side: Promos list */}
			<LRColumn>
				<PromotionsList {...{ promotions, selectedPromoId, onSelectPromo }} />
			</LRColumn>
			{/* Right side: Focused promo */}
			<LRColumn>
				{flags.TargetedPromotionIterationsPhase1 ? (
					<SelectedPromotionV2 promotion={selectedPromo} />
				) : (
					<SelectedPromotion promotion={selectedPromo} />
				)}
			</LRColumn>
		</LRColumnsContainer>
	);
}

const LRColumnsContainer = styled(Flex)`
	gap: 64px;
	@media (max-width: 1024px) {
		gap: 32px;
	}
	align-items: flex-start;
	flex-grow: 1;
	width: 100%;
`;

const LRColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 1px;
	height: 100%;
`;
