import React from 'react';
import Menu from 'Singletons/Menu';

import deleteProjectModal, {
	DeleteProjectModalProps,
} from '../Modals/DeleteProject';
import renameProjectModal, {
	RenameProjectModalProps,
} from '../Modals/RenameProject';
import shareProjectModal, {
	ShareProjectModalProps,
} from '../Modals/ShareProject';

import styles from 'Pages/Analytics/Builder/Components/styles/menu.less';
import popupStyles from 'Components/Popup/popup.less';
import { ModalActions } from 'Singletons/Modal/actions';

export type ProjectMenuProps = {
	modalActions: Pick<ModalActions, 'showModal'>;
} & RenameProjectModalProps &
	ShareProjectModalProps &
	DeleteProjectModalProps;

export const ProjectMenu = (props: ProjectMenuProps) => {
	const rename = () => {
		props.modalActions.showModal(renameProjectModal, props);
	};

	const share = () => {
		props.modalActions.showModal(shareProjectModal, props);
	};

	const remove = () => {
		props.modalActions.showModal(deleteProjectModal, props);
	};

	return (
		<ul className={styles.menuContent}>
			<li onClick={rename}>Rename</li>
			<li onClick={share}>Share</li>
			<li onClick={remove}>Delete</li>
		</ul>
	);
};

Menu.addComponent('project-menu', ProjectMenu, {
	className: popupStyles.compactWhite,
});

export default 'project-menu';
