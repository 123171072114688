import { memo } from 'react';
import { TooltipV2 } from '@compstak/ui-kit';
import { mapValues } from 'lodash';
import { nullValueText } from '@compstak/common';
import { getRentPeriodPostfix, formatRentWithPsfPostfix } from 'format';
import { PropertyLeaseSummary, PropertyTypeName } from 'api';
import { useLeaseContext } from '../LeasesTab/LeaseProvider';
import { AuxText } from './PropertyLeaseSnapshot';
import IconThreeDot from 'ui/svg_icons/three-dot.svg';
import styled from 'styled-components';
import { BlueBoxLabel, BlueBoxValue } from '../UI';
import { HelpTooltip } from '../HelpTooltip';

type Props = {
	summary: PropertyLeaseSummary;
	isMonthlyMarket: boolean;
	propertyType?: PropertyTypeName;
};
export const AverageInPlaceRents = memo(
	({ summary, isMonthlyMarket, propertyType }: Props) => {
		const { leasesGrouppedBySpaceType } = useLeaseContext();

		const formattedSpaceTypeRents: Record<string, any> = {};
		summary.spaceTypeRents?.forEach(({ spaceType, weightedAvgCurrentRent }) => {
			if (weightedAvgCurrentRent) {
				formattedSpaceTypeRents[spaceType] = formatRentWithPsfPostfix(
					isMonthlyMarket,
					weightedAvgCurrentRent
				);
			}
		});

		const leasesBySpaceTypesLengths = mapValues(
			leasesGrouppedBySpaceType,
			(leasesBySpaceType) => leasesBySpaceType?.length ?? 0
		);
		const sortedLeasesBySpaceTypesKeys = Object.entries(
			leasesBySpaceTypesLengths
		)
			.sort((l1, l2) => l2[1] - l1[1])
			.map((l) => l[0]);

		const formattedSpaceTypeKeysLength = Object.keys(
			formattedSpaceTypeRents
		).length;

		const secondarySpaceTypesKeys = propertyType
			? sortedLeasesBySpaceTypesKeys.filter((key) => key !== propertyType)
			: sortedLeasesBySpaceTypesKeys;

		const shouldShowMore = formattedSpaceTypeKeysLength > RENT_DISPLAY_NUMBER;

		return (
			<div>
				<BlueBoxLabel>
					Average In-Place Rent {getRentPeriodPostfix(isMonthlyMarket)}{' '}
					<HelpTooltip text="This average is calculated by weighting the rent of each lease by its square footage, providing a more accurate reflection of rental values. Additionally, rents are broken out by space type (Office, Industrial, Flex R&D, Retail, Other, Land) for more detail." />
				</BlueBoxLabel>
				<div>
					{formattedSpaceTypeKeysLength > 0 ? (
						<RentsList>
							{propertyType && formattedSpaceTypeRents[propertyType] && (
								<div>
									• {formattedSpaceTypeRents[propertyType]}{' '}
									<AuxText>({propertyType})</AuxText>
								</div>
							)}
							{secondarySpaceTypesKeys.map((key) =>
								formattedSpaceTypeRents[key] ? (
									<div key={key}>
										• {formattedSpaceTypeRents[key]} <AuxText>({key})</AuxText>
									</div>
								) : (
									<></>
								)
							)}
						</RentsList>
					) : (
						<BlueBoxValue>{nullValueText}</BlueBoxValue>
					)}
					{shouldShowMore && (
						<TooltipV2
							side="top"
							content={
								<ul style={{ listStyle: 'inside' }}>
									{propertyType && formattedSpaceTypeRents[propertyType] && (
										<li>
											{propertyType} - {formattedSpaceTypeRents[propertyType]}
										</li>
									)}
									{secondarySpaceTypesKeys.map((key) =>
										formattedSpaceTypeRents[key] ? (
											<li key={key}>
												{key} - {formattedSpaceTypeRents[key]}
											</li>
										) : (
											<></>
										)
									)}
								</ul>
							}
						>
							<ShowMoreContainer>
								<IconThreeDot />
								SHOW MORE
							</ShowMoreContainer>
						</TooltipV2>
					)}
				</div>
			</div>
		);
	}
);

AverageInPlaceRents.displayName = 'AverageInPlaceRents';

const RENT_DISPLAY_NUMBER = 4;

const RentsList = styled.div`
	padding-left: 0.5rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.5rem;
	div {
		color: ${(p) => p.theme.colors.gray.gray700};
		font-size: 0.875rem;
		font-weight: 500;
		white-space: nowrap;
	}
`;

const ShowMoreContainer = styled.div`
	width: fit-content;
	display: flex;
	align-items: center;
	margin-top: 0.5rem;

	color: ${(p) => p.theme.colors.blue.blue500};
	font-weight: 400;
	font-size: 0.75rem;

	svg {
		path {
			fill: ${(p) => p.theme.colors.blue.blue500};
		}
		width: 10px;
		height: 10px;
		transform: rotate(90deg);
		margin-right: 4px;
	}

	&:hover {
		cursor: pointer;
	}
`;
