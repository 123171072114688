import { PropertySubtypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertySubtypeFilter';
import { PropertyTypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertyTypeFilter';
import { SpaceSubtypeFilter } from 'Components/Filters/Fields/AggregationFilter/SpaceSubtypeFilter';
import { SpaceTypeFilter } from 'Components/Filters/Fields/AggregationFilter/SpaceTypeFilter';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { useListSearchInput } from 'Components/ListSearchInput/ListSearchInput';
import { useIsExchangePlusUser } from 'exchange/exchangePlus/exchangePlusStatus';
import { SearchPageProvider } from 'Layouts/SearchProvider';
import { LeaseFilterFields } from 'Pages/Search/Sidebar/Components/LeaseFilterFields';
import { useEffect } from 'react';
import { AppConfig } from 'types/config';

type ExchangeDashboardFiltersProps = {
	appConfig: AppConfig;
};

export const ExchangeDashboardFilters = ({
	appConfig,
}: ExchangeDashboardFiltersProps) => {
	const { setSearchTerm } = useFilterFieldContext();
	const isExchangePlusUser = useIsExchangePlusUser();
	const { element: searchInput, searchTerm: inputSearchTerm } =
		useListSearchInput({
			inputDataQaId: 'exchangeDashboardFilterFieldsSearchInputTestId',
			inputPlaceholder: 'Search for filters',
		});

	useEffect(() => {
		setSearchTerm(inputSearchTerm);
	}, [inputSearchTerm, setSearchTerm]);

	return (
		<ul className="search_sidebar_filters">
			{appConfig.isEnterprise || isExchangePlusUser ? (
				<SearchPageProvider>
					{searchInput}
					<LeaseFilterFields />
				</SearchPageProvider>
			) : (
				<>
					<SpaceTypeFilter defaultSelected={true}>
						<SpaceSubtypeFilter />
					</SpaceTypeFilter>
					<PropertyTypeFilter defaultSelected={true}>
						<PropertySubtypeFilter />
					</PropertyTypeFilter>
				</>
			)}
		</ul>
	);
};
