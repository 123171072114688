import { ResultsButton } from 'Components/ResultsButton';
import Stats from 'Components/Stats';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { CompType } from 'types';
import MultiSelectOptionsButton from '../../../MultiSelectMode/Singletons/MultiSelectOptionsButton';
import { MapResultsButton } from '../MapResultsButton';
import { useMultiSelect } from '../MultiSelectProvider';
import { LeagueTablesNavButton } from './LeagueTablesNavButton';
import { SearchToolbarButtons } from './SearchToolbarButtons';
import './toolbar.nomodule.less';

export type SearchToolbarProps = {
	compType: CompType;
	params: SearchLayoutRouteParams;
};

// TODO to remove once sidebarRevampFF is on (don't forget about css)
export const SearchToolbar = ({ compType, params }: SearchToolbarProps) => {
	const { isMultiSelectOn } = useMultiSelect();
	const { leagueTables } = useFeatureFlags();

	const renderNavigationButton = () => {
		if (params.view !== 'list') {
			return isMultiSelectOn ? (
				<MultiSelectOptionsButton compType={compType} />
			) : (
				<ResultsButton compType={compType} />
			);
		}
		return <MapResultsButton compType={compType} />;
	};

	return (
		<div className={'search_content_toolbar ' + params.view}>
			<SearchToolbarButtons compType={compType} params={params} />
			<ul className="toolbar_controls_right">
				{!isMultiSelectOn ? (
					<li className="search_stats_container js-search_stats_container">
						<Stats compType={compType} view={params.view} />
					</li>
				) : null}
				<li className="search_results_switch js-search_results-switch">
					{renderNavigationButton()}
				</li>
				{leagueTables && compType === 'sale' && (
					<li className="search_results_switch js-search_results-switch">
						<LeagueTablesNavButton
							compType={compType}
							isActive={params.view === 'leagueTables'}
						/>
					</li>
				)}
			</ul>
		</div>
	);
};
