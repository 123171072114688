import { MAP_CONTROLS } from 'actions/search';
import { MapFilterV2Base } from './MapFilterV2Base';
import IconSubmarketsMapFilter from 'ui/svg_icons/submarkets.svg';
import { SubmarketFilter } from '../SubmarketFilter/SubmarketFilter';

export const SubmarketsMapFilterV2 = () => {
	return (
		<MapFilterV2Base
			Icon={IconSubmarketsMapFilter}
			key={MAP_CONTROLS.SUBMARKETS}
			label="Submarkets"
			mapControl={MAP_CONTROLS.SUBMARKETS}
		>
			{(props) => (
				<SubmarketFilter
					filters={props.filters}
					isActive={props.isActive}
					onFilterChange={props.onFilterChange}
					setIsLoading={props.setIsLoading}
				/>
			)}
		</MapFilterV2Base>
	);
};
