import { Market, User } from '@compstak/common';
import { FormSelect, TextInput } from '@compstak/ui-kit';
import { useHasMufaChartbuilderAccess } from 'hooks/chartBuilderHooks';
import { FiltersObject } from 'models/filters/types';
import { mufaLevelFilters } from 'models/filters/types';
import { Chart, DataSet, DataSetType } from 'Pages/Analytics/analytics';
import { connector } from 'Pages/Analytics/Builder';
import { ChartBuilderActions } from 'Pages/Analytics/Builder/actions';
import {
	attributeToDataSetType,
	DATASET_TYPES_URL_SEARCH_PARAM_KEY,
	dataSetTypeToSeriesMap,
	DEFAULT_CHART,
	MAX_DATASETS_ALLOWED,
} from 'Pages/Analytics/Builder/chartBuilderConstants';
import { getDataSetTypesFromUrlSearchParam } from 'Pages/Analytics/Builder/chartBuilderHelpers';
import { NoAnalyticsAccessModal } from 'Components/Modals/NoAnalyticsAccessModal/NoAnalyticsAccessModal';
import { DRAFTS_PROJECT_NAME } from 'Pages/Analytics/config';
import { saveChartDraftAndNewDataSets } from 'Pages/Analytics/Repository/actions';
import withProjects from 'Pages/Analytics/Repository/withProjects';
import { PermissionsState } from 'Pages/Login/reducers';
import { useMemo, useRef, useState } from 'react';
import { replace as reduxReplace } from 'router';
import { AppState } from 'reducers/root';
import { ModalActions } from 'Singletons/Modal/actions';
import styled from 'styled-components';
import { CompType } from 'types';
import { FeatureFlags } from 'api/featureFlags/types';
import button from 'ui/styles/button.less';
import spinner from 'ui/styles/spinner.less';
import { useAppDispatch } from 'util/useAppDispatch';
import {
	getAttrOptionsForCreateDSForm,
	mapMarketsToSelectOptionsAndSort,
} from './CreateNewDataSetV2/utils';
import { Form, Wrap } from './CreateNewDataSetV2/UI';
import { isSet } from 'models/filters/util/getSetFilters';
import { setFilters } from 'models/filters/util/setFilters';
import {
	filtersFromQueryString,
	getParamsFromQueryString,
} from 'models/filters/util/urls';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { RouteComponentProps } from 'router/migration/types';
import { useMarkets } from 'hooks/useMarkets';
import {
	Modal,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { ChartBuilderMarketFormSelect } from 'Pages/Analytics/Components/Modals/ChartBuilderMarketFormSelect';
import { useHistoryState } from 'router/HistoryProvider';

enum ModalType {
	CHART_IN_PROGRESS = 'CHART_IN_PROGRESS',
	DATASET_LIMIT = 'DATASET_LIMIT',
	TITLE = 'TITLE',
	CHART_SAVED = 'CHART_SAVED',
	NO_PERMISSION = 'NO_PERMISSION',
}

const DATA_SET_TYPE_TO_COMP_TYPE = {
	[DataSetType.LEASES]: 'lease',
	[DataSetType.COMMERCIAL]: 'lease',
	[DataSetType.SALES]: 'sale',
	[DataSetType.MUFA]: 'mufa',
} as const;

type QueryParams = {
	title?: string;
	[DATASET_TYPES_URL_SEARCH_PARAM_KEY]?: string;
	series: string;
	propertyId?: string;
	tab: CompType;
};

type ImportDataSetProps = RouteComponentProps<unknown, QueryParams> & {
	chart: Chart;
	chartDraft: Chart;
	chartBuilderActions: ChartBuilderActions;
	featureFlags: FeatureFlags;
	user: User;
	permissions: PermissionsState;
	modalActions: ModalActions;
	replace: typeof reduxReplace;
	selection: AppState['selection'];
	filters: FiltersObject;
};

type AttributeToPlotKey = keyof typeof attributeToDataSetType;

type AttributeToPlotState = AttributeToPlotKey | '';

const getInitialModalType = ({
	filters,
	chartDraft,
	permissions,
}: ImportDataSetProps) => {
	const hasPermission = getFiltersMarkets(filters).some(
		(market) => !!permissions[market.id]?.analytics
	);

	if (!hasPermission) {
		return ModalType.NO_PERMISSION;
	}

	if (!chartDraft || chartDraft.dataSets.length === 0) {
		return ModalType.TITLE;
	}

	const currentDatasetsAmount = chartDraft.dataSets?.length ?? 0;
	const expectedAmount = currentDatasetsAmount + 1;
	const exceedsDatasetLimit = expectedAmount > MAX_DATASETS_ALLOWED;

	return exceedsDatasetLimit
		? ModalType.DATASET_LIMIT
		: ModalType.CHART_IN_PROGRESS;
};

const ImportDataSetBase = (props: ImportDataSetProps) => {
	const markets = useMarkets();
	const [modalType, setModalType] = useState(() => {
		return getInitialModalType(props);
	});
	const shouldAddToCurrentChart = useRef(true);
	const [title, setTitle] = useState(props.location.query.title ?? '');
	const [marketName, setMarketName] = useState('');
	const dispatch = useAppDispatch();
	const [attributeToPlot, setAttributeToPlot] =
		useState<AttributeToPlotState>('');

	const urlMarkets: Market[] = useMemo(() => {
		try {
			const urlParams = getParamsFromQueryString(props.location.query.series);
			const marketNames = urlParams.marketNames;
			if (!marketNames) return [markets[urlParams.marketName]];

			return (
				JSON.parse(marketNames)
					// @ts-expect-error TS7006: Parameter 'marketName' implici...
					.map((marketName) => {
						return markets[marketName];
					})
					// @ts-expect-error TS7006: Parameter 'market' implicitly ...
					.filter((market) => market)
			);
		} catch (e) {
			console.error('something went wrong parsing the url params');
			return [];
		}
	}, [props.location.query.series, markets]);

	const tab = props.location?.query?.tab;

	const redirectToChartBuilder = () => {
		props.replace('/analytics');
	};

	const getDataSetTypes = () => {
		const urlSearchParams = props.location.query;
		const urlSearchParamValue =
			urlSearchParams?.[DATASET_TYPES_URL_SEARCH_PARAM_KEY];
		return getDataSetTypesFromUrlSearchParam(urlSearchParamValue);
	};

	const getSelected = () => {
		const selected = props.selection.list;
		if (!Array.isArray(selected)) {
			console.error('Selected should be an array!?', selected);
			return [];
		}
		return selected;
	};

	const getFiltersFromUrlSearchParams = (dataSetType: DataSetType) => {
		const urlSearchParams = props.location.query;
		const series = urlSearchParams.series;
		const propertyId = urlSearchParams.propertyId;

		const propertyIdSearchParam = `${
			dataSetType === DataSetType.MUFA ? 'id' : 'propertyId'
		}=${propertyId}`;
		const queryString = [
			series,
			...(propertyId ? [propertyIdSearchParam] : []),
		].join('&');

		let filters = filtersFromQueryString({
			compType: DATA_SET_TYPE_TO_COMP_TYPE[dataSetType],
			markets,
			queryString,
			extraOptions: {
				ignoreErrors: true,
			},
		});
		const currentMarkets = getFiltersMarkets(filters);
		// @ts-expect-error TS2345: Argument of type 'null' is not...
		filters = setFilters(filters, 'markets', null);

		if (currentMarkets.length > 1) {
			filters = setFilters(filters, 'market', markets[marketName]);
		} else if (currentMarkets.length === 1) {
			filters = setFilters(filters, 'market', currentMarkets[0]);
		}
		// Added PropertyId because if user is on lease tab,
		// their id will be id of Lease, not propertyId
		// so now if user is on lease tab it will get a propertyId as value
		const selectedIds = [
			// @ts-expect-error what
			...new Set(getSelected().map(({ id, propertyId }) => propertyId ?? id)),
		];

		if (selectedIds.length < 1) {
			return filters;
		}
		if (dataSetType === DataSetType.MUFA) {
			return setFilters(filters, 'id', selectedIds);
		}
		return setFilters(filters, 'propertyId', selectedIds);
	};

	const getNewDataSetsFromUrlSearchParams = () => {
		const dataSetTypes = getDataSetTypes();
		const dataSets: DataSet[] = dataSetTypes.map((dst, idx) => {
			console.assert(
				idx !== 1 || dst === DataSetType.MUFA,
				'Second dataset type should always be MUFA?!'
			);
			const dataSet: DataSet = {
				name: idx === 1 ? `${title} (Multifamily)` : title,
				// @ts-expect-error TS2551: Property 'commercialLeases' do...
				series: dataSetTypeToSeriesMap[dst],
				filters: getFiltersFromUrlSearchParams(dst),
				isVisible: true,
				type: dst,
			};
			return dataSet;
		});
		return dataSets;
	};

	const getDataSets = () => {
		if (tab === 'property') {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			const dataSetType = attributeToDataSetType[attributeToPlot];
			const dataSet: DataSet = {
				name: title,
				type: dataSetType,
				filters: getFiltersFromUrlSearchParams(dataSetType),
				isVisible: true,
				series: attributeToPlot,
			};
			return [dataSet];
		} else {
			return getNewDataSetsFromUrlSearchParams();
		}
	};

	const addToCurrentChart = () => {
		let chartDraft = props.chartDraft;
		if (!chartDraft) {
			console.warn('chartDraft is falsy?!', chartDraft);
			chartDraft = { ...DEFAULT_CHART };
		}
		props.chartBuilderActions.addDataSets(chartDraft, getDataSets(), markets);
		redirectToChartBuilder();
	};

	const createNewChartAndSaveCurrent = () => {
		dispatch(saveChartDraftAndNewDataSets(getDataSets()));
		redirectToChartBuilder();
	};

	const chartInProgressActions = {
		onAddToCurrent: () => {
			setModalType(ModalType.TITLE);
			shouldAddToCurrentChart.current = true;
		},
		onCreateNew: () => {
			setModalType(ModalType.CHART_SAVED);
			shouldAddToCurrentChart.current = false;
		},
	};

	const dataSetLimitActions = {
		onReviewCurrent: () => {
			redirectToChartBuilder();
		},
		onCreateNew: () => {
			setModalType(ModalType.TITLE);
			shouldAddToCurrentChart.current = false;
		},
	};

	const titleActions = {
		onSubmit: () => {
			if (shouldAddToCurrentChart.current) {
				addToCurrentChart();
			} else {
				createNewChartAndSaveCurrent();
			}
		},
	};

	const chartSavedActions = {
		onGoBack: () => {
			setModalType(ModalType.CHART_IN_PROGRESS);
		},
		onOk: () => {
			setModalType(ModalType.TITLE);
		},
	};

	if (props.chartDraft === null) {
		return <div className={spinner.large} />;
	}

	if (urlMarkets.length === 0) {
		return (
			<div>
				The URL is malformed. Please try again, starting from the beginning.
			</div>
		);
	}

	switch (modalType) {
		case ModalType.CHART_IN_PROGRESS:
			return (
				<ChartInProgressModal
					onAddToCurrent={chartInProgressActions.onAddToCurrent}
					onCreateNew={chartInProgressActions.onCreateNew}
				/>
			);
		case ModalType.DATASET_LIMIT:
			return (
				<DataSetLimitModal
					onReviewCurrent={dataSetLimitActions.onReviewCurrent}
					onCreateNew={dataSetLimitActions.onCreateNew}
				/>
			);
		case ModalType.TITLE: {
			return (
				<TitleModal
					title={title}
					marketName={marketName}
					onChangeTitle={setTitle}
					onChangeMarketName={setMarketName}
					attributeToPlot={attributeToPlot}
					onChangeAttributeToPlot={setAttributeToPlot}
					onSubmit={titleActions.onSubmit}
					compType={props.location.query.tab}
					urlMarkets={urlMarkets}
					filters={props.filters}
				/>
			);
		}
		case ModalType.CHART_SAVED:
			return (
				<ChartSavedModal
					onGoBack={chartSavedActions.onGoBack}
					onOk={chartSavedActions.onOk}
				/>
			);
		case ModalType.NO_PERMISSION:
			return <NoPermissionModal />;
		default:
			return <div />;
	}
};

export const ImportDataSet = withProjects(connector(ImportDataSetBase));

// @ts-expect-error TS7031: Binding element 'onAddToCurren...
const ChartInProgressModal = ({ onAddToCurrent, onCreateNew }) => {
	return (
		<ModalContainer>
			<ModalTitle>Chart In Progress</ModalTitle>
			<ModalParagraph>
				You currently have an Analytics Chart in progress.
			</ModalParagraph>
			<ModalActionsContainer>
				<ModalButton
					data-qa-id="add-to-current-chart-btn"
					className={button.darkBlue}
					onClick={onAddToCurrent}
				>
					Add To Current Chart
				</ModalButton>
				<ModalButton
					data-qa-id="create-new-chart-btn"
					className={button.blue}
					onClick={onCreateNew}
				>
					Create New Chart
				</ModalButton>
			</ModalActionsContainer>
		</ModalContainer>
	);
};

// @ts-expect-error TS7031: Binding element 'onGoBack' imp...
const ChartSavedModal = ({ onGoBack, onOk }) => {
	return (
		<ModalContainer>
			<ModalTitle>Current Chart Saved</ModalTitle>
			<ModalParagraph>
				The chart you previously created will be saved under{' '}
				{DRAFTS_PROJECT_NAME} in My Projects.
			</ModalParagraph>
			<ModalActionsContainer>
				<ModalButton
					data-qa-id="chart-saved-modal-go-back-btn"
					className={button.darkBlue}
					onClick={onGoBack}
				>
					Go Back
				</ModalButton>
				<ModalButton
					data-qa-id="chart-saved-modal-ok-btn"
					className={button.blue}
					onClick={onOk}
				>
					OK
				</ModalButton>
			</ModalActionsContainer>
		</ModalContainer>
	);
};

// @ts-expect-error TS7031: Binding element 'onReviewCurre...
const DataSetLimitModal = ({ onReviewCurrent, onCreateNew }) => {
	return (
		<ModalContainer>
			<ModalTitle>Data Set Limit</ModalTitle>
			<ModalParagraph>
				You currently have an Analytics chart in progress. Sending this data to
				this chart will exceed the {MAX_DATASETS_ALLOWED} data sets limit.
				<br />
				<br />
				Would you like to add selected data set to the current chart, discard
				your current chart, or save the current chart to start a new chart?
			</ModalParagraph>
			<ModalActionsContainer>
				<ModalButton
					data-qa-id="dataset-limit-modal-review-current-btn"
					className={button.darkBlue}
					onClick={onReviewCurrent}
				>
					REVIEW CURRENT CHART
				</ModalButton>
				<ModalButton
					data-qa-id="dataset-limit-modal-create-new-btn"
					className={button.blue}
					onClick={onCreateNew}
				>
					CREATE NEW CHART
				</ModalButton>
			</ModalActionsContainer>
		</ModalContainer>
	);
};

interface TitleModalProps {
	title: string;
	marketName: string;
	onChangeTitle: (title: string) => void;
	onChangeMarketName: (marketName: string) => void;
	attributeToPlot: string;
	onChangeAttributeToPlot: (attributeToPlot: AttributeToPlotState) => void;
	compType: CompType;
	onSubmit: () => void;
	urlMarkets: Market[];
	filters: FiltersObject;
}

const TitleModal = ({
	title,
	marketName,
	onChangeTitle,
	onChangeMarketName,
	attributeToPlot,
	onChangeAttributeToPlot,
	compType,
	onSubmit,
	urlMarkets,
	filters,
}: TitleModalProps) => {
	const hasMufaChartBuilderAccess = useHasMufaChartbuilderAccess();
	const { salesCompsAnalytics2 } = useFeatureFlags();
	const showMultiMarket = urlMarkets.length > 1;

	const incompatibleFiltersToDataSetType = useMemo(() => {
		if (
			compType === 'property' &&
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			attributeToDataSetType[attributeToPlot] === DataSetType.COMMERCIAL
		) {
			return Object.keys(mufaLevelFilters).some((filter) =>
				// @ts-expect-error TS2345: Argument of type 'string' is n...
				isSet(filters[filter], filter)
			);
		}
		return false;
	}, [attributeToPlot, compType, filters]);

	const attributeToPlotItems = getAttrOptionsForCreateDSForm(
		hasMufaChartBuilderAccess,
		salesCompsAnalytics2
	);

	let canSubmit = title.length > 0;
	if (compType === 'property') {
		canSubmit = canSubmit && attributeToPlot.length > 0;
	}
	if (showMultiMarket) {
		canSubmit = canSubmit && !!marketName;
	}

	return (
		<Wrap style={{ width: 650 }}>
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					onSubmit();
				}}
			>
				<ModalTitle>Title Data Set</ModalTitle>
				<TextInput
					data-qa-id={'set-dataset-title'}
					label="Title Your Data Set"
					placeholder="Enter name here"
					value={title}
					onChange={(value) => {
						onChangeTitle(value);
					}}
				/>
				{showMultiMarket && (
					<ChartBuilderMarketFormSelect
						items={mapMarketsToSelectOptionsAndSort(urlMarkets)}
						value={marketName}
						onChange={({ selectedItem }) => {
							onChangeMarketName(selectedItem?.value.toString() ?? '');
						}}
					/>
				)}
				{compType === 'property' && (
					<div>
						<FormSelect
							data-qa-id="select-attribute-to-plot"
							label="Attribute to Plot"
							items={attributeToPlotItems}
							onChange={({ selectedItem }) => {
								onChangeAttributeToPlot(
									selectedItem?.value == null
										? ''
										: (selectedItem.value as AttributeToPlotKey)
								);
							}}
							value={attributeToPlot}
							placeholder="Select from Dropdown"
						/>
						{incompatibleFiltersToDataSetType && (
							<IncompatibleFiltersMessage>
								RealPage Multifamily Filters will only be applied to RealPage
								Attributes
							</IncompatibleFiltersMessage>
						)}
					</div>
				)}
				<ModalButtons>
					<ModalButton
						type="submit"
						data-qa-id="title-modal-done-btn"
						className={button.blue}
						disabled={!canSubmit}
						style={{
							flex: 'unset',
						}}
					>
						DONE
					</ModalButton>
				</ModalButtons>
			</Form>
		</Wrap>
	);
};

const NoPermissionModal = () => {
	const { goBack } = useHistoryState();

	return (
		<Modal onClose={goBack}>
			<NoAnalyticsAccessModal onClose={goBack} />
		</Modal>
	);
};

const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 3px;
	font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	background-color: #ffffff;
	padding: 40px;
	min-width: 300px;
	max-width: 630px;
	margin: 0 auto;
`;

const ModalActionsContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const ModalButton = styled.button`
	flex: 1px;
	height: unset;
	padding: 17px 24px;
	line-height: 1rem;
	&.button_dark_blue:focus {
		background-color: #464d5e;
	}
`;

const IncompatibleFiltersMessage = styled(ModalParagraph)`
	margin-top: 0.75rem;
`;
