import Sidebar from './Components/Sidebar';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { CompType } from 'types';
import { FiltersObject } from 'models/filters/types';
import { SearchLayoutProps } from 'Layouts/SearchLayout';
import { User } from '@compstak/common';
import { SidebarV2 } from './Components/SidebarV2';

export type SearchSidebarProps = {
	compType: CompType;
	filters: FiltersObject;
	onFilterChange: (newFilters: Partial<FiltersObject>) => void;
	params: SearchLayoutProps['params'];
	search: SearchLayoutProps['search'];
	selection: SearchLayoutProps['selection'];
	onNewSearchClick: NoArgCallback;
	user: User;
};

export default function SidebarWrapper(props: SearchSidebarProps) {
	const { sidebarRevampFF } = useFeatureFlags();

	return sidebarRevampFF ? <SidebarV2 {...props} /> : <Sidebar {...props} />;
}
