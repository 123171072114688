import { useFeatureFlags } from 'hooks/useFeatureFlags';
import SearchLayout from 'Layouts/SearchLayout';
import { GoToSurveyForCreditsBanner } from 'Pages/SurveyForCredits/components/goToSurveyBanner';
import { ExchangeDashboard } from '../ExchangeDashboard';
import { useIsExchangePlusUser } from './exchangePlus/exchangePlusStatus';
import { RouteComponentProps } from 'router/migration/types';
import { SearchLayoutRouteProps } from 'router';

export type SearchLayoutCommonProps<RouteParams = {}> = RouteComponentProps<
	RouteParams,
	{ isMyComps: boolean }
> & { route: SearchLayoutRouteProps };

export const ExchangeHome = (props: SearchLayoutCommonProps) => {
	const flags = useFeatureFlags();
	const isExchangePlusUser = useIsExchangePlusUser();

	if (!isExchangePlusUser && flags['exchange-dashboard']) {
		return (
			<>
				<GoToSurveyForCreditsBanner />
				<ExchangeDashboard />
			</>
		);
	}

	return (
		<>
			<GoToSurveyForCreditsBanner />
			<SearchLayout {...props} />;
		</>
	);
};
