import { Pending as PendingOriginal, Table } from '@compstak/ui-kit';
import { useSimilarSalesQuery } from 'api';
import { useIsExchange } from 'hooks';
import { useNavigate } from 'router';
import {
	SeeAllLink,
	TabContentContainer,
	TabContentHeader,
	TabContentTitle,
} from '../../Components';
import { TabsProps } from '../../Tabs';
import { useSaleMarket } from '../../useSaleMarket';
import { useCompAddressesSearchUrl } from '../../utils';
import { columns } from './columns';
import { useMemo } from 'react';
import styled from 'styled-components';

type Props = Pick<TabsProps, 'data'>;

export const SimilarSales = ({ data }: Props) => {
	const navigate = useNavigate();
	const isExchange = useIsExchange();
	const market = useSaleMarket({ id: data.id });
	const propertyId = data.portfolio[0].propertyId;

	const { data: similarSalesData, isFetching } = useSimilarSalesQuery({
		propertyId,
	});

	const addresses = useMemo(
		() => [
			...new Set(
				similarSalesData?.comps.map(
					(similarSale) => similarSale.portfolio[0].buildingAddressAndCity
				) ?? []
			),
		],
		[similarSalesData]
	);

	const compsetSalesUrl = useCompAddressesSearchUrl({
		compType: 'sale',
		addresses,
		market,
	});

	return (
		<TabContentContainer>
			<TabContentHeader>
				<TabContentTitle>Similar Sales</TabContentTitle>

				{isFetching ? (
					<Pending margin="0" />
				) : (
					<SeeAllLink
						isDisabled={!similarSalesData}
						to={compsetSalesUrl}
						data-qa-id="SeeAllSalesLink"
					>
						view all sales in this set
					</SeeAllLink>
				)}
			</TabContentHeader>
			<Table
				columns={columns}
				rows={similarSalesData?.comps ?? []}
				isLoading={isFetching}
				extraData={{ isExchange }}
				bodyCellProps={({ row }) => ({
					onClick: () => {
						navigate(`/comps/sales/${row.id}`, {
							state: {
								fromNewSalePage: true,
							},
						});
					},
				})}
			/>
		</TabContentContainer>
	);
};

const Pending = styled(PendingOriginal)`
	height: 1em;
	width: 12.5rem;
	margin-left: auto;
`;
