import { FiltersObject } from 'models/filters/types';
import { filtersToQueryString } from 'models/filters/util/urls';
import { PermissionsState } from 'Pages/Login/reducers';
import { CompType } from 'types';
import { getAttributeHash } from 'util/comp-format/src/format';
import { DataSet, DataSetType, Shape } from '../analytics';
import { DATASET_TYPES_URL_SEARCH_PARAM_KEY } from './chartBuilderConstants';
import { dataSetTypeToFormattingAttr } from './chartBuilderConstants';

export function attributesHashFromShape(shape: Shape) {
	return getAttributesHashForDatasetType(shape.dataSetType);
}

export function getAttributesHashForDatasetType(dataSetType?: DataSetType) {
	return getAttributeHash(
		dataSetTypeToFormattingAttr[dataSetType ?? DataSetType.LEASES]
	);
}

export function createSendToAnalyticsHref({
	filters,
	title,
	dataSetTypes,
	compType,
}: {
	filters: FiltersObject;
	title?: string;
	dataSetTypes?: DataSetType[];
	compType?: CompType;
}) {
	const titlePart = title ? `title=${encodeURIComponent(title)}` : null;
	const filtersPart = `series=${encodeURIComponent(
		filtersToQueryString(filters)
	)}`;
	const dataSetTypesPart = dataSetTypes
		? `${DATASET_TYPES_URL_SEARCH_PARAM_KEY}=${encodeURIComponent(
				JSON.stringify(dataSetTypes)
		  )}`
		: null;
	const tabPart = compType ? `tab=${compType}` : null;

	return (
		'/analytics/import?' +
		[titlePart, filtersPart, dataSetTypesPart, tabPart]
			.filter(Boolean)
			.join('&')
	);
}

export function isPermittedDataset(
	dataset: DataSet,
	permissions: PermissionsState
) {
	if (!dataset) {
		console.error('dataset is falsy!?', dataset);
		return false;
	}
	if (!permissions) {
		console.error('permissions is falsy!?', permissions);
		return false;
	}
	try {
		if (dataset.type === DataSetType.MUFA) {
			const datasetMarket = dataset.filters?.market;
			if (!datasetMarket || !datasetMarket?.id) {
				console.warn('Encountered a dataset with no market specified!?');
				return false;
			}
			const mufaChartBuilderAllowed =
				permissions?.[datasetMarket.id]?.multifamilyAccess;
			return mufaChartBuilderAllowed;
		}
		return true;
	} catch (err) {
		console.error('Filtering permitted datasets threw!?', err);
		return false;
	}
}

export function getDataSetTypesFromUrlSearchParam(
	urlSearchParamValue: string | undefined,
	extraOptions: { logErrors: boolean } = { logErrors: true }
) {
	const DEFAULT_DATA_SET_TYPES = [DataSetType.COMMERCIAL];
	if (!urlSearchParamValue) {
		return DEFAULT_DATA_SET_TYPES;
	}
	if (typeof urlSearchParamValue !== 'string') {
		extraOptions?.logErrors &&
			console.error(
				'urlSearchParamValue should be a string!?',
				urlSearchParamValue
			);
		return DEFAULT_DATA_SET_TYPES;
	}
	try {
		const dataSetTypesJson = decodeURIComponent(urlSearchParamValue);
		const dataSetTypes = JSON.parse(dataSetTypesJson) as DataSetType[];
		if (
			!Array.isArray(dataSetTypes) ||
			![1, 2].includes(dataSetTypes.length) ||
			dataSetTypes.some((dst) => typeof dst !== 'string')
		) {
			throw Error('Invalid dataSetTypes');
		}
		if (
			dataSetTypes.length === 2 &&
			(dataSetTypes[0] !== DataSetType.COMMERCIAL ||
				dataSetTypes[1] !== DataSetType.MUFA)
		) {
			throw Error('Invalid dataSetTypes (length=2)');
		}
		return dataSetTypes;
	} catch (err) {
		extraOptions?.logErrors && console.error(err, urlSearchParamValue);
	}
	return DEFAULT_DATA_SET_TYPES;
}
