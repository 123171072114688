import { HTMLAttributes, memo } from 'react';
import styled from 'styled-components';
import FilterSVG from 'ui/svg_icons/filter.svg';

export const FilterMenuToggler = memo<HTMLAttributes<HTMLDivElement>>(
	(props) => {
		return (
			<FilterMenuTogglerContainer {...props}>
				<FilterIcon width="23px" height="17px" />
			</FilterMenuTogglerContainer>
		);
	}
);

FilterMenuToggler.displayName = 'FilterMenuToggler';

export const TopRightContainerItem = styled.div`
	min-width: 56px;
	height: 48px;
`;

const FilterMenuTogglerContainer = styled(TopRightContainerItem)`
	align-items: center;
	display: flex;
	justify-content: center;
	border-radius: 2px;
	margin-top: 20px;
	background-color: ${({ theme }) => theme.colors.blue.blue400};
	box-shadow: 0 2px rgba(48, 52, 65, 0.1);
	cursor: pointer;
	transition: transform 0.2s ease-in-out;

	&:active {
		margin-top: 1.3rem;
	}

	@media (min-width: 768px) {
		display: none;
	}
`;

const FilterIcon = styled(FilterSVG)`
	fill: rgba(255, 255, 255, 0.6);
`;
