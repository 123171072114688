import { CopyPortfolioParams, Portfolio, useCopyPortfolioMutation } from 'api';
import { routes, useNavigate } from 'router';
import { PortfolioDialog } from '../Components';

type Props = {
	portfolio: Portfolio;
	closeDialog: () => void;
};

export const CopyPortfolioDialog = ({ portfolio, closeDialog }: Props) => {
	const { mutateAsync: copyPortfolio, isLoading } = useCopyPortfolioMutation();
	const navigate = useNavigate();

	return (
		<PortfolioDialog
			title="Duplicate Portfolio"
			closeDialog={closeDialog}
			isLoadingMutation={isLoading}
			onSubmit={async (values) => {
				const body: CopyPortfolioParams['body'] = {
					portfolioId: portfolio.id,
					name: values.name,
					description: undefined,
				};
				if (values.description.length > 0) {
					body.description = values.description;
				}
				await copyPortfolio({
					body,
				});
				closeDialog();
				navigate(routes.portfolios.path);
			}}
			initialFormState={{
				name: `${portfolio.name} copy`,
				description: portfolio.description ?? '',
			}}
		/>
	);
};
