import React, { memo, useMemo, useCallback, useEffect, useState } from 'react';
import Modal from 'Singletons/Modal';
import { useDispatch } from 'react-redux';
import { loadSavedSearches } from 'Pages/SavedSearches/actions';
import { ChartBuilderActions } from '../../actions';
import { ModalActions } from 'Singletons/Modal/actions';
import styles from '../styles/analytics.less';
import { Chart } from 'Pages/Analytics/analytics';
import {
	FormSelect,
	Button,
	SelectListItem,
	SelectOnChangeParams,
} from '@compstak/ui-kit';
import { DataSetGroupTitles, DataSetType } from 'Pages/Analytics/analytics';
import {
	SalesAttributeToPlotValues,
	AttributeToPlotCommercialValues,
} from 'Components/Graphs/Selectors';
import {
	SavedSearch,
	SavedSearchType,
	useSavedSearches,
} from 'reducers/savedSearches';
import { mapMarketsToSelectOptionsAndSort } from './DataSets/CreateNewDataSetV2/utils';
import styled from 'styled-components';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { filtersFromServerJSON } from 'models/filters/util';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { MarketsState, useUser } from 'Pages/Login/reducers';
import { useMarkets } from 'hooks/useMarkets';
import { ChartBuilderMarketFormSelect } from 'Pages/Analytics/Components/Modals/ChartBuilderMarketFormSelect';
import { FiltersObject } from 'models/filters/types';

const getSavedSearchesOptionsByType = ({
	type,
	savedSearches,
	markets,
}: {
	type: SavedSearchType;
	savedSearches: SavedSearch[];
	markets: MarketsState;
}): SavedSearchListItem[] => {
	return savedSearches
		.filter((savedSearch) => savedSearch.searchType === type)
		.map((savedSearch) => {
			const savedSearchFilters = filtersFromServerJSON(
				type,
				markets,
				savedSearch.filters
			);
			return {
				title: savedSearch.name,
				value: savedSearch.id,
				isMultiMarket: getFiltersMarkets(savedSearchFilters).length > 1,
			};
		});
};

type SavedSearchListItem = SelectListItem & {
	isMultiMarket?: boolean;
	selectedSavedSearchData?: SavedSearch;
};

type SelectSavedSearchProps = {
	markets: MarketsState;
	selectedSearch: SavedSearchListItem | null;
	setSelectedSearch: (search: SavedSearchListItem) => void;
};

const SavedSearchSelector = memo<SelectSavedSearchProps>(
	({ markets, setSelectedSearch, selectedSearch }) => {
		const savedSearches = useSavedSearches().savedSearchList;
		const { salesCompsAnalytics2 } = useFeatureFlags();

		const savedSearchListItems = useMemo(() => {
			if (!savedSearches) {
				return [];
			}

			const savedSearchesOptions: SavedSearchListItem[] = [];

			const leaseSavedSearches = getSavedSearchesOptionsByType({
				type: SavedSearchType.LEASES,
				savedSearches,
				markets,
			});

			if (leaseSavedSearches.length) {
				savedSearchesOptions.push({
					type: 'header',
					value: DataSetGroupTitles.LEASES,
				});
				savedSearchesOptions.push(...leaseSavedSearches);
			}

			if (salesCompsAnalytics2) {
				const saleSavedSearches = getSavedSearchesOptionsByType({
					type: SavedSearchType.SALES,
					savedSearches,
					markets,
				});

				if (saleSavedSearches.length) {
					savedSearchesOptions.push({
						type: 'header',
						value: DataSetGroupTitles.SALES,
					});
					savedSearchesOptions.push(...saleSavedSearches);
				}
			}

			// TODO AP-12954: uncomment this when the green light is on
			// const propertySavedSearches = getSavedSearchesOptionsByType({
			// 	type: SavedSearchType.PROPERTIES,
			// 	savedSearches,
			// 	markets,
			// });

			// if (propertySavedSearches.length) {
			// 	savedSearchesOptions.push({
			// 		type: 'header',
			// 		value: DataSetGroupTitles.PROPERTIES,
			// 	});
			// 	savedSearchesOptions.push(...propertySavedSearches);
			// }

			return savedSearchesOptions;
		}, [savedSearches, salesCompsAnalytics2, markets]);

		const handleSelectChange = useCallback(
			({ selectedItem }: { selectedItem?: SavedSearchListItem | null }) => {
				if (!selectedItem) return;
				const { value: newSelectedId, isMultiMarket } = selectedItem;

				const selectedSavedSearch = savedSearches?.find(
					(item) => item.id === newSelectedId
				);

				selectedSavedSearch &&
					setSelectedSearch({
						title: selectedSavedSearch.name,
						value: selectedSavedSearch.id,
						selectedSavedSearchData: selectedSavedSearch,
						isMultiMarket,
					});
			},
			[savedSearches, setSelectedSearch]
		);

		return (
			<FormSelect
				isSearchable
				label="Select Saved Search"
				items={savedSearchListItems}
				onChange={handleSelectChange}
				value={selectedSearch?.value}
				placeholder={
					savedSearchListItems.length ? 'Choose' : 'No options available'
				}
				disabled={!savedSearchListItems.length}
			/>
		);
	}
);

SavedSearchSelector.displayName = 'SavedSearchSelector';

type SavedSearchesProps = {
	chartBuilderActions: ChartBuilderActions;
	chartDraft: Chart;
	modalActions: ModalActions;
};

const SavedSearches = ({
	chartBuilderActions,
	chartDraft,
	modalActions,
}: SavedSearchesProps) => {
	const [search, setSearch] = useState<SavedSearchListItem | null>(null);
	const [market, setMarket] = useState<SelectListItem | null>(null);

	const user = useUser();
	const markets = useMarkets();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadSavedSearches(user));
	}, [dispatch, user]);

	const getSavedSearchFilters = () => {
		if (!search?.selectedSavedSearchData) return null;

		return filtersFromServerJSON(
			search.selectedSavedSearchData.searchType,
			markets,
			search.selectedSavedSearchData.filters
		);
	};

	const savedSearchFilters = getSavedSearchFilters();
	const marketListItems = mapMarketsToSelectOptionsAndSort(
		savedSearchFilters ? getFiltersMarkets(savedSearchFilters) : []
	);

	const handleSavedSearchSelect = (selectedSearch: SavedSearchListItem) => {
		setSearch(selectedSearch);
	};

	const handleMarketSelect = ({ selectedItem }: SelectOnChangeParams) => {
		selectedItem && setMarket(selectedItem);
	};

	const handleSubmit = () => {
		if (!search?.selectedSavedSearchData) return;

		const filtersFromSavedSearchJSON = getSavedSearchFilters();
		const singleMarket = filtersFromSavedSearchJSON
			? getFiltersMarkets(filtersFromSavedSearchJSON)[0]
			: null;
		const marketObj = markets.list.find((m) => m.name === market?.value);
		const filters: Partial<FiltersObject> = savedSearchFilters
			? mergeFilters(savedSearchFilters, {
					market: search.isMultiMarket ? marketObj! : singleMarket!,
					// @ts-expect-error TS2322: Type 'null' is not assignable ...
					markets: null,
				})
			: {};
		chartBuilderActions.addDataSet(
			chartDraft,
			search.selectedSavedSearchData.name,
			filters,
			markets,
			search.selectedSavedSearchData.searchType === SavedSearchType.SALES
				? SalesAttributeToPlotValues.CAP_RATE
				: AttributeToPlotCommercialValues.STARTING_RENT,
			{
				dataSetType:
					search.selectedSavedSearchData.searchType === SavedSearchType.SALES
						? DataSetType.SALES
						: DataSetType.COMMERCIAL,
			}
		);
		modalActions.hideModal();
	};

	return (
		<ModalContainer>
			<ModalTitle>Saved Searches</ModalTitle>
			<SavedSearchSelector
				markets={markets}
				setSelectedSearch={handleSavedSearchSelect}
				selectedSearch={search}
			/>
			<br />
			{search?.isMultiMarket && (
				<ChartBuilderMarketFormSelect
					items={marketListItems}
					value={market?.value}
					onChange={handleMarketSelect}
				/>
			)}
			<ButtonContainer>
				<StyledButton
					variant="secondary"
					onClick={() => modalActions.popModal()}
				>
					Back
				</StyledButton>
				<StyledButton
					onClick={handleSubmit}
					disabled={!search || (search.isMultiMarket && !market)}
					variant="primary"
				>
					Done
				</StyledButton>
			</ButtonContainer>
		</ModalContainer>
	);
};

Modal.addComponent({
	id: 'analytics-saved-searches',
	Component: SavedSearches,
	className: styles.analyticsModal,
});

export default 'analytics-saved-searches';

const ModalContainer = styled.div`
	width: 632px;
	background-color: ${(props) => props.theme.colors.white.white};
	color: ${(props) => props.theme.colors.gray.gray500};
	padding: 40px 48px;
	display: block;
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 24px;
	margin-top: 24px;
`;

const ModalTitle = styled.h3`
	color: ${(props) => props.theme.colors.gray.gray700};
	font-weight: 350;
	margin-bottom: 24px;
`;

const StyledButton = styled(Button)`
	width: 152px;
	height: 50px;
	&:disabled {
		background-color: ${(props) => props.theme.colors.gray.n70};
	}
`;
