import { usePortfolioByIdQuery } from 'api';
import { useMemo } from 'react';
import { usePortfolioFilters } from './PortfolioFiltersProvider';

type Props = {
	portfolioId: number;
};

export const usePortfolioTotalActiveLeaseCount = ({ portfolioId }: Props) => {
	const { filters } = usePortfolioFilters();

	const { data: portfolio } = usePortfolioByIdQuery({
		id: portfolioId,
		filters,
	});

	return useMemo(() => {
		if (!portfolio) return 0;
		return portfolio.markets.reduce(
			(sum, m) => sum + (m.activeLeaseCount ?? 0),
			0
		);
	}, [portfolio]);
};
