import {
	collapseSubmarketsList,
	loadSubmarkets,
	removeSubmarkets,
} from 'actions/search';
import { SubmarketPolygon } from 'api/submarketPolygons/useSubmarketPolygons';
import { Checkbox } from 'Components';
import { SubmarketsFilter } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	getSearchTermsForMarket,
	getSearchTermsForSubmarkets,
} from 'util/getSearchTerms';
import { trimString } from 'util/trimString';
import { useAppSelector } from 'util/useAppSelector';
import MapControl, { MapControlProps } from './MapControl';
import { MapControlSearchInput } from './MapControlSearchInput';
import {
	CollapseButton,
	CollapsedContainer,
	Container,
	IconArrowBottom,
	MapControlItem,
	MarketLabel,
	MarketSection,
	ScrollContainer,
} from './MapControl.styles';
import { useMarkets } from 'hooks/useMarkets';
import { FilterChips } from 'Components/Filters/Base/Filter/FilterChips';

type SubmarketsMapControlProps = MapControlProps & {
	collapsedByDefault?: boolean;
};

// TODO to remove once sidebarRevampFF is on (don't forget about css)
const SubmarketsMapControl = (
	Component: React.ComponentType<MapControlProps>
) => {
	return function SubmarketsMapControlComponent(
		props: SubmarketsMapControlProps
	) {
		const { active, collapsedByDefault, filters, onFilterChange } = props;

		const [searchTerm, setSearchTerm] = useState('');

		const dispatch = useDispatch();

		useEffect(() => {
			if (active) {
				dispatch(loadSubmarkets(getFiltersMarkets(filters)));
			} else {
				dispatch(removeSubmarkets());
			}
			// this effect should trigger only on `active` change
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [active, dispatch]);

		const marketIdToSubmarkets = useAppSelector(
			(s) => s.searchReducer.marketIdToSubmarkets
		);
		const collapsed = useAppSelector(
			(s) => s.searchReducer.submarketsListCollapsed
		);
		const markets = useMarkets();

		const collapseSubmarketList = useCallback(() => {
			dispatch(collapseSubmarketsList(true));
		}, [dispatch]);

		const expandSubmarketList = useCallback(() => {
			dispatch(collapseSubmarketsList(false));
		}, [dispatch]);

		useEffect(() => {
			if (collapsedByDefault) {
				collapseSubmarketList();
			}
		}, [collapsedByDefault, collapseSubmarketList]);

		const trimmedSearchTerm = trimString(searchTerm).toLowerCase();

		const marketNameToSubmarkets = useMemo(() => {
			if (!marketIdToSubmarkets) {
				return {};
			}

			let marketToSubmarkets: Record<string, SubmarketPolygon[]> = {};

			if (trimmedSearchTerm) {
				marketToSubmarkets = Object.keys(marketIdToSubmarkets).reduce<
					Record<string, SubmarketPolygon[]>
				>((acc, _marketId) => {
					const marketId = Number(_marketId);
					const market = markets[marketId];
					const marketDisplayName = market.displayName;

					const isMatchingMarket = getSearchTermsForMarket(
						market,
						false
					).includes(trimmedSearchTerm);

					// add all market submarkets if there is a match with the market name
					// otherwise find matching submarkets and add only them
					if (isMatchingMarket) {
						acc[marketDisplayName] = marketIdToSubmarkets[marketId];
					} else {
						const marketSubmarkets = marketIdToSubmarkets[marketId];
						const searchTermsToSubmarkets =
							getSearchTermsForSubmarkets(marketSubmarkets);
						const matchingSubmarkets = Object.keys(searchTermsToSubmarkets)
							.filter((term) => term.includes(trimmedSearchTerm))
							.map((term) => searchTermsToSubmarkets[term]);

						if (matchingSubmarkets.length) {
							acc[marketDisplayName] = matchingSubmarkets;
						}
					}

					return acc;
				}, {});
			} else {
				marketToSubmarkets = Object.keys(marketIdToSubmarkets).reduce<
					Record<string, SubmarketPolygon[]>
				>((acc, _marketId) => {
					const marketId = Number(_marketId);
					const marketDisplayName = markets[marketId].displayName;
					acc[marketDisplayName] = marketIdToSubmarkets[marketId];
					return acc;
				}, {});
			}

			return Object.keys(marketToSubmarkets)
				.sort()
				.reduce<Record<string, SubmarketPolygon[]>>(
					(obj, sortedDisplayName) => {
						obj[sortedDisplayName] = marketToSubmarkets[sortedDisplayName];
						return obj;
					},
					{}
				);
		}, [markets, marketIdToSubmarkets, trimmedSearchTerm]);

		const toggleSubmarket = (clickedSubmarketId: number, checked: boolean) => {
			const currentSubmarkets = filters.submarkets;
			const clickedSubmarket = Object.values(marketIdToSubmarkets ?? {})
				.flat()
				.find(
					(selectedSubmarket) =>
						selectedSubmarket.properties.id === clickedSubmarketId
				)?.properties;

			let newSubmarkets: SubmarketsFilter | null;

			if (!clickedSubmarket) {
				return;
			}

			if (checked) {
				newSubmarkets = currentSubmarkets
					? [...currentSubmarkets, clickedSubmarket]
					: [clickedSubmarket];
			} else {
				newSubmarkets = (currentSubmarkets ?? []).filter(
					(submarket) => submarket.id !== clickedSubmarketId
				);
				if (newSubmarkets.length === 0) {
					newSubmarkets = null;
				}
			}
			onFilterChange({ submarkets: newSubmarkets });
		};

		const renderSubmarketList = (submarkets: SubmarketPolygon[]) => {
			return submarkets.map(({ properties }) => {
				const isChecked = Boolean(
					filters.submarkets?.some(({ id }) => id === properties.id)
				);
				const inputId = String(properties.id);

				return (
					<MapControlItem
						key={properties.id}
						isChecked={isChecked}
						onClick={() => toggleSubmarket(properties.id, !isChecked)}
					>
						<Checkbox
							name="submarket"
							id={inputId}
							value={properties.id}
							checked={isChecked}
							data-qa-id="toggle-submarket-checkbox"
						/>
						<label>{properties.name}</label>
					</MapControlItem>
				);
			});
		};

		const renderSubmarkets = () => {
			if (!active || !marketIdToSubmarkets) return undefined;

			if (collapsed) {
				const selected = Object.values(marketIdToSubmarkets)
					.flat()
					.filter((submarket) =>
						filters.submarkets?.some(
							(selectedSubmarket) =>
								selectedSubmarket.id === submarket.properties.id
						)
					);

				return (
					<Container>
						<CollapsedContainer
							data-qa-id="expand-submarket-list-button"
							onClick={expandSubmarketList}
						>
							<FilterChips
								chips={
									!selected.length
										? ['All Submarkets']
										: selected.map((submarket) => submarket.properties.name)
								}
							/>
							<IconArrowBottom />
						</CollapsedContainer>
					</Container>
				);
			}

			const hasMultipleMarkets = Object.keys(marketIdToSubmarkets).length > 1;

			return (
				<Container>
					{hasMultipleMarkets && (
						<MapControlSearchInput
							placeholder="Search by market or submarket"
							onChange={setSearchTerm}
							value={searchTerm}
						/>
					)}
					<ScrollContainer>
						{Object.keys(marketNameToSubmarkets).map((marketName) => {
							return (
								<MarketSection key={marketName}>
									{hasMultipleMarkets && (
										<MarketLabel>{marketName}</MarketLabel>
									)}
									<span>
										{renderSubmarketList(marketNameToSubmarkets[marketName])}
									</span>
								</MarketSection>
							);
						})}
					</ScrollContainer>
					<CollapseButton
						data-qa-id="submarkets-filter-collapse-button"
						onClick={collapseSubmarketList}
						variant="secondary"
					>
						Collapse
					</CollapseButton>
				</Container>
			);
		};

		return <Component {...props}>{renderSubmarkets()}</Component>;
	};
};

SubmarketsMapControl.displayName = 'SubmarketsMapControl';

export default SubmarketsMapControl(MapControl);
