import { User } from '@compstak/common';
import { useSubmarketsByMarket } from 'api/submarkets/useSubmarketsByMarket';
import { useIsExchangePlusUser } from 'exchange/exchangePlus/exchangePlusStatus';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { MarketsState, PermissionsState } from 'Pages/Login/reducers';
import { useDispatch } from 'react-redux';
import { useFilters } from 'reducers/filtersReducer';
import Modal from 'Singletons/Modal';
import { modalActions } from 'Singletons/Modal/actions';
import styled from 'styled-components';
import button from 'ui/styles/button.less';
import Dollar from '../../../../../ui/svg_icons/dollar.svg';
import MycompsSavedsearch from '../../../../../ui/svg_icons/mycomps_savedsearch.svg';
import Plus from '../../../../../ui/svg_icons/plus.svg';
import Submarkets from '../../../../../ui/svg_icons/submarkets.svg';
import Time from '../../../../../ui/svg_icons/time.svg';
import { ChartBuilderActions } from '../../actions';
import { FromPortfolioModal } from '../../../Components/Modals/FromPortfolioModal';
import styles from '../styles/analytics.less';
import modalStyles from '../styles/chart-builder-modals.less';
import CreateNewDataSet from './DataSets/CreateNewDataSet';
import SavedSearches from './SavedSearches';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useModal } from 'providers/ModalProvider';

export const BlankAnalytics = (props: {
	markets: MarketsState;
	user: User;
	chartBuilderActions: ChartBuilderActions;
	permissions: PermissionsState;
}) => {
	const { openModal, closeModal } = useModal();

	const portfolioAnalytics = useFeatureFlags()['portfolio-analytics'];
	const isExchangePlusUser = useIsExchangePlusUser();

	const dispatch = useDispatch();
	const pushModal = (...args: Parameters<typeof modalActions.pushModal>) =>
		dispatch(modalActions.pushModal(...args));

	const [filters] = useFilters();
	const marketForDefaultChart = getFiltersMarkets(filters)
		.filter(({ id }) => props.permissions[id]?.analytics)
		.sort((market1, market2) =>
			market1.displayName
				.toLowerCase()
				.localeCompare(market2.displayName.toLowerCase())
		)[0];
	const { data: marketSubmarkets } = useSubmarketsByMarket(
		marketForDefaultChart
	);

	const openNewDataSetModal = () => {
		pushModal(CreateNewDataSet, props);
	};

	const openSavedSearchModal = () => {
		pushModal(SavedSearches, props);
	};

	const firstChart = () => {
		props.chartBuilderActions.createFirstDefaultChart(props.markets);
	};

	const secondChart = () => {
		props.chartBuilderActions.createSecondDefaultChart(
			marketForDefaultChart,
			props.markets,
			marketSubmarkets ?? []
		);
	};

	const thirdChart = () => {
		props.chartBuilderActions.createThirdDefaultChart(
			marketForDefaultChart,
			props.markets
		);
	};

	return (
		<div className={`${modalStyles.container} ${modalStyles.blankAnalytics}`}>
			<span className={modalStyles.headers}>
				<h3>Start New Chart</h3>
			</span>
			<div className={modalStyles.optionCategory}>
				<h5>Use my own data series</h5>
				<div className={modalStyles.options}>
					<div
						className={modalStyles.ownSeriesOption}
						onClick={openNewDataSetModal}
					>
						<SpanStyled className={modalStyles.icon}>
							<Plus width={32} height={32} />
						</SpanStyled>
						<span className={modalStyles.verticalLine} />
						<span className={modalStyles.box}>
							<div className={modalStyles.medium}>Create</div>
							<div className={modalStyles.large}>New Data Set</div>
						</span>
					</div>
					<div
						className={modalStyles.ownSeriesOption}
						onClick={openSavedSearchModal}
					>
						<DivStyled className={modalStyles.icon}>
							<MycompsSavedsearch width="32px" height="32px" />
						</DivStyled>
						<span className={modalStyles.verticalLine} />
						<span className={modalStyles.box}>
							<div className={modalStyles.medium}>From</div>
							<div className={modalStyles.large}>Saved Search</div>
						</span>
					</div>
					{portfolioAnalytics && !isExchangePlusUser && (
						<>
							<div
								className={modalStyles.ownSeriesOption}
								onClick={() =>
									openModal({
										modalContent: (
											<FromPortfolioModal closeModal={closeModal} />
										),
									})
								}
							>
								<DivStyled className={modalStyles.icon}>
									<MycompsSavedsearch width="32px" height="32px" />
								</DivStyled>
								<span className={modalStyles.verticalLine} />
								<span className={modalStyles.box}>
									<div className={modalStyles.medium}>From</div>
									<div className={modalStyles.large}>Portfolio</div>
								</span>
							</div>
						</>
					)}
				</div>
			</div>
			<div className={modalStyles.optionCategory}>
				<h5>Choose from pre-made charts</h5>
				<div className={modalStyles.options}>
					<div className={modalStyles.preMadeChart} onClick={firstChart}>
						<div className={modalStyles.preMadeChartHeader}>
							<Dollar width={28} height={20} />
							<div className={modalStyles.preMadeChartTitle}>
								<div className={modalStyles.medium}>Trends in</div>
								<div className={modalStyles.large}>Starting Rents</div>
							</div>
						</div>
						<span className={modalStyles.horizontalLine} />
						<div className={modalStyles.small}>
							NYC vs. SF vs. LA vs. Chicago
						</div>
						<div className={modalStyles.small}>CBD, Office Buildings</div>
					</div>
					<div className={modalStyles.preMadeChart} onClick={secondChart}>
						<div className={modalStyles.preMadeChartHeader}>
							<SubmarketsStyled width={21} height={21} />
							<div className={modalStyles.preMadeChartTitle}>
								<div className={modalStyles.medium}>Compare</div>
								<div className={modalStyles.large}>Submarkets</div>
							</div>
						</div>
						<span className={modalStyles.horizontalLine} />
						<div className={modalStyles.small}>
							{marketForDefaultChart.displayName}
						</div>
						<div className={modalStyles.small}>Office Buildings</div>
					</div>
					<div className={modalStyles.preMadeChart} onClick={thirdChart}>
						<div className={modalStyles.preMadeChartHeader}>
							<Time width={24} height={24} />
							<div className={modalStyles.preMadeChartTitle}>
								<div className={modalStyles.medium}>Compare</div>
								<div className={modalStyles.large}>Avg. Lease Term</div>
							</div>
						</div>
						<span className={modalStyles.horizontalLine} />
						<div className={modalStyles.small}>
							{marketForDefaultChart.displayName}
						</div>
						<div className={modalStyles.small}>Class A vs. B vs. C</div>
					</div>
				</div>
			</div>
			<a
				className={`${button.blue} ${modalStyles.viewProjects}`}
				href="/analytics/projects"
			>
				View My Projects
			</a>
		</div>
	);
};

const SubmarketsStyled = styled(Submarkets)`
	fill: ${({ theme }) => theme.colors.blue.blue400};
`;

const SpanStyled = styled.span`
	margin-bottom: 6px;
`;

const DivStyled = styled.div`
	margin-bottom: 6px;
`;

Modal.addComponent({
	id: 'analytics-blank-analytics',
	Component: BlankAnalytics,
	className: styles.analyticsModal,
});

export default 'analytics-blank-analytics';
