import { API } from '@compstak/common';
import {
	QueryClient,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { loadUser } from 'Pages/Login/actions';
import * as leaseActions from 'actions/lease';
import { useDispatch } from 'react-redux';
import { LeaseComp } from 'types';
import { MUTATION_KEYS, QUERY_KEYS } from '../constants';
import { updateCachePropertyComps } from '../utils';
import { invalidateUserQuery } from 'hooks/userHooks';

const unlockLeases = async ({ ids }: UnlockLeasesParams) => {
	const response = await API.put<UnlockLeasesResponse>(
		`/api/comps/actions/unlock`,
		{
			ids,
		}
	);
	return response.data;
};

type Props = {
	onSuccess?: OnUnlockLeasesSuccessFn;
};

export type OnUnlockLeasesSuccessFn = (args: {
	data: UnlockLeasesResponse;
	params: UnlockLeasesParams;
	queryClient: QueryClient;
}) => void;

export const useUnlockLeasesMutation = (props?: Props) => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	return useMutation({
		mutationKey: [MUTATION_KEYS.UNLOCK_LEASES],
		mutationFn: unlockLeases,
		onSuccess: (data, params) => {
			// redux legacy
			const ids = data.comps.map((lease) => lease.id);
			dispatch(leaseActions.unlockLeases(ids));
			dispatch(loadUser());

			// new
			updateCachePropertyComps('lease', queryClient, data);
			invalidateLeaseQueries(queryClient);
			invalidateUserQuery(queryClient);

			props?.onSuccess?.({ data, params, queryClient });
		},
	});
};

export const invalidateLeaseQueries = (queryClient: QueryClient) => {
	queryClient.invalidateQueries({
		predicate: (query) => {
			const [staticKey] = query.queryKey;
			return [
				QUERY_KEYS.SEARCH_LEASES,
				QUERY_KEYS.SEARCH_LEASES_INFINITE,
				QUERY_KEYS.PROPERTY_LEASES,
				QUERY_KEYS.SUBMARKET_RECENT_LEASES,
			].includes(staticKey as QUERY_KEYS);
		},
	});
};

export type UnlockLeasesParams = {
	ids: number[];
};

export type UnlockLeasesResponse = {
	points: number;
	freeComps: number;
	comps: LeaseComp[];
};
