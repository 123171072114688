import {
	Chip,
	ChipStyled,
	defaultTheme,
	FormMultiSelect,
	FormSelect,
	MultiselectListItem,
	OpenButton,
	SalesAnalyticsSidebarStyled,
	SelectedOption,
	SelectOnChangeParams,
	SpaceTypeSelectListItem,
} from '@compstak/ui-kit';
import { PROPERTY_TYPE_ID_TO_NAME, PropertyTypeId } from 'api';
import {
	Submarket,
	useSubmarketsByMarket,
} from 'api/submarkets/useSubmarketsByMarket';
import { FiltersObject } from 'models/filters/types';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DataSet, DataSetType } from '../../../analytics';
import { useDataSetForm, UseDataSetFormProps } from '../DataSet/useDataSetForm';
import {
	MUFA_PROPERTY_TYPE_ITEMS,
	SALE_PROPERTY_TYPE_ITEMS,
} from '../Modals/DataSets/CreateNewDataSetV2/propertyTypeItems';
import {
	getSpaceTypeIdsFromItems,
	SPACE_TYPE_SELECT_ITEMS,
} from '../Modals/DataSets/CreateNewDataSetV2/spaceTypeItemsV2';
import { MarketItem } from '../Modals/DataSets/CreateNewDataSetV2/utils';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { SubmarketFilter } from 'Components/Filters/Fields/SubmarketFilter/SubmarketFilter';
import { SpaceTypeMultiselect } from 'Components/Filters/Fields/AggregationFilter/SpaceTypeMultiselect';
import { PropertyTypeMultiselect } from 'Components/Filters/Fields/AggregationFilter/PropertyTypeMultiselect';

interface SidebarDataSetFormProps extends UseDataSetFormProps {
	initialSubmarkets: SpaceTypeSelectListItem[];
	onAttributeToPlotChange: (val?: string | null) => void;
	onFilterChange: (newFilters: Partial<FiltersObject>) => void;
	onApplyAll: (val?: string) => void;
	dataSet: DataSet;
	expanded: boolean;
}

export const DataSetFormV2 = (props: SidebarDataSetFormProps) => {
	const {
		initialSubmarkets,
		onFilterChange,
		onAttributeToPlotChange,
		onApplyAll,
		dataSet,
		expanded,
	} = props;
	const dataSetType = dataSet.type;

	const { options, handlers, state } = useDataSetForm({
		...props,
		dataSetType,
	});

	const marketId = state.market?.id;
	const { data: submarketResponse } = useSubmarketsByMarket(
		{
			id: marketId!,
		},
		{ enabled: !!marketId }
	);
	const [selectedSubmarkets, setSelectedSubmarkets] =
		useState(initialSubmarkets);

	const submarketOptions = useMemo(
		() => adaptSubmarketResponse(submarketResponse),
		[submarketResponse]
	);

	const handleMarketChange = useCallback(
		(event: SelectOnChangeParams<MarketItem>) => {
			if (!event.selectedItem) return;

			handlers.onChangeMarket(event);
			setSelectedSubmarkets([]);
			onFilterChange({
				market: props.markets?.[event.selectedItem.id],
				submarkets: [],
			});
		},
		[handlers, onFilterChange, props.markets]
	);

	const handleSubmarketChange = useCallback(
		// @ts-expect-error TS7006: Parameter 'val' implicitly has...
		(val) => {
			setSelectedSubmarkets(val);
			onFilterChange({
				// @ts-expect-error TS7006: Parameter 'i' implicitly has a...
				submarkets: val.map((i) => ({ id: Number(i.value), name: i.title })),
			});
		},
		[onFilterChange]
	);

	const handleAttributeToPlotChange = useCallback(
		(arg: SelectOnChangeParams) => {
			if (!arg.selectedItem) return;

			handlers.onChangeAttributeToPlot(arg);
			onAttributeToPlotChange(String(arg.selectedItem.value));
		},
		[handlers, onAttributeToPlotChange]
	);

	const handleSpaceTypeChange = useCallback(
		// @ts-expect-error TS7006: Parameter 'value' implicitly h...
		(value) => {
			handlers.onChangeSpaceType(value);
			const spaceTypeIds = getSpaceTypeIdsFromItems(value);
			onFilterChange({
				spaceTypeId: spaceTypeIds,
			});
		},
		[onFilterChange, handlers]
	);

	const handlePropertyTypeChange = useCallback(
		(value: MultiselectListItem[]) => {
			handlers.onChangePropertyType(value);
			const propertyTypeIds = value.map(
				(v) => Number(v.value) as PropertyTypeId
			);
			onFilterChange({
				buildingPropertyTypeId: propertyTypeIds,
			});
		},
		[onFilterChange, handlers]
	);

	const handleApplyAll = useCallback(
		// @ts-expect-error TS7006: Parameter 'value' implicitly h...
		(value) => (e) => {
			e.stopPropagation();
			onApplyAll?.(value);
		},
		[onApplyAll]
	);

	const optionContentRenderer = useCallback(
		// @ts-expect-error TS7031: Binding element 'title' implic...
		({ title, value }) => {
			return (
				<OptionStyled>
					<span>{title}</span>
					{/* @ts-expect-error TS2322: Type '{ children: string; "dat... */}
					<Chip data-qa-id="apply-all" onClick={handleApplyAll(value)}>
						Apply All
					</Chip>
				</OptionStyled>
			);
		},
		[handleApplyAll]
	);

	const { sidebarRevampFF } = useFeatureFlags();

	return (
		<>
			<SalesAnalyticsSidebarStyled style={{ marginBottom: 0 }}>
				{getAttrToPlotSelect({
					dataSetType,
					options,
					state,
					onChange: handleAttributeToPlotChange,
					optionContentRenderer,
				})}
			</SalesAnalyticsSidebarStyled>
			<SalesAnalyticsSidebarStyled>
				<OpenButtonWrapper>
					<FormSelect<MarketItem>
						placeholder="Search or select"
						items={options.market}
						onChange={handleMarketChange}
						value={state.market?.name}
						noResultMessage="No data"
						isSearchable
					/>
				</OpenButtonWrapper>
			</SalesAnalyticsSidebarStyled>
			{sidebarRevampFF ? (
				<>
					<FilterContainer>
						<SubmarketFilter
							filters={dataSet.filters}
							isActive={expanded}
							onFilterChange={onFilterChange}
						/>
					</FilterContainer>

					<FilterContainer>
						{(dataSetType === DataSetType.LEASES ||
							dataSetType === DataSetType.COMMERCIAL) && (
							<SpaceTypeMultiselect />
						)}
						{dataSetType === DataSetType.SALES && <PropertyTypeMultiselect />}
						{dataSetType === DataSetType.MUFA && (
							<PropertyTypeMultiselect
								multiSelectProps={{
									renderAllPlaceholder: () => PROPERTY_TYPE_ID_TO_NAME[2],
									disabled: true,
								}}
							/>
						)}
					</FilterContainer>
				</>
			) : (
				<SalesAnalyticsSidebarStyled>
					<FormMultiSelect
						data-qa-id="select-submarket"
						label="Submarkets"
						items={submarketOptions}
						onChange={handleSubmarketChange}
						value={selectedSubmarkets}
						placeholder={<Chip>All</Chip>}
						hideButtonIfOpen
					/>
					{(dataSetType === DataSetType.LEASES ||
						dataSetType === DataSetType.COMMERCIAL) && (
						<FormMultiSelect
							label="Space Type"
							data-qa-id="select-space-type"
							items={SPACE_TYPE_SELECT_ITEMS}
							// @ts-expect-error TS2322: Type 'SpaceTypeSelectListItem[...
							value={state.spaceTypes}
							onChange={handleSpaceTypeChange}
							placeholder={<Chip>All</Chip>}
							hideButtonIfOpen
						/>
					)}
					{dataSetType === DataSetType.SALES && (
						<FormMultiSelect
							label="Property Type"
							data-qa-id="select-property-type"
							items={SALE_PROPERTY_TYPE_ITEMS}
							// @ts-expect-error TS2322: Type 'MultiselectListItem[] | ...
							value={state.propertyTypes}
							onChange={handlePropertyTypeChange}
							placeholder={<Chip>All</Chip>}
							hideButtonIfOpen
						/>
					)}
					{dataSetType === DataSetType.MUFA && (
						<FormSelect
							label="Property Type"
							data-qa-id="select-property-type"
							items={MUFA_PROPERTY_TYPE_ITEMS}
							value={MUFA_PROPERTY_TYPE_ITEMS[0].value}
							onChange={() => {}}
						/>
					)}
				</SalesAnalyticsSidebarStyled>
			)}
		</>
	);
};

function adaptSubmarketResponse(submarketResponse: Submarket[] = []) {
	const data = submarketResponse
		.map(({ id, name }) => {
			return {
				value: String(id),
				title: name,
			};
		})
		.sort((a, b) => {
			if (a.title < b.title) return -1;
			if (a.title > b.title) return 1;
			return 0;
		});

	data.unshift({
		value: 'all',
		title: 'All',
	});
	return data;
}

const getAttrToPlotSelect = ({
	// @ts-expect-error TS7031: Binding element 'dataSetType' ...
	dataSetType,
	// @ts-expect-error TS7031: Binding element 'options' impl...
	options,
	// @ts-expect-error TS7031: Binding element 'state' implic...
	state,
	// @ts-expect-error TS7031: Binding element 'onChange' imp...
	onChange,
	// @ts-expect-error TS7031: Binding element 'optionContent...
	optionContentRenderer,
}) => {
	const dataSetTypeToSelectProps = {
		[DataSetType.SALES]: {
			label: '',
			items: options.salesAttributeToPlot,
			placeholder: '',
		},
		[DataSetType.COMMERCIAL]: {
			// with switching on the BE to "commercialLeases", this key will be deleted
			label: '',
			items: options.attributeToPlot,
			placeholder: 'Starting Rent (by default)',
		},
		[DataSetType.LEASES]: {
			label: '',
			items: options.attributeToPlot,
			placeholder: 'Starting Rent (by default)',
		},
		[DataSetType.MUFA]: {
			label: '',
			items: options.attributeToPlot,
			placeholder: 'Starting Rent (by default)',
		},
	};

	return (
		<OpenButtonWrapper>
			<FormSelect
				data-qa-id="select-attribute-to-plot"
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				label={dataSetTypeToSelectProps[dataSetType]?.label}
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				items={dataSetTypeToSelectProps[dataSetType]?.items}
				onChange={onChange}
				value={state.attributeToPlot}
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				placeholder={dataSetTypeToSelectProps[dataSetType]?.placeholder}
				optionContentRenderer={optionContentRenderer}
			/>
		</OpenButtonWrapper>
	);
};

const OpenButtonWrapper = styled.div`
	${OpenButton} {
		height: 50px;
	}

	${SelectedOption} {
		background-color: transparent;
		padding: 0;
		letter-spacing: 0.2px;

		padding-right: 16px;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	& input {
		color: ${({ theme }) => theme.colors.white.white};
		background-color: transparent;
		border: unset;
		padding: 0 35px 0 13px;
	}

	&& svg {
		position: relative;
		right: 4px;

		width: 18px;
		height: 18px;
	}
`;

const OptionStyled = styled.div`
	position: relative;

	${ChipStyled} {
		position: absolute;
		right: 0px;
		top: -5px;
		padding: 6px 10px;
		font-size: 11px;
		background-color: ${defaultTheme.colors.neutral.n90};

		&:hover {
			background-color: ${defaultTheme.colors.neutral.n85};
		}
		&:active {
			background-color: ${defaultTheme.colors.neutral.n85};
		}
	}
`;

const FilterContainer = styled.div`
	margin: 0.5rem 0;

	.ms-parent:not(.expanded) {
		padding: 0.75rem 0.5rem;
	}
`;
