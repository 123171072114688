import { usePortfolioByIdQuery } from 'api';
import constate from 'constate';
import { useCallback, useMemo, useState } from 'react';

type Props = {
	portfolioId?: number;
};

export const [PortfolioFiltersProvider, usePortfolioFilters] = constate(
	({ portfolioId }: Props) => {
		const [filters, setFilters] = useState<FiltersState>(initialState);

		const { data: portfolio } = usePortfolioByIdQuery({ id: portfolioId });

		const resetFilters = useCallback(() => {
			setFilters(initialState);
		}, []);

		const marketIds = useMemo(() => {
			if (!portfolio) return [];
			return portfolio.markets
				.filter((m) => {
					return m.submarkets.some((s) =>
						filters.submarketIds.includes(s.submarketId)
					);
				})
				.map((m) => m.marketId);
		}, [portfolio, filters.submarketIds]);

		return {
			filters: {
				...filters,
				marketIds,
			},
			setFilters,
			resetFilters,
		};
	}
);

const initialState: FiltersState = {
	submarketIds: [],
	propertyTypeIds: [],
	buildingClassIds: [],
	leaseExpirationsMonths: null,
};

export type FiltersState = {
	submarketIds: number[];
	propertyTypeIds: number[];
	buildingClassIds: number[];
	leaseExpirationsMonths: LeaseExpirationsMonths;
};

export type LeaseExpirationsMonths = 12 | 24 | 36 | 48 | 60 | null;
