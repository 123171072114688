import React from 'react';

import EmailIcon from '../../../../../ui/svg_icons/email_light.svg';
import TrashIcon from '../../../../../ui/svg_icons/delete.svg';
import DuplicateIcon from '../../../../../ui/svg_icons/duplicate.svg';
import TickIcon from '../../../../../ui/svg_icons/tick.svg';
import EditIcon from '../../../../../ui/svg_icons/edit.svg';

import withModal from 'Singletons/Modal/withModal';
import shareChartModal from '../Modals/ShareChart';
import deleteChartModal from '../Modals/DeleteChart';
import duplicateChartModal from '../Modals/DuplicateChart';

import { AnimatedDataSetRows } from './DataSetRows';

import styles from '../../repository.less';
import { Chart } from 'Pages/Analytics/analytics';
import { formatDate } from 'format';

type Props = {
	handleToggle: (chartId: number) => void;
	openNewChart: (chart: Chart) => void;
	chart: Chart;
};

type State = {
	chart: Chart;
	title: string;
	focused: boolean;
};

export default withModal(
	class ChartRow extends React.Component<Props, State> {
		state = {
			chart: this.props.chart,
			title: this.props.chart.title ?? '',
			focused: false,
		};

		// @ts-expect-error TS7006: Parameter 'nextProps' implicit...
		static getDerivedStateFromProps(nextProps, prevState) {
			if (nextProps.chart.title !== prevState.chart.title) {
				return {
					chart: nextProps.chart,
					title: nextProps.chart.title,
				};
			} else {
				return {};
			}
		}

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		handleToggle = (event) => {
			event.preventDefault();
			if (this.props.chart.id) {
				this.props.handleToggle(this.props.chart?.id);
			}
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		openNewChart = (event) => {
			if (!event.isDefaultPrevented()) {
				this.props.openNewChart(this.props.chart);
			}
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		openShareModal = (event) => {
			event.preventDefault();
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.modalActions.pushModal(shareChartModal, this.props);
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		openDeleteModal = (event) => {
			event.preventDefault();
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.modalActions.pushModal(deleteChartModal, this.props);
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		openDuplicateModal = (event) => {
			event.preventDefault();
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.modalActions.pushModal(duplicateChartModal, this.props);
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		handleTitleChange = (event) => {
			if (event.target.value.length <= 100) {
				this.setState({ title: event.target.value });
			}
		};

		handleTitleFocus = () => {
			this.setState({ focused: true });
		};

		handleTitleBlur = () => {
			this.handleTitleSubmit();
			this.setState({ focused: false });
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		handleKeyUp = (event) => {
			if (event.keyCode === 13) {
				event.currentTarget.blur();
			}
		};

		handleIconClick = () => {
			// @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
			event.preventDefault();
			if (this.state.focused) {
				this.handleTitleSubmit();
				this.setState({ focused: false });
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'myRef' does not exist on type 'ChartRow'... Remove this comment to see the full error message
				this.myRef.current.blur();
			}
		};

		handleTitleSubmit = () => {
			if (this.state.title !== this.state.chart.title) {
				const trimmedTitle = this.state.title.trim();
				if (trimmedTitle.length === 0) {
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjectActions' does not exist ... Remove this comment to see the full error message
					this.props.analyticsProjectActions.nonameChartError();
					// @ts-expect-error TS2322: Type 'string | undefined' is n...
					this.setState({ title: this.state.chart.title });
				} else if (
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'project' does not exist on type 'Readonl... Remove this comment to see the full error message
					this.props.project.charts.some((c) => c.title === trimmedTitle)
				) {
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjectActions' does not exist ... Remove this comment to see the full error message
					this.props.analyticsProjectActions.renameChartError();
					// @ts-expect-error TS2322: Type 'string | undefined' is n...
					this.setState({ title: this.state.chart.title });
				} else {
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjectActions' does not exist ... Remove this comment to see the full error message
					this.props.analyticsProjectActions.saveChart(
						{
							...this.state.chart,
							title: trimmedTitle,
						},
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'markets' does not exist on type 'Readonl... Remove this comment to see the full error message
						this.props.markets
					);
				}
			}
		};

		// @ts-expect-error TS7006: Parameter 'projectName' implic...
		isNew = (projectName, chartDate) => {
			// is it a received chart more than a week old?
			return (
				projectName === 'Received Charts' &&
				// @ts-expect-error ts-migrate(2363) FIXME: The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message
				Date.now() - new Date(chartDate) <= 604800
			);
		};

		render() {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'toggled' does not exist on type 'Readonl... Remove this comment to see the full error message
			const arrowBoxClass = this.props.toggled ? styles.toggled : '';

			let icon;
			let iconClass;
			let focusClass;
			if (this.state.focused) {
				icon = <TickIcon />;
				iconClass = styles.iconFocus;
				focusClass = styles.focused;
			} else {
				icon = <EditIcon />;
				iconClass = styles.iconHidden;
			}

			let newTag;
			let newTagInputClass;
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'project' does not exist on type 'Readonl... Remove this comment to see the full error message
			if (this.isNew(this.props.project.name, this.props.chart.dateCreated)) {
				newTag = <span className={styles.newTag}>New</span>;
				newTagInputClass = styles.newTagInput;
			}

			const chartRow = (
				<div
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'colorClass' does not exist on type 'Read... Remove this comment to see the full error message
					className={`${styles.row} ${this.props.colorClass}`}
					onClick={this.openNewChart}
				>
					<span
						className={`${styles.arrowBox} ${arrowBoxClass}`}
						onClick={this.handleToggle}
					>
						<div className={styles.arrow} />
					</span>
					<div className={styles.name}>
						{newTag}
						<span
							className={`${styles.customInput} ${focusClass} ${newTagInputClass}`}
							onClick={(event) => event.preventDefault()}
						>
							<input
								className={styles.nameInput}
								value={this.state.title}
								onChange={this.handleTitleChange}
								onFocus={this.handleTitleFocus}
								onBlur={this.handleTitleBlur}
								onKeyUp={this.handleKeyUp}
							/>
							<div
								className={`${styles.iconContainer} ${iconClass}`}
								onClick={this.handleIconClick}
							>
								{icon}
							</div>
						</span>
					</div>
					<span className={styles.dateCreated}>
						{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'chart' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
						{formatDate(this.props.chart.dateCreated)}
					</span>
					<span className={styles.chartDateModified}>
						{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'chart' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
						{formatDate(this.props.chart.lastUpdated)}
					</span>
					<span className={`${styles.icon} ${styles.duplicate}`}>
						<DuplicateIcon
							width="18px"
							height="18px"
							onClick={this.openDuplicateModal}
						/>
					</span>
					<span className={`${styles.icon} ${styles.email}`}>
						<EmailIcon
							width="20px"
							height="15px"
							onClick={this.openShareModal}
						/>
					</span>
					<span className={`${styles.icon} ${styles.trash}`}>
						<TrashIcon
							width="16px"
							height="18px"
							onClick={this.openDeleteModal}
						/>
					</span>
				</div>
			);

			return (
				<React.Fragment>
					{chartRow}
					<AnimatedDataSetRows
						dataSets={this.props.chart.dataSets}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'toggled' does not exist on type 'Readonl... Remove this comment to see the full error message
						toggled={this.props.toggled}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'referenceData' does not exist on type 'R... Remove this comment to see the full error message
						spaceTypeMap={this.props.referenceData.spaceTypes}
					/>
				</React.Fragment>
			);
		}
	}
);
