import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const FilterGroupTitle = ({ children }: PropsWithChildren) => {
	const { searchTerm } = useFilterFieldContext();

	return (
		<FilterGroupTitleUI isHidden={!!searchTerm}>{children}</FilterGroupTitleUI>
	);
};

const FilterGroupTitleUI = styled.li<{ isHidden?: boolean }>`
	display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
	align-items: center;
	justify-content: space-between;
	text-transform: uppercase;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	color: ${({ theme }) => theme.colors.blue.blue400};
	font-size: 11px;
	font-weight: 400;
	padding: 5px 20px;
`;
