import { MAP_CONTROLS } from 'actions/search';
import { MapFilterV2Base } from './MapFilterV2Base';
import IconOpportunityZones from 'ui/svg_icons/opportunity_zones_icon.svg';
import { OpportunityZonesFilter } from '../OpportunityZonesFilter/OpportunityZonesFilter';

export const OpportunityZonesMapFilterV2 = () => {
	return (
		<MapFilterV2Base
			Icon={IconOpportunityZones}
			key={MAP_CONTROLS.OPPORTUNITY_ZONES}
			label="Opportunity Zones"
			mapControl={MAP_CONTROLS.OPPORTUNITY_ZONES}
		>
			{(props) => (
				<OpportunityZonesFilter
					filters={props.filters}
					isActive={props.isActive}
					onFilterChange={props.onFilterChange}
					setIsLoading={props.setIsLoading}
				/>
			)}
		</MapFilterV2Base>
	);
};
