import { Portfolio, PortfolioPropertySummary } from 'api';
import { useState } from 'react';
import { PropertyComp } from 'types';
import { createSimpleProvider } from '../../utils';
import { useResetMarketRentState } from './useResetMarketRentState';

type Props = {
	portfolio: Portfolio;
};

export const {
	Provider: MarketRentProvider,
	useProviderContext: useMarketRentState,
} = createSimpleProvider({
	useValue: ({ portfolio }: Props) => {
		const [state, setState] = useState<MarketRentState>({
			selectedMarketId: null,
			selectedPropertyTypeId: null,
			propertyPopup: null,
		});

		useResetMarketRentState(state, setState, portfolio);

		return [state, setState] as [
			MarketRentState,
			React.Dispatch<React.SetStateAction<MarketRentState>>
		];
	},
});

export type MarketRentState = {
	selectedMarketId: number | null;
	selectedPropertyTypeId: number | null;
	propertyPopup: (PropertyComp & Partial<PortfolioPropertySummary>) | null;
};
