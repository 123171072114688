'use strict';
import React, { ReactNode, useState } from 'react';

import { email as validateEmail } from 'util/validate';
import ArrowLeft from '../../../ui/svg_icons/arrow_left.svg';
import Tick from '../../../ui/svg_icons/tick_small.svg';
import styled from 'styled-components';
import styles from '../stylesheets/login.less';
import button from 'ui/styles/button.less';
import spinner from 'ui/styles/spinner.less';

import { forgotPassword } from '../actions';
import { AuthStateType } from '../reducers';
import { emailConstants } from '@compstak/common';
import { routes } from 'router';
import { useDispatch } from 'react-redux';

interface ForgotPasswordFormProps {
	authState: AuthStateType;
}

interface ForgotPasswordFormState {
	email: string;
	isValidEmail: boolean;
	error: string;
}

export const ForgotPasswordForm = ({ authState }: ForgotPasswordFormProps) => {
	const [state, setState] = useState<ForgotPasswordFormState>({
		email: '',
		error: '',
		isValidEmail: false,
	});

	const dispatch = useDispatch();

	const submitIfEnter = (
		event: React.KeyboardEvent<HTMLInputElement>
	): void => {
		if (event.keyCode === 13) {
			send();
		}
	};

	const updateState = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setState({
			...state,
			email: event.target.value,
			isValidEmail: validateEmail(event.target.value),
		});
	};

	const send = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		if (event) {
			event.preventDefault();
		}

		if (!validateEmail(state.email)) {
			setState({
				...state,
				error: 'Please enter a valid email address.',
			});
			return;
		}

		setState({
			...state,
			error: '',
		});

		dispatch(forgotPassword(state.email));
	};

	const buttonComponent = () => {
		if (authState.forgotPasswordSucceeded) {
			return <div />;
		} else if (authState.forgotPasswordPending) {
			return (
				<div className={button.disabled}>
					<div className={spinner.small} />
				</div>
			);
		} else {
			return (
				<div
					className={`${button.button} ${
						!state.isValidEmail ? button.disabled : ''
					}`}
					onClick={send}
				>
					SEND
				</div>
			);
		}
	};

	let message: ReactNode = '';

	if (authState.forgotPasswordSucceeded === true) {
		message = (
			<div className={styles.forget_password_success}>
				<StyledTickIcon />
				<div>
					<p>
						<b>Please check your email for further instructions.</b>
					</p>
					<p>
						If you didn't receive the reset email, check the spam folder of your
						inbox.
					</p>
					<p>
						We're here to help:
						<br />
						<a href={`mailto:${emailConstants.help}`}>{emailConstants.help}</a>
						<br />
						<a href="tel://1-646-926-6707">1-646-926-6707</a>
					</p>
				</div>
			</div>
		);
	} else if (authState.forgotPasswordSucceeded === false) {
		message = (
			<div className={styles.forget_password_error}>
				Unable to reset password
			</div>
		);
	} else if (state.error) {
		message = <div className={styles.forget_password_error}>{state.error}</div>;
	}

	return (
		<div
			className={`${styles.forget_password_content} ${styles.login_screen_content}`}
		>
			<h1>Recover Your Password</h1>

			<p>Enter your email and we`ll send you a password reset link.</p>

			<div>
				<input
					name="email"
					type="email"
					placeholder="Email address"
					className={styles.login_input}
					onChange={updateState}
					onKeyDown={submitIfEnter}
				/>
			</div>

			{message}

			<div>{buttonComponent()}</div>

			<div className={styles.back_to_login}>
				<StyledArrowLeftIcon />
				<a href={routes.login.path}>Back to Login</a>
			</div>
		</div>
	);
};

const StyledArrowLeftIcon = styled(ArrowLeft)`
	width: 7px;
	fill: ${({ theme }) => theme.colors.neutral.n70};
	transition: all 0.2s ease-in-out;
	float: left;
	margin: 0 9px 0 0;
`;

const StyledTickIcon = styled(Tick)`
	height: 11px;
	width: 12px;
	fill: ${({ theme }) => theme.colors.green.green500};
	display: inline;
	position: absolute;
	margin-top: 5px;
`;
