import { PropertySale } from 'api';
import styled from 'styled-components';
import { UseSelectionStateReturn } from 'utils';
import { EditPdfTable } from '../EditPdfTable';
import { ExportPdfSalesStatus } from '../ExportPdfStatus';
import { EditPageSectionTitle } from '../UI';
import { PdfColumn } from '../types';
import { ExportPdfData } from '../useExportPdfData';
import { EditablePropertySale } from './useSalesPdfColumns';

type Props = {
	editableSales: EditablePropertySale[];
	setEditableSales: React.Dispatch<
		React.SetStateAction<EditablePropertySale[]>
	>;
	editableSalesColumns: PdfColumn<EditablePropertySale, PropertySale>[];
	setEditableSalesColumns: React.Dispatch<
		React.SetStateAction<PdfColumn<EditablePropertySale, PropertySale>[]>
	>;
	data: ExportPdfData;
	salesSelection: UseSelectionStateReturn;
};

export const EditSalesPage = ({
	editableSales,
	setEditableSales,
	editableSalesColumns,
	setEditableSalesColumns,
	data,
	salesSelection,
}: Props) => {
	return (
		<TableContainer>
			<EditPageSectionTitle>
				<div>Property Sales History ({editableSales.length})</div>
				<ExportPdfSalesStatus data={data} />
			</EditPageSectionTitle>
			<EditPdfTable
				rows={editableSales}
				columns={editableSalesColumns}
				onChange={({ row, column, value }) => {
					setEditableSales((state) => {
						return state.map((r) => {
							if (r.id === row.id) {
								return {
									...r,
									[column.id]: value,
								};
							}
							return r;
						});
					});
				}}
				onToggleColumn={({ column }) => {
					setEditableSalesColumns((cols) =>
						cols.map((c) => {
							if (c.id === column.id) {
								return {
									...c,
									isVisible: !c.isVisible,
								};
							}
							return c;
						})
					);
				}}
				selection={{
					...salesSelection,
					getIsRowSelected: (row) => salesSelection.getIsRowSelected(row.id),
					toggleRow: (row) => {
						salesSelection.toggleRow(row.id);
					},
				}}
			/>
		</TableContainer>
	);
};

const TableContainer = styled.div`
	margin-top: 1.5rem;
`;
