import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { composeEnabled, createFetchFn } from '../utils';
import { PortfolioItem, SalesComp } from 'types';

const fetchSimilarSales = createFetchFn<
	SimilarSalesParams,
	SimilarSalesResponse
>({
	method: 'get',
	getURL: ({ propertyId }) => `/properties/${propertyId}/similarSales`,
});

export const useSimilarSalesQuery = (
	params: SimilarSalesParams,
	options?: UseQueryOptions<SimilarSalesResponse>
) =>
	useQuery({
		queryFn: fetchSimilarSales(params),
		queryKey: [QUERY_KEYS.SIMILAR_SALES, params],
		enabled: composeEnabled(params.propertyId != null, options?.enabled),
		...options,
	});

type SimilarSalesParams = {
	propertyId?: string | number;
};

export type SimilarSalesResponse = {
	comps: SimilarSalesSaleComp[];
};

export type SimilarSalesSaleComp = Pick<
	SalesComp,
	| 'buyer'
	| 'buyerRepCompanies'
	| 'capRate'
	| 'cost'
	| 'dataSource'
	| 'id'
	| 'isPortfolio'
	| 'own'
	| 'saleDate'
	| 'salePricePsf'
	| 'seller'
	| 'sellerRepCompanies'
	| 'totalSalePrice'
> & {
	portfolio: SimilarSalesPortfolioItem[];
};

export type SimilarSalesPortfolioItem = Pick<
	PortfolioItem,
	'buildingAddress' | 'buildingAddressAndCity' | 'submarket'
>;
