import {
	SimpleTableColumn,
	numberUtils,
	renderTableValue,
	defaultTheme,
} from '@compstak/ui-kit';
import { CheckedState } from '@radix-ui/react-checkbox';
import {
	Checkbox,
	MultiPropertyItemsTooltip,
	StopPropagation,
} from 'Components';
import { PropertySale } from 'api';
import { formatDate, formatPercent } from 'format';
import { useSalesSelectionContext } from '../SalesSelectionProvider';
import { UnlockButtonRow } from 'Components/Unlock';
import { ExportCompsButton } from 'Components/Export';
import { IconLockGrey } from 'ui/UI';
import { getShouldShowLockIcon } from 'utils';
import { useIsExchange } from 'hooks';
import styled from 'styled-components';

export const useSalesTableColumns = ({
	propertySalesComps,
}: {
	propertySalesComps?: PropertySale[];
}): SimpleTableColumn<PropertySale>[] => {
	const isExchange = useIsExchange();
	const {
		selection,
		areAllSelected,
		areSomeSelected,
		toggleAllSelected,
		toggleRow,
	} = useSalesSelectionContext();

	let checkboxState: CheckedState = false;
	if (areSomeSelected && !areAllSelected) {
		checkboxState = 'indeterminate';
	} else if (areAllSelected) {
		checkboxState = true;
	}

	const columns: SimpleTableColumn<PropertySale>[] = [
		{
			id: 'selection',
			header: () => {
				return (
					<Checkbox
						id="selectAll"
						name="selectAll"
						data-qa-id="select-all-checkbox"
						checked={checkboxState}
						onCheckedChange={toggleAllSelected}
						bgColorUnchecked={defaultTheme.colors.white.white}
						disabled={
							selection.length === 0 &&
							selection.length === propertySalesComps?.length
						}
					/>
				);
			},
			body: ({ row }) => {
				return (
					<StopPropagation handler="onClick">
						<Checkbox
							name={`selectRow-${row.id}`}
							data-qa-id={`select-row-checkbox-${row.id}`}
							checked={selection.some((id) => id === row.id)}
							onCheckedChange={() => toggleRow(row.id)}
						/>
					</StopPropagation>
				);
			},
			width: 45,
			hAlign: 'center',
		},
		{
			id: 'actions',
			header: () => '',
			body: ({ row }) => (
				<StopPropagation handler="onClick">
					{row.own ? (
						<ExportCompsButton compType="sale" comps={[row]} buttonType="row" />
					) : (
						<UnlockButtonRow
							comp={{ id: row.id, cost: row.cost }}
							type="sale"
						/>
					)}
				</StopPropagation>
			),
			width: isExchange ? 96 : 88,
		},
		{
			id: 'saleDate',
			header: () => 'Sale Date',
			width: 100,
			body: ({ row }) => {
				return renderTableValue(row.saleDate, formatDate);
			},
		},
		{
			id: 'portfolio',
			header: () => 'Multi Property Sale',
			width: 128,
			body: ({ row }) => {
				if (
					getShouldShowLockIcon(
						row.isPortfolio,
						{ own: Boolean(row.own) },
						isExchange
					)
				) {
					return <IconLockGrey />;
				}

				return row.isPortfolio ? (
					<MultiPropertyItemsTooltip saleComp={row} key={row.id}>
						<MultiPropertyValue>
							{row.portfolio.length} Propert
							{row.portfolio.length === 1 ? 'y' : 'ies'}
						</MultiPropertyValue>
					</MultiPropertyItemsTooltip>
				) : (
					'No'
				);
			},
		},
		{
			id: 'totalSalePrice',
			header: () => 'Total Sale Price',
			width: 104,
			body: ({ row }) => {
				if (
					getShouldShowLockIcon(
						row.totalSalePrice,
						{ own: Boolean(row.own) },
						isExchange
					)
				) {
					return <IconLockGrey />;
				}
				return renderTableValue(row.totalSalePrice, () => {
					return numberUtils.formatCurrency(
						Number(row.totalSalePrice),
						'currencyInteger'
					);
				});
			},
			hAlign: 'right',
		},
		{
			id: 'dataSource',
			header: () => 'Data Source',
			width: 96,
			body: ({ row }) => {
				if (
					getShouldShowLockIcon(
						row.dataSource,
						{ own: Boolean(row.own) },
						isExchange
					)
				) {
					return <IconLockGrey />;
				}

				return renderTableValue(row.dataSource);
			},
			hAlign: 'left',
		},
		{
			id: 'buyer/buyerRepCompanies',
			header: () => 'Buyer/Buyer Rep Companies',
			width: 114,
			body: ({ row }) => {
				if (
					getShouldShowLockIcon(
						row.buyer,
						{ own: Boolean(row.own) },
						isExchange
					) ||
					getShouldShowLockIcon(
						row.buyerRepCompanies,
						{ own: Boolean(row.own) },
						isExchange
					)
				) {
					return <IconLockGrey />;
				}

				return `${renderTableValue(row.buyer)} / ${renderTableValue(row.buyerRepCompanies)}`;
			},
			hAlign: 'left',
			flex: 1,
		},
		{
			id: 'seller/sellerRepCompanies',
			header: () => 'Seller/Seller Rep Companies',
			width: 114,
			body: ({ row }) => {
				if (
					getShouldShowLockIcon(
						row.seller,
						{ own: Boolean(row.own) },
						isExchange
					) ||
					getShouldShowLockIcon(
						row.sellerRepCompanies,
						{ own: Boolean(row.own) },
						isExchange
					)
				) {
					return <IconLockGrey />;
				}

				return `${renderTableValue(row.seller)} / ${renderTableValue(row.sellerRepCompanies)}`;
			},
			flex: 1,
			hAlign: 'left',
		},
		{
			id: 'capRate',
			header: () => 'Cap Rate',
			width: 72,
			body: ({ row }) => {
				if (
					getShouldShowLockIcon(
						row.capRate,
						{ own: Boolean(row.own) },
						isExchange
					)
				) {
					return <IconLockGrey />;
				}

				return renderTableValue(row.capRate, () =>
					formatPercent(Number(row.capRate) / 100, 2)
				);
			},
			hAlign: 'right',
		},
		{
			id: 'percentOccupied',
			header: () => 'Occupancy Rate',
			width: 110,
			body: ({ row }) => {
				if (
					getShouldShowLockIcon(
						row.percentOccupied,
						{ own: Boolean(row.own) },
						isExchange
					)
				) {
					return <IconLockGrey />;
				}

				return renderTableValue(row.percentOccupied, () =>
					formatPercent(Number(row.percentOccupied) / 100, 0)
				);
			},
			hAlign: 'right',
		},
		{
			id: 'interestPercentage',
			header: () => 'Interest',
			width: 80,
			body: ({ row }) => {
				if (
					getShouldShowLockIcon(
						row.interestPercentage,
						{ own: Boolean(row.own) },
						isExchange
					)
				) {
					return <IconLockGrey />;
				}

				return renderTableValue(row.interestPercentage);
			},
			hAlign: 'right',
		},
		{
			id: 'documentNumber',
			header: () => 'Deed Doc#',
			width: 116,
			body: ({ row }) => {
				if (
					getShouldShowLockIcon(
						row.documentNumber,
						{ own: Boolean(row.own) },
						isExchange
					)
				) {
					return <IconLockGrey />;
				}

				return renderTableValue(row.documentNumber);
			},
			hAlign: 'right',
			flex: 1,
		},
	];

	return columns;
};

const MultiPropertyValue = styled.span`
	font-weight: bold;
	color: ${({ theme }) => theme.colors.blue.blue500};
`;
