import { Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQuery } from 'api';
import { useMemo } from 'react';
import { PortfolioView } from 'router';
import styled from 'styled-components';
import { RouterLocation } from 'types';
import { APP_HEADER_HEIGHT } from '../constants';
import { Dashboard } from './Dashboard';
import { EmptyPortfolio } from './EmptyPortfolio';
import { ListView } from './ListView';
import { MapView } from './MapView';
import { PortfolioFiltersProvider } from './PortfolioFiltersProvider';
import { PortfolioSidebar } from './PortfolioSidebar';
import { useRedirectPortfolio } from './useRedirectPortfolio';

type Props = {
	params: {
		viewType?: PortfolioView;
		portfolioId?: string;
	};
	location: RouterLocation;
};

export const PortfolioLayout = ({ params, location }: Props) => {
	useRedirectPortfolio({ params });

	const { data: portfolio, isLoading } = usePortfolioByIdQuery({
		id: params.portfolioId ? Number(params.portfolioId) : undefined,
	});

	const isPortfolioEmpty = useMemo(() => {
		if (!portfolio) return;
		const totalPropertyCount = portfolio.markets.reduce(
			(sum, m) => sum + m.propertyCount,
			0
		);
		return totalPropertyCount === 0;
	}, [portfolio]);

	const content = (() => {
		const portfolioId = Number(params.portfolioId);

		if (isLoading) {
			return (
				<LoadingContainer>
					<Spinner />
				</LoadingContainer>
			);
		}

		if (isPortfolioEmpty) {
			return <EmptyPortfolio portfolio={portfolio} />;
		} else {
			switch (params.viewType) {
				case 'map':
					return <MapView portfolioId={portfolioId} />;
				case 'list':
					return <ListView portfolioId={portfolioId} location={location} />;
				case 'dashboard': {
					return <Dashboard portfolioId={portfolioId} />;
				}
			}
		}
	})();

	return (
		<Root>
			<PortfolioSidebar
				portfolioId={
					params.portfolioId ? Number(params.portfolioId) : undefined
				}
			/>
			<PortfolioFiltersProvider
				key={params.portfolioId}
				portfolioId={
					params.portfolioId ? Number(params.portfolioId) : undefined
				}
			>
				<Content>{content}</Content>
			</PortfolioFiltersProvider>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	height: calc(100vh - ${APP_HEADER_HEIGHT}px);
	// defaults
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	line-height: 1.2;
	box-sizing: border-box;
`;

const Content = styled.div`
	flex: 1;
	height: 100%;
`;

const LoadingContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
