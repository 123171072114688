import { DataSet, Chart } from 'Pages/Analytics/analytics';
import { FiltersObject } from 'models/filters/types';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelectionState } from 'utils/useSelectionState';

import { useUnderlyingDataColumns } from './useUnderlyingDataColumns';
import {
	SearchLeasesResponse,
	useSearchInfiniteParams,
	useSearchLeasesInfiniteQuery,
} from 'api';
import { useSearchTableRows } from 'Pages/Search/useSearchTableRows';
import { LeaseComp } from 'types';
import {
	VirtualTable,
	SimpleTableBodyCellRoot,
	SimpleTableHeaderCellRoot,
	colors,
} from '@compstak/ui-kit';
import { useNavigate, routes } from 'router';
import styled from 'styled-components';
import {
	useUnderlyingDataTableContext,
	COMP_VISIBILITY_OPTIONS,
} from '../UnderlyingDataTableProvider';
import { calculateMinDateBasedOnTimespan } from 'Components/Graphs/configGenerators/axes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useUpdateChartExcludedCompsMutation } from './useUpdateExcludedCompIds';

export const UnderlyingLeasesDataTable = ({
	dataSet,
	chart,
}: {
	dataSet: DataSet;
	chart: Chart;
}) => {
	const { timespan } = chart;

	const { chartBuilderCanExcludeComps } = useFeatureFlags();
	const navigate = useNavigate();
	const {
		setSelectedCompIds,
		compVisibilitySelectedOption,
		excludedCompIdsForDataset,
		sortField,
		setSortField,
		sortDirection,
		setSortDirection,
	} = useUnderlyingDataTableContext();

	const dataSetHasExcludedComps =
		chartBuilderCanExcludeComps && excludedCompIdsForDataset?.length > 0;

	const filtersWithCompIdAndSortingAndTimespan: FiltersObject = useMemo(() => {
		const minExecutionDateBasedOnTimespan =
			calculateMinDateBasedOnTimespan(timespan);
		const executionDateFilterBasedOnTimespan = {
			min: minExecutionDateBasedOnTimespan.toDate(),
			max: null,
		};
		const filtersWithUpdatedSortingAndTimespan = {
			...dataSet.filters,
			sortField,
			sortDirection,
			executionDate: executionDateFilterBasedOnTimespan,
		};
		if (!dataSetHasExcludedComps) {
			return filtersWithUpdatedSortingAndTimespan;
		}
		if (
			compVisibilitySelectedOption ===
			COMP_VISIBILITY_OPTIONS.showOnlyExcludedComps
		) {
			return {
				...filtersWithUpdatedSortingAndTimespan,
				compId: { value: excludedCompIdsForDataset, exclude: false },
			};
		}
		if (
			compVisibilitySelectedOption === COMP_VISIBILITY_OPTIONS.hideExcludedComps
		) {
			return {
				...filtersWithUpdatedSortingAndTimespan,
				compId: { value: excludedCompIdsForDataset, exclude: true },
			};
		}
		return filtersWithUpdatedSortingAndTimespan;
	}, [
		compVisibilitySelectedOption,
		excludedCompIdsForDataset,
		dataSet.filters,
		sortField,
		sortDirection,
	]);

	const updateChartExcludedCompsMutation = useUpdateChartExcludedCompsMutation({
		chart,
		dataSet,
		filters: filtersWithCompIdAndSortingAndTimespan,
	});

	useEffect(() => {
		updateChartExcludedCompsMutation.mutate();
	}, [excludedCompIdsForDataset]);

	const params = useSearchInfiniteParams({
		filters: filtersWithCompIdAndSortingAndTimespan,
	});

	const {
		data: leasesData,
		isFetching,
		fetchNextPage,
		hasNextPage,
	} = useSearchLeasesInfiniteQuery(params);

	const rows = useSearchTableRows<LeaseComp>({
		data: leasesData,
		getRows: useCallback((page: SearchLeasesResponse) => page.comps, []),
		isFetching,
	});
	const rowsToDisplayInTable = useMemo(() => {
		return compVisibilitySelectedOption ===
			COMP_VISIBILITY_OPTIONS.showOnlyExcludedComps && !dataSetHasExcludedComps
			? []
			: rows;
	}, [compVisibilitySelectedOption, rows, excludedCompIdsForDataset]);

	const rowSelectionMethods = useSelectionState(rows);

	useEffect(() => {
		setSelectedCompIds(rowSelectionMethods.selection);
	}, [rowSelectionMethods.selection]);

	useEffect(() => {
		rowSelectionMethods.resetSelection();
	}, [excludedCompIdsForDataset]);

	const underlyingColumns = useUnderlyingDataColumns({
		rowSelectionMethods,
		filters: filtersWithCompIdAndSortingAndTimespan,
		onSort: (columnId) => {
			const isSame = sortField === columnId;
			const updatedSortDirection = isSame
				? sortDirection === 'desc'
					? 'asc'
					: 'desc'
				: 'desc';
			setSortField(columnId);
			setSortDirection(updatedSortDirection);
		},
	});

	const cellLeftPadding = chartBuilderCanExcludeComps ? '0' : '0.5rem';

	return (
		<Wrapper cellLeftPadding={cellLeftPadding}>
			<VirtualTable
				id="chartbuilder-underlying-data"
				rows={rowsToDisplayInTable}
				columns={underlyingColumns}
				maxBodyHeight={600}
				bodyRowHeight={48}
				isBodyHoverable
				getBodyCellProps={({ row, columnIndex }) => ({
					onClick: () => {
						if (chartBuilderCanExcludeComps && columnIndex === 0) {
							return;
						}
						navigate(routes.leaseById.toHref({ id: row.id }));
					},
					style:
						excludedCompIdsForDataset &&
						excludedCompIdsForDataset.includes(row.id)
							? { color: colors.neutral.n70, fontStyle: 'italic' }
							: {},
				})}
				onLoadMore={() => {
					if (hasNextPage && !isFetching) {
						fetchNextPage();
					}
				}}
				isLoadingMore={isFetching}
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div<{ cellLeftPadding: string }>`
	${SimpleTableBodyCellRoot} {
		div {
			padding-right: 2px;
		}
	}
	${SimpleTableBodyCellRoot}, ${SimpleTableHeaderCellRoot} {
		padding-left: ${({ cellLeftPadding }) => cellLeftPadding};
	}
`;
