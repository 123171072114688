import { nullValueText } from '@compstak/common';
import {
	MultiPropertyItemsTooltip,
	NAV_KEY,
	SearchTable,
	getIsPortfolioField,
	getShouldShowDefinedValuesForPortfolioField,
	getShouldShowUniqueValuesForPortfolioField,
	useSearchSalesNavHeaderContext,
} from 'Components';
import {
	LeaseAndSaleAveragesResponse,
	SearchSalesResponse,
	useSearchInfiniteParams,
	useSearchSalesInfiniteQuery,
	useSearchTableAverages,
} from 'api';
import { useLeasesSalesAveragesFormatting } from 'hooks/useLeasesSalesAveragesFormatting';
import { useSearchTableSelection } from 'hooks/useSearchTableSelection';
import { useCallback } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { routes } from 'router';
import styled from 'styled-components';
import { PortfolioItem, SalesComp } from 'types';
import { TableField } from 'types/table';
import {
	getNumberOfDefinedValuesInPortfolio,
	getUniqueDefinedValuesInPortfolio,
} from '../../../utils';
import { ExportOrUnlockButton } from '../ExportOrUnlockButton';
import { useSearchTableRows } from '../useSearchTableRows';
import { useSearchTableSort } from '../useSearchTableSort';

export const SalesSearchTable = () => {
	const [filters] = useFilters();

	const params = useSearchInfiniteParams({ filters });

	const {
		data: salesData,
		isFetching,
		fetchNextPage,
		hasNextPage,
	} = useSearchSalesInfiniteQuery(params);

	const totalCount = salesData?.pages[0].totalCount ?? 0;
	const searchAverages = salesData?.pages[0].averages;

	const rows = useSearchTableRows<SalesComp>({
		data: salesData,
		getRows: useCallback((page: SearchSalesResponse) => page.comps, []),
		isFetching,
	});

	const { data: averages } = useSearchTableAverages({
		compType: 'sale',
		searchAverages,
		filters,
	});

	const averagesFormatting = useLeasesSalesAveragesFormatting({
		averages: averages as LeaseAndSaleAveragesResponse,
		filters,
	});

	const { initNavHeader } = useSearchSalesNavHeaderContext();

	const selectionProps = useSearchTableSelection({
		compType: 'sale',
		totalCount,
		filters,
	});

	const sortProps = useSearchTableSort();

	return (
		<SearchTable
			{...selectionProps}
			{...sortProps}
			rows={rows}
			averages={averagesFormatting}
			getRowId={(row) => row.id}
			noData={!isFetching && totalCount === 0}
			onLoadMore={() => {
				if (hasNextPage && !isFetching) {
					fetchNextPage();
				}
			}}
			columnPreferencesType="sale"
			renderActionButton={(row) => (
				<ExportOrUnlockButton type="sale" row={row} />
			)}
			getBodyCellProps={(row, rowIndex) => ({
				onClick: () => {
					initNavHeader(rowIndex, { filters });
				},
				to: routes.saleById.toHref({ id: row.id }),
				state: {
					[NAV_KEY]: true,
				},
			})}
			renderCellContent={({ row, config, defaultRenderContent }) => {
				if (row.isPortfolio && getIsPortfolioField(config.name)) {
					const legacyField = {
						name: config.name,
						displayName: config.label,
					} as TableField;

					return renderPortfolioField({
						row,
						name: config.name,
						legacyField,
					});
				}

				return defaultRenderContent();
			}}
		/>
	);
};

const renderPortfolioField = ({
	row,
	name,
	legacyField,
}: {
	row: SalesComp;
	name: string;
	legacyField: TableField;
}) => {
	if (name === 'buildingAddress') {
		const numOfProperties = row.portfolio.length;
		const text = `${numOfProperties} Propert${
			numOfProperties === 1 ? 'y' : 'ies'
		}`;

		return (
			<MultiPropertyItemsTooltip saleComp={row}>
				<PortfolioText>{text}</PortfolioText>
			</MultiPropertyItemsTooltip>
		);
	} else if (getShouldShowUniqueValuesForPortfolioField(name)) {
		const uniqueValues = getUniqueDefinedValuesInPortfolio(row.portfolio, name);
		const numOfUniqueValues = uniqueValues.length;

		if (numOfUniqueValues === 0) {
			return nullValueText;
		} else if (numOfUniqueValues === 1) {
			return uniqueValues[0];
		} else {
			let secondWord = 'Values';

			if (name === 'submarket') {
				secondWord = 'Submarkets';
			} else if (name === 'marketDisplayName') {
				secondWord = 'Markets';
			}

			const text = `${numOfUniqueValues} ${secondWord}`;

			return (
				<MultiPropertyItemsTooltip saleComp={row} field={legacyField}>
					<PortfolioText>{text}</PortfolioText>
				</MultiPropertyItemsTooltip>
			);
		}
	} else if (getShouldShowDefinedValuesForPortfolioField(name)) {
		const numOfValues = getNumberOfDefinedValuesInPortfolio(
			row.portfolio as unknown as PortfolioItem[],
			name
		);

		if (numOfValues === 0) {
			return nullValueText;
		} else {
			const text = `${numOfValues} Value${numOfValues > 1 ? 's' : ''}`;

			return (
				<MultiPropertyItemsTooltip saleComp={row} field={legacyField}>
					<PortfolioText>{text}</PortfolioText>
				</MultiPropertyItemsTooltip>
			);
		}
	}
};

const PortfolioText = styled.span`
	color: ${(p) => p.theme.colors.blue.blue400};
	font-weight: bold;
`;
