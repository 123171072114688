import { Market } from '@compstak/common';
import { loadSubmarkets, MAP_CONTROLS, removeSubmarkets } from 'actions/search';
import * as userActions from 'actions/user';
import 'Components/Filters/styles/filters.nomodule.less';
import SubmarketsMapControl from 'Components/SearchSidebar/MapControls/SubmarketsMapControl';
import { MarketSelector } from 'Pages/Home/Sidebar/Components/MarketSelector';
import 'Pages/Home/Sidebar/styles/marketSelector.nomodule.less';
import { useAppConfig, usePermissions } from 'Pages/Login/reducers';
import { useSearch } from 'Pages/Search/searchReducer';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'router';
import { useFilters } from 'reducers/filtersReducer';
import { Dispatch } from 'redux';
import { modalActions } from 'Singletons/Modal/actions';
import styled from 'styled-components';
import { DarkSidebar } from 'ui/UI';
import actionWrapper from 'util/actionWrapper';
import { useAppSelector } from 'util/useAppSelector';
import Submarkets from '../ui/svg_icons/submarkets.svg';
import { ExchangeDashboardFilters } from './ExchangeDashboardFilters';
import { Section } from './types';

export type ExchangeDashboardSideBarProps = {
	sections: Section[];
	sideBarState: 'open' | 'closed';
} & ReturnType<typeof mapDispatchToProps>;

export const FILTER_CONTEXT = 'exchange-dashboard';

const Sidebar = (props: ExchangeDashboardSideBarProps) => {
	const [filters, setFilters] = useFilters(FILTER_CONTEXT);
	const appConfig = useAppConfig();
	const permissions = usePermissions();
	const marketSelectorExpanded = useAppSelector(
		(s) => s.home.marketSelectorExpanded
	);
	const currentMarket = filters.market as Market;
	const { loadingSubmarkets } = useSearch();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(removeSubmarkets());
		dispatch(loadSubmarkets(currentMarket));
	}, [currentMarket, dispatch]);

	return (
		<DarkSidebar sideBarState={props.sideBarState}>
			<Wrap>
				<MarketSelector
					appConfig={appConfig}
					compType={'lease'}
					filters={filters}
					permissions={permissions}
					marketSelectorExpanded={marketSelectorExpanded}
				/>
				<ul className="home-sidebar-controls">
					{/* @ts-expect-error TS2739: Type '{ sections: Section[]; s... */}
					<SubmarketsMapControl
						active={true}
						alwaysActive
						collapsedByDefault
						filters={filters}
						icon={Submarkets}
						loading={loadingSubmarkets}
						id={MAP_CONTROLS.SUBMARKETS}
						key={MAP_CONTROLS.SUBMARKETS}
						label="Submarkets"
						onFilterChange={setFilters}
						{...props}
					/>
				</ul>
				<ExchangeDashboardFilters appConfig={appConfig} />
			</Wrap>
		</DarkSidebar>
	);
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return actionWrapper(
		{
			userActions,
			modalActions,
		},
		dispatch
	);
};

export const DashboardSidebar = connect(null, mapDispatchToProps)(Sidebar);

const SectionButton = styled(Link)`
	background: #222530;
	color: #fff;
	display: block;
	min-height: 50px;
	margin-bottom: 1px;
	padding: 10px 12px;
	cursor: pointer;
	position: relative;
	border: 2px solid transparent;
	transition:
		background 0.3s ease-in-out,
		border 0.3s ease-in-out,
		padding 0.5s ease-in-out;
`;

const Wrap = styled.div`
	color: #fff;
	width: 100%;

	& ${SectionButton}:first-of-type {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		margin-top: 2rem;
	}

	& ${SectionButton}:last-of-type {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.market-selector {
		margin: 20px;
	}

	.home-sidebar-controls {
		padding: 0 20px 20px;
	}
`;
