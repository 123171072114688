import { MapImage, MapImagePosition } from '@compstak/maps';
import { point } from '@turf/helpers';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { deselectAll } from '../../actions/selection';
import { LinkButtonHoverOverlay } from '../../Components';
import { useFilters } from '../../reducers/filtersReducer';
import { CompType } from '../../types';
import { useMarkets } from 'hooks/useMarkets';
import { CENTER_OF_CONTINENTAL_US } from './constants';
import { useMultiSelect } from './MultiSelectProvider';
import MapIcon from 'ui/svg_icons/map.svg';
import { useMediaQuery } from 'react-responsive';
import { Link, SEARCH_BY_VIEW_ROUTES } from 'router';

type Props = {
	compType: CompType;
};

export const MapResultsButton = ({ compType }: Props) => {
	const { resetMultiSelect } = useMultiSelect();
	const dispatch = useDispatch();
	const [filters] = useFilters();

	const isMin1300Width = useMediaQuery({ minWidth: 1300 });

	const removeSelection = () => {
		resetMultiSelect();
		dispatch(deselectAll());
	};

	const href = SEARCH_BY_VIEW_ROUTES[compType].toHref({ view: 'map' });

	const allMarkets = useMarkets().list;
	const selectedMarkets = getFiltersMarkets(filters);

	const areAllMarketsSelected = allMarkets.length === selectedMarkets.length;

	const mapImagePosition = useMemo(() => {
		let position: MapImagePosition;

		if (selectedMarkets.length === 1) {
			const market = selectedMarkets[0];

			position = {
				type: 'center',
				latitude: market.lat,
				longitude: market.lon,
				zoom: market.zoom,
			};
		} else if (areAllMarketsSelected) {
			position = {
				type: 'center',
				...CENTER_OF_CONTINENTAL_US,
			};
		} else {
			position = {
				type: 'bounds',
				points: selectedMarkets.map((m) => point([m.lon, m.lat], m)),
				padding: '0',
			};
		}
		return position;
	}, [areAllMarketsSelected, selectedMarkets]);

	return (
		<>
			{isMin1300Width ? (
				<StyledLink
					to={href}
					className="button_button button_large"
					onClick={removeSelection}
					data-qa-id="return-to-map-btn"
				>
					<MapContainer>
						<LinkButtonHoverOverlay>Return to Map</LinkButtonHoverOverlay>
						<MapImage
							width={280}
							height={48}
							position={mapImagePosition}
							showLogo={false}
							showAttribution={false}
						/>
					</MapContainer>
				</StyledLink>
			) : (
				<MapIconStyledLink
					to={href}
					onClick={removeSelection}
					data-qa-id="return-to-map-btn"
				>
					<MapIcon />
				</MapIconStyledLink>
			)}
		</>
	);
};

const MapIconStyledLink = styled(Link)`
	display: flex;
	padding: 12px;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	background: rgba(0, 0, 0, 0.05);
	transition: background 0.2s linear;

	&:hover {
		background: rgba(0, 0, 0, 0.1);
	}
	&:active {
		background: rgba(0, 0, 0, 0.15);
	}
`;

const StyledLink = styled(Link)`
	width: 280px;
`;

const MapContainer = styled.div`
	width: 280px;
	height: 48px;
	position: relative;
`;
