import PortfolioTabCharts from 'PortfolioAnalytics/Charts/PortfolioTabCharts';

type PortfolioTabProps = {
	portfolioId: number;
	redirect: (path: string) => void;
};

const PortfolioTab = ({ portfolioId, redirect }: PortfolioTabProps) => (
	<>
		<PortfolioTabCharts portfolioId={portfolioId} redirect={redirect} />
	</>
);

export default PortfolioTab;
