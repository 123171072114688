import { TooltipV2 } from '@compstak/ui-kit';
import * as Tabs from '@radix-ui/react-tabs';
import { usePortfolioByIdQuery } from 'api';
import styled from 'styled-components';
// TODO: deprecated icon - use 'ui/svg_icons/help_v2.svg' instead
import IconHelp from '../../../ui/svg_icons/help.svg';
import { PortfolioOptionsButton } from '../../PortfolioOptions';
import { PortfolioFilters } from './PortfolioFilters';

type Props = {
	portfolioId: number;
	dashboardElement: HTMLElement | null;
};

export const DashboardHeader = ({ portfolioId, dashboardElement }: Props) => {
	const { data: portfolio } = usePortfolioByIdQuery({ id: portfolioId });

	if (!portfolio) return null;

	const hasDescription =
		typeof portfolio.description === 'string' &&
		portfolio.description.length > 0;

	return (
		<>
			<Header>
				<TopRow>
					<TitleContainer>
						<Title>{portfolio.name}</Title>
						{hasDescription && (
							<TooltipV2 content={portfolio.description}>
								<span>
									<IconHelp width={20} height={20} />
								</span>
							</TooltipV2>
						)}
					</TitleContainer>
					<PortfolioOptionsButton
						portfolio={portfolio}
						dashboardElement={dashboardElement}
					/>
				</TopRow>
				<TabList>
					<Tab value="portfolio">Portfolio</Tab>
					<Tab value="markets">Markets</Tab>
				</TabList>
				<PortfolioFilters portfolioId={portfolioId} />
			</Header>
		</>
	);
};

const Header = styled.div`
	padding: 1rem 1.5rem;
	background-color: ${(p) => p.theme.colors.white.white};
	border-bottom: 1px solid ${(p) => p.theme.colors.neutral.n30};
	margin-bottom: 1rem;
`;

const TopRow = styled.div`
	display: grid;
	gap: 0.5rem;
	grid-template-columns: minmax(50px, 1fr) auto;
	margin-bottom: 1rem;
`;

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const Title = styled.h1`
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-weight: 400;
	font-size: 1.5rem;
	text-transform: uppercase;
	color: ${(p) => p.theme.colors.gray.gray700};
	overflow: hidden;
`;

const TabList = styled(Tabs.List)`
	display: flex;
	gap: 0.5rem;
	margin-bottom: 1rem;
`;

const Tab = styled(Tabs.Trigger)`
	all: unset;
	font-size: 0.875rem;
	line-height: 1.5;
	font-weight: 400;
	padding: 0.75rem 0.5rem;
	cursor: pointer;
	box-sizing: border-box;
	text-transform: uppercase;
	position: relative;
	border-radius: 0.125rem;
	&:hover {
		background-color: ${(p) => p.theme.colors.neutral.n20};
	}
	&[data-state='active'] {
		font-weight: 500;
		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			height: 4px;
			width: 100%;
			background-color: ${(p) => p.theme.colors.blue.blue500};
		}
	}
`;
