import { Button } from '@compstak/ui-kit';
import { CheckboxRoot } from 'Components/Checkbox/Checkbox';
import { DarkScrollBarCSS } from 'Components/shared/DarkScrollBarCSS';
import styled, { css } from 'styled-components';
import ArrowBottom from 'ui/svg_icons/arrow_bottom.svg';

// TODO to remove once sidebarRevampFF is on
export const Container = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.gray.gray990};
	border-top: none;
	border-radius: 0 0 3px;
	box-sizing: border-box;
	transition: max-height 0.15s ease-in-out;
`;

export const ScrollContainer = styled.div`
	${DarkScrollBarCSS}
	padding: 0 1em 1em;
	overflow-x: hidden;
	overflow-y: auto;
	box-sizing: border-box;
	height: calc(30vh - 3em);
	transition: max-height 0.15s ease-in-out;
`;

export const CollapsedContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.25rem;
	padding: 0.5rem;
	cursor: pointer;
	&:hover {
		svg {
			fill: ${({ theme }) => theme.colors.white.white};
		}
	}
`;

export const IconArrowBottom = styled(ArrowBottom)`
	fill: ${({ theme }) => theme.colors.gray.gray990};
	margin-right: 0.5rem;
	width: 0.5rem;
	flex-shrink: 0;
`;

export const CollapseButton = styled(Button)`
	height: 30px;
	line-height: 30px;
	width: 100%;
	font-size: 12px;
	letter-spacing: 0.1em;
	border-radius: 0px;
	border-top: ${({ theme }) => `1px solid ${theme.colors.gray.gray990}`};
	color: ${({ theme }) => theme.colors.gray.gray100};
	&:hover {
		color: ${({ theme }) => theme.colors.white.white};
	}
`;

export const MarketSection = styled.div`
	&:not(:first-child) {
		margin-top: 1em;
	}
`;

export const MapControlItem = styled.div<{
	isChecked?: boolean;
	isDisabled?: boolean;
}>`
	display: flex;
	margin-top: 0.5em;
	gap: 5px;
	align-items: center;

	${({ isDisabled }) =>
		isDisabled
			? css`
					cursor: not-allowed;
				`
			: css`
					cursor: pointer;
				`};

	label {
		text-transform: capitalize;
		font-size: 12px;
		color: ${({ isChecked, theme }) =>
			isChecked ? theme.colors.white.white : theme.colors.gray.gray100};
		font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	}

	&:hover {
		${CheckboxRoot} {
			${({ isDisabled, theme }) =>
				!isDisabled &&
				css`
					border-color: ${theme.colors.white.white};
				`};
		}
		label {
			${({ isDisabled, theme }) =>
				isDisabled
					? css`
							cursor: not-allowed;
						`
					: css`
							color: ${theme.colors.white.white};
							cursor: pointer;
						`};
		}
	}
`;

export const MarketLabel = styled.label`
	color: ${({ theme }) => theme.colors.blue.blue500};
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	font-size: 11px;
	text-transform: uppercase;
`;
