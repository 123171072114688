import { DataProviderLogoBase } from '@compstak/ui-kit';
import { FilterGroupTitle } from 'Components/Filters/Base/Filter/FilterGroupTitle';
import { FilterSpacer } from 'Components/Filters/Base/Filter/FilterSpacer';
import { AddressFilter } from 'Components/Filters/Fields/AddressFilter/AddressFilter';
import { PropertySubtypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertySubtypeFilter';
import { PropertyTypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertyTypeFilter';
import { AssetClassFilter } from 'Components/Filters/Fields/AssetClassFilter/AssetClassFilter';
import { AverageTransactionSizeFilter } from 'Components/Filters/Fields/AverageTransactionSizeFilter/AverageTransactionSizeFilter';
import { BuildingClassFilter } from 'Components/Filters/Fields/BuildingClassFilter/BuildingClassFilter';
import { BuildingFloorsCount } from 'Components/Filters/Fields/BuildingFloorsCount/BuildingFloorsCount';
import { BuildingNameFilter } from 'Components/Filters/Fields/BuildingNameFilter/BuildingNameFilter';
import { BuildingSizeFilter } from 'Components/Filters/Fields/BuildingSizeFilter/BuildingSizeFilter';
import { BuildingSqFtExpiringInTwelveMonthsFilter } from 'Components/Filters/Fields/BuildingSqFtExpiringInTwelveMonthsFilter/BuildingSqFtExpiringInTwelveMonthsFilter';
import { CityFilter } from 'Components/Filters/Fields/CityFilter/CityFilter';
import { CountyAPNFilter } from 'Components/Filters/Fields/CountyAPNFilter/CountyAPNFilter';
import { CurrentOccupancyRateFilter } from 'Components/Filters/Fields/CurrentOccupancyRateFilter/CurrentOccupancyRateFilter';
import { EffectiveRentPerSqfFilter } from 'Components/Filters/Fields/EffectiveRentPerSqfFilter/EffectiveRentPerSqfFilter';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { LandlordFilter } from 'Components/Filters/Fields/LandlordFilter/LandlordFilter';
import { LastSalePriceFilter } from 'Components/Filters/Fields/LastSalePriceFilter/LastSalePriceFilter';
import { CurrentLoanRateFilter } from 'Components/Filters/Fields/LoanFilters/CurrentLoanRateFilter/CurrentLoanRateFilter';
import { LenderFilter } from 'Components/Filters/Fields/LoanFilters/LenderFilter/LenderFilter';
import { LoanAmountFilter } from 'Components/Filters/Fields/LoanFilters/LoanAmountFilter/LoanAmountFilter';
import { LoanInterestTypeFilter } from 'Components/Filters/Fields/LoanFilters/LoanInterestTypeFilter/LoanInterestTypeFilter';
import { LoanMaturityDateFilter } from 'Components/Filters/Fields/LoanFilters/LoanMaturityDateFilter/LoanMaturityDateFilter';
import { LoanOriginationDateFilter } from 'Components/Filters/Fields/LoanFilters/LoanOriginationDateFilter/LoanOriginationDateFilter';
import { LoanSourceFilter } from 'Components/Filters/Fields/LoanFilters/LoanSourceFilter/LoanSourceFilter';
import { LoanToValueFilter } from 'Components/Filters/Fields/LoanFilters/LoanToValueFilter/LoanToValueFilter';
import { ManagementCompanyFilter } from 'Components/Filters/Fields/ManagementCompanyFilter/ManagementCompanyFilter';
import { MarketStartingRentFilter } from 'Components/Filters/Fields/MarketStartingRentFilter/MarketStartingRentFilter';
import { PropertyStatusFilter } from 'Components/Filters/Fields/PropertyStatusFilter/PropertyStatusFilter';
import { StartingRentPerSqfFilter } from 'Components/Filters/Fields/StartingRentPerSqfFilter/StartingRentPerSqfFilter';
import { TotalUnitsFilter } from 'Components/Filters/Fields/TotalUnitsFilter/TotalUnitsFilter';
import { YearBuilt } from 'Components/Filters/Fields/YearBuilt/YearBuilt';
import { YearRenovatedFilter } from 'Components/Filters/Fields/YearRenovatedFilter/YearRenovatedFilter';
import { ZipFilter } from 'Components/Filters/Fields/ZipFilter/ZipFilter';
import { BetaChip } from 'Components/UI';
import { useIsExchange, useIsMultifamily } from 'hooks';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import styled from 'styled-components';
import { useIsShowTreppUI } from 'utils';
import { RealPageFilterInfo } from './RealPageFilterInfo';

export const PropertyFilterFields = () => {
	const { filters } = useFilterFieldContext();

	const isMultifamily = useIsMultifamily({
		markets: getFiltersMarkets(filters),
	});
	const isEnterprise = !useIsExchange();

	return (
		<>
			<FilterSpacer />
			{isMultifamily && <FilterGroupTitle>Property</FilterGroupTitle>}
			<AddressFilter />
			<CityFilter />
			<ZipFilter />
			<CountyAPNFilter />
			<FilterSpacer />

			<BuildingNameFilter />
			<YearBuilt />
			<PropertyTypeFilter>
				<PropertySubtypeFilter />
			</PropertyTypeFilter>

			<BuildingClassFilter />
			<BuildingSizeFilter />
			<YearRenovatedFilter />
			<BuildingFloorsCount />
			<LastSalePriceFilter />
			<LandlordFilter />
			<FilterSpacer />

			<BuildingSqFtExpiringInTwelveMonthsFilter />
			<AverageTransactionSizeFilter />
			<MarketStartingRentFilter />
			{useIsShowTreppUI({
				markets: getFiltersMarkets(filters),
			}) && (
				<>
					<FilterSpacer />
					<FilterGroupTitle>
						<LoansTitle>
							<div>Loan Info</div>
							{!isEnterprise && <BetaChip>Beta</BetaChip>}
						</LoansTitle>
					</FilterGroupTitle>
					<LoanSourceFilter />
					<LenderFilter />
					<LoanAmountFilter />
					<LoanInterestTypeFilter />
					<CurrentLoanRateFilter />
					<LoanOriginationDateFilter />
					<LoanMaturityDateFilter />
					<LoanToValueFilter />
				</>
			)}
			{isMultifamily && (
				<>
					<FilterSpacer />
					<FilterGroupTitle>
						<span>Multifamily</span>
						<DataProviderLogoBase
							baseStyles={{ alignItems: 'baseline' }}
							logoImageStyles={{ height: 9 }}
						/>
					</FilterGroupTitle>
					<PropertyStatusFilter />
					<TotalUnitsFilter />
					<FilterSpacer />

					<CurrentOccupancyRateFilter />
					<StartingRentPerSqfFilter />
					<EffectiveRentPerSqfFilter />
					<FilterSpacer />
					<AssetClassFilter />
					<ManagementCompanyFilter />
				</>
			)}
			{isEnterprise &&
				!isMultifamily &&
				getFiltersMarkets(filters).length > 1 && (
					<>
						<FilterSpacer />
						<RealPageFilterInfo />
					</>
				)}
		</>
	);
};

const LoansTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;
