import * as Tabs from '@radix-ui/react-tabs';
import { PortfolioTab } from 'PortfolioAnalytics/Tabs';
import { MarketsTab } from 'PortfolioAnalytics/Tabs/MarketsTab';
import { useCallback, useState } from 'react';
import { useNavigate } from 'router';
import styled from 'styled-components';
import { DashboardHeader } from './DashboardHeader';
import { DashboardSidebar } from './DashboardSidebar';
import { PortfolioOverview } from './PortfolioOverview';

type Props = {
	portfolioId: number;
};

export const Dashboard = ({ portfolioId }: Props) => {
	const [tab, setTab] = useState('portfolio');
	const [contentEl, setContentEl] = useState<HTMLDivElement | null>(null);

	const navigate = useNavigate();
	// temporary because entire subtrees depend on this function
	const redirect = useCallback(
		(path: string) => {
			navigate(path);
		},
		[navigate]
	);

	return (
		<Root>
			<Content ref={setContentEl}>
				<Tabs.Root value={tab} onValueChange={setTab}>
					<DashboardHeader
						portfolioId={portfolioId}
						dashboardElement={contentEl}
					/>
					<PortfolioOverview portfolioId={portfolioId} />
					<TabContentContainer>
						<Tabs.Content value="portfolio">
							<PortfolioTab portfolioId={portfolioId} redirect={redirect} />
						</Tabs.Content>
						<Tabs.Content value="markets">
							<MarketsTab portfolioId={portfolioId} redirect={redirect} />
						</Tabs.Content>
					</TabContentContainer>
				</Tabs.Root>
			</Content>
			<DashboardSidebar portfolioId={portfolioId} />
		</Root>
	);
};

const Root = styled.div`
	display: grid;
	grid-template-columns: 1fr 320px;
	height: 100%;
`;

const Content = styled.div`
	height: 100%;
	flex: 1;
	overflow: auto;
`;

const TabContentContainer = styled.div`
	padding: 0 1.5rem;
`;
