import { BUILDING_CLASS_ID_TO_NAME } from 'api';
import { usePortfolioFilters } from '../../PortfolioFiltersProvider';
import { CheckboxFilter } from './CheckboxFilter';
import { FilterOption } from './types';

export const BuildingClassFilter = () => {
	const { filters, setFilters } = usePortfolioFilters();

	return (
		<CheckboxFilter
			label="Building Class"
			emptyValueLabel="All Classes"
			options={OPTIONS}
			value={filters.buildingClassIds}
			onChange={(value) => {
				setFilters((s) => ({
					...s,
					buildingClassIds: value,
				}));
			}}
			buttonProps={{
				style: {
					width: 124,
				},
			}}
		/>
	);
};

const buildingClassOptions = Object.entries(BUILDING_CLASS_ID_TO_NAME).reduce(
	(o, [id, name]) => {
		o.push({
			label: name,
			value: String(id),
		});
		return o;
	},
	[] as FilterOption[]
);

const OPTIONS = [
	...buildingClassOptions,
	{
		label: 'Unknown',
		value: '-1',
	},
];
