import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { StrFormOption, UploadFormInputSC } from './UploadFormCommon';
import { withErrorBoundaryDefault } from 'Components/ErrorBoundary';

export const SelectOrInputText = withErrorBoundaryDefault(
	SelectOrInputTextUnsafe
);
SelectOrInputText.displayName = 'SelectOrInputText';

function SelectOrInputTextUnsafe({
	options,
	name,
	value,
	onChange,
	placeholder = '',
	avoidAutofillByName,
}: {
	value: string;
	name: string;
	options: StrFormOption[];
	onChange: (value: string) => void;
	placeholder?: string;
	avoidAutofillByName?: boolean;
}) {
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const [focusedOptionIdx, setFocusedOptionIdx] = useState<number>(0);
	const inputRef = useRef<HTMLInputElement>(null);
	const dropdownRef = useRef<HTMLUListElement>(null);

	const filteredOptions = options.filter((option) =>
		option.label.toLowerCase().includes(value.toLowerCase())
	);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node) &&
				inputRef.current &&
				!inputRef.current.contains(event.target as Node)
			) {
				setIsDropdownVisible(false);
				setFocusedOptionIdx(0);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		setFocusedOptionIdx(0);
	}, [value]);

	const handleOptionClick = (option: StrFormOption) => {
		setIsDropdownVisible(false);
		onChange(option.value);
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		switch (event.key) {
			case 'ArrowDown':
				focusAdjacentOption('next');
				break;
			case 'ArrowUp':
				focusAdjacentOption('previous');
				break;
			case 'Tab':
			case 'Enter': {
				event.preventDefault();
				if (focusedOptionIdx) {
					const focusedOpt = filteredOptions?.[focusedOptionIdx];
					if (focusedOpt) handleOptionClick(focusedOpt);
				}
				setIsDropdownVisible(false);
				break;
			}
			case 'Escape':
				setIsDropdownVisible(false);
				break;
		}
	};

	const focusAdjacentOption = (direction: 'next' | 'previous') => {
		const index = focusedOptionIdx;
		const nextIndex =
			direction === 'next'
				? (index + 1) % filteredOptions.length
				: (index - 1 + filteredOptions.length) % filteredOptions.length;
		setFocusedOptionIdx(nextIndex);
	};

	return (
		<InputContainer>
			<UploadFormInputSC
				ref={inputRef}
				type="text"
				name={avoidAutofillByName ? undefined : name}
				id={avoidAutofillByName ? undefined : name}
				placeholder={placeholder}
				value={value}
				onChange={(ev) => onChange(ev.target.value)}
				onFocus={() => setIsDropdownVisible(true)}
				onKeyDown={handleKeyDown}
				autoComplete={avoidAutofillByName ? 'do-not-autofill' : 'off'}
			/>
			{isDropdownVisible && (
				<DropdownSC ref={dropdownRef}>
					{filteredOptions.map((option, idx) => (
						<OptionSC
							key={option.value}
							onMouseDown={() => handleOptionClick(option)}
							isFocused={focusedOptionIdx === idx}
						>
							{option.label}
						</OptionSC>
					))}
				</DropdownSC>
			)}
		</InputContainer>
	);
}

const InputContainer = styled.div`
	position: relative;
	flex-grow: 1;
`;

const DropdownSC = styled.ul`
	display: block;
	position: absolute;
	background-color: white;
	border: 1px solid #ccc;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	z-index: 1;
	width: 100%;
	max-height: 160px;
	overflow-y: auto;
	list-style: none;
	padding: 4px;
`;

const OptionSC = styled.li<{ isFocused: boolean }>`
	padding: 0.5rem;
	cursor: pointer;
	background-color: ${(props) => (props.isFocused ? '#f0f0f0' : 'white')};

	&:hover {
		background-color: #f0f0f0;
	}
`;
