import { Shape } from 'Pages/Analytics/analytics';
import { SalesAttributeToPlotValues } from 'Components/Graphs/Selectors';
import { filtersToServerJSON } from 'models/filters/util/serverJson';
import { FiltersObject } from 'models/filters/types';

export function shapeToInsight(shape: Shape) {
	const { filters } = shape;
	const { excludedCompIds } = shape;
	const modifiedFilters: FiltersObject = excludedCompIds?.length
		? { ...filters, compId: { value: excludedCompIds, exclude: true } }
		: filters;
	let points;
	if (shape.points) {
		points = 50;
	} else if (!shape.points) {
		points = 0;
	} else {
		points = shape.points;
	}

	let quartiles;
	if (shape.quartiles === true) {
		quartiles = 1500;
	} else if (!shape.quartiles) {
		quartiles = 10e9;
	} else {
		quartiles = shape.quartiles;
	}

	const filtersToServerJson = filtersToServerJSON(modifiedFilters);

	return {
		filter: filtersToServerJson,
		properties: [shape.y, shape.x, 'transactionSize', 'id'].filter((a) => a),
		fullDataThreshold:
			shape.y === SalesAttributeToPlotValues.CAP_RATE
				? NO_DATA_THRESHOLD
				: points,
		quartileThreshold: quartiles,
		trendMonths: shape.trendMonths,
		summaries: shape.summaries,
	};
}

export type Insight = ReturnType<typeof shapeToInsight>;
const NO_DATA_THRESHOLD = 1e6;
