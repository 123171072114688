import React from 'react';
import { MAP_CONTROLS } from 'actions/search';

import MapControl from './MapControl';
import SubmarketsMapControl from './SubmarketsMapControl';
import OpportunityZoneMapControl from './OpportunityZonesMapControl';

import MapViewportIcon from '../../../ui/svg_icons/map-viewport-icon.svg';
import MapRadiusIcon from '../../../ui/svg_icons/map-radius-icon.svg';
import MapPolygonIcon from '../../../ui/svg_icons/map-polygon-icon.svg';
import Submarkets from '../../../ui/svg_icons/submarkets.svg';

import './mapControls.nomodule.less';
import { useSearch } from 'Pages/Search/searchReducer';
import { CompType } from 'types/comp';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { FiltersObject } from 'models/filters/types';

type MapControlsProps = {
	compType: CompType;
	params?: SearchLayoutRouteParams;
	filters: FiltersObject;
	onFilterChange: (newFilters: Partial<FiltersObject>) => void;
};

export default function MapControls(props: MapControlsProps) {
	const { activeMapControl, loadingSubmarkets } = useSearch();
	return (
		<ul className="home-sidebar-controls">
			<MapControl
				label="Search Within View"
				icon={MapViewportIcon}
				key={MAP_CONTROLS.SEARCH_WITHIN_VIEW}
				id={MAP_CONTROLS.SEARCH_WITHIN_VIEW}
				active={activeMapControl === MAP_CONTROLS.SEARCH_WITHIN_VIEW}
				compType={props.compType}
				filters={props.filters}
				onFilterChange={props.onFilterChange}
				params={props.params}
			/>

			<MapControl
				label="Radius Filter"
				icon={MapRadiusIcon}
				key={MAP_CONTROLS.RADIUS}
				id={MAP_CONTROLS.RADIUS}
				active={activeMapControl === MAP_CONTROLS.RADIUS}
				compType={props.compType}
				filters={props.filters}
				onFilterChange={props.onFilterChange}
				params={props.params}
			/>

			<MapControl
				label="Polygon Filter"
				icon={MapPolygonIcon}
				key={MAP_CONTROLS.POLYGON}
				id={MAP_CONTROLS.POLYGON}
				active={activeMapControl === MAP_CONTROLS.POLYGON}
				compType={props.compType}
				filters={props.filters}
				onFilterChange={props.onFilterChange}
				params={props.params}
			/>
			{/* @ts-expect-error expected missing properties error */}
			<SubmarketsMapControl
				label="Submarkets"
				icon={Submarkets}
				key={MAP_CONTROLS.SUBMARKETS}
				id={MAP_CONTROLS.SUBMARKETS}
				active={activeMapControl === MAP_CONTROLS.SUBMARKETS}
				loading={loadingSubmarkets}
				compType={props.compType}
				filters={props.filters}
				onFilterChange={props.onFilterChange}
				params={props.params}
			/>

			<OpportunityZoneMapControl
				active={activeMapControl === MAP_CONTROLS.OPPORTUNITY_ZONES}
				compType={props.compType}
				filters={props.filters}
				onFilterChange={props.onFilterChange}
				params={props.params}
			/>
		</ul>
	);
}
