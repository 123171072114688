import React, { memo } from 'react';
import styled from 'styled-components';
import { ExportButton } from 'Components';
import MonitoringIcon from '../../../ui/svg_icons/monitoring.svg';
import buttonStyles from '../../../ui/styles/button.less';
import { useAppSelector } from 'util/useAppSelector';
import {
	useIsSendToAnalyticsBtnAllowed,
	useSendToAnalyticsBtn,
} from 'hooks/chartBuilderHooks';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { UnlockButton } from 'Components/UnlockButton/UnlockButton';
import { FiltersType, useFilters } from 'reducers/filtersReducer';
import { AppState } from 'reducers/root';
import { CompType } from 'types';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { GoToCompTypeSection } from './GoToCompTypeSection';

import { useMediaQuery } from 'react-responsive';

type SearchToolbarButtonsProps = {
	compType: CompType;
	params: SearchLayoutRouteParams;
	filterContext?: FiltersType;
};

export const SearchToolbarButtons = memo<SearchToolbarButtonsProps>(
	({ compType, params, filterContext }) => {
		const isMin1500Width = useMediaQuery({ minWidth: 1500 });

		const isSendToAnalyticsBtnAllowed =
			useIsSendToAnalyticsBtnAllowed(compType);
		const [filters] = useFilters(filterContext);
		const selection = useAppSelector((state) => state.selection.list);

		const allPropertiesCount = useAppSelector(
			(store: AppState) => store.searchReducer.totalResults ?? 0
		);
		const { isEnabled, href, tooltipText } = useSendToAnalyticsBtn({
			filters,
			compType,
		});

		if (params.view !== 'list') {
			return null;
		}

		const relevantPropertiesCount =
			selection.length > 0
				? abbreviateNumber(selection.length)
				: getFiltersMarkets(filters).length > 1
					? undefined
					: abbreviateNumber(allPropertiesCount);

		return (
			<ul className="toolbar_controls">
				{compType !== 'property' && (
					<li className="single_toolbar_control">
						<UnlockButton
							compType={compType}
							markets={getFiltersMarkets(filters)}
						/>
					</li>
				)}
				<li className="single_toolbar_control">
					<ExportButton
						compType={compType}
						inactiveClassName="button_dark_blue button_export"
						className="button_export"
					/>
				</li>
				{compType === 'property' && <GoToCompTypeSection />}
				{isSendToAnalyticsBtnAllowed && (
					<>
						<VerticalSeparator />
						<li id="send_to_chartbuilder" className="single_toolbar_control">
							<a
								href={href}
								data-qa-id="send-to-chartbuilder"
								data-tooltipdelay="0"
								data-tooltip={tooltipText}
								data-tooltip-position={isEnabled ? 'above' : 'below'}
								className={
									`${buttonStyles.button} button_export` +
									(isEnabled ? '' : ' button_disabled')
								}
							>
								<MonitoringIcon />
								{isMin1500Width && 'SEND TO CHARTBUILDER'}
								{relevantPropertiesCount
									? `\u00A0(${relevantPropertiesCount})`
									: ''}
							</a>
						</li>
					</>
				)}
			</ul>
		);
	}
);

const VerticalSeparator = styled.span`
	width: 1px;
	height: 32px;
	background-color: ${({ theme }) => theme.colors.neutral.n50};
	float: left;
	position: relative;
	top: 9px;
	margin-left: 8px;
`;

SearchToolbarButtons.displayName = 'SearchToolbarButtons';
