import React, {
	ChangeEvent,
	Component,
	FocusEvent,
	KeyboardEvent,
} from 'react';
import '../styles/formInput.nomodule.less';

type InputOrTextArea = HTMLInputElement | HTMLTextAreaElement;

type Props = {
	value: string;
	name: string;
	allowHorizontalScrolling?: boolean;
	inputClass?: string;
	isDate?: boolean;
	isLease?: boolean;
	label?: string;
	labelClass?: string;
	onBlur?: (event: React.FocusEvent<InputOrTextArea>) => void;
	onChange?: (name: string, value: string) => void;
	onFocus?: (event: React.FocusEvent<InputOrTextArea>) => void;
	placeholder?: string;
	postUnit?: string;
	required?: boolean;
	type?: string;
	unit?: string;
};

export default class FormInput extends Component<Props> {
	// TODO: Replace this single component with a few more specific components?
	constructor(props: Props) {
		super(props);
	}

	static defaultProps = {
		allowHorizontalScrolling: true,
	};

	handleKeyDown = (event: KeyboardEvent<InputOrTextArea>) => {
		if (event.keyCode === 13) {
			event.preventDefault();
		}
	};

	onChange = (event: ChangeEvent<InputOrTextArea>) => {
		if (this.props.onChange) {
			const value =
				event.target.value === undefined
					? event.target.textContent
					: event.target.value;
			if (value == null) return;
			this.props.onChange(this.props.name, value);
		}
	};

	onFocus = (event: FocusEvent<InputOrTextArea>) => {
		if (this.props.onFocus) {
			this.props.onFocus(event);
		}
	};

	onBlur = (event: FocusEvent<InputOrTextArea>) => {
		if (this.props.onBlur) {
			this.props.onBlur(event);
		}
	};

	renderLabel() {
		let required;
		const checklist = [
			'buyer',
			'recordedBuyer',
			'seller',
			'recordedSeller',
			'buildingSize',
			'lotSize',
			'transactionSize',
			'salePricePsf',
			'totalSalePrice',
			'leaseTerm',
			'expirationDate',
		];

		if (checklist.includes(this.props.name)) {
			required = '**';
			// not proud of this hack
			if (
				this.props.name === 'transactionSize' &&
				this.props.isLease &&
				this.props.required
			) {
				required = '*';
			}
		} else {
			required = this.props.required ? '*' : false;
		}

		let postUnit;
		if (this.props.postUnit) {
			if (this.props.unit !== this.props.postUnit) {
				postUnit = '(' + this.props.postUnit + ')';
			}
		}

		if (this.props.label) {
			const labelClass = this.props.labelClass || '';
			return (
				<div
					className={
						'label input_label' + (this.props.required ? ' required' : '')
					}
				>
					<label className={labelClass}>
						{this.props.label} {postUnit} {required}{' '}
					</label>
				</div>
			);
		} else {
			return false;
		}
	}

	replaceNewLines = (oldStr = '') => {
		return oldStr.replace(/\n+/g, ' ');
	};

	render() {
		let className = 'form-input';
		if (this.props.inputClass) {
			className += ' ' + this.props.inputClass;
		}
		const scrollingClass = this.props.allowHorizontalScrolling
			? 'scrolling'
			: '';

		const type = this.props.type || 'text';
		if (this.props.isDate) {
			return (
				<div className={className}>
					{this.renderLabel()}
					<div className="input-container">
						<input
							className="input"
							autoComplete="off"
							onChange={this.onChange}
							onFocus={this.onFocus}
							onBlur={this.onBlur}
							value={this.props.value}
							type={type}
							placeholder={this.props.placeholder}
							data-qa-id={this.props.name + '-input'}
						/>
						{this.props.unit ? (
							<div className="form-input-unit">{this.props.unit}</div>
						) : (
							false
						)}
					</div>
				</div>
			);
		} else {
			return (
				<div className={className}>
					{this.renderLabel()}
					<div className="form-input-container">
						<textarea
							className={`form-input ${scrollingClass}`}
							onChange={this.onChange}
							onKeyDown={this.handleKeyDown}
							onFocus={this.onFocus}
							onBlur={this.onBlur}
							placeholder={this.props.placeholder}
							value={this.replaceNewLines(this.props.value)}
						/>
						{this.props.unit ? (
							<div className="form-input-unit">{this.props.unit}</div>
						) : (
							false
						)}
					</div>
				</div>
			);
		}
	}
}
