import constate from 'constate';
import { RouterLocation } from 'types';
import { useSelectionState } from 'utils/useSelectionState';
import { useListViewProperties } from './useListViewProperties';

type Props = {
	portfolioId: number;
	location: RouterLocation;
};

export const [PropertiesSelectionProvider, usePropertiesSelectionContext] =
	constate(({ portfolioId, location }: Props) => {
		const { data: properties } = useListViewProperties({
			portfolioId,
			location,
		});

		return useSelectionState(properties);
	});
