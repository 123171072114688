import { calendarUtils, Flex } from '@compstak/ui-kit';
import { APP_HEADER_HEIGHT } from 'constants/sizes';
import { useModal } from 'providers/ModalProvider';
import styled from 'styled-components';
import { PromotionMarketsDialog } from './PromotionMarketsDialog';
import { Promotion } from './promotionTypes';
import { useMemo } from 'react';

export const PromoPageOutline = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	inset: ${APP_HEADER_HEIGHT}px 0 0 0;
	overflow: auto;
`;

export const PROMO_TABS_MAX_WIDTH = 1600;

function formatPromoDate(date: string) {
	if (!date) {
		return 'N/A';
	}
	return calendarUtils.getDate(date, 'fullDate2');
}

export function PromoTitleAndDate({ promotion }: { promotion: Promotion }) {
	return (
		<Flex>
			<PromoTitle>{promotion.title}</PromoTitle>
			<div>
				{formatPromoDate(promotion.marketingStartDate)} -{' '}
				{formatPromoDate(promotion.marketingEndDate)}
			</div>
		</Flex>
	);
}

const useRewardLabelText = ({ promotion }: { promotion: Promotion }) => {
	const creditRewardMultiplier = promotion.creditReward?.multiplier ?? 0;
	const giftcardTiers = promotion.giftCardReward?.tiers ?? [];
	const hasCreditReward = creditRewardMultiplier !== 0;
	const hasGiftcardReward =
		Array.isArray(giftcardTiers) && giftcardTiers.length > 0;
	if (hasCreditReward && hasGiftcardReward) {
		return `${creditRewardMultiplier}x Credits + Gift Card`;
	}
	if (hasCreditReward) {
		return `${creditRewardMultiplier}x Credits`;
	}
	if (hasGiftcardReward) {
		return 'Gift Card';
	}
	return 'N/A';
};

export function PromoDetailsSection({ promotion }: { promotion: Promotion }) {
	const rewardLabelText = useRewardLabelText({ promotion });

	return (
		<PromoDetailsContainer>
			<div>
				<BoldLabel>Markets</BoldLabel>
				<div>{promotion.markets.join(', ')}</div>
			</div>
			<GridStyled>
				<div>
					<BoldLabel>Space Types</BoldLabel>
					<div>{promotion.spaceTypes.join(', ')}</div>
				</div>
				<div>
					<BoldLabel>Execution Date</BoldLabel>
					<div>
						Since {formatPromoDate(promotion.marketingExecutionStartDate)}
					</div>
				</div>
				<div>
					<BoldLabel>Rewards</BoldLabel>
					<div>{rewardLabelText}</div>
				</div>
			</GridStyled>
		</PromoDetailsContainer>
	);
}

export const PromoDetailsSectionV2 = ({
	promotion,
}: {
	promotion: Promotion;
}) => {
	const rewardLabelText = useRewardLabelText({ promotion });

	const { openModal } = useModal();

	const sortedMarkets = useMemo(() => {
		return promotion.markets.sort((a, b) => a.localeCompare(b));
	}, [promotion.markets]);

	return (
		<PromoDetailsContainer>
			<GridStyled>
				<div>
					<BoldLabel>Space Types</BoldLabel>
					<div>{promotion.spaceTypes.join(', ')}</div>
				</div>
				{promotion.executionDateLookback && (
					<div>
						<BoldLabel>Lease Executed In</BoldLabel>
						<div>Last {promotion.executionDateLookback.slice(1)} days</div>
					</div>
				)}
				{!promotion.executionDateLookback && (
					<div>
						<BoldLabel>Execution Date</BoldLabel>
						<div>
							Since {formatPromoDate(promotion.marketingExecutionStartDate)}
						</div>
					</div>
				)}
				<div>
					<BoldLabel>Rewards</BoldLabel>
					<div>{rewardLabelText}</div>
				</div>
			</GridStyled>
			<div>
				<BoldLabel>Markets</BoldLabel>
				{promotion.marketsDisplayText && promotion.markets.length > 10 ? (
					<div>
						{promotion.marketsDisplayText} (
						<SeeMore
							onClick={() => {
								openModal({
									modalContent: (
										<PromotionMarketsDialog promotion={promotion} />
									),
								});
							}}
						>
							See List of Markets
						</SeeMore>
						)
					</div>
				) : (
					<div>{sortedMarkets.join(', ')}</div>
				)}
			</div>
		</PromoDetailsContainer>
	);
};

const PromoTitle = styled.h4`
	text-transform: uppercase;
`;

const PromoDetailsContainer = styled(Flex)`
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	width: 100%;
`;

const GridStyled = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	width: 100%;
	gap: 8px;
`;

const BoldLabel = styled.b`
	font-size: 16px;
	font-weight: 600;
`;

export const PromoSimpleWarning = styled.div`
	margin: 16px;
`;

const SeeMore = styled.button`
	all: unset;
	color: ${(p) => p.theme.colors.blue.blue400};
	text-decoration: underline;
	cursor: pointer;
`;
