import {
	NotificationType,
	copyToClipboard,
	useNotifications,
} from '@compstak/ui-kit';
import { PropertyLease, PropertySale, useUserQuery } from 'api';
import { routes, useNavigate } from 'router';
import { getExportPopupTitle } from './getExportPopupTitle';
import { useModal } from 'providers/ModalProvider';
import { ExportTrialLimitExceededModal } from './ExportLimitExceededModal';
import { CompType, PropertyComp } from 'types';
import { useCheckLeasesExportStatusQuery } from 'api/checkExportStatus/useCheckLeasesExportStatusQuery';
import { useCheckSalesExportStatusQuery } from 'api/checkExportStatus/useCheckSalesExportStatusQuery';
import { useCheckPropertiesExportStatusQuery } from 'api/checkExportStatus/useCheckPropertiesExportStatusQuery';
import { useExportLeasesMutation } from 'api/export/useExportLeasesMutation';
import { useExportSalesMutation } from 'api/export/useExportSalesMutation';
import { useExportPropertiesMutation } from 'api/export/useExportPropertiesMutation';
import { useMemo } from 'react';

type Props<T extends CompType> = {
	comps: (T extends 'lease'
		? PropertyLease
		: T extends 'sale'
			? PropertySale
			: PropertyComp)[];
	type: T;
	closePopup: () => void;
};

const EXCEL_EXPORT_NOTIFICATION_ID = 'EXCEL_EXPORT_NOTIFICATION_ID';

export const useCompExport = <T extends CompType>({
	comps,
	type,
	closePopup,
}: Props<T>) => {
	const { data: user, isFetching: isFetchingUser } = useUserQuery();
	const isAdmin = user?.userType === 'admin';
	const isTrial = Boolean(user?.enterpriseTrialData);
	const navigate = useNavigate();
	const { openModal } = useModal();

	const compIds = useMemo(() => {
		return comps.map(({ id }) => id);
	}, [comps]);

	const exportLeasesResult = useCheckLeasesExportStatusQuery(
		{
			compIds,
			format: 'xls',
		},
		{
			enabled: type === 'lease',
		}
	);

	const exportSalesResult = useCheckSalesExportStatusQuery(
		{
			compIds,
			format: 'xls',
		},
		{
			enabled: type === 'sale',
		}
	);

	const exportPropertiesResult = useCheckPropertiesExportStatusQuery(
		{
			compIds,
			format: 'xls',
		},
		{
			enabled: type === 'property',
		}
	);

	const { data: exportExcelStatus, isFetching: isFetchingExportExcelStatus } =
		type === 'lease'
			? exportLeasesResult
			: type === 'property'
				? exportPropertiesResult
				: exportSalesResult;

	const exportLeasesMutation = useExportLeasesMutation();
	const exportSalesMutation = useExportSalesMutation();
	const exportPropertiesMutation = useExportPropertiesMutation();

	const { mutate: exportExcel, isLoading: isLoadingExportExcel } =
		type === 'lease'
			? exportLeasesMutation
			: type === 'property'
				? exportPropertiesMutation
				: exportSalesMutation;

	const { addNotification, deleteNotification } = useNotifications();

	const onClickExportExcel = ({
		monthly,
		withFinancial = true,
	}: {
		monthly: boolean;
		/** @default true */
		withFinancial?: boolean;
	}) => {
		if (exportExcelStatus && !exportExcelStatus.canExport) {
			if (isTrial) {
				openModal({ modalContent: <ExportTrialLimitExceededModal /> });
			} else {
				addNotification({
					icon: 'info',
					description:
						'We`re sorry, but you`ve exceeded your monthly export limit.',
				});
			}
			closePopup();
			return;
		}

		addNotification({
			id: EXCEL_EXPORT_NOTIFICATION_ID,
			icon: 'info',
			description: 'Exporting Comps...',
			type: NotificationType.BLUE,
			duration: 2000,
		});

		exportExcel(
			{
				compIds,
				format: 'xls',
				monthly,
				withFinancial,
			},
			{
				onSuccess: ({ exportId }) => {
					deleteNotification(EXCEL_EXPORT_NOTIFICATION_ID);
					addNotification({
						id: EXCEL_EXPORT_NOTIFICATION_ID,
						description: 'Comps are downloading.',
						type: NotificationType.BLUE,
						duration: 2000,
					});
					closePopup();
					window.location.href =
						type === 'lease'
							? `/api/comps/actions/export/${exportId}`
							: `/api/salesComps/export/${exportId}`;
				},
			}
		);
	};

	const onClickExportPdf = () => {
		closePopup();
		navigate(
			type === 'lease'
				? routes.exportPdfLeases.toHref({ compIds })
				: routes.exportPdfSales.toHref({ compIds })
		);
	};

	const onClickCopyIds = () => {
		copyToClipboard(compIds.join(','));
		closePopup();
		addNotification({
			id: 'excelCopyIds',
			description: 'IDs copied to Clipboard',
			icon: 'info',
			type: NotificationType.BLUE,
			duration: 2000,
		});
	};

	const title = exportExcelStatus ? getExportPopupTitle(exportExcelStatus) : '';

	return {
		onClickCopyIds,
		onClickExportPdf,
		onClickExportExcel,
		title,
		isFetching: isFetchingExportExcelStatus || isFetchingUser,
		isLoadingExportExcel,
		shouldShowIdsButton: isAdmin,
	};
};
