import { useIsExchangePlusUser } from 'exchange/exchangePlus/exchangePlusStatus';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Chart } from 'Pages/Analytics/analytics';
import { FromPortfolioModal } from 'Pages/Analytics/Components/Modals/FromPortfolioModal';
import React, { useState } from 'react';
import Modal from 'Singletons/Modal';
import MycompsSavedsearch from '../../../../../../ui/svg_icons/mycomps_savedsearch.svg';
import Plus from '../../../../../../ui/svg_icons/plus.svg';
import styles from '../../styles/analytics.less';
import modalStyles from '../../styles/chart-builder-modals.less';
import SavedSearches from '../SavedSearches';
import CreateNewDataSet from './CreateNewDataSet';
import { Modal as CommonModal } from 'Components/Modals/common/UI';

const AddDataSet = (props: {
	canShowAnalyticsOnExchange: boolean;
	modalActions: { pushModal: (arg0: string, arg1: any) => void };
	chartDraft: Chart;
}) => {
	const [isFromPortfolioDialogOpen, setIsFromPortfolioDialogOpen] =
		useState(false);
	const portfolioAnalytics = useFeatureFlags()['portfolio-analytics'];
	const isExchangePlusUser = useIsExchangePlusUser();
	const openNewDataSetModal = React.useCallback(() => {
		props.modalActions.pushModal(CreateNewDataSet, props);
	}, [props]);

	const openSavedSearchModal = React.useCallback(() => {
		props.modalActions.pushModal(SavedSearches, props);
	}, [props]);

	return (
		<div className={`${modalStyles.container} ${modalStyles.addDataSet}`}>
			<h3>How would you like to add your data set?</h3>
			<div className={modalStyles.boxContainer}>
				<span className={modalStyles.box} onClick={openNewDataSetModal}>
					<div className={modalStyles.icon}>
						<Plus width="32px" height="32px" />
					</div>
					<div className={modalStyles.line} />
					<div className={modalStyles.smallText}>Create</div>
					<div className={modalStyles.bigText}>New Data Set</div>
				</span>
				<span className={modalStyles.box} onClick={openSavedSearchModal}>
					<div className={modalStyles.icon}>
						<MycompsSavedsearch width="32px" height="32px" />
					</div>
					<div className={modalStyles.line} />
					<div className={modalStyles.smallText}>From</div>
					<div className={modalStyles.bigText}>Saved Search</div>
				</span>
				{portfolioAnalytics && !isExchangePlusUser && (
					<>
						<span
							className={modalStyles.box}
							onClick={() => setIsFromPortfolioDialogOpen(true)}
						>
							<div className={modalStyles.icon}>
								<MycompsSavedsearch width="32px" height="32px" />
							</div>
							<div className={modalStyles.line} />
							<div className={modalStyles.smallText}>From</div>
							<div className={modalStyles.bigText}>Portfolio</div>
						</span>
						{isFromPortfolioDialogOpen && (
							<CommonModal onClose={() => setIsFromPortfolioDialogOpen(false)}>
								<FromPortfolioModal
									closeModal={() => setIsFromPortfolioDialogOpen(false)}
								/>
							</CommonModal>
						)}
					</>
				)}
			</div>
		</div>
	);
};

Modal.addComponent({
	id: 'analytics-add-dataset',
	Component: AddDataSet,
	className: styles.analyticsModal,
});

export default 'analytics-add-dataset';
