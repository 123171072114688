import {
	Portfolio,
	UpdatePortfolioParams,
	useUpdatePortfolioMutation,
} from 'api';
import { PortfolioDialog } from '../Components';
import { invalidatePortfolioQueries } from '../invalidatePortfolioQueries';

type Props = {
	portfolio: Portfolio;
	closeDialog: () => void;
};

export const UpdatePortfolioDialog = ({ portfolio, closeDialog }: Props) => {
	const { mutateAsync: updatePortfolio, isLoading: isLoadingUpdatePortfolio } =
		useUpdatePortfolioMutation({
			onSuccess: ({ queryClient }) => {
				invalidatePortfolioQueries(queryClient);
			},
		});

	return (
		<PortfolioDialog
			title="Name & Description"
			closeDialog={closeDialog}
			isLoadingMutation={isLoadingUpdatePortfolio}
			onSubmit={async (values) => {
				const body: UpdatePortfolioParams['body'] = {
					id: portfolio.id,
					name: values.name,
					description: undefined,
				};
				if (values.description.length > 0) {
					body.description = values.description;
				}
				await updatePortfolio({ id: portfolio.id, body });
				closeDialog();
			}}
			initialFormState={{
				name: portfolio.name,
				description: portfolio.description ?? '',
			}}
			editedPortfolio={portfolio}
		/>
	);
};
