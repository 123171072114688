import constate from 'constate';
import { useState } from 'react';
import { SortDirection } from 'api/types/shared';
import { LeaseComp } from 'types/comp';
export const COMP_VISIBILITY_OPTIONS: Record<string, CompVisibilityOption> = {
	showAllComps: 'show-all-comps',
	hideExcludedComps: 'hide-excluded-comps',
	showOnlyExcludedComps: 'show-only-excluded-comps',
};
export type CompVisibilityOption =
	| 'show-all-comps'
	| 'hide-excluded-comps'
	| 'show-only-excluded-comps';

export const [UnderlyingDataTableProvider, useUnderlyingDataTableContext] =
	constate(() => {
		const [selectedCompIds, setSelectedCompIds] = useState<number[]>([]);
		const [compVisibilitySelectedOption, setCompVisibilitySelectedOption] =
			useState<CompVisibilityOption>(COMP_VISIBILITY_OPTIONS.showAllComps);
		const [excludedCompIdsForDataset, setExcludedCompIdsForDataset] = useState<
			number[]
		>([]);
		const [selectedDataSetIndex, setSelectedDataSetIndex] = useState<number>(0);
		const [sortField, setSortField] =
			useState<keyof LeaseComp>('executionQuarter');
		const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
		return {
			selectedCompIds,
			setSelectedCompIds,
			compVisibilitySelectedOption,
			setCompVisibilitySelectedOption,
			excludedCompIdsForDataset,
			setExcludedCompIdsForDataset,
			selectedDataSetIndex,
			setSelectedDataSetIndex,
			sortField,
			setSortField,
			sortDirection,
			setSortDirection,
		};
	});
