import dayjs from 'dayjs';
import { Component, FormEvent } from 'react';
import { connect } from 'react-redux';

import FormInput from './FormInput';
import FormMultiInput from './FormMultiInput';
import FormRadio from './FormRadio';
import FormSelect from './FormSelect';

import { AppState } from 'reducers/root';
import { AppDispatch } from 'store';
import wrapActions from 'util/actionWrapper';
import { sendSubmission, updateLeaseSubmissionFormState } from '../actions';
import { getLocaleForUserMarketId } from '../utils/submissionLocale';
import { getLeaseSubmissionMapFromAppStore } from '../utils/submissionMap';
import { LeaseSubmission } from '../types';

import '../styles/uploadForm.nomodule.less';
import {
	ShowAdditionalFieldsButton,
	StrFormOption,
	UploadCompFormOptionsForLeasesAndSales,
	WORK_TYPE_OPTIONS,
	getInitialLeasesAndSalesUploadCompFormState,
	getSelectOptionsFromRefrenceData,
} from './UploadFormCommon';
import { withQueryClient } from 'api';
import { QueryClient } from '@tanstack/react-query';
import { invalidateUserQuery } from 'hooks/userHooks';
import { ShowUploadSuccessModal } from 'Components/Modals/UploadSuccessModal/UploadSuccessModal';

type Props = ReturnType<typeof mapStoreToProps> &
	ReturnType<typeof mapDispatchToProps> & {
		queryClient: QueryClient;
		showUploadSuccessModal: ShowUploadSuccessModal;
	};

type State = {
	additionalFields: boolean;
	submissionComplete: boolean;
	submissionMap: ReturnType<typeof getLeaseSubmissionMapFromAppStore>;
	executionYearOptions: Array<StrFormOption>;
	expirationYearOptions: Array<StrFormOption>;
	leaseTypeOptions: Array<StrFormOption>;
	transactionTypeOptions: Array<StrFormOption>;
	spaceTypeOptions: Array<StrFormOption>;
	workTypeOptions: Array<StrFormOption>;
	subleaseOptions: Array<StrFormOption>;
} & UploadCompFormOptionsForLeasesAndSales;

class LeaseUploadForm extends Component<Props, State> {
	constructor(
		props: Props & { showUploadSuccessModal: ShowUploadSuccessModal }
	) {
		super(props);
		const year = dayjs().year();
		const executionYearOptions = Array.from({ length: 10 }, (_, k) => ({
			label: String(year - k),
			value: String(year - k),
		}));
		const expirationYearOptions = Array.from({ length: 14 }, (_, k) => ({
			label: String(year + k),
			value: String(year + k),
		}));
		const subleaseOptions = [
			{ label: 'Yes', value: 'yes' },
			{ label: 'No', value: 'no' },
		];

		const workTypeOptions = WORK_TYPE_OPTIONS;

		this.state = {
			...getInitialLeasesAndSalesUploadCompFormState(),
			additionalFields: false,
			submissionComplete: false,
			submissionMap: this.props.submissionMap,
			executionYearOptions,
			expirationYearOptions,
			workTypeOptions,
			subleaseOptions,
			...getSelectOptionsFromRefrenceData(this.props.referenceData),
		};
	}

	showAdditionalFields = () => {
		this.setState({
			additionalFields: true,
		});
	};

	updateSubmissionField = (name: string | undefined, value: string) => {
		if (!name) return;
		const { submissionMap } = this.state;
		submissionMap[name].value = value;

		this.setState({
			submissionMap,
		});

		this.props.uploadActions.updateLeaseSubmissionFormState(
			name as keyof LeaseSubmission,
			value
		);
		this.updateSubmissionComplete();
	};

	// @ts-expect-error TS7006: Parameter 'values' implicitly ha...
	handleMultiInputChange = (name: string, values) => {
		const joinedValue =
			values['month'] + '/' + values['day'] + '/' + values['year'];

		if ('month' in values && 'year' in values) {
			this.updateSubmissionField(name, joinedValue);
		}
	};

	updateSubmissionComplete() {
		const { submissionMap } = this.state;
		const outstandingRequiredFields = Object.keys(submissionMap)
			.map((key) => submissionMap[key])
			.filter(
				(field) => field.required && (!field.value || field.value.length === 0)
			);

		let submissionComplete = outstandingRequiredFields.length === 0;
		if (
			outstandingRequiredFields.length <= 2 &&
			['executionDate', 'commencementDate'].some(
				(field) => submissionMap[field].value
			) &&
			['leaseTerm', 'expirationDate'].some(
				(field) => submissionMap[field].value
			)
		) {
			submissionComplete = true;
		}
		this.setState({
			submissionComplete,
		});
	}

	sendSubmission = (event: FormEvent) => {
		const submission = {
			...this.props.uploads.leaseSubmission,
			dataType: 'lease',
			locale: getLocaleForUserMarketId(this.props.user?.primaryMarketId ?? -1),
			spaceType: undefined,
		};

		// @ts-expect-error TS2551: Property 'rawSpaceType' does n...
		submission['rawSpaceType'] = submission.spaceType;
		delete submission.spaceType;

		event.preventDefault();
		if (
			this.props.uploads.status !== 'PENDING' &&
			this.state.submissionComplete
		) {
			this.props.uploadActions.sendSubmission({
				submission,
				onSuccess: (compsAwarded) => {
					this.props.showUploadSuccessModal({ compsAwarded: compsAwarded });
					invalidateUserQuery(this.props.queryClient);
				},
			});
		}
	};

	renderLandlordFields() {
		const { submissionMap } = this.state;
		const { updateSubmissionField } = this;
		return (
			<div className="form-group">
				<div className="row">
					<div className="col-12-12">
						<FormInput
							{...submissionMap['landlordName']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-12">
						<FormInput
							{...submissionMap['sublessorName']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-12">
						<FormInput
							{...submissionMap['landlordRealtyBrokers']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-12">
						<FormInput
							{...submissionMap['landlordRealtyCompanies']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-12">
						<FormInput
							{...submissionMap['tenantRealtyBrokers']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-12">
						<FormInput
							{...submissionMap['tenantRealtyCompanies']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
			</div>
		);
	}

	renderAdditionalFields() {
		const { submissionMap } = this.state;
		const { updateSubmissionField } = this;
		return (
			<div>
				<div className="row">
					<div className="col-12-4">
						<FormSelect
							{...submissionMap['transactionType']}
							type="text"
							options={this.state.transactionTypeOptions}
							onChange={updateSubmissionField}
							matchProp="label"
						/>
					</div>
					<div className="col-12-8">
						<FormInput
							{...submissionMap['effectiveRent']}
							type="text"
							unit={submissionMap['effectiveRent']?.unit || '$/SF'}
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-3">
						<FormSelect
							{...submissionMap['leaseType']}
							type="text"
							options={this.state.leaseTypeOptions}
							onChange={updateSubmissionField}
							matchProp="label"
						/>
					</div>
					<div className="col-12-3">
						<FormInput
							{...submissionMap['rentBumpsPercent']}
							type="text"
							unit={submissionMap['rentBumpsPercent']?.unit || '%'}
							onChange={updateSubmissionField}
						/>
					</div>
					<div className="col-12-3">
						<FormInput
							{...submissionMap['rentBumpsDollar']}
							type="text"
							unit={submissionMap['rentBumpsDollar']?.unit || '$'}
							onChange={updateSubmissionField}
						/>
					</div>
					<div className="col-12-3">
						<FormInput
							{...submissionMap['leaseEscalations']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-4">
						<FormInput
							{...submissionMap['freeMonths']}
							type="text"
							unit={submissionMap['freeMonths']?.unit || 'Months'}
							onChange={updateSubmissionField}
						/>
					</div>
					<div className="col-12-4">
						<FormSelect
							{...submissionMap['workType']}
							type="text"
							options={this.state.workTypeOptions}
							onChange={updateSubmissionField}
						/>
					</div>
					<div className="col-12-4">
						<FormInput
							{...submissionMap['workValue']}
							type="text"
							unit={submissionMap['workValue']?.unit || '$/SF'}
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const requiredInfo = (
			<>
				<div>* Required field</div>
				<div>
					** At least one is required: execution date/commencement date and
					lease term/expiration date
				</div>
			</>
		);
		const extraFields = this.state.additionalFields ? (
			<div>
				{this.renderLandlordFields()}
				{requiredInfo}
			</div>
		) : (
			<div>
				{requiredInfo}
				<ShowAdditionalFieldsButton
					onClick={this.showAdditionalFields.bind(this)}
				/>
			</div>
		);

		const { submissionMap } = this.state;
		const { updateSubmissionField } = this;
		return (
			<form onSubmit={this.sendSubmission} className="upload-form-content">
				<div className="upload-form-center">
					<div className="form-group">
						<div className="row">
							<div className="col-12-12">
								<FormInput
									{...submissionMap['tenantName']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-12-12">
								<FormInput
									{...submissionMap['buildingAddress']}
									type="text"
									onChange={this.updateSubmissionField}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-12-3">
								<FormInput
									{...submissionMap['city']}
									type="text"
									onChange={this.updateSubmissionField}
								/>
							</div>
							<div className="col-12-3">
								<FormSelect
									{...submissionMap['state']}
									type="text"
									options={this.state.stateOptions}
									onChange={this.updateSubmissionField}
									matchProp="label"
								/>
							</div>
							<div className="col-12-3">
								<FormInput
									{...submissionMap['floorOccupancies']}
									type="text"
									onChange={this.updateSubmissionField}
								/>
							</div>
							<div className="col-12-3">
								<FormInput
									{...submissionMap['suite']}
									type="text"
									onChange={this.updateSubmissionField}
								/>
							</div>
						</div>
					</div>

					<div className="form-group">
						<div className="row">
							<div className="col-12-6">
								<FormSelect
									{...submissionMap['spaceType']}
									type="text"
									options={this.state.spaceTypeOptions}
									onChange={this.updateSubmissionField}
									matchProp="label"
									required={true}
								/>
							</div>
							<div className="col-12-6">
								<FormInput
									{...submissionMap['transactionSize']}
									isLease={true}
									type="text"
									onChange={this.updateSubmissionField}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12-4">
								<FormInput
									{...submissionMap['startingRent']}
									type="text"
									unit={submissionMap['startingRent']?.unit || '$'}
									onChange={this.updateSubmissionField}
								/>
							</div>
							<div className="col-12-4">
								<FormInput
									{...submissionMap['askingRent']}
									type="text"
									unit={submissionMap['leaseTerm']?.unit || '$'}
									onChange={this.updateSubmissionField}
								/>
							</div>
							<div className="col-12-4">
								<FormInput
									{...submissionMap['leaseTerm']}
									type="text"
									unit={submissionMap['leaseTerm']?.unit || 'Months'}
									onChange={this.updateSubmissionField}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12-4">
								<FormMultiInput
									{...submissionMap['executionDate']}
									onChange={this.handleMultiInputChange}
									required={true}
								>
									<FormSelect
										name="executionDate.month"
										placeholder="Month"
										options={this.state.monthOptions}
										matchProp="label"
										isDate={true}
									/>
									<FormSelect
										name="executionDate.day"
										placeholder="Day"
										options={this.state.dayOptions}
										matchProp="label"
										isDate={true}
									/>
									<FormSelect
										name="executionDate.year"
										placeholder="Year"
										options={this.state.executionYearOptions}
										matchProp="label"
										isDate={true}
									/>
								</FormMultiInput>
							</div>
							<div className="col-12-4">
								<FormMultiInput
									{...submissionMap['commencementDate']}
									onChange={this.handleMultiInputChange}
									required={true}
								>
									<FormSelect
										name="commencementDate.month"
										placeholder="Month"
										options={this.state.monthOptions}
										matchProp="label"
										isDate={true}
									/>
									<FormSelect
										name="commencementDate.day"
										placeholder="Day"
										options={this.state.dayOptions}
										matchProp="label"
										isDate={true}
									/>
									<FormSelect
										name="commencementDate.year"
										placeholder="Year"
										options={this.state.executionYearOptions}
										matchProp="label"
										isDate={true}
									/>
								</FormMultiInput>
							</div>
							<div className="col-12-4">
								<FormMultiInput
									{...submissionMap['expirationDate']}
									onChange={this.handleMultiInputChange}
								>
									<FormSelect
										name="expirationDate.month"
										placeholder="Month"
										options={this.state.monthOptions}
										matchProp="label"
										isDate={true}
									/>
									<FormSelect
										name="expirationDate.day"
										placeholder="Day"
										options={this.state.dayOptions}
										matchProp="label"
										isDate={true}
									/>
									<FormSelect
										name="expirationDate.year"
										placeholder="Year"
										options={this.state.expirationYearOptions}
										matchProp="label"
										isDate={true}
									/>
								</FormMultiInput>
							</div>
						</div>
						<div className="row extra-margin-full">
							<div className="col-12-8">
								<FormInput
									{...submissionMap['comments']}
									type="text"
									onChange={this.updateSubmissionField}
								/>
							</div>
							<div className="col-12-4">
								<FormRadio
									{...submissionMap['sublease']}
									options={this.state.subleaseOptions}
									inputClass={'pad'}
									onChange={this.updateSubmissionField}
								/>
							</div>
						</div>

						{this.state.additionalFields
							? this.renderAdditionalFields()
							: false}
					</div>

					{extraFields}

					<button
						disabled={!this.state.submissionComplete}
						className={
							'button_button button_large ' +
							(this.state.submissionComplete
								? 'button_green'
								: 'button_disabled')
						}
						data-qa-id="submit-comp-button"
					>
						Submit Comp
					</button>
				</div>
			</form>
		);
	}
}

function mapStoreToProps(store: AppState) {
	return {
		submissionMap: getLeaseSubmissionMapFromAppStore(store),
		uploads: store.uploads,
		referenceData: store.referenceData,
		user: store.user,
	};
}

function mapDispatchToProps(dispatch: AppDispatch) {
	const wrappedActions = wrapActions(
		{
			uploadActions: {
				updateLeaseSubmissionFormState,
				sendSubmission,
			},
		},
		dispatch
	);
	return wrappedActions;
}

export default connect(
	mapStoreToProps,
	mapDispatchToProps
)(withQueryClient(LeaseUploadForm));
