import React from 'react';

import CrossIcon from '../../../../ui/svg_icons/cross.svg';
import CompItem from './CompItem';
import styles from '../styles/details.less';
import styled from 'styled-components';
import { marketIdToMarketNameAndRentPeriod } from '@compstak/common';
import { useMarkets } from 'hooks/useMarkets';
import { LeaseComp } from 'types';
import {
	CompSectionedMapping,
	FormattedComp,
} from 'util/comp-format/src/format';
import { useIsExchange } from 'hooks';
import UnlockOrExportButton from 'Components/UnlockOrExportButton';

type CompSectionProps = {
	section: string;
	comp: LeaseComp;
	sectionedAttrs: CompSectionedMapping;
};

const CompSection = (props: CompSectionProps) => {
	const markets = useMarkets();

	return (
		<div className={styles.dataSection}>
			<h3>{props.section}</h3>
			{props.section === 'Tenant' && (
				<TenantCta tenantAttributes={props.sectionedAttrs?.Tenant ?? []} />
			)}

			<ul>
				{props.sectionedAttrs[props.section]
					.filter((item) => item.inDetailScreen)
					.map((item) => {
						const isInsideView = item.name.includes('insideview');
						const displayName = `${
							props.section === 'Tenant'
								? item.displayName?.replace('Tenant ', '')
								: item.displayName
						}${isInsideView ? ' *' : ''}`;

						return (
							<CompItem
								key={item.name}
								name={item.name as keyof LeaseComp}
								comp={props.comp}
								isMonthly={
									!!marketIdToMarketNameAndRentPeriod[
										props.comp
											.marketId as keyof typeof marketIdToMarketNameAndRentPeriod
									]
								}
								markets={markets}
								displayName={displayName}
								item={item}
							/>
						);
					})}
			</ul>
			{props.section === 'Tenant' && (
				<span className={styles.insideview}>* Data provided by insideview</span>
			)}
			{!props.comp.own && (
				<div className={styles.unlockOverlay}>
					<UnlockOrExportButton
						compType="lease"
						configuration={'details'}
						comp={props.comp}
					/>
				</div>
			)}
		</div>
	);
};

export default CompSection;

type TenantCtaProps = {
	tenantAttributes: FormattedComp[];
};

const TenantCta = (props: TenantCtaProps) => {
	const isExchange = useIsExchange();
	const hasInsideViewAttrs = props.tenantAttributes.some((attr) =>
		attr.name.includes('insideview')
	);
	const localValue = window.localStorage.getItem('show_tenant_cta');
	const [showTenantCta, setShowTenantCta] = React.useState(
		localValue ? (JSON.parse(localValue) as boolean) ?? true : true
	);
	const hideCta = () => {
		window.localStorage.setItem('show_tenant_cta', 'false');
		setShowTenantCta(false);
	};

	React.useEffect(() => {
		const updateLeaseCount = () => {
			const storageCount = window.localStorage.getItem('lease_count');
			const leaseCount =
				(storageCount == null ? 0 : parseInt(storageCount)) + 1;

			window.localStorage.setItem('lease_count', String(leaseCount));

			if (leaseCount >= 5) {
				window.localStorage.setItem('show_tenant_cta', 'false');
			}
		};

		if (hasInsideViewAttrs && showTenantCta) {
			updateLeaseCount();
		}
	}, [hasInsideViewAttrs, showTenantCta]);

	if (!hasInsideViewAttrs || showTenantCta === false) {
		return null;
	}
	const readMoreLink = isExchange
		? 'https://help.compstak.com/About-Tenant-Info'
		: 'https://support.compstak.com/hc/en-us/articles/360039624612-About-Tenant-Information';

	return (
		<TenantCtaContainer>
			<CrossContainer>
				<CrossIcon height="15px" width="15px" onClick={hideCta} />
			</CrossContainer>

			<span className="new-copy">NEW!</span>
			<h2>Tenant Information</h2>
			<p>
				Key information about tenants in CompStak. Includes HQ address, employee
				count, phone number, website, industry, stock ticker, and more.
			</p>
			<a
				href={readMoreLink}
				target="_blank"
				rel="noopener noreferrer"
				className="cta-readmore"
			>
				Read More
			</a>
			<span className="cta" onClick={hideCta}>
				Hide Notification
			</span>
		</TenantCtaContainer>
	);
};

const TenantCtaContainer = styled.div`
	width: auto;
	height: auto;
	left: 919px;
	top: 400px;
	font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	margin: 1rem 0;
	padding: 1rem;
	background: #ffffff;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
	border-radius: 3px;

	.new-copy {
		font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 11px;
		line-height: 18px;

		/* identical to box height, or 164% */

		color: #2690f9;
	}

	.cta {
		font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
		color: #2690f9;
		cursor: pointer;
	}

	.cta-readmore {
		font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
		color: #2690f9;
		cursor: pointer;
		margin: 0 1rem 0 0;
	}

	h2 {
		color: #303441;
		font-family: Gotham;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 24px;
		margin: 0 0 1rem;
	}

	p {
		font-family: Gotham;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		color: #303441;
		margin: 0 0 1rem;
	}
`;

const CrossContainer = styled.div`
	color: #909297;
	cursor: pointer;
	position: absolute;
	right: 1rem;
	top: 3.6rem;
`;
