import { colors, CompNotFound, ErrorUI, Spinner } from '@compstak/ui-kit';
import {
	usePropertyByIdQuery,
	usePropertyInfoQuery,
	useSaleCompQuery,
	useUserQuery,
} from 'api';
import {
	SearchSalesNavHeader,
	useSearchSalesNavHeaderContext,
} from 'Components';
import { memo } from 'react';
import { LegacyRouter } from 'router/migration/types';
import styled from 'styled-components';
import { RouterLocation } from 'types';
import { isPortfolioSaleComp } from 'utils/compHelpers';
import { APP_HEADER_HEIGHT } from '../../constants';
import { ExchangeBanner } from './Components/ExchangeBanner';
import { UNLOCK_BUTTON_STATE } from './Components/UnlockButton';
import { KeyInfo, PortfolioPropertyRail, PropertyRail } from './sections';
import { SourceCountFooter } from './sections/SourceCountFooter';
import { SourceInfoFooter } from './sections/SourceInfoFooter';
import { Tabs } from './Tabs';

export type Props = {
	params: {
		id: string;
	};
	location: RouterLocation;
	router: LegacyRouter;
};

export const SalesDetailsV2 = memo<Props>((props) => {
	const salesCompMainInfoResponse = useSaleCompQuery({
		id: Number(props.params.id),
	});
	const { data, isError, error } = salesCompMainInfoResponse;
	const { data: userData } = useUserQuery();

	const hasEnoughCredits = userData?.freeComps
		? UNLOCK_BUTTON_STATE.FREE_COMP
		: // @ts-expect-error TS18048: 'userData.points' is possibly ...
			userData?.points >= data?.cost
			? UNLOCK_BUTTON_STATE.ENOUGH_CREDITS
			: UNLOCK_BUTTON_STATE.NOT_ENOUGH_CREDITS;

	const propertyId = data?.portfolio[0].propertyId;

	const { data: propertyInfo } = usePropertyInfoQuery({
		propertyId,
	});

	const { data: propertyData } = usePropertyByIdQuery({ id: propertyId });

	const { getIsNavHeaderActive } = useSearchSalesNavHeaderContext();

	const isPageLoading = !data || !propertyInfo || !propertyData;

	if (isError) {
		if (error.response.status === 404)
			return (
				<CompNotFound
					onNewSearch={() => {
						props.router.replace('/search/sales/home');
					}}
				/>
			);
		return <ErrorUI error={error} />;
	}

	const isPortfolio = isPortfolioSaleComp(data);

	const isNavHeaderActive = getIsNavHeaderActive();

	return (
		<PageContainer>
			{isNavHeaderActive && <SearchSalesNavHeader />}
			{isPageLoading ? (
				<SpinnerContainer>
					<Spinner size="xl" />
				</SpinnerContainer>
			) : (
				<ContentWrapper>
					<Main>
						<LeftColumn isPortfolio={isPortfolio}>
							{isPortfolio ? (
								<PortfolioPropertyRail data={data} />
							) : (
								<PropertyRail data={data} />
							)}
						</LeftColumn>
						<RightColumn>
							{!data.own &&
								hasEnoughCredits !== UNLOCK_BUTTON_STATE.FREE_COMP && (
									<ExchangeBanner
										creditCount={userData?.points}
										compCost={data?.cost}
										hasEnoughCredits={hasEnoughCredits}
									/>
								)}
							<KeyInfo
								data={data}
								hasEnoughCredits={hasEnoughCredits}
								isPortfolio={isPortfolio}
							/>
							<Tabs
								data={data}
								hasEnoughCredits={hasEnoughCredits}
								isPortfolio={isPortfolio}
							/>
						</RightColumn>
					</Main>
					<footer>
						<SourceCountFooter
							salesCompMainInfoResponse={salesCompMainInfoResponse}
						/>
						<SourceInfoFooter data={data} />
					</footer>
				</ContentWrapper>
			)}
		</PageContainer>
	);
});

SalesDetailsV2.displayName = 'SalesDetailsV2';

const PageContainer = styled.div`
	height: calc(100vh - ${APP_HEADER_HEIGHT}px);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	overflow-y: auto;
`;

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - ${APP_HEADER_HEIGHT}px);
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const Main = styled.main`
	display: flex;
	flex: 1;
`;

const LeftColumn = styled.div<{ isPortfolio?: boolean }>`
	width: 500px;
	flex-shrink: 0;
	padding: 30px 24px;
	background-color: ${colors.neutral.n10};

	${({ isPortfolio }) =>
		isPortfolio &&
		`box-shadow: 4px 0 8px rgb(23, 25, 32, 0.1);
		z-index: 0;`}
`;

const RightColumn = styled.div`
	flex: 1;
	background-color: #ffffff;
	overflow: hidden;
`;
