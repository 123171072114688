import { z } from 'zod';

const promotionValidatorShared = {
	id: z.number(),
	title: z.string(),
	shortDescription: z.string(),
	description: z.string(),
	state: z.string(),
	spaceTypes: z.array(z.string()),
	creditReward: z.object({ multiplier: z.number() }).nullish(),
	giftCardReward: z
		.object({
			tiers: z
				.array(
					z.object({
						compThreshold: z.number(),
						rewardDollars: z.number(),
					})
				)
				.nullable(),
		})
		.nullish(),
	marketingStartDate: z.string(),
	marketingEndDate: z.string(),
	marketingExecutionStartDate: z.string(),
	executionDateLookback: z.string().optional(),
	marketsDisplayText: z.string().optional(),
};

export const promotionValidator = z.object({
	// Spec from swagger:
	// https://user-service.dev.cs-int-592.com/docs/swagger/#/promotion/get_admin_promotions__promotionId_
	...promotionValidatorShared,
	markets: z.array(z.object({ marketId: z.number(), displayName: z.string() })),
	tagIds: z.array(z.number()),
});

const promotionValidatorInternal = z.object({
	...promotionValidatorShared,
	markets: z.array(z.string()),
});

export enum PromotionState {
	DRAFT = 'Draft',
	PENDING = 'Pending',
	ACTIVE = 'Active',
	COMPLETED = 'Completed',
	TERMINATED = 'Terminated',
	DELETED = 'Deleted',
}

export type Promotion = Omit<
	z.infer<typeof promotionValidatorInternal>,
	'state'
> & {
	state: PromotionState;
};
