import {
	AngleArrow,
	BaseDropdownMenu,
	DropdownListItem,
	DropdownListItemStyled,
	DropdownListStyled,
	Spinner,
	basicDropdownListItemStyles,
} from '@compstak/ui-kit';
import { usePropertyAverages } from 'api';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import CompTypeIcon from 'ui/svg_icons/compTypeIcon.svg';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { useAppSelector } from 'util/useAppSelector';
import { filtersToQueryString } from '../../../models/filters/util';
import { CompType, PropertyComp } from '../../../types';
import buttonStyles from '../../../ui/styles/button.less';
import { EXPLICIT_SPACE } from '../../../utils';

const LEASES_LINK = '/search/leases/list';
const SALES_LINK = '/search/sales/list';
const MAX_SELECTED_COMPS_LIMIT = 1000;

type DropdownItem = DropdownListItem & { disabled: boolean; tooltip?: string };

export const GoToCompTypeSection = () => {
	const selection = useAppSelector(
		(state) => state.selection.list
	) as PropertyComp[];

	const selectionIds = useMemo(() => {
		return selection.map((c) => c.id);
	}, [selection]);

	const [filters] = useFilters();

	const marketIds = useMemo(() => {
		return getFiltersMarkets(filters).map((m) => m.id);
	}, [filters]);

	const { data: averages, isFetching: isFetchingAverages } =
		usePropertyAverages({
			ids: selectionIds,
			marketIds,
		});
	const isMin1050Width = useMediaQuery({ minWidth: 1050 });

	const leaseCount = selection.length > 0 ? averages?.leases ?? 0 : 0;
	const saleCount = selection.length > 0 ? averages?.sales ?? 0 : 0;

	const addresses = selection.map(
		(property) => property.buildingAddressAndCity
	);

	const filterString = filtersToQueryString({
		address: addresses,
		sortField: 'address',
		sortDirection: 'asc',
	});

	const isZeroSelected = selection.length === 0;
	const leasesOverLimitSelected = leaseCount > MAX_SELECTED_COMPS_LIMIT;
	const salesOverLimitSelected = saleCount > MAX_SELECTED_COMPS_LIMIT;
	const leaseDisabled =
		isZeroSelected ||
		leasesOverLimitSelected ||
		isFetchingAverages ||
		leaseCount === 0;
	const saleDisabled =
		isZeroSelected ||
		salesOverLimitSelected ||
		isFetchingAverages ||
		saleCount === 0;

	const saleLabel = getLabel('SEE SALES', isFetchingAverages, saleCount);
	const leaseLabel = getLabel('SEE LEASES', isFetchingAverages, leaseCount);

	const leaseTooltip = getTooltip('lease', leaseDisabled);
	const saleTooltip = getTooltip('sale', saleDisabled);

	return isMin1050Width ? (
		<>
			<li className="single_toolbar_control">
				<a
					href={leaseDisabled ? undefined : `${LEASES_LINK}?${filterString}`}
					className={getBtnClassName(leaseDisabled)}
					data-tooltip={leaseTooltip}
					data-tooltip-position={leasesOverLimitSelected ? 'below' : 'above'}
				>
					{leaseLabel}
				</a>
			</li>
			<li className="single_toolbar_control">
				<a
					href={saleDisabled ? undefined : `${SALES_LINK}?${filterString}`}
					className={getBtnClassName(saleDisabled)}
					data-tooltip={saleTooltip}
					data-tooltip-position={salesOverLimitSelected ? 'below' : 'above'}
				>
					{saleLabel}
				</a>
			</li>
		</>
	) : (
		<li className="single_toolbar_control">
			<BaseDropdownMenu<DropdownItem>
				OpenButton={({ onClick, isOpen }) => (
					<StyledDropdownButton className={getBtnClassName()} onClick={onClick}>
						<CompTypeIcon />
						<StyledAngleArrow rotate={isOpen ? 180 : 0} />
					</StyledDropdownButton>
				)}
				MenuContainer={MenuContainer}
				dropdownItemRenderer={({ title, value, disabled, tooltip }) => (
					<StyledDropdownItemLink
						href={disabled ? undefined : value}
						disabled={disabled}
						data-tooltip={tooltip}
						data-tooltip-position={
							leasesOverLimitSelected || salesOverLimitSelected
								? 'below'
								: 'above'
						}
					>
						{title}
					</StyledDropdownItemLink>
				)}
				items={[
					{
						value: `${LEASES_LINK}?${filterString}`,
						title: leaseLabel,
						disabled: leaseDisabled,
						tooltip: leaseTooltip,
					},
					{
						value: `${SALES_LINK}?${filterString}`,
						title: saleLabel,
						disabled: saleDisabled,
						tooltip: saleTooltip,
					},
				]}
			/>
		</li>
	);

	function getTooltip(compType: CompType, disabled: boolean) {
		if (isFetchingAverages || !disabled) {
			return undefined;
		}

		if (
			(compType === 'lease' && leasesOverLimitSelected) ||
			(compType === 'sale' && salesOverLimitSelected)
		) {
			return `You can see a max of ${MAX_SELECTED_COMPS_LIMIT} comps from this set of properties. Please refine your filters or change your selected properties to view these comps`;
		}

		return `There are no ${compType} comps associated with the selected set of properties`;
	}
};

function getLabel(title: string, isAveragesLoading: boolean, count: number) {
	return (
		<>
			<span>
				{title}
				{EXPLICIT_SPACE}
			</span>
			{isAveragesLoading ? (
				<Spinner size="m" />
			) : (
				`(${abbreviateNumber(count)})`
			)}
		</>
	);
}

function getBtnClassName(goToCompTypeBtnsDisabled?: boolean) {
	return (
		`${buttonStyles.button} button_export` +
		(goToCompTypeBtnsDisabled ? ' button_disabled' : '')
	);
}

const StyledDropdownButton = styled.button`
	margin-right: 0;
`;

const StyledDropdownItemLink = styled.a<{ disabled: boolean }>`
	${basicDropdownListItemStyles};
	padding: 0 8px;
	height: 40px;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	color: ${({ theme }) => theme.colors.gray.gray0};
	display: flex;
	align-items: center;

	${({ disabled }) => (disabled ? 'opacity: 0.5;' : '')};

	&:hover {
		color: ${({ theme }) => theme.colors.gray.gray0};
	}
`;

const MenuContainer = styled.div`
	position: relative;

	${DropdownListStyled} {
		border: 1px solid ${({ theme }) => theme.colors.neutral.n100};
		box-shadow: none;
		top: 50px;
		width: 160px;
		left: 0px;
		padding: 0px;
	}

	${DropdownListItemStyled} {
		button {
			padding: 4px 8px;
			height: 40px;
		}
	}
`;

const StyledAngleArrow = styled(AngleArrow)`
	path {
		stroke: white;
	}
`;
