import { usePortfolioByIdQuery } from 'api';
import { MarketRentAnalyticsProvider } from 'maps';
import { MarketTrendComponent as MarketTrend } from 'PortfolioAnalytics/MarketTrend';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import LeaseExpiration from '../LeaseExpiration';
import { MarketRent } from '../MarketRent';
import { MarketRentProvider } from '../MarketRent/MarketRentProvider';

type Props = {
	portfolioId: number;
	redirect: (path: string) => void;
};

export const MarketsTab = ({ portfolioId, redirect }: Props) => {
	const { filters } = usePortfolioFilters();
	const { data: portfolio } = usePortfolioByIdQuery({
		id: portfolioId,
		filters,
	});

	if (!portfolio) return null;

	return (
		<MarketRentAnalyticsProvider>
			<MarketRentProvider portfolio={portfolio}>
				<MarketRent portfolioId={portfolioId} portfolio={portfolio} />
				<MarketTrend portfolio={portfolio} filters={filters} />
				<LeaseExpiration
					portfolio={portfolio}
					filters={filters}
					redirect={redirect}
				/>
			</MarketRentProvider>
		</MarketRentAnalyticsProvider>
	);
};
