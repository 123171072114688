import React from 'react';
import styled from 'styled-components';
import { VictoryScatter, VictoryLabel } from 'victory';
import { ExchangePanel, LoadingBox } from './UI';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import sortBy from 'lodash/sortBy';
import { useExchangeDashboardState } from 'reducers/exchangeDashboard';
import { useExchangeDashboardLargeLandlords } from './hooks/largestLandlords/useExchangeDashboardLargeLandlords';
import { Waypoint } from 'react-waypoint';

export const LargestLandlordsChart = () => {
	const [view, setView] = React.useState<'visible' | 'hidden'>('hidden');

	const { setFullPageState, fullPageState } = useExchangeDashboardState();

	const { data: largestLandlordsData, isFetching } =
		useExchangeDashboardLargeLandlords({
			enabled: view === 'visible' && fullPageState === null,
		});

	const largestLandlordsPlot = largestLandlordsData?.largestLandlords.map(
		(landlord) => {
			const formattedLandlord =
				landlord.landlord.length > 20
					? `${landlord.landlord.slice(0, 20)}…`
					: landlord.landlord;
			return {
				...landlord,
				label: `${abbreviateNumber(landlord.sqft)} SQFT\n ${formattedLandlord}`,
				x: `${formattedLandlord}\n${abbreviateNumber(landlord.sqft)} sqft`,
				y: 1,
			};
		}
	);

	const data = sortBy(largestLandlordsPlot ?? [], 'sqft')
		.reverse()
		.filter((d, i) => i < 6);

	const title = 'largest landlords by active leases';

	return (
		<Waypoint onEnter={() => setView('visible')}>
			<ExchangePanel
				id="largest-landlords"
				title={title}
				tooltip="List is based on landlord property square footage by default. If a lease or tenant filter is selected, then landlord size is determined by active lease square footage."
				cta={
					!isFetching && (
						<AverageIndicator>
							{`${abbreviateNumber(
								largestLandlordsData?.averageSqft ?? 0
							)} sqft, Average Landlord Size`}
						</AverageIndicator>
					)
				}
			>
				{isFetching && (
					<Row>
						{Array.from(Array(6).keys()).map((_, index) => (
							<div key={index}>
								<LoadingPlot />
								<LoadingStat />
								<LoadingLandlord />
							</div>
						))}
					</Row>
				)}
				{!isFetching ? (
					data.length === 0 ? (
						<div>No Data To Display. Update Filters and Try Again.</div>
					) : (
						<VictoryScatter
							height={150}
							width={800}
							style={{
								data: { cursor: 'pointer', fill: '#228fff' },
								labels: { fontSize: 10 },
							}}
							size={data?.length ?? 0}
							maxBubbleSize={30}
							minBubbleSize={5}
							bubbleProperty="sqft"
							labelComponent={<VictoryLabel dy={60} />}
							events={[
								{
									target: 'data',
									eventHandlers: {
										onClick: (
											e: unknown,
											clickedProps: { datum: { landlord: string } }
										) => {
											if (clickedProps.datum.landlord) {
												setFullPageState &&
													setFullPageState({
														type: 'list',
														field: 'landlordName',
														landlordName: clickedProps.datum.landlord,
													});
											}
										},
									},
								},
							]}
							data={data}
						/>
					)
				) : null}
			</ExchangePanel>
		</Waypoint>
	);
};

export const AverageIndicator = styled.div`
	background: #f9f9f9;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #d5d8df;
	border-radius: 3px;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 22px;
	padding: 0 8px;

	color: ${({ theme }) => theme.colors.gray.gray0};
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
`;

const LoadingPlot = styled(LoadingBox)`
	margin: 4px;
	height: 80px;
	width: 80px;
`;

const LoadingStat = styled(LoadingBox)`
	margin: 4px;
	height: 22px;
	width: 50px;
`;

const LoadingLandlord = styled(LoadingBox)`
	margin: 4px;
	height: 22px;
	width: 66px;
`;
