import { setAccessToken } from 'auth/auth';
import { getParamsFromQueryString } from 'models/filters/util';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { routes, useNavigate } from 'router';
import { PageSpinner } from './styles/PageSpinner';

const LandingPageTokenTransfer = () => {
	const location = useLocation();
	const { accessToken } = getParamsFromQueryString(location.search);
	const navigate = useNavigate();

	if (accessToken) {
		setAccessToken(accessToken);
	}

	useEffect(() => {
		navigate(routes.home.path, { replace: true });
	}, [navigate]);

	return <PageSpinner />;
};

export default LandingPageTokenTransfer;
