import { PropertyLease, QUERY_KEYS } from 'api';
import {
	useUnlockComps,
	useUnlockButtonLongText,
	UnlockButton,
} from 'Components/Unlock';
import { LockIconStyled } from 'Components/UnlockButton/UnlockButtonComponent';
import styled from 'styled-components';

export const LeaseSnapshotUnlockOverlay = ({
	lockedLease,
	propertyId,
}: {
	lockedLease: PropertyLease;
	propertyId: number;
}) => {
	const { unlock, disabled, isLoading } = useUnlockComps({
		comps: [lockedLease],
		type: 'lease',
		onUnlockLeasesSuccess: ({ queryClient }) => {
			queryClient.invalidateQueries([
				QUERY_KEYS.PROPERTY_LEASES_SUMMARY,
				propertyId,
			]);
		},
	});
	const unlockButtonText = useUnlockButtonLongText({
		comps: [lockedLease],
	});
	return (
		<SnapshotUnlockOverlay>
			<UnlockButton
				disabled={disabled}
				isLoading={isLoading}
				onClick={() => {
					unlock({ ids: [lockedLease.id] });
				}}
			>
				<LockIconStyled /> {unlockButtonText}
			</UnlockButton>
		</SnapshotUnlockOverlay>
	);
};

const SnapshotUnlockOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.6);
`;
