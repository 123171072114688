import React from 'react';
import IconCircleTick from '../../../../../ui/svg_icons/circle_tick.svg';
import button from 'ui/styles/button.less';

import styles from '../../repository.less';
import modalStyles from '../../../Components/Modals/analytics-modals.less';
import { Project } from 'Pages/Analytics/analytics';
import styled from 'styled-components';
import { ModalActions } from 'Singletons/Modal/actions';

type Props = {
	project: Project;
	modalActions: Pick<ModalActions, 'hideModal'>;
};

export const NewProjectSuccess: React.FC<Props> = (props) => {
	const handleClose = () => {
		props.modalActions.hideModal();
	};

	return (
		<div className={`${modalStyles.container}`}>
			<div className={modalStyles.success}>
				<IconCircleTickStyled width="35px" height="35px" />
			</div>
			<MessageContainer>
				You’ve created a new project named:
				<div className={styles.successModalProjectName}>
					{props.project.name}
				</div>
			</MessageContainer>
			<span
				className={`${button.blue} ${modalStyles.buttonSmall}`}
				data-close-button
				onClick={handleClose}
			>
				OKAY
			</span>
		</div>
	);
};

const MessageContainer = styled.div`
	margin: 10px 0;
`;

const IconCircleTickStyled = styled(IconCircleTick)`
	display: initial;

	path {
		fill: ${({ theme }) => theme.colors.green.green500};
	}
`;

NewProjectSuccess.displayName = 'NewProjectSuccess';
