import React from 'react';

import Modal from 'Singletons/Modal';

import modalStyles from '../../../Components/Modals/analytics-modals.less';
import styles from '../../repository.less';

import spinner from 'ui/styles/spinner.less';
import button from 'ui/styles/button.less';
import { Chart, Project } from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';

type Props = {
	analyticsProjectActions: {
		saveChart: (newChart: Chart, markets: MarketsState) => void;
	};
	chart: Chart;
	redirect: (url: string) => void;
	markets: MarketsState;
	project: Project;
	modalActions: {
		popModal: Function;
		pushModal: Function;
	};
};

type State = {
	newName: string;
	suggestedName: string;
	errorMessage: string;
	saveInProgress?: boolean;
};

export class DuplicateChart extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'chart' does not exist on type 'Readonly<... Remove this comment to see the full error message
		const nameWithoutCopy = this.props.chart.title.replace(/ Copy (\d+)$/i, '');
		const highestCopyNumber = this.props.project.charts.reduce((acc, chart) => {
			// @ts-expect-error TS18048: 'chart.title' is possibly 'und...
			if (chart.title.startsWith(nameWithoutCopy)) {
				// @ts-expect-error TS18048: 'chart.title' is possibly 'und...
				const match = chart.title.match(/Copy (\d+)$/i);
				if (match !== null) {
					const number = parseInt(match[1]);
					return Math.max(number, acc);
				}
				return Math.max(acc, 1);
			}
			return acc;
		}, 0);

		const newName = nameWithoutCopy + ' Copy ' + (highestCopyNumber + 1);
		this.state = {
			newName,
			suggestedName: newName,
			errorMessage: '',
		};
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	updateName = (event) => {
		this.setState({
			newName: event.target.value,
		});
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleKeyDown = (event) => {
		if (event.keyCode === 13) {
			event.preventDefault();
			this.saveChart();
		}
	};

	saveChart = () => {
		const trimmedName = this.state.newName.trim();
		if (this.props.project.charts.some((c) => c.title === trimmedName)) {
			this.setState({
				errorMessage: `The chart ${trimmedName} already exists. Enter a new name to continue.`,
			});
		} else {
			const newChart = {
				...this.props.chart,
				title: this.state.newName,
				id: null,
			};
			this.props.analyticsProjectActions.saveChart(
				newChart,
				this.props.markets
			);
			this.props.modalActions.popModal();
		}
	};

	render() {
		if (this.state.saveInProgress) {
			return (
				<div className={modalStyles.container}>
					<h3>Duplicate Chart</h3>
					<div className={spinner.large} />
				</div>
			);
		}

		return (
			<div className={`${modalStyles.container} ${styles.duplicateChartModal}`}>
				<h3>Duplicate Chart</h3>
				<div>Duplicating this chart will create a copy:</div>
				<div className={styles.suggestedName}>{this.state.suggestedName}.</div>
				<div>Change the title below, if you want to.</div>
				<div>
					<label className={styles.modalInputLabel}>
						Chart Name
						<input
							type="text"
							value={this.state.newName}
							onChange={this.updateName}
							onKeyDown={this.handleKeyDown}
							className={this.state.errorMessage ? styles.error : ''}
						/>
						<div className={styles.errorMessage}>{this.state.errorMessage}</div>
					</label>
					<div className={modalStyles.buttonContainer}>
						<div
							className={button.button}
							data-close-button
							onClick={() => {
								this.props.modalActions.popModal();
							}}
						>
							Cancel
						</div>
						<div className={button.blue} onClick={this.saveChart}>
							Duplicate
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const name = 'analytics-duplicate-chart';

Modal.addComponent({
	id: name,
	Component: DuplicateChart,
});

export default name;
