import produce from 'immer';
import { useUser } from 'Pages/Login/reducers';
import { useMarkets } from 'hooks/useMarkets';
import React, { useMemo } from 'react';

import styled from 'styled-components';
import { InviteDTO, useTeamInviteMutation } from 'api';
import {
	ModalButton,
	ModalButtons,
	ModalCenteredContent,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useModal } from 'providers/ModalProvider';
import { Spinner } from '@compstak/ui-kit';
import { validateEmail, validatePhone } from 'util/validate';
import { checkStringLength } from 'util/checkStringLength';

type Stage = 'stage-1' | 'stage-2';

type TeamMemberInviteFormProps = {
	index: number;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	deleteInput: (index: number) => void;
};

type MemberInfo = {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber?: number;
};

const TeamMemberInviteForm = (
	props: TeamMemberInviteFormProps & MemberInfo
) => {
	const {
		email,
		firstName,
		index,
		lastName,
		phoneNumber,
		onChange,
		deleteInput,
	} = props;
	return (
		<InputContainer index={index}>
			<label>
				First Name
				<input
					data-qa-id={`team-invite-firstname-${index}`}
					onChange={onChange}
					type="text"
					value={firstName}
					name="firstName"
					placeholder="First Name"
				/>
			</label>
			<label>
				Last Name
				<input
					data-qa-id={`team-invite-lastname-${index}`}
					onChange={onChange}
					placeholder="Last Name"
					value={lastName}
					name="lastName"
					type="text"
				/>
			</label>
			<label>
				Email Address
				<input
					data-qa-id={`team-invite-email-${index}`}
					onChange={onChange}
					placeholder="Email"
					value={email}
					name="email"
					type="email"
				/>
			</label>
			<label>
				Phone (Optional)
				<input
					data-qa-id={`team-invite-phonenumber-${index}`}
					onChange={onChange}
					placeholder="Phone"
					value={phoneNumber}
					name="phoneNumber"
					type="tel"
				/>
			</label>
			{index > 0 && <button onClick={() => deleteInput(index)}>Remove</button>}
		</InputContainer>
	);
};

type TeamInviteFormData = MemberInfo[];

const defaultFormData = {
	firstName: '',
	lastName: '',
	email: '',
};

export const TeamInviteModal = () => {
	const currentUser = useUser();
	const markets = useMarkets();
	const [stage, setStage] = React.useState<Stage>('stage-1');
	const [formData, setFormData] = React.useState<TeamInviteFormData>([
		defaultFormData,
	]);

	const handleAddTeamMember: React.MouseEventHandler<HTMLButtonElement> = (
		event
	) => {
		event.preventDefault();
		setFormData((prevData) => prevData.concat(defaultFormData));
		return false;
	};

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		event.preventDefault();
		const { name, value } = event.target;
		setFormData((prevState) =>
			produce(prevState, (draftState) => {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				draftState[index][name] = value;
			})
		);
	};

	const { mutate: teamInvite, isLoading } = useTeamInviteMutation({
		onSuccess: () => setStage('stage-2'),
		onError: (error) => console.error(error),
	});
	const { closeModal } = useModal();

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const invites: InviteDTO[] = formData.map((member) => {
			const { email, firstName, lastName, phoneNumber } = member;
			const marketId =
				currentUser?.officeMarketId ?? currentUser?.primaryMarketId ?? null;
			return {
				application: 'exchange',
				email,
				firstName,
				lastName,
				phoneNumber,
				madeNoMarket: false,
				market: markets[marketId].name ?? undefined,
				marketId,
				tags: [],
				userInfo: 'CompStak',
				utmSource: null,
				utmMedium: null,
				utmCampaign: null,
				utmTerm: null,
			};
		});

		teamInvite(invites);
	};

	const isFormInvalid = useMemo(
		() =>
			formData.some(
				(field) =>
					!validateEmail(field.email) ||
					!checkStringLength(field.firstName) ||
					!checkStringLength(field.lastName) ||
					(!!field.phoneNumber && !validatePhone(field.phoneNumber))
			),
		[formData]
	);

	if (stage === 'stage-1') {
		return (
			<form onSubmit={handleSubmit}>
				<ModalTitle style={{ textAlign: 'left' }}>
					Ready to {currentUser?.team ? 'expand' : 'create'} your team?
				</ModalTitle>
				<ModalParagraph>
					New members can be added to your CompStak team by your account
					manager.
				</ModalParagraph>
				<ModalParagraph>
					Please provide details on your new CompStak team member(s).
				</ModalParagraph>

				{formData.map((member, index) => {
					return (
						<TeamMemberInviteForm
							index={index}
							key={index}
							{...member}
							onChange={(event) => handleChange(event, index)}
							deleteInput={(index) =>
								setFormData((oldData) =>
									oldData.filter((_, idx) => idx !== index)
								)
							}
						/>
					);
				})}
				<AddMemberButton
					onClick={handleAddTeamMember}
					data-qa-id="team-invite-add-another"
					type="button"
				>
					<span>+</span>
					Add another team member
				</AddMemberButton>
				<ModalButtons>
					<ModalButton
						variant="secondary"
						size="l"
						type="reset"
						data-qa-id="team-invite-modal-cancel-button"
						onClick={closeModal}
					>
						Cancel
					</ModalButton>
					<ModalButton
						variant="primary"
						size="l"
						data-qa-id="team-invite-modal-submit-button"
						type="submit"
						disabled={isLoading || isFormInvalid}
					>
						{isLoading ? <Spinner isCentered size="m" /> : 'Submit'}
					</ModalButton>
				</ModalButtons>
			</form>
		);
	}

	if (stage === 'stage-2') {
		return (
			<ModalCenteredContent>
				<ModalParagraph>
					Thank you! Your CompStak account manager will follow up shortly.
				</ModalParagraph>
				<ModalButtons>
					<ModalButton
						variant="primary"
						data-qa-id="team-invite-modal-close-button"
						onClick={closeModal}
					>
						Close
					</ModalButton>
				</ModalButtons>
			</ModalCenteredContent>
		);
	}

	return null;
};

const AddMemberButton = styled.button`
	height: 20px;
	display: flex;
	align-items: center;
	background-color: transparent;
	border: none;
	font:
		normal normal normal 13px 'Gotham',
		'Open Sans',
		Helvetica,
		Arial,
		sans-serif;
	text-transform: uppercase;
	color: #228fff;
	margin-top: 16px;
	padding: 0 0 0 32px;
	span {
		font-size: 20px;
		margin-right: 12px;
	}
`;

const InputContainer = styled.div<{ index: number }>`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 2rem;

	background-color: ${(props) =>
		props.index !== 0 ? 'rgb(236, 237, 240)' : 'inherit'};

	label {
		width: 48%;
		font-size: 16px;
		font-weight: 400;
		font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
		input {
			width: 100%;
			color: rgba(0, 0, 0, 0.87);
		}
	}
	button {
		background: inherit;
		border: none;
		color: #b94641;
		text-transform: uppercase;
		font-size: 11px;
		font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	}
`;
