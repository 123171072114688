import { usePortfolioPropertiesQuery } from 'api';
import { RouterLocation } from 'types';
import { BUILDING_CLASS_IDS, PROPERTY_TYPE_IDS } from '../constants';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { getFilterQueryParam } from './getFilterQueryParam';

type Props = {
	portfolioId: number;
	location: RouterLocation;
};

export const useListViewProperties = ({ portfolioId, location }: Props) => {
	const { filters } = usePortfolioFilters();

	return usePortfolioPropertiesQuery({
		portfolioId,
		...filters,
		propertyTypeIds:
			getFilterQueryParam(location, PROPERTY_TYPE_IDS) ??
			filters.propertyTypeIds,
		buildingClassIds:
			getFilterQueryParam(location, BUILDING_CLASS_IDS) ??
			filters.buildingClassIds,
	});
};
