import { pushModal } from 'Singletons/Modal/actions';

import saveModal from './Save';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useDispatch } from 'react-redux';
import { deleteChartDraft } from '../../actions';
import { useModal } from 'providers/ModalProvider';

export const ClearAnalyticsModal = () => {
	const dispatch = useDispatch();

	const { closeModal } = useModal();

	const onContinue = () => {
		dispatch(deleteChartDraft());
		closeModal();
	};

	const onSaveAndContinue = () => {
		const onSave = () => dispatch(deleteChartDraft());

		dispatch(
			pushModal(saveModal, {
				onSave,
			})
		);

		closeModal();
	};

	return (
		<>
			<ModalTitle>Create New Chart</ModalTitle>
			<ModalParagraph>
				By creating a new chart, you will lose all progess on your current
				chart. Would you like to save your changes or continue with a new, blank
				chart?
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={onContinue}>
					Continue
				</ModalButton>
				<ModalButton onClick={onSaveAndContinue}>Save and Continue</ModalButton>
			</ModalButtons>
		</>
	);
};
