import { ColumnPreference } from '@compstak/common';
import {
	LOAN_SECTION,
	MUFA_SECTION,
	SearchTable,
	SearchTableExportButton,
} from 'Components';
import { ColumnConfig } from 'Components/SearchTable/columnConfigs';
import { TableSection } from 'Components/SearchTable/types';
import { routes } from 'router';
import { RouterLocation } from 'types';
import { usePropertiesSelectionContext } from './PropertiesSelectionProvider';
import { useListViewProperties } from './useListViewProperties';

type Props = {
	portfolioId: number;
	location: RouterLocation;
};

export const PortfolioTable = ({ portfolioId, location }: Props) => {
	const {
		data: properties,
		isLoading,
		isFetching,
	} = useListViewProperties({
		portfolioId,
		location,
	});

	const totalCount = properties?.length ?? 0;

	const {
		getIsRowSelected,
		toggleRow,
		areAllSelected,
		toggleAllSelected,
		areSomeSelected,
	} = usePropertiesSelectionContext();

	return (
		<SearchTable
			rows={properties ?? []}
			getRowId={(row) => row.id}
			isLoading={isFetching}
			noData={!isLoading && totalCount === 0}
			columnPreferencesType="property"
			getIsRowSelected={(row) => getIsRowSelected(row.id)}
			areAllSelected={areAllSelected}
			areSomeSelected={areSomeSelected}
			toggleRowSelection={(row) => toggleRow(row.id)}
			toggleAllRowsSelection={toggleAllSelected}
			renderActionButton={(row) => (
				<SearchTableExportButton compType="property" row={row} />
			)}
			getBodyCellProps={(row) => ({
				to: routes.propertyById.toHref({ propertyId: row.id }),
			})}
			filterColumnSections={(sections: TableSection[]) => {
				return sections.filter(
					(section) => !['loanInfo', 'mufa'].includes(section.id)
				);
			}}
			filterTableColumns={(columns: ColumnPreference[]) => {
				return columns.filter(
					(column) =>
						![...MUFA_SECTION.ids, ...LOAN_SECTION.ids].includes(
							column.compAttributeId as keyof ColumnConfig
						)
				);
			}}
		/>
	);
};
