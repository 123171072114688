import React, { memo, useCallback } from 'react';
import {
	Button,
	FormSelect,
	TextInput,
	FormMultiSelect,
	Chip,
} from '@compstak/ui-kit';
import { Wrap, Title, Form, Footer } from './UI';
import { DataSetType } from '../../../../../analytics';
import {
	useDataSetForm,
	UseDataSetFormProps,
} from '../../../DataSet/useDataSetForm';
import { getAttrOptionsForCreateDSForm } from './utils';
import { SPACE_TYPE_SELECT_ITEMS } from './spaceTypeItemsV2';
import {
	MUFA_PROPERTY_TYPE_ITEMS,
	SALE_PROPERTY_TYPE_ITEMS,
	getPropertyTypeIdsFromItems,
} from './propertyTypeItems';
import { getSpaceTypeIdsFromItems } from './spaceTypeItemsV2';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
interface CreateNewDataSetProps extends UseDataSetFormProps {
	onBack: () => void;
	onSubmit: (
		name: string,
		market: string,
		spaceType: { value: { id: number } | { ids: number[] } },
		dataSetType: DataSetType,
		series: string
	) => void;
	setPreventModalClosingOnClickOutside: (isOpen: boolean) => void;
}

export const CreateNewDataSetV2 = memo(
	({
		onBack,
		onSubmit,
		setPreventModalClosingOnClickOutside,
		...props
	}: CreateNewDataSetProps) => {
		const { state, handlers, options, meta } = useDataSetForm({
			...props,
			isMufaSpaceTypesIfAtLeastOneMarketAccess: true,
		});

		const handleSubmit = useCallback(
			// @ts-expect-error TS7006: Parameter 'e' implicitly has a...
			(e) => {
				e.preventDefault();

				const {
					title,
					spaceTypes,
					market,
					attributeToPlot,
					dataSetType,
					propertyTypes,
				} = state;

				onSubmit(
					// @ts-expect-error TS2345: Argument of type 'string | und...
					title,
					market,
					{
						value:
							dataSetType === DataSetType.MUFA
								? { id: -1 }
								: {
										ids:
											dataSetType === DataSetType.SALES
												? // @ts-expect-error TS2345: Argument of type 'MultiselectL...
													getPropertyTypeIdsFromItems(propertyTypes)
												: // @ts-expect-error TS2345: Argument of type 'SpaceTypeSel...
													getSpaceTypeIdsFromItems(spaceTypes),
									},
					},
					dataSetType,
					attributeToPlot
				);
			},
			[onSubmit, state]
		);

		const { salesCompsAnalytics2 } = useFeatureFlags();

		const attributeToPlotOptions = getAttrOptionsForCreateDSForm(
			meta.hasMufaAccess,
			salesCompsAnalytics2
		);

		const attributeAndTypeLabel = salesCompsAnalytics2
			? 'Property / Space Type'
			: 'Space Type';

		const attributeAndTypeTooltip = salesCompsAnalytics2
			? propertySpaceTypeTooltip
			: null;

		React.useEffect(() => {
			setPreventModalClosingOnClickOutside(true);

			return () => setPreventModalClosingOnClickOutside(false);
		}, []);

		return (
			<Wrap>
				<Title>CREATE NEW DATA SET</Title>
				<Form onSubmit={handleSubmit}>
					<TextInput
						data-qa-id={'set-dataset-title'}
						label="Title Your Data Set"
						placeholder="Enter name here"
						value={state.title}
						onChange={handlers.onChangeTitle}
						// @ts-expect-error TS2322: Type '(e: any) => void' is not...
						onBlur={handlers.onBlurTitle}
						errorText={options.titleFieldError}
					/>
					<FormSelect
						label="Market"
						placeholder="Search or select"
						items={options.market}
						value={state.market?.name}
						onChange={handlers.onChangeMarket}
						noResultMessage="No data"
						showScrollbarOnHover
						isSearchable
					/>
					<FormSelect
						data-qa-id={'select-attribute-to-plot'}
						label="Attribute to Plot"
						items={
							state.market ? state.attributeToPlotItems : attributeToPlotOptions
						}
						onChange={handlers.onChangeAttributeToPlot}
						value={state.attributeToPlot}
						placeholder="Select from Dropdown"
						showScrollbarOnHover
					/>
					{(state.dataSetType === DataSetType.LEASES ||
						state.dataSetType === DataSetType.COMMERCIAL) && (
						<FormMultiSelect
							data-qa-id={'select-spacetype'}
							label={attributeAndTypeLabel}
							optionsTitle="Space type"
							items={SPACE_TYPE_SELECT_ITEMS}
							onChange={handlers.onChangeSpaceType}
							// @ts-expect-error TS2322: Type 'SpaceTypeSelectListItem[...
							value={state.spaceTypes}
							disabled={!state.attributeToPlot}
							tooltipContent={attributeAndTypeTooltip}
						/>
					)}
					{state.dataSetType === DataSetType.SALES && (
						<FormMultiSelect
							data-qa-id={'select-property-type'}
							label={attributeAndTypeLabel}
							optionsTitle="Property type"
							items={SALE_PROPERTY_TYPE_ITEMS}
							onChange={handlers.onChangePropertyType}
							// @ts-expect-error TS2322: Type 'MultiselectListItem[] | ...
							value={state.propertyTypes}
							tooltipContent={attributeAndTypeTooltip}
						/>
					)}
					{state.dataSetType === DataSetType.MUFA && (
						// @ts-expect-error TS2741: Property 'value' is missing in...
						<FormMultiSelect
							data-qa-id={'select-mufa-type'}
							label={attributeAndTypeLabel}
							items={MUFA_PROPERTY_TYPE_ITEMS}
							onChange={() => {}}
							tooltipContent={attributeAndTypeTooltip}
							placeholder={<Chip>{MUFA_PROPERTY_TYPE_ITEMS[0].title}</Chip>}
							disabled={true}
						/>
					)}
					<Footer>
						<Button size="l" variant="secondary" type="button" onClick={onBack}>
							Back
						</Button>
						<Button
							size="l"
							type="submit"
							// @ts-expect-error TS2322: Type 'string | true' is not as...
							disabled={!meta.formComplete || options.titleFieldError}
						>
							Create
						</Button>
					</Footer>
				</Form>
			</Wrap>
		);
	}
);

CreateNewDataSetV2.displayName = 'CreateNewDataSetV2';

const propertySpaceTypeTooltip =
	'Property Types will be available for Sales Comp Data Sets and Space Types will be available for Lease Data Sets.';
