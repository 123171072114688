import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormInput from './FormInput';
import FormMultiInput from './FormMultiInput';
import FormSelect from './FormSelect';
import FormRadio from './FormRadio';

import { AppState } from 'reducers/root';
import { getSaleSubmissionMapFromAppStore } from '../utils/submissionMap';
import { getLocaleForUserMarketId } from '../utils/submissionLocale';
import { sendSubmission, updateSaleSubmissionFormState } from '../actions';
import { AppDispatch } from 'store';
import wrapActions from 'util/actionWrapper';
import { ShowUploadSuccessModal } from 'Components/Modals/UploadSuccessModal/UploadSuccessModal';
import { SaleSubmission } from '../types';

import '../styles/uploadForm.nomodule.less';
import {
	UploadCompFormOptionsForLeasesAndSales,
	ShowAdditionalFieldsButton,
	StrFormOption,
	getInitialLeasesAndSalesUploadCompFormState,
	PROPERTY_TYPE_OPTIONS,
	INTEREST_TYPE_OPTIONS,
} from './UploadFormCommon';
import { withQueryClient } from 'api';
import { QueryClient } from '@tanstack/react-query';
import { invalidateUserQuery } from 'hooks/userHooks';
import { CompIsPortfolioCompOptionT } from '../uploadFieldConstants';

type Props = ReturnType<typeof mapStoreToProps> &
	ReturnType<typeof mapDispatchToProps> & {
		queryClient: QueryClient;
		showUploadSuccessModal: ShowUploadSuccessModal;
	};

type State = {
	additionalFields: boolean;
	submissionComplete: boolean;
	submissionMap: ReturnType<typeof getSaleSubmissionMapFromAppStore>;
	executionYearOptions: ReadonlyArray<StrFormOption>;
	expirationYearOptions: ReadonlyArray<StrFormOption>;
	propertyTypes: ReadonlyArray<StrFormOption>;
	interestTypes: ReadonlyArray<StrFormOption>;
	portfolioCondoOptions: ReadonlyArray<StrFormOption>;
} & UploadCompFormOptionsForLeasesAndSales;

class SaleUploadForm extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		const propertyTypeOptions = PROPERTY_TYPE_OPTIONS;
		const interestTypeOptions = INTEREST_TYPE_OPTIONS;

		const executionYearOptions = Array.from({ length: 10 }, (v, k) => ({
			label: String(new Date().getFullYear() - k),
			value: String(new Date().getFullYear() - k),
		}));
		const expirationYearOptions = Array.from({ length: 24 }, (v, k) => ({
			label: String(new Date().getFullYear() + 14 - k),
			value: String(new Date().getFullYear() + 14 - k),
		}));
		const portfolioCondoOptions: ReadonlyArray<{
			label: string;
			value: CompIsPortfolioCompOptionT;
		}> = [
			{ label: 'Yes', value: 'yes' },
			{ label: 'No', value: 'no' },
		];

		this.state = {
			...getInitialLeasesAndSalesUploadCompFormState(),
			additionalFields: false,
			submissionComplete: false,
			submissionMap: this.props.saleSubmissionMap,
			executionYearOptions: executionYearOptions,
			expirationYearOptions: expirationYearOptions,
			propertyTypes: propertyTypeOptions,
			interestTypes: interestTypeOptions,
			portfolioCondoOptions: portfolioCondoOptions,
		};
	}

	showAdditionalFields() {
		this.setState({
			additionalFields: true,
		});
	}

	updateSubmissionField(name: string | undefined, value: string) {
		if (!name) return;
		const submissionMap = this.state.submissionMap;
		submissionMap[name].value = value;

		this.setState({
			submissionMap,
		});

		this.props.uploadActions.updateSaleSubmissionFormState(
			name as keyof SaleSubmission,
			value
		);
		this.updateSubmissionComplete();
	}

	// @ts-expect-error TS7006: Parameter 'name' implicitly ha...
	handleMultiInputChange(name, values) {
		const joinedValue =
			values['month'] + '/' + values['day'] + '/' + values['year'];

		if ('month' in values && 'year' in values) {
			this.updateSubmissionField(name, joinedValue);
		}
	}

	updateSubmissionComplete() {
		const { submissionMap } = this.state;

		const outstandingRequiredFields = Object.keys(submissionMap)
			.map((key) => {
				return submissionMap[key];
			})
			.filter((field) => {
				return field.required;
			})
			.filter((field) => {
				return !field.value || field.value.length === 0;
			})
			.filter((field) => {
				const buyerFields = ['buyer', 'recordedBuyer'];
				const sellerFields = ['seller', 'recordedSeller'];

				const buyerIsSet =
					submissionMap['buyer'].value.length !== 0 ||
					submissionMap['recordedBuyer'].value.length !== 0;
				const sellerIsSet =
					submissionMap['seller'].value.length !== 0 ||
					submissionMap['recordedSeller'].value.length !== 0;

				if (!buyerIsSet && buyerFields.includes(field.name)) {
					return true;
				} else if (!sellerIsSet && sellerFields.includes(field.name)) {
					return true;
				} else if (
					!buyerFields.includes(field.name) &&
					!sellerFields.includes(field.name)
				) {
					return true;
				} else {
					return false;
				}
			})
			.filter((field) => {
				const saleFields = ['salePricePsf', 'totalSalePrice'];

				const saleIsSet =
					submissionMap['salePricePsf'].value.length !== 0 ||
					submissionMap['totalSalePrice'].value.length !== 0;

				if (!saleIsSet && saleFields.includes(field.name)) {
					return true;
				} else if (!saleFields.includes(field.name)) {
					return true;
				} else {
					return false;
				}
			})
			.filter((field) => {
				const sizeFields = ['buildingSize', 'lotSize', 'transactionSize'];

				const sizeIsSet =
					submissionMap['buildingSize'].value.length !== 0 ||
					submissionMap['lotSize'].value.length !== 0 ||
					submissionMap['transactionSize'].value.length !== 0;

				if (!sizeIsSet && sizeFields.includes(field.name)) {
					return true;
				} else if (!sizeFields.includes(field.name)) {
					return true;
				} else {
					return false;
				}
			});

		this.setState({
			submissionComplete: outstandingRequiredFields.length === 0,
		});
	}

	sendSubmission(event: React.FormEvent<HTMLFormElement>) {
		const submission = {
			...this.props.uploads.saleSubmission,
			dataType: 'sales',
			locale: getLocaleForUserMarketId(this.props.user?.primaryMarketId ?? -1),
			additionalAddresses: undefined,
		};
		// additionalAddresses is relevant only on the v2 form.
		delete submission.additionalAddresses;

		event.preventDefault();
		if (
			this.props.uploads.status !== 'PENDING' &&
			this.state.submissionComplete
		) {
			this.props.uploadActions.sendSubmission({
				submission,
				onSuccess: (compsAwarded) => {
					this.props.showUploadSuccessModal({ compsAwarded: compsAwarded });
					invalidateUserQuery(this.props.queryClient);
				},
			});
		}
	}

	renderAdditionalFields() {
		const { submissionMap } = this.state;
		const updateSubmissionField = this.updateSubmissionField.bind(this);
		return (
			<div>
				<div className="row">
					<div className="col-12-12">
						<FormSelect
							{...submissionMap['buildingPropertyType']}
							type="text"
							options={this.state.propertyTypes}
							onChange={updateSubmissionField}
							matchProp="label"
						/>
					</div>
				</div>
				<div className="row extra-margin-half">
					<div className="col-12-3">
						<FormInput
							{...submissionMap['capRate']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
					<div className="col-12-9">
						<FormInput
							{...submissionMap['capRateNotes']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-3">
						<FormInput
							{...submissionMap['totalNetOperatingIncome']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
					<div className="col-12-3">
						<FormInput
							{...submissionMap['netOperatingIncomePsf']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
					<div className="col-12-3">
						<FormInput
							{...submissionMap['totalOperatingExpenses']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
					<div className="col-12-3">
						<FormInput
							{...submissionMap['operatingExpensesValue']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-6">
						<FormInput
							{...submissionMap['interestPercentage']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
					<div className="col-12-6">
						<FormSelect
							{...submissionMap['interestType']}
							type="text"
							options={this.state.interestTypes}
							onChange={updateSubmissionField}
							matchProp="label"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-12">
						<FormInput
							{...submissionMap['parcelNumber']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-12">
						<FormInput
							{...submissionMap['financingInfo']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-12">
						<FormInput
							{...submissionMap['propertyRights']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-6">
						<FormInput
							{...submissionMap['buyerRepBrokers']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
					<div className="col-12-6">
						<FormInput
							{...submissionMap['buyerRepCompanies']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12-6">
						<FormInput
							{...submissionMap['sellerRepBrokers']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
					<div className="col-12-6">
						<FormInput
							{...submissionMap['sellerRepCompanies']}
							type="text"
							onChange={updateSubmissionField}
						/>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { submissionMap } = this.state;
		const updateSubmissionField = this.updateSubmissionField.bind(this);
		return (
			<form
				onSubmit={this.sendSubmission.bind(this)}
				className="upload-form-content"
			>
				<div className="upload-form-center">
					<div className="form-group">
						<div className="row">
							<div className="col-12-12">
								<FormInput
									{...submissionMap['buildingAddress']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12-3">
								<FormInput
									{...submissionMap['city']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
							<div className="col-12-3">
								<FormSelect
									{...submissionMap['state']}
									type="text"
									options={this.state.stateOptions}
									onChange={updateSubmissionField}
									matchProp="label"
								/>
							</div>
							<div className="col-12-3">
								<FormInput
									{...submissionMap['floorOccupancies']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
							<div className="col-12-3">
								<FormInput
									{...submissionMap['suite']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
						</div>
					</div>
					<div className="form-group">
						<div className="row">
							<div className="col-12-4">
								<FormInput
									{...submissionMap['buildingSize']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
							<div className="col-12-4">
								<FormInput
									{...submissionMap['transactionSize']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
							<div className="col-12-4">
								<FormInput
									{...submissionMap['lotSize']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12-6">
								<FormInput
									{...submissionMap['buyer']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
							<div className="col-12-6">
								<FormInput
									{...submissionMap['recordedBuyer']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12-6">
								<FormInput
									{...submissionMap['seller']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
							<div className="col-12-6">
								<FormInput
									{...submissionMap['recordedSeller']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12-3">
								<FormInput
									{...submissionMap['totalSalePrice']}
									type="text"
									unit={this.state.submissionMap['totalSalePrice'].unit || '$'}
									onChange={updateSubmissionField}
								/>
							</div>
							<div className="col-12-3">
								<FormInput
									{...submissionMap['salePricePsf']}
									type="text"
									unit={this.state.submissionMap['salePricePsf'].unit || '$'}
									onChange={updateSubmissionField}
								/>
							</div>
							<div className="col-12-3">
								<FormInput
									{...submissionMap['totalAskingSalePrice']}
									type="text"
									unit={
										this.state.submissionMap['totalAskingSalePrice'].unit || '$'
									}
									onChange={updateSubmissionField}
								/>
							</div>
							<div className="col-12-3">
								<FormInput
									{...submissionMap['askingSalePricePsf']}
									type="text"
									unit={
										this.state.submissionMap['askingSalePricePsf'].unit || '$'
									}
									onChange={updateSubmissionField}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12-12">
								<FormMultiInput
									{...submissionMap['saleDate']}
									onChange={this.handleMultiInputChange.bind(this)}
									required={true}
								>
									<FormSelect
										name="saleDate.month"
										placeholder="Month"
										options={this.state.monthOptions}
										matchProp="label"
									/>
									<FormSelect
										name="saleDate.day"
										placeholder="Day"
										options={this.state.dayOptions}
										matchProp="label"
									/>
									<FormSelect
										name="saleDate.year"
										placeholder="Year"
										options={this.state.executionYearOptions}
										matchProp="label"
									/>
								</FormMultiInput>
							</div>
						</div>
						<div className="row extra-margin-full">
							<div className="col-12-6">
								<FormInput
									{...submissionMap['comments']}
									type="text"
									onChange={updateSubmissionField}
								/>
							</div>
							<div className="col-12-3">
								<FormRadio
									{...submissionMap['isPortfolio']}
									options={this.state.portfolioCondoOptions}
									inputClass={'pad'}
									onChange={updateSubmissionField}
								/>
							</div>
							<div className="col-12-3">
								<FormRadio
									{...submissionMap['condo']}
									options={this.state.portfolioCondoOptions}
									inputClass={'pad'}
									onChange={updateSubmissionField}
								/>
							</div>
						</div>
						{this.state.additionalFields
							? this.renderAdditionalFields()
							: false}
					</div>

					<div>* Required field</div>
					<div>
						** At least one size, buyer, seller, and price are required.
					</div>
					{!this.state.additionalFields && (
						<ShowAdditionalFieldsButton
							onClick={this.showAdditionalFields.bind(this)}
						/>
					)}
					<button
						disabled={!this.state.submissionComplete}
						className={
							'button_button button_large ' +
							(this.state.submissionComplete
								? 'button_green'
								: 'button_disabled')
						}
						data-qa-id="submit-comp"
					>
						Submit Comp
					</button>
				</div>
			</form>
		);
	}
}

function mapStoreToProps(store: AppState) {
	const saleSubmissionMap = getSaleSubmissionMapFromAppStore(store);
	return {
		saleSubmissionMap,
		uploads: store.uploads,
		user: store.user,
	};
}

function mapDispatchToProps(dispatch: AppDispatch) {
	const wrappedActions = wrapActions(
		{
			uploadActions: {
				updateSaleSubmissionFormState,
				sendSubmission,
			},
		},
		dispatch
	);
	return wrappedActions;
}

export default connect(
	mapStoreToProps,
	mapDispatchToProps
)(withQueryClient(SaleUploadForm));
