import {
	ModalButton,
	ModalButtons,
	ModalCloseButton,
	ModalCloseIcon,
	ModalContent,
	ModalOverlay,
	onInteractOutsideModal,
} from 'Components/Modals/common/UI';
import styled from 'styled-components';
import {
	SECTIONS_MAP,
	SectionKey,
	useExportPdfContext,
} from '../pdf/ExportPdfProvider';
import {
	ExportPdfLeasesStatus,
	ExportPdfSalesStatus,
} from '../pdf/ExportPdfStatus';
import { LeaseOptions } from '../pdf/LeaseOptions';
import { Subtext } from '../pdf/UI';
import {
	DEFAULT_NUM_OF_LEASES_SELECTED,
	DEFAULT_NUM_OF_SALES_SELECTED,
} from '../pdf/constants';
import { ExportPdfStep } from '../pdf/types';
import { ExportPdfData } from '../pdf/useExportPdfData';
import { CheckboxControl } from './CheckboxControl';

type Props = {
	data: ExportPdfData;
};

export const ExportPdfStepOne = ({ data }: Props) => {
	const {
		setStep,
		sections,
		setSections,
		leasesInCompsetOptionsState,
		leaseOptionsState,
	} = useExportPdfContext();

	return (
		<ModalOverlay>
			<ModalCloseButton>
				<ModalCloseIcon />
			</ModalCloseButton>
			<DialogContent onInteractOutside={onInteractOutsideModal}>
				<Section>
					<SectionLabel>
						Select the sections to include in the PDF:
					</SectionLabel>
					{Object.keys(sections).map((name) => {
						let subContent;
						let sideLabel;

						if (name === 'leasesInCompset') return <></>;

						if (name === 'compset' && data.leasesInCompset.length > 0) {
							sideLabel = <ExportPdfLeasesStatus data={data} />;
							subContent = (
								<div style={{ marginLeft: '0.5rem', marginTop: '0.5rem' }}>
									<CheckboxControl
										key={'leasesInCompset'}
										label={SECTIONS_MAP.leasesInCompset}
										checked={!!sections.leasesInCompset}
										onCheckedChange={(value) => {
											setSections((s) => ({
												...s,
												leasesInCompset: value,
											}));
										}}
										disabled={!sections.compset}
										subContent={
											<>
												<Subtext>
													The {DEFAULT_NUM_OF_LEASES_SELECTED} most recent
													leases from the competitive set are selected by
													default. To export within limit constraints, you can
													customize your selection on the next page.
												</Subtext>
												<LeaseOptions
													leaseOptionsState={leasesInCompsetOptionsState}
													radioName="pdf-leases-in-compset-status"
													leases={data.leasesInCompset}
													disabled={
														!sections.compset || !sections.leasesInCompset
													}
												/>
											</>
										}
									/>
								</div>
							);
						} else if (name === 'leases') {
							sideLabel = <ExportPdfLeasesStatus data={data} />;
							subContent = (
								<>
									<Subtext>
										The {DEFAULT_NUM_OF_LEASES_SELECTED} most recent leases for
										the property are selected by default. To export within limit
										constraints, you can customize your selection on the next
										page.
									</Subtext>
									<LeaseOptions
										leaseOptionsState={leaseOptionsState}
										radioName="pdf-leases-status"
										leases={data.leases}
										disabled={!sections.leases}
									/>
								</>
							);
						} else if (name === 'sales') {
							sideLabel = <ExportPdfSalesStatus data={data} />;
							subContent = (
								<>
									<Subtext>
										The {DEFAULT_NUM_OF_SALES_SELECTED} most recent sales for
										the property are selected by default. To export within limit
										constraints, you can customize your selection on the next
										page.
									</Subtext>
								</>
							);
						}

						return (
							<CheckboxControl
								key={name}
								label={SECTIONS_MAP[name as SectionKey]}
								checked={!!sections[name as SectionKey]}
								onCheckedChange={(value) => {
									setSections((s) => ({
										...s,
										[name]: value,
									}));
								}}
								subContent={subContent}
								sideLabel={sideLabel}
							/>
						);
					})}
				</Section>
				<StyledModalButtons>
					<ModalButton
						variant="primary"
						onClick={() => {
							setStep(ExportPdfStep.TWO);
						}}
					>
						Next
					</ModalButton>
				</StyledModalButtons>
			</DialogContent>
		</ModalOverlay>
	);
};

const DialogContent = styled(ModalContent)`
	width: fit-content;
	max-width: 700px;
	text-align: left;
	padding: 3rem 3rem 1.5rem 3rem;
`;

const Section = styled.div`
	margin-bottom: 2rem;
	padding-right: 2.5rem;
`;

const SectionLabel = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 1.125rem;
	font-weight: 325;
	line-height: 1.5;
	margin-bottom: 1.25rem;
`;

const StyledModalButtons = styled(ModalButtons)`
	justify-content: center;
`;
