import { Tab, TabContent, Tabs as UIKitTabs, TabsList } from '@compstak/ui-kit';
import styled from 'styled-components';
import { SalesComp } from 'types/comp';
import { UNLOCK_BUTTON_STATE } from '../Components/UnlockButton';
import {
	CompsetContainer,
	LeasesInCompset,
	LeaseSnapshot,
	LeaseSnapshotPortfolio,
	SimilarSales,
	TransactionDetails,
} from '../sections';
import { SalesCompPageTab } from '../types';
import { useTabVisibility } from '../utils';

export type TabsProps = {
	data: SalesComp;
	hasEnoughCredits: UNLOCK_BUTTON_STATE;
	isPortfolio: boolean;
};

export const Tabs = ({ data, hasEnoughCredits, isPortfolio }: TabsProps) => {
	const tabVisibility = useTabVisibility({
		propertyId: data.portfolio[0].propertyId,
		isPortfolio,
	});

	if (!tabVisibility) return null;

	const { TRANSACTION, LEASE, SIMILAR, COMPSET, LEASES_COMP_SET } =
		tabVisibility;

	return (
		<UIKitTabs defaultValue={SalesCompPageTab.TRANSACTION}>
			<TabListContainer isPortfolio={isPortfolio}>
				<TabsListStyled>
					{TRANSACTION && (
						<Tab value={SalesCompPageTab.TRANSACTION}>Transaction Details</Tab>
					)}
					{LEASE && <Tab value={SalesCompPageTab.LEASE}>Lease snapshot</Tab>}
					{SIMILAR && <Tab value={SalesCompPageTab.SIMILAR}>Similar sales</Tab>}
					{COMPSET && (
						<Tab value={SalesCompPageTab.COMPSET}>competitive set</Tab>
					)}
					{LEASES_COMP_SET && (
						<Tab value={SalesCompPageTab.LEASES_COMP_SET}>
							Leases in Comp Set
						</Tab>
					)}
				</TabsListStyled>
			</TabListContainer>
			<TabContent value={SalesCompPageTab.TRANSACTION}>
				<TransactionDetails
					data={data}
					hasEnoughCredits={hasEnoughCredits}
					isPortfolio={isPortfolio}
				/>
			</TabContent>
			<TabContent value={SalesCompPageTab.LEASE}>
				{isPortfolio ? (
					<LeaseSnapshotPortfolio
						portfolio={data.portfolio}
						market={data.market}
						saleDate={data.saleDate}
					/>
				) : (
					<LeaseSnapshot data={data} />
				)}
			</TabContent>
			<TabContent value={SalesCompPageTab.SIMILAR}>
				<SimilarSales data={data} />
			</TabContent>
			<TabContent value={SalesCompPageTab.COMPSET}>
				<CompsetContainer propertyId={data.portfolio[0].propertyId} />
			</TabContent>
			<TabContent value={SalesCompPageTab.LEASES_COMP_SET}>
				<LeasesInCompset data={data} />
			</TabContent>
		</UIKitTabs>
	);
};

const TabListContainer = styled.div<{ isPortfolio?: boolean }>`
	${({ isPortfolio }) =>
		isPortfolio &&
		`
		background-color: #FCF5FF;
	`};

	overflow-x: auto;
	overflow-y: hidden;

	::-webkit-scrollbar {
		height: 5px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: ${({ theme }) => theme.colors.gray.n70};
	}
`;

const TabsListStyled = styled(TabsList)`
	display: inline-flex;
	min-width: 100%;
`;
