import { VirtualTable } from '@compstak/ui-kit';
import { useNavigate } from 'router';
import { routes } from 'router/routes/routes';
import styled from 'styled-components';
import IconAsterisk from 'ui/svg_icons/asterisk.svg';
import { useLeasesTableColumns } from '../useLeasesTableColumns';
import { useLeasesInCompsetTableData } from './LeasesInCompsetTableDataProvider';
import { RadioButtonGroup } from '../LeasesTab/Components';
import { useIsExchange } from 'hooks';
import { EmptyTab } from '../EmptyTab';
import { UploadCompButton } from '../UploadCompButton';

type Props = {
	propertyId: number;
};

export const LeasesInCompsetTable = ({ propertyId }: Props) => {
	const { selectionState, filteredLeases, ...radioButtonGroupProps } =
		useLeasesInCompsetTableData();

	const columns = useLeasesTableColumns({
		selection: selectionState,
	});

	const isExchange = useIsExchange();

	const navigate = useNavigate();

	return (
		<>
			<RadioButtonGroup {...radioButtonGroupProps} />
			{filteredLeases.length > 0 ? (
				<VirtualTable
					id="property-leases-in-compset"
					rows={filteredLeases}
					columns={columns}
					maxBodyHeight={600}
					isBodyHoverable
					getBodyCellProps={({ row }) => ({
						onClick: () => {
							navigate(routes.leaseById.toHref({ id: row.id }), {
								state: {
									fromNewPropertyPage: true,
								},
							});
						},
					})}
					renderBodyCellContent={({ column, columnRender, row }) => {
						const isInSubjectProperty = row.propertyId === propertyId;

						if (column.id === 'executionQuarter') {
							return (
								<SubjectPropertyContainer
									style={{
										fontWeight: isInSubjectProperty ? 'bold' : undefined,
										gap: '0.25rem',
									}}
								>
									{isInSubjectProperty ? (
										<IconAsterisk width={8} />
									) : (
										<div style={{ width: 8 }} />
									)}
									{columnRender()}
								</SubjectPropertyContainer>
							);
						}

						if (isInSubjectProperty) {
							return (
								<SubjectPropertyContainer style={{ fontWeight: 'bold' }}>
									{columnRender()}
								</SubjectPropertyContainer>
							);
						}

						return columnRender();
					}}
				/>
			) : (
				<EmptyTab
					message={
						<>
							<div>
								CompStak doesn't have any Active Leases for this competitive set
								at the moment.
							</div>
							{isExchange && (
								<>
									<div>
										Be the first to report an Active Lease for this competitive
										set and earn an extra FREE comp!
									</div>
								</>
							)}
						</>
					}
					action={
						isExchange && (
							<UploadCompButton section="lease">
								Upload lease comp
							</UploadCompButton>
						)
					}
				/>
			)}
		</>
	);
};

const SubjectPropertyContainer = styled.div`
	display: flex;
	align-items: center;
`;
