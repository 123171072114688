import { Button, Spinner } from '@compstak/ui-kit';
import { SortDirection } from 'api';
import { Triangle } from 'Components';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { useState } from 'react';
import { Link } from 'router';
import styled, { useTheme } from 'styled-components';
import { APP_HEADER_HEIGHT } from '../../../constants';
import {
	ListSortButton,
	ListSortButtonsContainer,
	ListSortContainer,
	ListSortRowInfoText,
	MapViewLink,
	SpinnerContainer,
} from '../../UI';
import { usePropertiesWithSummaries } from '../../usePropertiesWithSummaries';
import { PropertyList } from './PropertyList';
import { SortFieldButton } from './SortFieldButton';
import { SortField } from './types';
import { useSortedPropertiesWithSummaries } from './useSortedPropertiesWithSummaries';

type Props = {
	portfolioId: number;
};

export const DashboardSidebar = ({ portfolioId }: Props) => {
	const [sortField, setSortField] = useState<SortField>(
		'buildingAddressAndCity'
	);
	const [sortDirection, setSortDirection] = useState<SortDirection>('asc');

	const theme = useTheme();

	const { filters } = usePortfolioFilters();
	const { data: propertiesWithSummaries, isFetching } =
		usePropertiesWithSummaries({
			portfolioId,
			filters,
		});

	const sortedPropertiesWithSummaries = useSortedPropertiesWithSummaries({
		propertiesWithSummaries,
		sortField,
		sortDirection,
	});

	return (
		<div>
			<ButtonsContainer>
				<StyledLink to={`/portfolios/${portfolioId}/list`}>
					<StyledButton variant="primary" size="l">
						List view
					</StyledButton>
				</StyledLink>
				<MapViewLink to={`/portfolios/${portfolioId}/map`} />
			</ButtonsContainer>
			{isFetching ? (
				<SpinnerContainer>
					<Spinner />
				</SpinnerContainer>
			) : (
				<>
					<ListSortContainer>
						<StyledListSortRowInfoText>
							{propertiesWithSummaries.length} properties
						</StyledListSortRowInfoText>
						<ListSortButtonsContainer>
							<SortFieldButton
								sortField={sortField}
								setSortField={setSortField}
							/>
							<ListSortButton
								onClick={() =>
									setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
								}
							>
								<Triangle
									size={14}
									color={theme.colors.neutral.n100}
									style={{
										transform:
											sortDirection === 'desc' ? 'rotate(180deg)' : undefined,
									}}
								/>
							</ListSortButton>
						</ListSortButtonsContainer>
					</ListSortContainer>
					<PropertyListContainer>
						<PropertyList
							propertiesWithSummaries={sortedPropertiesWithSummaries}
						/>
					</PropertyListContainer>
				</>
			)}
		</div>
	);
};

const BUTTONS_HEIGHT = 124;
const SORT_ROW_HEIGHT = 30;

const ButtonsContainer = styled.div`
	padding: 1rem 1.25rem;
	height: ${BUTTONS_HEIGHT}px;
`;

const PropertyListContainer = styled.div`
	height: calc(
		100vh - ${APP_HEADER_HEIGHT + BUTTONS_HEIGHT + SORT_ROW_HEIGHT}px
	);
`;

const StyledLink = styled(Link)`
	width: 100%;
	display: block;
	margin-bottom: 0.5rem;
`;

const StyledButton = styled(Button)`
	width: 100%;
`;

const StyledListSortRowInfoText = styled(ListSortRowInfoText)`
	color: ${(p) => p.theme.colors.neutral.n300};
`;
