import React from 'react';

import Modal from 'Singletons/Modal';

import withProjects from '../../withProjects';

import styles from '../../repository.less';
import modalStyles from '../../../Components/Modals/analytics-modals.less';
import button from 'ui/styles/button.less';
import { Project } from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';

export type RenameProjectModalProps = {
	markets: MarketsState;
	project: Project;
	closeModal: (ev: React.MouseEvent) => void;
};

type State = {
	name: string;
	errorMessage: string;
};

export const RenameProject = withProjects(
	class NewProject extends React.Component<RenameProjectModalProps, State> {
		state = {
			name: this.props.project.name,
			errorMessage: '',
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		updateName = (event) => {
			if (event.target.value.length <= 75) {
				this.setState({
					name: event.target.value,
					errorMessage: '',
				});
			}
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		handleKeyDown = (event) => {
			if (event.keyCode === 13) {
				event.preventDefault();
				this.saveProject();
			}
		};

		saveProject = () => {
			if (this.state.name && this.state.name !== this.props.project.name) {
				const trimmedName = this.state.name.trim();
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjects' does not exist on typ... Remove this comment to see the full error message
				if (this.props.analyticsProjects.some((p) => p.name === trimmedName)) {
					this.setState({
						errorMessage: `The project ${trimmedName} already exists. Enter a new name to continue.`,
					});
				} else {
					const renamedProject = {
						...this.props.project,
						name: trimmedName,
					};
					// This next line is a hack so that the "date modified" will be
					// updated correctly on the backend. Eventually the backend should
					// be fixed so that separate updates are handled separately
					// @ts-expect-error TS2551: Property 'lastUpdated' does no...
					delete renamedProject.lastUpdated;
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjectActions' does not exist ... Remove this comment to see the full error message
					this.props.analyticsProjectActions.saveProject(
						renamedProject,
						this.props.markets
					);
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
					this.props.modalActions.hideModal();
				}
			}
		};

		render() {
			let buttonClass;
			if (this.state.name && this.state.name !== this.props.project.name) {
				buttonClass = button.blue;
			} else {
				buttonClass = button.disabled;
			}

			return (
				<div
					className={`${modalStyles.container} ${styles.renameProjectModal}`}
				>
					<h3>Rename Project</h3>
					<form>
						<label className={styles.modalInputLabel}>
							Project Name
							<input
								type="text"
								value={this.state.name}
								onChange={this.updateName}
								onKeyDown={this.handleKeyDown}
								className={this.state.errorMessage ? styles.error : ''}
							/>
							<div className={styles.errorMessage}>
								{this.state.errorMessage}
							</div>
						</label>
						<div className={modalStyles.buttonContainer}>
							<div
								className={button.button}
								onClick={this.props.closeModal}
								data-close-button
							>
								Cancel
							</div>
							<div className={buttonClass} onClick={this.saveProject}>
								Done
							</div>
						</div>
					</form>
				</div>
			);
		}
	}
);

const name = 'analytics-rename-project';

Modal.addComponent({
	id: name,
	Component: RenameProject,
});

export default name;
