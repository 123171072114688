import { Market, User } from '@compstak/common';
import React, { useState, memo, useMemo } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { Flex } from '@compstak/ui-kit';
import {
	Chart,
	DataSet,
	DataSetType,
	InsightDataResponse,
	Shape,
} from 'Pages/Analytics/analytics';
import { MarketsState, PermissionsState } from 'Pages/Login/reducers';
import { FeedbackActions } from 'Singletons/Feedback/actions';
import { ModalActions } from 'Singletons/Modal/actions';
import { FeatureFlags } from '../../../../../api/featureFlags/types';
import { ChartBuilderActions } from '../../actions';
import { DataSetColor, getColorForDataSetIndex } from '../../colors';
import {
	ExpandedFiltersLeases,
	ExpandedFiltersMufa,
	ExpandedFiltersSales,
} from '../ExpandedFilters';
import '../Menus/DatasetMenu';
import ApplyAll from '../Modals/ApplyAll';
import {
	ArrowDownExpand,
	Counter,
	DatasetContainer,
	DataSetErrorIndicator,
	DatasetSummary,
	EyeOffStyled,
	EyeStyled,
	FormWrapper,
	MoreFiltersButton,
	MoreFiltersButtonV2,
	RightGroupWrapper,
	StyledAngleArrow,
	StyledThreeDot,
	TileClickContainer,
	Title,
	TitleWrapper,
	TopContainer,
} from './Components';
import { DataSetFormLegacyAdapter } from './DataSetFormLegacyAdapter';

import { attributeToPlotValuesToTitles } from '../Modals/DataSets/CreateNewDataSetV2/utils';
import {
	findDSIndexInDataArray,
	getDatasetStats,
	getStatsCounter,
	CounterType,
	getCounterTooltip,
} from '../helpers';
import { FiltersObject } from 'models/filters/types';
import {
	PROPERTY_TYPE_ID_TO_NAME,
	PropertyTypeId,
	ReferenceData,
	SPACE_TYPE_ID_TO_NAME,
	SPACE_TYPE_NAME_TO_ID,
	SpaceTypeId,
} from 'api/referenceData/useReferenceDataQuery';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { CompType } from 'types';
import { FilterFieldContextProvider } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { PropertyFilterFieldsV2 } from 'Pages/Search/Sidebar/Components/PropertyFilterFieldsV2';
import { LeaseFilterFieldsV2 } from 'Pages/Search/Sidebar/Components/LeaseFilterFieldsV2';
import { SaleFilterFieldsV2 } from 'Pages/Search/Sidebar/Components/SaleFilterFieldsV2';
import { getSetFilterKeysExceptMarkets } from 'models/filters/util/filterHelpers';

type DatasetV2Props = {
	chartBuilderActions: ChartBuilderActions;
	chartDraft: Chart;
	dataSet: DataSet;
	dataSets: DataSet[];
	expanded: boolean;
	expandedDataSetIndex: number | null;
	feedbackActions: FeedbackActions;
	filters: FiltersObject;
	market?: Market;
	markets: MarketsState;
	permissions: PermissionsState;
	referenceData: ReferenceData;
	// @ts-expect-error TS7051: Parameter has a name but no ty...
	replace: (string) => void;
	// @ts-expect-error TS7006: Parameter 'value' implicitly h...
	setChartAttribute: (attribute: string, value) => void;
	shapes: Shape[];
	index: number;
	user: User;
	featureFlags: FeatureFlags;
	modalActions: ModalActions;
	colors: DataSetColor[];
	insightsData: InsightDataResponse[];
};

// TODO: This is a temporary solution. It should be deleted after releasing sidebarRevampFF
const componentsMap: Record<
	Exclude<DataSetType, DataSetType.LEASES>,
	{ old: any; new: any; compType: CompType }
> = {
	[DataSetType.MUFA]: {
		old: ExpandedFiltersMufa,
		new: PropertyFilterFieldsV2,
		compType: 'property',
	},
	[DataSetType.COMMERCIAL]: {
		old: ExpandedFiltersLeases,
		new: LeaseFilterFieldsV2,
		compType: 'lease',
	},
	[DataSetType.SALES]: {
		old: ExpandedFiltersSales,
		new: SaleFilterFieldsV2,
		compType: 'sale',
	},
};

const DatasetV2Impl = memo<DatasetV2Props>((props) => {
	const {
		index,
		dataSet,
		insightsData,
		chartDraft,
		chartBuilderActions,
		markets,
		modalActions,
		expanded,
	} = props;

	const [areFiltersVisible, setAreFiltersVisible] = useState(false);

	const titleRef = React.useRef<HTMLElement>(null);
	const isTitleOverflown = useMemo(() => {
		if (titleRef.current)
			return titleRef.current.scrollWidth > titleRef.current.clientWidth;
	}, [titleRef.current]);

	const colorFromDatasetIndex = useMemo(
		() => getColorForDataSetIndex(index),
		[index]
	);

	const insightsDataItem =
		insightsData[findDSIndexInDataArray(chartDraft.dataSets, dataSet)];

	const stats = getDatasetStats({
		insightsDataItem,
		timespan: chartDraft.timespan,
	});

	const marketName = dataSet.filters?.market?.displayName || 'N/A';

	const spaceTypeIds = dataSet.filters?.spaceTypeId
		? dataSet.filters.spaceTypeId
		: null;

	const propertyTypeIds = dataSet.filters?.buildingPropertyTypeId
		? dataSet.filters.buildingPropertyTypeId
		: null;

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	const expandDataSet = (event) => {
		if (!event.defaultPrevented) {
			if (index === props.expandedDataSetIndex) {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'chartBuilderActions' does not exist on t... Remove this comment to see the full error message
				props.chartBuilderActions.expandDataSet(null);
			} else {
				props.chartBuilderActions.expandDataSet(index);
			}

			setAreFiltersVisible(false);
		}
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	const showSidebarMenu = (event) => {
		event.preventDefault();
		// @ts-expect-error TS2339: Property 'menuActions' does no...
		props.menuActions.showMenu('dataset-menu', event.target, 'onright', props);
	};

	// @ts-expect-error TS7006: Parameter 'val' implicitly has...
	const handleApplyAll = (val) => {
		props.modalActions.showModal(ApplyAll, {
			chartDraft,
			chartBuilderActions,
			modalActions,
			markets,
			value: { y: val },
		});
	};

	// @ts-expect-error TS7006: Parameter 'newSeries' implicit...
	const updateSeries = (newSeries) => {
		const newDataSet = {
			...dataSet,
			series: newSeries,
		};
		chartBuilderActions.updateDataSet(chartDraft, newDataSet, markets);
	};

	const onFilterChange = (newFilters: Partial<FiltersObject>) => {
		chartBuilderActions.updateDataSetFilters(
			dataSet.id,
			newFilters,
			chartDraft,
			markets
		);
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	const toggleDSVisibility = (event) => {
		event.preventDefault();
		const updatedDataSet = {
			...dataSet,
			isVisible: !dataSet.isVisible,
		};
		chartBuilderActions.updateDataSet(chartDraft, updatedDataSet, markets);
	};

	const toggleFiltersVisibility = () => setAreFiltersVisible((prev) => !prev);
	const { sidebarRevampFF } = useFeatureFlags();

	const config =
		componentsMap[dataSet.type as Exclude<DataSetType, DataSetType.LEASES>];
	const Filters = (() => {
		if (config) {
			const SelectedComponent = sidebarRevampFF ? config.new : config.old;
			return (
				<SelectedComponent
					{...props}
					compType={config.compType}
					onFilterChange={onFilterChange}
				/>
			);
		}
		return <>Unknown dataset type</>;
	})();

	const moreFiltersCount = useMemo(() => {
		const dataSetFilters = dataSet.filters;

		const {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			submarkets,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			spaceTypeId,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			buildingPropertyTypeId,
			...dataSetFiltersToCount
		} = dataSetFilters;

		return getSetFilterKeysExceptMarkets(dataSetFiltersToCount).length;
	}, [dataSet.filters]);

	return (
		<FilterFieldContextProvider
			compType={config.compType}
			filters={dataSet.filters}
			onFilterChange={onFilterChange}
			context="analytics"
		>
			<DatasetContainer
				bgColor={colorFromDatasetIndex}
				expanded={expanded}
				sidebarRevampFF={sidebarRevampFF}
			>
				<TileClickContainer
					onClick={expandDataSet}
					expanded={expanded}
					bgColor={colorFromDatasetIndex}
				>
					<TopContainer>
						<TitleWrapper>
							<Title
								data-tooltip={isTitleOverflown ? dataSet.name : ''}
								data-tooltip-position="onright"
								data-tooltipdelay="0"
								// @ts-expect-error TS2769: No overload matches this call....
								ref={titleRef}
							>
								{dataSet.name}
							</Title>
							<DataSetErrorIndicator
								isSuccess={insightsDataItem?.isSuccess}
								isError={insightsDataItem?.isError}
							/>
						</TitleWrapper>
						<RightGroupWrapper>
							<Counter
								bgColor={colorFromDatasetIndex}
								expanded={expanded}
								data-tooltip={getCounterTooltip({
									counterType: CounterType.COMPS,
									stats,
									dsType: dataSet.type,
								})}
								data-tooltip-position="above"
								data-tooltipdelay="0"
							>
								{getStatsCounter({
									counterType: CounterType.COMPS,
									stats,
									dsType: dataSet.type,
									isLoading: insightsDataItem?.isLoading,
									isSuccess: insightsDataItem?.isSuccess,
								})}
							</Counter>
							{dataSet.isVisible ? (
								<EyeStyled onClick={toggleDSVisibility} />
							) : (
								<EyeOffStyled onClick={toggleDSVisibility} />
							)}
							<StyledThreeDot width={4} height={16} onClick={showSidebarMenu} />
						</RightGroupWrapper>
					</TopContainer>
					<DatasetSummary>
						{createDataSetSummary({
							// @ts-expect-error TS7053: Element implicitly has an 'any...
							attrToPlot: attributeToPlotValuesToTitles[dataSet.series],
							dataSetType: dataSet.type,
							marketName,
							spaceTypeIds:
								spaceTypeIds?.filter(
									(id) => id !== SPACE_TYPE_NAME_TO_ID.Unknown
								) ?? [],
							propertyTypeIds,
						})}
					</DatasetSummary>
					<ArrowDownExpand
						expanded={expanded}
						bgColor={colorFromDatasetIndex}
					/>
				</TileClickContainer>
				<FormWrapper sidebarRevampFF={sidebarRevampFF} expanded={expanded}>
					<DataSetFormLegacyAdapter
						markets={props.markets}
						permissions={props.permissions}
						dataSet={props.dataSet}
						chartDraft={props.chartDraft}
						onFilterChange={onFilterChange}
						// @ts-expect-error TS2322: Type '{ markets: MarketsState;...
						updateSeries={updateSeries}
						handleApplyAll={handleApplyAll}
						expanded={expanded}
					/>
				</FormWrapper>
				{expanded && areFiltersVisible && Filters}
				{expanded &&
					(sidebarRevampFF ? (
						<MoreFiltersButtonV2
							type="button"
							onClick={toggleFiltersVisibility}
						>
							{areFiltersVisible ? 'Show Less Filters' : 'Show More Filters'}{' '}
							{moreFiltersCount ? `(${moreFiltersCount})` : ''}{' '}
							<StyledAngleArrow rotate={areFiltersVisible ? 180 : 0} />
						</MoreFiltersButtonV2>
					) : (
						!areFiltersVisible && (
							<MoreFiltersButton
								type="button"
								onClick={toggleFiltersVisibility}
							>
								More filters {moreFiltersCount ? `(${moreFiltersCount})` : ''}
							</MoreFiltersButton>
						)
					))}
			</DatasetContainer>
		</FilterFieldContextProvider>
	);
});

DatasetV2Impl.displayName = 'DatasetV2Impl';

export const DatasetV2 = withErrorBoundary(DatasetV2Impl, {
	FallbackComponent: () => {
		const fireExtinguisherIcon = '\uD83E\uDDEF';

		return (
			<Flex justifyContent={'center'} style={{ padding: '4px' }}>
				<span>Error in dataset {fireExtinguisherIcon}</span>
			</Flex>
		);
	},
	onError(error) {
		console.error('Error in dataset', error);
	},
});

type CreateDataSetSummaryParams = {
	attrToPlot: string;
	dataSetType: DataSetType | undefined;
	marketName: string;
	spaceTypeIds: SpaceTypeId[] | null;
	propertyTypeIds: PropertyTypeId[] | null;
};

const createDataSetSummary = ({
	attrToPlot,
	dataSetType,
	marketName,
	spaceTypeIds,
	propertyTypeIds,
}: CreateDataSetSummaryParams) => {
	const createSpaceOrPropertyTypesString = (
		ids: PropertyTypeId[] | SpaceTypeId[] | null
	) => {
		if (!ids) return '';

		const mapTypeToClientValue =
			dataSetType === DataSetType.SALES
				? PROPERTY_TYPE_ID_TO_NAME
				: SPACE_TYPE_ID_TO_NAME;

		const allTypesAreSelected =
			Object.keys(mapTypeToClientValue).length === ids.length;

		return allTypesAreSelected
			? ''
			: // @ts-expect-error TS7053: Element implicitly has an 'any...
				ids.map((id) => mapTypeToClientValue[id]).join(', ');
	};

	const dataSetTypeToCounterValue = {
		[DataSetType.COMMERCIAL]: createSpaceOrPropertyTypesString(spaceTypeIds),
		[DataSetType.LEASES]: createSpaceOrPropertyTypesString(spaceTypeIds),
		[DataSetType.SALES]: createSpaceOrPropertyTypesString(propertyTypeIds),
		[DataSetType.MUFA]: PROPERTY_TYPE_ID_TO_NAME[2],
	};

	// @ts-expect-error TS7053: Element implicitly has an 'any...
	const types = dataSetTypeToCounterValue[dataSetType || ''];

	return `${attrToPlot} ${types ? `for ${types}` : ''} in ${marketName}`;
};
