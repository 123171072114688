import React, { ReactNode } from 'react';
import {
	ExchangePanel,
	ExpandedChartContainer,
	ChartTooltip,
	TooltipRow,
	LoadingChart,
} from './UI';
import styled from 'styled-components';
import {
	VictoryChart,
	VictoryVoronoiContainer,
	VictoryStack,
	VictoryBar,
	VictoryAxis,
	VictoryLabel,
	VictoryTooltip,
} from 'victory';
import { useExchangeDashboardState } from 'reducers/exchangeDashboard';
import { Waypoint } from 'react-waypoint';
import { useExchangeDashboardLeaseExpirationBySubmarket } from './hooks/leaseExpirationBySubmarket/useExchangeDashboardLeaseExpirationBySubmarket';
import { Button } from '@compstak/ui-kit';

export const getYearKeys = () => {
	const currentYear = new Date().getFullYear();
	return Array.from(Array(5).keys()).map((n) => currentYear + n);
};

export const COLOR_SCALE = [
	'#065bb2',
	'#228fff',
	'#7abcff',
	'#d3e9ff',
	'#246b44',
	'#50b87f',
	'#b9e3cc',
	'#dcf1e5',
	'#000000',
	'#303441',
	'#cccccc',
];

export const LeaseExpirationSubmarket = () => {
	const [view, setView] = React.useState<'visible' | 'hidden'>('hidden');
	const { fullPageState, setFullPageState } = useExchangeDashboardState();

	const { data, submarkets, isFetching } =
		useExchangeDashboardLeaseExpirationBySubmarket({
			enabled: view === 'visible' && fullPageState?.type !== 'list',
		});

	const upperDomain = submarkets?.length ?? 5;

	const DOMAIN: [number, number] = [
		0,
		fullPageState ? upperDomain : Math.min(upperDomain, 5),
	];

	const submarketsToDisplay = submarkets?.slice(...DOMAIN).reverse();

	const ParentEl = ({ children }: { children: ReactNode }) =>
		fullPageState ? (
			<ExpandedChartContainer>{children}</ExpandedChartContainer>
		) : (
			<Waypoint onEnter={() => setView('visible')}>
				<ExchangePanel
					id="leaseExpirationSubmarket"
					title="Lease Expiration by Submarket"
				>
					{children}
				</ExchangePanel>
			</Waypoint>
		);

	const yearKeys = getYearKeys();

	const barDataByYearKey = yearKeys.reduce((acc, yearKey) => {
		return {
			...acc,
			[yearKey]: submarketsToDisplay?.map((submarket) => {
				return {
					x: submarket,
					y: data?.[yearKey]?.[submarket] ?? 0,
					yearKey,
					label: '',
				};
			}),
		};
	}, {});

	const containerHeight = fullPageState
		? 64 * (submarketsToDisplay?.length ?? 1)
		: 380;

	const chartHeight = fullPageState
		? 60 * (submarketsToDisplay?.length ?? 1)
		: 340;

	const hasData =
		yearKeys.flatMap((yearKey) => {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			return barDataByYearKey?.[yearKey]?.filter((d) => d.y !== 0);
		}).length > 0;

	return (
		<ParentEl>
			{fullPageState && <h3>Lease Expiration by Submarket</h3>}
			{isFetching ? (
				<LoadingChart />
			) : data && hasData ? (
				<ChartContainer>
					<VictoryChart
						horizontal
						maxDomain={{ x: DOMAIN[1] }}
						domain={{ x: DOMAIN }}
						domainPadding={0}
						style={{ parent: { maxWidth: '100%', height: '100%' } }}
						height={chartHeight < 340 ? 340 : chartHeight}
						width={fullPageState ? 1000 : 520}
						padding={{
							left: 0,
							top: 50,
							bottom: 0,
							right: 50,
						}}
						containerComponent={
							<VictoryVoronoiContainer
								voronoiDimension="x"
								responsive={!fullPageState}
								height={containerHeight < 380 ? 380 : containerHeight}
								style={{ margin: '0 auto' }}
							/>
						}
					>
						<VictoryAxis
							axisLabelComponent={
								<VictoryLabel
									style={{
										fontSize: 12,
										fontFamily: 'Gotham',
									}}
								/>
							}
							fixLabelOverlap
							tickLabelComponent={
								<VictoryLabel
									textAnchor="start"
									dx={10}
									dy={-28}
									style={{
										fontSize: 12,
										fontFamily: 'Gotham',
										textTransform: 'capitalize',
									}}
								/>
							}
							style={{
								axis: { stroke: 'none' },
								axisLabel: {
									fontSize: 12,
									fontFamily: 'Gotham',
									color: '#000',
									textTransform: 'uppercase',
								},
								grid: { stroke: 'none' },
								ticks: { stroke: '#F0F0F0' },
								tickLabels: {
									fontSize: 12,
									fontFamily: 'Gotham',
									color: '#000',
									textTransform: 'uppercase',
								},
							}}
						/>
						<VictoryAxis
							dependentAxis
							label="Number of Leases Expiring"
							axisLabelComponent={
								<VictoryLabel
									dy={fullPageState ? 0 : -22}
									style={{ fontFamily: 'Gotham' }}
									renderInPortal={!!fullPageState}
								/>
							}
							tickLabelComponent={
								<VictoryLabel
									dy={-30}
									style={{ fontSize: 14, fontFamily: 'Gotham' }}
								/>
							}
							fixLabelOverlap
							style={{
								axis: { stroke: 'none', padding: 0, margin: 0 },
								axisLabel: {
									fontSize: 14,
									fontFamily: 'Gotham',
									color: '#000',
									textTransform: 'capitalize',
								},
								grid: { stroke: 'none', margin: 0, padding: 0 },
								ticks: { stroke: '#F0F0F0' },
								tickLabels: {
									fontSize: 12,
									fontFamily: 'Gotham',
									color: '#000',
								},
							}}
						/>
						<VictoryStack
							colorScale={COLOR_SCALE.filter(
								(c, idx) =>
									// @ts-expect-error TS7053: Element implicitly has an 'any...
									barDataByYearKey[yearKeys[idx]]?.filter((d) => d.y !== 0)
										.length > 0
							)}
							labels={() => ' '}
						>
							{yearKeys.map((yearKey) => {
								// @ts-expect-error TS7053: Element implicitly has an 'any...
								const barData = barDataByYearKey[yearKey].map(
									// eslint-disable-next-line @typescript-eslint/no-unused-vars
									// @ts-expect-error TS7031: Binding element 'label' implic...
									({ label, ...rest }) => rest
								);

								return (
									<VictoryBar
										key={yearKey}
										data={barData}
										barWidth={36}
										labels={() => ' '}
										style={{
											data: {
												cursor: 'pointer',
											},
										}}
										labelComponent={
											<VictoryTooltip
												dy={-170}
												dx={(data) => {
													// Set smaller offset for small bars
													if (data.datum.y < 10) return -50;
													return -80;
												}}
												orientation="left"
												flyoutHeight={150}
												flyoutComponent={<GraphFlyout />}
											/>
										}
										events={[
											{
												target: 'data',
												eventHandlers: {
													onClick: (_e, clickProps) => {
														const {
															datum: { xName: submarketName, yearKey },
														} = clickProps;
														setFullPageState &&
															setFullPageState({
																type: 'list',
																field: 'submarket',
																submarket: submarketName,
																year: yearKey,
															});
													},
												},
											},
										]}
									/>
								);
							})}
						</VictoryStack>
					</VictoryChart>
					<Legend isFullPage={!!fullPageState}>
						{yearKeys.map((yearKey, i) => (
							<div key={i} className="year-key">
								<div
									className="color"
									style={{ backgroundColor: COLOR_SCALE[i] }}
								/>
								<div>{yearKey}</div>
							</div>
						))}
					</Legend>
					{!fullPageState && submarkets && submarkets?.length > 5 && (
						<ViewMoreButton
							variant="primary2"
							onClick={() =>
								setFullPageState &&
								setFullPageState({ type: 'graph', field: 'submarket' })
							}
						>
							View more
						</ViewMoreButton>
					)}
				</ChartContainer>
			) : (
				<div>No Data To Display. Update Filters and Try Again.</div>
			)}
		</ParentEl>
	);
};

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
const GraphFlyout = (props) => {
	const { data, submarkets } = useExchangeDashboardLeaseExpirationBySubmarket();
	const { datum, x, y } = props;
	const submarket = datum.xName;
	const yearKeys = getYearKeys();

	return (
		<g
			width={200}
			height={150}
			transform={`translate(${x + props.dx}, ${y + props.dy})`}
			style={{ pointerEvents: 'none' }}
		>
			<foreignObject width={200} height={158}>
				<Tooltip>
					<SubmarketTooltipRow>
						<span className="submarket">{submarket}</span>
						<span>Leases</span>
					</SubmarketTooltipRow>
					{yearKeys.map((year, i) => {
						if (submarkets?.some((name) => name === submarket)) {
							return (
								<TooltipRow key={i}>
									<TooltipRow>
										<div
											className="color"
											style={{ backgroundColor: COLOR_SCALE[i] }}
										/>
										<div>{year}</div>
									</TooltipRow>
									<div>{data?.[year]?.[submarket] ?? 0}</div>
								</TooltipRow>
							);
						} else {
							return null;
						}
					})}
				</Tooltip>
			</foreignObject>
		</g>
	);
};

const ChartContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
`;

const Legend = styled.div<{ isFullPage: boolean }>`
	max-width: 500px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	margin: ${(props) => (props.isFullPage ? '60px 54px 0 auto' : '25px 0 0')};

	.year-key {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;

		.color {
			width: 18px;
			height: 18px;
			border-radius: 3px;
			margin-right: 8px;
		}
	}
`;

const ViewMoreButton = styled(Button)`
	display: flex;
	align-self: flex-end;
	font-size: 12px;
	text-transform: none;
	font-weight: 400;
`;

const Tooltip = styled(ChartTooltip)`
	height: 150px;

	&:after {
		top: 150px;
	}
`;

const SubmarketTooltipRow = styled(TooltipRow)`
	.submarket {
		width: 50%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;
