import React, { Component } from 'react';

type Props = {
	value: string;
	name: string;
	options: ReadonlyArray<{ label: string; value: string }>;
	inputClass?: string;
	label?: string;
	labelClass?: string;
	onChange?: (name: string, value: string) => void;
	required?: boolean;
};

export default class FormRadio extends Component<Props> {
	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	onChange(event) {
		if (this.props.onChange) {
			this.props.onChange(event.target.name, event.target.value);
		}
	}

	renderLabel() {
		if (this.props.label) {
			const labelClass = this.props.labelClass || '';
			return (
				<div
					className={
						'label input_label' + (this.props.required ? ' required' : '')
					}
				>
					<label className={labelClass}>
						{this.props.label} {this.props.required ? '*' : false}{' '}
					</label>
				</div>
			);
		} else {
			return false;
		}
	}

	render() {
		let className = 'form-input form-radio';
		if (this.props.inputClass) {
			className += ' ' + this.props.inputClass;
		}

		return (
			<div className={className}>
				{this.renderLabel()}
				{this.props.options.map((option, index) => {
					const style = { width: 100 / this.props.options.length + '%' };
					return (
						<div
							style={style}
							key={this.props.name + '-' + index}
							className="input-container"
						>
							<input
								className="radio"
								type="radio"
								name={this.props.name}
								id={this.props.name + '-' + index}
								value={option.value}
								onChange={this.onChange.bind(this)}
								checked={option.value === this.props.value}
							/>
							<label htmlFor={this.props.name + '-' + index}>
								{option.label}
							</label>
						</div>
					);
				})}
			</div>
		);
	}
}
