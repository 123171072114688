import React, { ChangeEventHandler } from 'react';
import Menu from 'Singletons/Menu';

import styles from 'Pages/Analytics/Builder/Components/styles/menu.less';
import popupStyles from 'Components/Popup/popup.less';

type SortBy = 'dateModified' | 'name' | 'numberOfCharts';

type Props = {
	setSortBy: (sortBy: SortBy) => void;
	sortBy: SortBy;
	menuActions: {
		hideMenu: Function;
	};
};
export const ProjectSort: React.FC<Props> = (props) => {
	const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value as SortBy;
		props.setSortBy(value);
		props.menuActions.hideMenu();
	};

	return (
		<form className={styles.sortMenu}>
			<input
				type="radio"
				id="projectRepoRadioDateModified"
				value="dateModified"
				className="radio"
				checked={props.sortBy === 'dateModified'}
				onChange={handleSelect}
			/>
			<label htmlFor="projectRepoRadioDateModified">Date Modified</label>
			<input
				type="radio"
				id="projectRepoRadioName"
				className="radio"
				value="name"
				checked={props.sortBy === 'name'}
				onChange={handleSelect}
			/>
			<label htmlFor="projectRepoRadioName">Name</label>
			<input
				type="radio"
				id="projectRepoRadioNumberOfCharts"
				className="radio"
				value="numberOfCharts"
				checked={props.sortBy === 'numberOfCharts'}
				onChange={handleSelect}
			/>
			<label htmlFor="projectRepoRadioNumberOfCharts">Number of Charts</label>
		</form>
	);
};

Menu.addComponent('project-sort', ProjectSort, {
	className: popupStyles.compactWhite,
});

export default 'project-sort';
