import { InsightData } from 'Pages/Analytics/analytics';

// @ts-expect-error TS7006: Parameter 'series' implicitly ...
export const getAxisId = (series) => {
	if (series.includes('Rent')) return 'rent';
	if (series.includes('Rpsf')) return 'rpsf';
	return series;
};
// @ts-expect-error TS7006: Parameter 'isMonthly' implicit...
export const transformValueForInput = (isMonthly, attribute, value) => {
	if (attribute === 'leaseTerm' || attribute === 'freeMonths') {
		return value; // / 12;
		// let transformed = moment(`2000-01-01`);
		// transformed.add(value-1, 'months');
		// return transformed.valueOf();
	}
	return isMonthly ? value / 12 : value;
};

// @ts-expect-error TS7006: Parameter 'round' implicitly h...
const roundDownTo = (round, n) => {
	return Math.floor(n / round) * round;
};

// @ts-expect-error TS7006: Parameter 'min' implicitly has...
export const dateTickPositioner = (min, max) => {
	const diff = max - min;
	let interval = null;
	switch (true) {
		case diff < 1.5:

			interval = 0.25;
			break;

		case diff < 3:

			interval = 0.5;
			break;

		case diff < 12:

			interval = 1;
			break;

		case diff < 18:

			interval = 3;
			break;

		case diff < 36:

			interval = 6;
			break;

		case diff < 60:

			interval = 12;
			break;

		default:

			interval = 24;
	}
	const start = roundDownTo(interval, min);
	let current = start;
	const intervals = [current];
	while (current < max) {

		current += interval;
		intervals.push(current);
	}
	return intervals;
};

export const NO_NAME = '\u00A0'; // non breaking space

export const isInsightDataShownAsLine = (data: InsightData) => {
	return !data.points && !!data.trendLine;
};

export const isInsightDataShownAsBubble = (data: InsightData) => {
	return !!data.points;
};
