import { FiltersObject } from 'models/filters/types';
import { Chart, DataSet } from 'Pages/Analytics/analytics';
import { MarketsState, PermissionsState } from 'Pages/Login/reducers';
import { useCallback, useMemo } from 'react';
import { getSalePropertyTypeItemById } from '../Modals/DataSets/CreateNewDataSetV2/propertyTypeItems';
import { getSpaceTypeItemsFromIds } from '../Modals/DataSets/CreateNewDataSetV2/spaceTypeItemsV2';
import { DataSetFormV2 } from './DataSetForm.v2';

type DataSetFormLegacyAdapterProps = {
	markets: MarketsState;
	permissions: PermissionsState;
	dataSet: DataSet;
	chartDraft: Chart;
	onFilterChange: (changes: Partial<FiltersObject>) => void;
	expanded: boolean;
};

export const DataSetFormLegacyAdapter = ({
	markets,
	permissions,
	dataSet,
	onFilterChange,
	// @ts-expect-error TS2339: Property 'updateSeries' does n...
	updateSeries,
	// @ts-expect-error TS2339: Property 'handleApplyAll' does...
	handleApplyAll,
	expanded,
}: DataSetFormLegacyAdapterProps) => {
	const spaceTypeInitOptions = useMemo(() => {
		const spaceTypeFilter = [...(dataSet.filters.spaceTypeId ?? [])];
		return getSpaceTypeItemsFromIds(spaceTypeFilter);
	}, [dataSet.filters.spaceTypeId]);

	const propertyTypeInitialValue = useMemo(() => {
		const propertyTypeFilter = [
			...(dataSet.filters.buildingPropertyTypeId ?? []),
		];
		return propertyTypeFilter.map((id) => getSalePropertyTypeItemById(id));
	}, [dataSet.filters.buildingPropertyTypeId]);

	const initialSubmarkets = useMemo(() => {
		const submarkets = dataSet.filters.submarkets ?? [];
		return submarkets.map(({ id, name }) => {
			return {
				value: String(id),
				title: name,
				inputType: 'checkbox',
				parentItem: 'commercial',
			};
		});
	}, [dataSet.filters.submarkets]);

	const onAttributeToPlotChange = useCallback(
		// @ts-expect-error TS7006: Parameter 'newSeries' implicit...
		(newSeries) => {
			updateSeries(newSeries);
		},
		[updateSeries]
	);

	const initialState = useMemo(
		() => ({
			market: dataSet.filters.market,
			spaceTypes: spaceTypeInitOptions,
			propertyTypes: propertyTypeInitialValue,
			attributeToPlot: dataSet.series,
		}),
		[
			dataSet.filters.market,
			dataSet.series,
			spaceTypeInitOptions,
			propertyTypeInitialValue,
		]
	);

	return (
		<DataSetFormV2
			markets={markets}
			permissions={permissions}
			// @ts-expect-error TS2322: Type '{ value: string; title: ...
			initialSubmarkets={initialSubmarkets}
			// @ts-expect-error TS2741: Property 'attributeToPlotItems...
			initialState={initialState}
			onAttributeToPlotChange={onAttributeToPlotChange}
			onFilterChange={onFilterChange}
			onApplyAll={handleApplyAll}
			dataSet={dataSet}
			expanded={expanded}
		/>
	);
};
