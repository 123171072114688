import { nullValueText } from '@compstak/common';
import { defaultTheme } from '@compstak/ui-kit';
import { LockIconStyled } from 'Components/UnlockButton/UnlockButtonComponent';
import { memo } from 'react';
import styled from 'styled-components';
import { CompKeys, SalesComp } from 'types/comp';
import IconBuilding from 'ui/svg_icons/building-multiple.svg';
import { Locale } from 'util/comp-format/attributes';
import { formatValue } from 'util/comp-format/src/format';
import dateFormat from 'util/comp-format/src/util/calendarDate';
import formatNumber from 'util/comp-format/src/util/number';
import {
	UnlockButton,
	UNLOCK_BUTTON_STATE,
} from '../../Components/UnlockButton';
import { KeyInfoItem, KeyInfoItems, Value } from 'Components/KeyInfoItem';

type Props = {
	data: SalesComp;
	hasEnoughCredits: UNLOCK_BUTTON_STATE;
	isPortfolio?: boolean;
};

export const KeyInfo = memo<Props>(
	({ data, hasEnoughCredits, isPortfolio }) => {
		const saleDateText = data.saleDate
			? `Sale on ${dateFormat(data.saleDate, true, data.locale ?? 'en_US')}`
			: 'Sale';

		const showBuyer =
			(data.own === false && data.buyer === null) ||
			(data.buyer && data.buyer.length) ||
			!data.recordedBuyer ||
			!data.recordedBuyer.length;

		const showSeller =
			(data.own === false && data.seller === null) ||
			(data.seller && data.seller.length) ||
			!data.recordedSeller ||
			!data.recordedSeller.length;

		return (
			<KeyInfoContainer isPortfolio={isPortfolio}>
				<SaleDateContainer>
					{isPortfolio && (
						<IconPortfolioContainer>
							<StyledIconBuilding />
						</IconPortfolioContainer>
					)}
					<SaleDate>
						{isPortfolio ? 'Multi-Property' : ''} {saleDateText}
					</SaleDate>
					{!data.own && (
						<StyledUnlockButton
							data={data}
							hasEnoughCredits={hasEnoughCredits}
						/>
					)}
				</SaleDateContainer>
				<KeyInfoItems>
					<TotalSaleValue
						title={keyInfo['totalSalePrice'] ?? ''}
						value={formatKeyInfo(
							'totalSalePrice',
							data['totalSalePrice'],
							data.own,
							data.locale
						)}
					/>
					{Object.typedKeys(keyInfo)
						.slice(1)
						.map((key) => {
							const value =
								key === 'percentOccupied' ? data.portfolio[0][key] : data[key];
							return (
								<KeyInfoItem
									key={key}
									title={keyInfo[key] ?? ''}
									value={formatKeyInfo(key, value, data.own, data.locale)}
								/>
							);
						})}
				</KeyInfoItems>
				<KeyInfoItems>
					<KeyInfoItem
						title={showBuyer ? 'Buyer' : 'Recorded buyer'}
						value={
							showBuyer
								? formatKeyInfo('buyer', data.buyer, data.own)
								: formatKeyInfo('recordedBuyer', data.recordedBuyer, data.own)
						}
					/>
					<KeyInfoItem
						title={showSeller ? 'Seller' : 'Recorded seller'}
						value={
							showSeller
								? formatKeyInfo('seller', data.seller, data.own)
								: formatKeyInfo('recordedSeller', data.recordedSeller, data.own)
						}
					/>
				</KeyInfoItems>
			</KeyInfoContainer>
		);
	}
);

KeyInfo.displayName = 'KeyInfo';

const KeyInfoContainer = styled.div<{ isPortfolio?: boolean }>`
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	padding: 16px 24px 8px;

	/* // TODO: Put this color to DefaultTheme interface  */
	${({ isPortfolio }) =>
		isPortfolio &&
		`
		background-color: #FCF5FF;
	`};
`;

const IconPortfolioContainer = styled.div`
	display: flex;
	min-width: 56px;
	height: 56px;
	/* // TODO: add color to the ui-kit color palette  */
	background-color: #7f20c7;
	border-radius: 50%;
	margin-right: 16px;
`;
const StyledIconBuilding = styled(IconBuilding)`
	width: 32px;
	height: 32px;

	align-self: center;
	margin: auto;

	& path {
		fill: ${defaultTheme.colors.white.white};
	}
`;

const TotalSaleValue = styled(KeyInfoItem)`
	${Value} {
		font-size: 24px;
		line-height: 24px;
	}
`;
const SaleDate = styled.span`
	font-size: 32px;
	color: ${({ theme }) => theme.colors.gray.gray700};
	line-height: 40px;
	font-weight: 400;
`;

const SaleDateContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 32px;
	white-space: nowrap;
`;

const StyledUnlockButton = styled(UnlockButton)`
	height: 38px;
	margin-left: 40px;
	align-items: center;
	border-radius: 3px;
`;

const keyInfoCurrencyKeys = {
	totalSalePrice: 'Total Sale Price',
	salePricePsf: 'Sale Price ($/SF)',
	totalNetOperatingIncome: 'Total NOI',
};
const keyInfoPercentKeys = {
	capRate: 'Cap Rate',
	percentOccupied: 'Occupancy Rate',
};
const keyInfo = {
	...keyInfoCurrencyKeys,
	...keyInfoPercentKeys,
};

export function formatKeyInfo(
	key: CompKeys,
	value: string | number | string[] | null | undefined,
	own?: boolean,
	locale?: Locale
) {
	if (own === false && value === null)
		return <LockIconStyled width={14} height={16} hasDarkColor />;
	if ((!value && value !== 0) || (Array.isArray(value) && !value.length))
		return nullValueText;
	if (locale && typeof value === 'number') {
		const currency = locale === 'en_GB' ? '£' : '$';
		if (Object.keys(keyInfoCurrencyKeys).includes(key))
			return `${currency}${formatNumber(value.toFixed(2))}`;
	}
	if (Object.keys(keyInfoPercentKeys).includes(key) && Number.isInteger(value))
		return `${value}%`;
	return formatValue(key, value, false, 'sale', false, {}, locale);
}
