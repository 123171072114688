import { MAP_CONTROLS } from 'actions/search';
import { MapFilterV2Base } from './MapFilterV2Base';
import IconPolygonMapFilter from 'ui/svg_icons/map-polygon-icon.svg';

export const PolygonMapFilterV2 = () => {
	return (
		<MapFilterV2Base
			Icon={IconPolygonMapFilter}
			key={MAP_CONTROLS.POLYGON}
			label="Polygon Filter"
			mapControl={MAP_CONTROLS.POLYGON}
		/>
	);
};
