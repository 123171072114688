import { View, Image, Text } from '@react-pdf/renderer';
import { PdfPageProps } from '../types';
import { pdfStyle } from '../style';
import { TrendChartURL } from 'Pages/PropertyPageV3/ExportPdf/ExportPdfStepTwo';
import { PdfRealPageLogoView } from '../PdfRealPageLogoView';

export const MufaSubmarketTrends = (
	props: PdfPageProps & {
		trendChartsURL: TrendChartURL;
	}
) => {
	return (
		<View wrap>
			<Text style={pdfStyle.pageTitle} fixed>
				Multifamily Submarket Trends - {props.property.submarket}
			</Text>
			<View>
				{props.trendChartsURL.mufa1 && (
					<Image src={props.trendChartsURL.mufa1} style={pdfStyle.charts} />
				)}
				<PdfRealPageLogoView />
			</View>
			<View break>
				{props.trendChartsURL.mufa2 && (
					<Image src={props.trendChartsURL.mufa2} style={pdfStyle.charts} />
				)}
				<PdfRealPageLogoView />
			</View>
		</View>
	);
};
