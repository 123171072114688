import React, { Component, ReactElement } from 'react';

type Props = {
	value: string;
	name: string;
	children: ReactElement[];
	allowHorizontalScrolling?: boolean;
	inputClass?: string;
	isDate?: boolean;
	isLease?: boolean;
	label?: string;
	labelClass?: string;
	onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
	onChange?: (name: string, value: string) => void;
	onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
	placeholder?: string;
	postUnit?: string;
	required?: boolean;
	type?: string;
	unit?: string;
};

type State = {
	value: any; // TODO: What is the type of value?
};

export default class FormMultInput extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			value: props.value || {},
		};
	}

	// @ts-expect-error TS7006: Parameter 'name' implicitly ha...
	onChildInputChange(name, inputValue) {
		const { value } = this.state;

		value[name.split('.')[1]] = inputValue;
		this.setState({
			value,
		});

		if (this.props.onChange) {
			this.props.onChange(this.props.name, value);
		}
	}

	render() {
		let required;
		if (
			[
				'executionDate',
				'commencementDate',
				'leaseTerm',
				'expirationDate',
			].includes(this.props.name)
		) {
			required = '**';
		} else {
			required = this.props.required ? '*' : false;
		}
		return (
			<div className="form-multi-input">
				<div
					className={
						'label input_label' + (this.props.required ? ' required' : '')
					}
				>
					<label>
						{this.props.label} {required}{' '}
					</label>
				</div>
				<div>
					{this.props.children.map((child) => {
						const styles = {
							width: (100 / this.props.children.length).toFixed(2) + '%',
						};
						return (
							<div
								key={child.props.name}
								style={styles}
								className="form-multi-input-item"
							>
								{React.cloneElement(child, {
									onChange: this.onChildInputChange.bind(this),
								})}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}
