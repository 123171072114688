import { Component, ChangeEvent } from 'react';
import LeaseUploadForm from './LeaseUploadForm';
import SaleUploadForm from './SaleUploadForm';
import '../styles/uploadToggle.nomodule.less';
import CrossMedium from '../../../ui/svg_icons/cross_medium.svg';
import { hideUploadForm } from '../actions';
import { UploadPageRouteProps, UploadPageSection } from '../types';
import { useAppDispatch } from 'util/useAppDispatch';
import { useUserIsSalesTrader } from 'hooks/userHooks';
import { useNavigate } from 'router';
import { LeaseUploadFormV2 } from './LeaseUploadFormV2';
import { SaleUploadFormV2 } from './SaleUploadFormV2';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import {
	useShowUploadSuccessModal,
	ShowUploadSuccessModal,
} from 'Components/Modals/UploadSuccessModal/UploadSuccessModal';

type Props = {
	salesTrader: boolean;
	hideUploadForm: () => void;
	navigate: ReturnType<typeof useNavigate>;
	v2: boolean;
	showUploadSuccessModal: ShowUploadSuccessModal;
} & UploadPageRouteProps;

type State = { selectedRadio: UploadPageSection };

class UploadFormsContainer extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			selectedRadio:
				props.salesTrader || props.params.section === 'sale' ? 'sale' : 'lease',
		};
	}

	componentDidMount(): void {
		this.props.navigate(`/upload/${this.state.selectedRadio}`, {
			replace: true,
		});
	}

	handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		if (value !== 'lease' && value !== 'sale') {
			return;
		}
		this.setState({
			selectedRadio: value,
		});

		this.props.navigate(`/upload/${value}`, { replace: true });
	};

	renderSwitch = () => {
		if (this.props.salesTrader) {
			return null;
		}

		return (
			<div>
				<div
					style={{
						textAlign: 'center',
						fontFamily: 'gotham',
						paddingTop: 20,
						paddingBottom: 10,
					}}
				>
					Select one:
				</div>
				<div className="toggle-switch">
					<input
						type="radio"
						value="lease"
						id="lease"
						data-qa-id="lease-comp-radio-option"
						checked={this.state.selectedRadio === 'lease'}
						onChange={this.handleChange}
					/>
					<label htmlFor="lease">LEASE COMP</label>
					<input
						type="radio"
						value="sale"
						id="sale"
						data-qa-id="sales-comp-radio-option"
						checked={this.state.selectedRadio === 'sale'}
						onChange={this.handleChange}
					/>
					<label htmlFor="sale">SALES COMP</label>
				</div>
			</div>
		);
	};

	renderForm = (): JSX.Element => {
		const { v2 } = this.props;
		const shouldShowLeaseForm = this.state.selectedRadio === 'lease';
		if (shouldShowLeaseForm) {
			if (v2) {
				return <LeaseUploadFormV2 />;
			}
			return (
				<LeaseUploadForm
					showUploadSuccessModal={this.props.showUploadSuccessModal}
				/>
			);
		}
		if (v2) {
			return <SaleUploadFormV2 />;
		}
		return (
			<SaleUploadForm
				showUploadSuccessModal={this.props.showUploadSuccessModal}
			/>
		);
	};

	hideForm = () => this.props.hideUploadForm();

	render() {
		const { v2 } = this.props;
		return (
			<div
				className={'upload-form-container' + (v2 ? ' flex-upload-form' : '')}
			>
				<div className="upload-form-header">
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
					<div onClick={this.hideForm} className="button_button button_blue">
						Upload Files Instead
					</div>

					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
					<div onClick={this.hideForm} className="upload-form-close">
						<CrossMedium />
					</div>
				</div>
				{this.renderSwitch()}
				{this.renderForm()}
			</div>
		);
	}
}

export default function UploadFormsContainerWrapped(
	props: UploadPageRouteProps
) {
	const showUploadSuccessModal = useShowUploadSuccessModal();
	const dispatch = useAppDispatch();
	const salesTrader = useUserIsSalesTrader();
	const navigate = useNavigate();
	const { ffUploadCompPageRevamp24Q3 } = useFeatureFlags();
	return (
		<UploadFormsContainer
			params={props.params}
			salesTrader={salesTrader}
			hideUploadForm={() => dispatch(hideUploadForm())}
			navigate={navigate}
			v2={ffUploadCompPageRevamp24Q3}
			showUploadSuccessModal={showUploadSuccessModal}
		/>
	);
}
