import { CheckboxProps } from '@compstak/ui-kit';
import { Checkbox } from 'Components/Checkbox';
import { PropertyLease } from 'api';
import { useIsExchange } from 'hooks';
import { useId, useMemo } from 'react';
import styled from 'styled-components';
import { LeaseComp } from 'types';
import { RadioButtonControl } from './RadioButtonControl';
import { FormControl, FormControlLabel } from './UI';
import { getLeasesByStatus } from './getLeasesByStatus';
import { LeaseStatus } from './types';
import { UseLeaseOptionsStateReturn } from './useLeaseOptionsState';

type Props = {
	leaseOptionsState: UseLeaseOptionsStateReturn;
	text?: string;
	radioName: string;
	leases: LeaseComp[] | PropertyLease[];
	disabled?: boolean;
};

export const LeaseOptions = ({
	leaseOptionsState,
	radioName,
	leases,
	disabled = false,
}: Props) => {
	const isExchange = useIsExchange();

	const { leaseStatus, setLeaseStatus, leaseSpaceTypes, setLeaseSpaceTypes } =
		leaseOptionsState;

	const onChangeLeaseStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLeaseStatus(e.target.value as LeaseStatus);
	};

	const numberOfActiveLeases = useMemo(() => {
		return getLeasesByStatus(LeaseStatus.ACTIVE, leases).length;
	}, [leases]);

	return (
		<Grid>
			<div>
				<SubsectionLabel>Status:</SubsectionLabel>
				<StatusContainer>
					<RadioButtonControl
						label={isExchange ? 'All Unlocked Leases' : 'All Leases'}
						name={radioName}
						value={LeaseStatus.ALL}
						checked={leaseStatus === LeaseStatus.ALL}
						onChange={onChangeLeaseStatus}
						disabled={disabled}
					/>
					{numberOfActiveLeases > 0 && (
						<RadioButtonControl
							label={isExchange ? 'Active Unlocked Leases' : 'Active Leases'}
							name={radioName}
							value={LeaseStatus.ACTIVE}
							checked={leaseStatus === LeaseStatus.ACTIVE}
							onChange={onChangeLeaseStatus}
							disabled={disabled}
						/>
					)}
				</StatusContainer>
			</div>
			<div>
				<SubsectionLabel>Lease Space Type(s):</SubsectionLabel>
				<SpaceTypeGrid>
					{Object.keys(leaseSpaceTypes).map((spaceType) => {
						return (
							<SpaceTypeCheckboxControl
								key={spaceType}
								label={spaceType}
								checked={leaseSpaceTypes[spaceType]}
								onCheckedChange={(checked) =>
									setLeaseSpaceTypes((s) => ({
										...s,
										[spaceType]: !!checked,
									}))
								}
								disabled={disabled}
							/>
						);
					})}
				</SpaceTypeGrid>
			</div>
		</Grid>
	);
};

const SpaceTypeCheckboxControl = ({
	label,
	...props
}: { label: string } & CheckboxProps) => {
	const id = useId();

	return (
		<FormControl style={{ alignItems: 'center' }}>
			<Checkbox {...props} id={id} />
			<FormControlLabel htmlFor={id}>{label}</FormControlLabel>
		</FormControl>
	);
};

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 1rem;
`;

const StatusContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const SpaceTypeGrid = styled(Grid)`
	gap: 1rem;
`;

const SubsectionLabel = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 0.75rem;
	font-weight: 300;
	line-height: 1;
	margin-bottom: 0.75rem;
`;
