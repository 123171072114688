import { useState } from 'react';
import { nullValueText } from '@compstak/common';
import {
	MajorTenant,
	PropertyLeaseSummary,
	usePropertyLeasesSummaryQuery,
} from 'api';
import { MufaPropertyComp, PropertyComp } from 'types';
import {
	TOP_TENANTS_NUMBER,
	getShouldHideActiveLeasesTotalSF,
} from 'Pages/PropertyPageV3/PropertyLeaseSnapshot';
import {
	formatCsv,
	formatMoney,
	formatPercent,
	formatSqft,
	getRentPeriodPostfix,
} from 'format';
import { calendarUtils } from '@compstak/ui-kit';
import { getIsMonthlyMarket } from 'utils';

type Props = {
	compsetSubjectProperty: PropertyComp | MufaPropertyComp;
	leasesSummary: ReturnType<typeof usePropertyLeasesSummaryQuery>['data'];
};

export const useEditableLeaseSnapshot = ({
	compsetSubjectProperty,
	leasesSummary,
}: Props) => {
	const {
		marketId,
		buildingSize,
		propertyLeasesExpiringInTwelveMonths,
		propertyLeasesExpiringInFiveYears,
		propertySqFtExpiringInTwelveMonths,
		propertySqFtExpiringInFiveYears,
	} = compsetSubjectProperty;

	const [editableLeaseSnapshotFields, setEditableLeaseSnapshotFields] =
		useState<EditableLeaseSnapshot>({
			visibleFields: {
				...editableLeaseSnapshotFieldKeys.reduce(
					(acc, key) => ({
						...acc,
						[key]: true,
					}),
					{} as LeaseSnapshotVisibleFields
				),
			},
			showLeaseSnapshot: !!leasesSummary?.activeLeaseCount,
			activeLeaseCount: getPropertyLeasesExpiringIn(
				leasesSummary?.activeLeaseCount,
				leasesSummary?.activeLeaseTotalSqFt,
				buildingSize,
				false
			),
			avgLeaseTerm: leasesSummary?.avgLeaseTerm
				? calendarUtils.getHumanizeDuration(
						leasesSummary.avgLeaseTerm,
						'months'
					)
				: nullValueText,
			avgCurrentRent: getAvgInPlaceRent(
				marketId,
				leasesSummary?.avgCurrentRent
			),
			propertyLeasesExpiringInTwelveMonths: getPropertyLeasesExpiringIn(
				propertyLeasesExpiringInTwelveMonths,
				propertySqFtExpiringInTwelveMonths,
				buildingSize,
				true
			),
			propertyLeasesExpiringInFiveYears: getPropertyLeasesExpiringIn(
				propertyLeasesExpiringInFiveYears,
				propertySqFtExpiringInFiveYears,
				buildingSize,
				true
			),
			tenants: getTopTenants(leasesSummary?.tenants),
		});

	return {
		editableLeaseSnapshotFields,
		setEditableLeaseSnapshotFields,
	};
};

type LeaseSnapshotVisibleFields = Record<EditableLeaseSnapshotField, boolean>;

export type EditableLeaseSnapshot = {
	visibleFields: LeaseSnapshotVisibleFields;
	showLeaseSnapshot: boolean;
} & Record<EditableLeaseSnapshotField, string | number>;
type LeaseSnapshotItem = number | undefined | null;

const getPropertyLeasesExpiringIn = (
	leasesCount: LeaseSnapshotItem,
	leasesSize: LeaseSnapshotItem,
	propertySize: number | undefined,
	showPercentage: boolean
) => {
	if (!leasesCount) return `0 Leases • 0 SF • (0% of total building)`;

	const hideSqft = getShouldHideActiveLeasesTotalSF(leasesSize, propertySize);
	const activeLeasesCount =
		leasesCount === 1 ? '1 Lease' : `${leasesCount} Leases`;

	if (hideSqft) return activeLeasesCount;

	const activeLeasesSize = leasesSize && formatSqft(leasesSize);
	const percentage =
		leasesSize && propertySize
			? ` • (${formatPercent(leasesSize / propertySize)} of total building)`
			: '';

	return `${activeLeasesCount}${activeLeasesSize ? ` • ${activeLeasesSize}${showPercentage ? percentage : ''}` : ''}`;
};

const getAvgInPlaceRent = (marketId: number, rent: LeaseSnapshotItem) => {
	if (!rent) return nullValueText;
	const isMonthlyMarket = getIsMonthlyMarket(marketId);
	const avgInPlaceRent = isMonthlyMarket ? rent / 12 : rent;
	const formattedRent = `${formatMoney(avgInPlaceRent)}${getRentPeriodPostfix(isMonthlyMarket)} PSF`;
	return formattedRent;
};

const getTopTenants = (tenants: MajorTenant) => {
	const topTenants = tenants ? tenants.slice(0, TOP_TENANTS_NUMBER) : '';
	return topTenants && topTenants.length
		? formatCsv(topTenants.map((t) => t.name))
		: nullValueText;
};

export type EditableLeaseSnapshotField = keyof (Pick<
	PropertyComp,
	'propertyLeasesExpiringInTwelveMonths' | 'propertyLeasesExpiringInFiveYears'
> &
	Pick<
		PropertyLeaseSummary,
		'activeLeaseCount' | 'avgLeaseTerm' | 'tenants' | 'avgCurrentRent'
	>);

export const editableLeaseSnapshotFieldKeys: EditableLeaseSnapshotField[] = [
	'activeLeaseCount',
	'avgLeaseTerm',
	'avgCurrentRent',
	'propertyLeasesExpiringInTwelveMonths',
	'propertyLeasesExpiringInFiveYears',
	'tenants',
];
