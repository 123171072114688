import { Button } from '@compstak/ui-kit';
import { usePortfolioByIdQuery, useUpdatePortfolioMutation } from 'api';
import { formatInteger } from 'format';
import styled from 'styled-components';
import { invalidatePortfolioQueries } from '../invalidatePortfolioQueries';
import IconMinusCircle from '../styles/svg_icons/minus-circle.svg';
import { usePropertiesSelectionContext } from './PropertiesSelectionProvider';

type Props = {
	portfolioId: number;
};

export const RemovePropertiesButton = ({ portfolioId }: Props) => {
	const { selection, resetSelection } = usePropertiesSelectionContext();
	const { data: portfolio } = usePortfolioByIdQuery({
		id: portfolioId,
	});
	const { mutate: updatePortfolio, isLoading: isLoadingUpdatePortfolio } =
		useUpdatePortfolioMutation({
			onSuccess: ({ queryClient }) => {
				resetSelection();
				invalidatePortfolioQueries(queryClient);
			},
		});

	return (
		<StyledButton
			size="l"
			icon={<IconMinusCircle width={14} height={14} />}
			disabled={
				!portfolio || selection.length === 0 || isLoadingUpdatePortfolio
			}
			onClick={() => {
				updatePortfolio({
					id: portfolioId,
					body: {
						id: portfolioId,
						name: portfolio!.name,
						description: portfolio!.description,
						removedPropertyIds: selection,
					},
				});
			}}
		>
			Remove ({formatInteger(selection.length)})
		</StyledButton>
	);
};

export const StyledButton = styled(Button)`
	background-color: ${(p) => p.theme.colors.neutral.n300};
	gap: 0.5rem;
	min-width: 160px;
	&:not(:disabled):hover {
		background-color: ${(p) => p.theme.colors.neutral.n100};
	}
`;
