import React, {
	useEffect,
	useState,
	MouseEvent,
	ChangeEvent,
	useMemo,
} from 'react';
import { User } from '@compstak/common';
import {
	FormSelect,
	SelectOnChangeParams,
	defaultTheme,
} from '@compstak/ui-kit';

import button from 'ui/styles/button.less';
import settings from '../styles/settings.less';
import { MarketsState, useAppConfig } from 'Pages/Login/reducers';
import styled from 'styled-components';
import produce from 'immer';

import { getDisabledTooltip } from './DisabledTooltip';
import { useDispatch } from 'react-redux';
import { updateUserInfo } from 'actions/user';
import { ReferenceData } from 'api/referenceData/useReferenceDataQuery';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type Props = {
	markets: MarketsState;
	user: User;
	referenceData: ReferenceData;
};

const EMPTY_OPTION_VALUE = 'EMPTY_OPTION_VALUE';
const getEmptyOption = (title: string) => ({
	title,
	value: EMPTY_OPTION_VALUE,
});

const AccountSettings: React.FC<Props> = (props) => {
	const { isExchange, isEnterprise } = useAppConfig();
	const [formData, setFormData] = useState(getFormDataFromUser(props.user));
	const dispatch = useDispatch();

	const {
		username,
		email,
		firstName,
		lastName,
		hierarchyLevelId,
		industryIds,
		jobId,
		officeMarketId,
		propertyTypeIds,
		specializationIds,
	} = formData;

	if (['liza@compstak.com', 'matija.milicevic@compstak.com'].includes(email)) {
		// TODO Matija: Remove this if block used for testing purposes once testing is over.
		// TODO: https://compstak.atlassian.net/browse/AP-16743
		// This is a temporary error case to test the error boundary component.
		throw Error('Test error boundary');
	}

	const flags = useFeatureFlags();

	const isValidationError = !flags['exchange-dashboard']
		? !lastName || !firstName
		: isExchange &&
		  (!jobId ||
				!lastName ||
				!firstName ||
				!officeMarketId ||
				!hierarchyLevelId ||
				!industryIds?.length ||
				!propertyTypeIds?.length ||
				!specializationIds?.length);

	useEffect(() => {
		setFormData(getFormDataFromUser(props.user));
	}, [props.user]);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	};

	const handleCancel = () => {
		setFormData(getFormDataFromUser(props.user));
	};

	const handleUserInfoUpdate = (ev: MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();
		if (email && firstName && lastName) {
			const updateUserRequest = formData;
			//@ts-expect-error  Types of property 'officeMarketId' are incompatible
			dispatch(updateUserInfo(props.user, updateUserRequest));
		}
	};

	const handleMarketSelection = ({ selectedItem }: SelectOnChangeParams) => {
		// @ts-expect-error `officeMarketId` being null
		setFormData((prevState) => ({
			...prevState,
			officeMarketId:
				selectedItem?.value === EMPTY_OPTION_VALUE
					? null
					: Number(selectedItem?.value),
		}));
	};

	const handleJobTypeSelection = ({ selectedItem }: SelectOnChangeParams) => {
		setFormData((prevState) => ({
			...prevState,
			jobId:
				selectedItem?.value === EMPTY_OPTION_VALUE
					? null
					: Number(selectedItem?.value),
		}));
	};

	const handleHierarchySelection = ({ selectedItem }: SelectOnChangeParams) => {
		// @ts-expect-error `hierarchyLevelId` being null
		setFormData((prevState) => ({
			...prevState,
			hierarchyLevelId:
				selectedItem?.value === EMPTY_OPTION_VALUE
					? null
					: Number(selectedItem?.value),
		}));
	};

	const handleSpecializationSelection = (specializationId: number) => {
		setFormData((prevState) => {
			return produce(prevState, (draftState) => {
				if (draftState.specializationIds == null) {
					draftState.specializationIds = [];
				}

				if (draftState.specializationIds.includes(specializationId)) {
					draftState.specializationIds.splice(
						draftState.specializationIds.findIndex(
							(id) => id === specializationId
						),
						1
					);
				} else {
					draftState.specializationIds.push(specializationId);
				}
			});
		});
	};

	const handleIndustrySelection = (industryId: number) => {
		setFormData((prevState) => {
			return produce(prevState, (draftState) => {
				if (draftState.industryIds == null) {
					draftState.industryIds = [];
				}
				if (draftState.industryIds.includes(industryId)) {
					draftState.industryIds.splice(
						draftState.industryIds.findIndex((id) => id === industryId),
						1
					);
				} else {
					draftState.industryIds.push(industryId);
				}
			});
		});
	};

	const handlePropertyTypeSelection = (propertyTypeId: number) => {
		setFormData((prevState) => {
			return produce(prevState, (draftState) => {
				if (draftState.propertyTypeIds == null) {
					draftState.propertyTypeIds = [];
				}
				if (draftState.propertyTypeIds.includes(propertyTypeId)) {
					draftState.propertyTypeIds.splice(
						draftState.propertyTypeIds.findIndex((id) => id === propertyTypeId),
						1
					);
				} else {
					draftState.propertyTypeIds.push(propertyTypeId);
				}
			});
		});
	};

	const handleFocusSelection = (focusId: number) => {
		setFormData((prevState) => {
			return produce(prevState, (draftState) => {
				if (draftState.focusIds == null) {
					draftState.focusIds = [];
				}
				if (draftState.focusIds.includes(focusId)) {
					draftState.focusIds.splice(
						draftState.focusIds.findIndex((id) => id === focusId),
						1
					);
				} else {
					draftState.focusIds.push(focusId);
				}
			});
		});
	};

	const officeLocationOptions = useMemo(
		() => [
			getEmptyOption('Select Office Location'),
			...props.markets.list.map((market) => ({
				title: market.displayName,
				value: market.id,
			})),
		],
		[props.markets]
	);

	const titleOptions = useMemo(() => {
		if (isEnterprise || !props.referenceData.hierarchyLevels) {
			return [];
		}

		return [
			getEmptyOption('Select Role'),
			...(props.referenceData.hierarchyLevels.map((hierarchy) => ({
				title: hierarchy.name,
				value: hierarchy.id,
			})) ?? []),
		];
	}, [isEnterprise, props.referenceData]);

	const roleOptions = useMemo(() => {
		if (isEnterprise || !props.referenceData.jobTypes) {
			return [];
		}

		return [
			getEmptyOption('Select Role'),
			...(props.referenceData.jobTypes.map((jobType) => ({
				title: jobType.name,
				value: jobType.id,
			})) ?? []),
		];
	}, [isEnterprise, props.referenceData]);

	return (
		<div className={`settings_content ${settings.contentGrid}`}>
			<div className={`form ${settings.form}`}>
				<form>
					<div className="form_section">
						<div>
							<FormHeading name="Username" required={isExchange} />
							<input
								type="text"
								name="username"
								value={username}
								placeholder="Username"
								data-tooltip={getDisabledTooltip('username')}
								data-tooltip-position="above-onleft"
								data-tooltip-class="account-settings"
								data-tooltipdelay="0"
								disabled
							/>
						</div>

						<div>
							<FormHeading name="Email" required={isExchange} />
							<input
								type="text"
								name="email"
								value={email}
								placeholder="Email"
								onChange={handleChange}
								data-tooltip={getDisabledTooltip('email')}
								data-tooltip-position="above-onleft"
								data-tooltip-class="account-settings"
								data-tooltipdelay="0"
								disabled
							/>
						</div>

						<div>
							<FormHeading name="First Name" required={isExchange} />
							<input
								type="text"
								name="firstName"
								value={firstName}
								placeholder="First name"
								onChange={handleChange}
							/>
						</div>
						<div>
							<FormHeading name="Last Name" required={isExchange} />
							<input
								type="text"
								name="lastName"
								value={lastName}
								placeholder="Last name"
								onChange={handleChange}
							/>
						</div>
					</div>
					{flags['exchange-dashboard'] && (
						<>
							<div className="form_section">
								<div>
									<FormHeading name="Office Location" required={isExchange} />
									<FormSelect
										placeholder="Select Office Location"
										items={officeLocationOptions}
										onChange={handleMarketSelection}
										value={officeMarketId ?? undefined}
										isSearchable
									/>
								</div>

								{isExchange && (
									<div>
										<FormHeading name="Role" required={isExchange} />
										<FormSelect
											items={roleOptions}
											value={jobId ?? undefined}
											onChange={handleJobTypeSelection}
											placeholder="Select Role"
										/>
									</div>
								)}

								{isExchange && (
									<div>
										<FormHeading name="Title" required={isExchange} />
										<FormSelect
											items={titleOptions}
											value={hierarchyLevelId ?? undefined}
											onChange={handleHierarchySelection}
											placeholder="Select Role"
										/>
									</div>
								)}

								{isEnterprise && (
									<div>
										<FormHeading name="Focus" />

										<CheckboxList>
											{props.referenceData?.focuses?.map((focus) => {
												return (
													<CheckboxListItem key={focus.id}>
														<input
															type="checkbox"
															id={`focus-${focus.name}`}
															name={`focus-${focus.name}`}
															className="checkbox"
															checked={formData?.focusIds?.includes(focus.id)}
															value={focus.id}
															onChange={() => handleFocusSelection(focus.id)}
														/>
														<label htmlFor={`focus-${focus.name}`}>
															{focus.name}
														</label>
													</CheckboxListItem>
												);
											})}
										</CheckboxList>
									</div>
								)}
							</div>
							<div className="form_section">
								{isExchange && (
									<div>
										<FormHeading name="Specialty" required={isExchange} />
										<CheckboxList>
											{props.referenceData?.specializations?.map(
												(specialization) => {
													return (
														<CheckboxListItem key={specialization.id}>
															<input
																type="checkbox"
																id={`specialization-${specialization.name}`}
																name={`specialization-${specialization.name}`}
																className="checkbox"
																checked={formData?.specializationIds?.includes(
																	specialization.id
																)}
																value={specialization.id}
																onChange={() =>
																	handleSpecializationSelection(
																		specialization.id
																	)
																}
															/>
															<label
																htmlFor={`specialization-${specialization.name}`}
															>
																{specialization.name}
															</label>
														</CheckboxListItem>
													);
												}
											)}
										</CheckboxList>
									</div>
								)}

								<div>
									<FormHeading
										name="Property Type Preferences"
										required={isExchange}
									/>
									<CheckboxList>
										{props.referenceData.propertyTypes.map((propertyType) => {
											return (
												<CheckboxListItem key={propertyType.id}>
													<input
														type="checkbox"
														id={`property-type-${propertyType.id}`}
														name={`property-type-${propertyType.id}`}
														className="checkbox"
														checked={formData?.propertyTypeIds?.includes(
															propertyType.id
														)}
														onChange={() =>
															handlePropertyTypeSelection(propertyType.id)
														}
													/>
													<label htmlFor={`property-type-${propertyType.id}`}>
														{propertyType.name}
													</label>
												</CheckboxListItem>
											);
										})}
									</CheckboxList>
								</div>
								<div>
									<FormHeading
										name="Industry Preferences"
										required={isExchange}
									/>
									<CheckboxList>
										{props.referenceData.tenantIndustries.map((industry) => {
											return (
												<CheckboxListItem key={industry.id}>
													<input
														type="checkbox"
														className="checkbox"
														checked={formData?.industryIds?.includes(
															industry.id
														)}
														id={`industry-type-${industry.id}`}
														name={`industry-type-${industry.id}`}
														onChange={() =>
															handleIndustrySelection(industry.id)
														}
													/>
													<label htmlFor={`industry-type-${industry.id}`}>
														{industry.name}
													</label>
												</CheckboxListItem>
											);
										})}
									</CheckboxList>
								</div>
							</div>
						</>
					)}

					<div className="form_section">
						<a
							className={`${button.large} ${button.liteBlue} cancel`}
							onClick={handleCancel}
						>
							<span>Cancel</span>
						</a>
						<button
							disabled={isValidationError}
							className={`${button.large} ${button.blue} save`}
							onClick={handleUserInfoUpdate}
						>
							<span>Save</span>
						</button>
						{isValidationError && (
							<ValidationError>
								Please fill in all information to save profile.
							</ValidationError>
						)}
					</div>
				</form>
			</div>
			<div className={`explanation ${settings.explanation}`}>
				<h1>Account</h1>
			</div>
		</div>
	);
};

export default AccountSettings;

function getFormDataFromUser(user: User) {
	return {
		username: user.username,
		email: user.email,
		firstName: user.firstName,
		lastName: user.lastName,
		focusIds: user.focusIds,
		title: user.title,
		hierarchyLevelId: user.hierarchyLevelId,
		industryIds: user.industryIds,
		jobId: user.preferences.jobId,
		officeMarketId: user.officeMarketId,
		propertyTypeIds: user.propertyTypeIds,
		specializationIds: user.specializationIds,
	};
}

const FormHeadingInner = styled.h5`
	font-size: 1rem;
`;

const CheckboxList = styled.ul`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const CheckboxListItem = styled.li`
	min-width: 50%;
`;

const ValidationError = styled.p`
	margin-top: 5px;
	font-size: 16px;
	color: ${defaultTheme.colors.red.red500};
`;

const RedAsterisk = styled.span`
	color: red;
	font-size: 16px;
`;

const FormHeading = ({
	name,
	required,
}: {
	name: string;
	required?: boolean;
}) => (
	<FormHeadingInner>
		{name} {required && <RedAsterisk>*</RedAsterisk>}
	</FormHeadingInner>
);
