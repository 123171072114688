import { SearchTable, SearchTableExportButton } from 'Components';
import { routes } from 'router';
import { RouterLocation } from 'types';
import { useLeasesSelectionContext } from './LeasesSelectionProvider';
import { useListViewLeases } from './useListViewLeases';

type Props = {
	portfolioId: number;
	location: RouterLocation;
};

export const PortfolioTableLeases = ({ portfolioId, location }: Props) => {
	const { data: leases, isLoading } = useListViewLeases({
		portfolioId,
		location,
	});

	const totalCount = leases?.length ?? 0;

	const {
		getIsRowSelected,
		toggleRow,
		areAllSelected,
		toggleAllSelected,
		areSomeSelected,
	} = useLeasesSelectionContext();

	return (
		<SearchTable
			rows={leases ?? []}
			getRowId={(row) => row.id}
			isLoading={isLoading}
			noData={!isLoading && totalCount === 0}
			columnPreferencesType="lease"
			getIsRowSelected={(row) => getIsRowSelected(row.id)}
			areAllSelected={areAllSelected}
			areSomeSelected={areSomeSelected}
			toggleRowSelection={(row) => toggleRow(row.id)}
			toggleAllRowsSelection={toggleAllSelected}
			renderActionButton={(row) => (
				<SearchTableExportButton compType="lease" row={row} />
			)}
			getBodyCellProps={(row) => ({
				to: routes.leaseById.toHref({ id: row.id }),
			})}
		/>
	);
};
