import { SimpleTable } from '@compstak/ui-kit';
import { useIsExchange } from 'hooks';
import { memo, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { PortfolioItem } from 'types/comp';
import { useAppSelector } from 'util/useAppSelector';
import {
	SeeAllLink,
	TabContentContainer,
	TabContentHeader,
	TabContentTitle,
} from '../../Components';
import { useCompAddressesSearchUrl } from '../../utils';
import { useColumnsPortfolio } from './columnsPortfolio';
import {
	useBodyCellProps,
	useCurrentlyActiveLeases,
	useLeasesActiveAtTimeOfSale,
	useLeaseSnapshotData,
} from './hooks';
import { useMarkets } from 'hooks/useMarkets';

type Props = {
	portfolio: PortfolioItem[];
	market: string;
	saleDate: string | undefined;
};

export const LeaseSnapshotPortfolio = memo<Props>(
	({ portfolio, market, saleDate }) => {
		const addresses = portfolio.map((p) => {
			return p.buildingAddressAndCity;
		});

		const markets = useMarkets();

		const {
			data: leaseSnapshotData,
			isLoading,
			refetch,
		} = useLeaseSnapshotData({
			addresses,
			market,
			sortBy: 'transactionSize',
		});

		const leasesActiveAtTimeOfSale = useLeasesActiveAtTimeOfSale({
			leaseSnapshotData,
			saleDate,
		}).slice(0, 10);

		const top10CurrentlyActiveLeases = useCurrentlyActiveLeases(
			leaseSnapshotData?.comps ?? []
		).slice(0, 10);

		const leasesSearchUrl = useCompAddressesSearchUrl({
			compType: 'lease',
			addresses,
			market: markets[market],
		});

		const seeAllLeasesLink = useMemo(() => {
			if (!leaseSnapshotData?.comps?.length) return null;
			return (
				<SeeAllLink to={leasesSearchUrl} data-qa-id="SeeAllSalesLink">
					view all leases
				</SeeAllLink>
			);
		}, [leaseSnapshotData, leasesSearchUrl]);

		const unlockedComps = useAppSelector((s) => s.unlockV2.unlockedComps);

		useEffect(() => {
			if (Object.keys(unlockedComps).length > 0) {
				refetch();
			}
		}, [unlockedComps]);

		const bodyCellProps = useBodyCellProps();
		const isExchange = useIsExchange();

		const unlockedInTitle = isExchange ? 'Unlocked' : '';

		const columns = useColumnsPortfolio();

		const commonProps = {
			isLoading,
			columns,
			scrollContainerProps: {
				style: {
					maxHeight: 400,
				},
			},
			isBodyHoverable: true,
			getBodyCellProps: bodyCellProps,
		};

		return (
			<div>
				<TabContentContainer>
					<TabContentHeader>
						<TabContentTitle>
							Top 10 {unlockedInTitle} Leases at Time of Sale
						</TabContentTitle>
						{seeAllLeasesLink}
					</TabContentHeader>
					<SimpleTable
						id="activeLeasesAtTimeOfSaleTable"
						rows={leasesActiveAtTimeOfSale}
						{...commonProps}
					/>
				</TabContentContainer>
				<StyledTabContentContainer>
					<TabContentHeader>
						<TabContentTitle>Top 10 Currently Active Leases</TabContentTitle>
						{seeAllLeasesLink}
					</TabContentHeader>
					<SimpleTable
						id="currentlyActiveLeasesTable"
						rows={top10CurrentlyActiveLeases}
						{...commonProps}
					/>
				</StyledTabContentContainer>
				{isExchange && (
					<StyledTabContentContainer>
						<TabContentHeader>
							<TabContentTitle>All Leases</TabContentTitle>
							{seeAllLeasesLink}
						</TabContentHeader>
						<SimpleTable
							id="allLeasesTable"
							rows={leaseSnapshotData?.comps ?? []}
							{...commonProps}
						/>
					</StyledTabContentContainer>
				)}
			</div>
		);
	}
);

LeaseSnapshotPortfolio.displayName = 'LeaseSnapshotPortfolio';

const StyledTabContentContainer = styled(TabContentContainer)`
	margin-top: 8px;
	margin-bottom: 32px;
`;
