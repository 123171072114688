import { UseQueryOptions } from '@tanstack/react-query';
import {
	createFetchFn,
	QUERY_KEYS,
	createFiltersParamWithMarketId,
	useQueryHook,
	ExchangeDashboardServerFilterItem,
} from 'api';
import { FiltersObject } from 'models/filters/types';
import { useMemo } from 'react';

type LargeTenantsResponse = {
	largestTenants: {
		tenant: string;
		sqft: number;
	}[];
	averageSqft: number;
};

type LargeTenantsParamsArg = {
	filters: FiltersObject;
};

type LargeLandlordsParams = {
	filters: ExchangeDashboardServerFilterItem[];
};

const fetchLargestTenants = createFetchFn<
	LargeLandlordsParams,
	LargeTenantsResponse
>({
	method: 'post',
	getURL: () => '/api/dashboard/topLargestTenants',
	getBody: (params) => ({ filters: params.filters }),
});

export type UseLargestTenantsOptions = UseQueryOptions<LargeTenantsResponse>;

export const useFetchLargestTenantsQuery = (
	params: LargeTenantsParamsArg,
	options?: UseLargestTenantsOptions
) => {
	const { filters: paramsFilters } = params;

	const filters = useMemo(() => {
		return createFiltersParamWithMarketId(paramsFilters);
	}, [paramsFilters]);

	return useQueryHook({
		queryKey: [QUERY_KEYS.LARGEST_TENANTS, filters],
		queryFn: fetchLargestTenants({
			filters,
		}),
		...options,
	});
};
