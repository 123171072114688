import { Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQuery } from 'api';
import { PortfolioHelpIcon } from 'PortfolioAnalytics/Singletons/PortfolioHelpIcon';
import { useRef } from 'react';
import styled from 'styled-components';
import { usePortfolioFilters } from '../../PortfolioFiltersProvider';
import { PortfolioMap } from '../../PortfolioMap';
import { DownloadBranding } from '../../Singletons/DownloadBranding';
import DownloadButton from '../../Singletons/DownloadButton';
import { DashboardCardLoadingContainer } from '../UI';
import { Averages } from './Averages';
import { MainOverview } from './MainOverview';
import { Spacer } from '../../UI';

type Props = {
	portfolioId: number;
};

export const PortfolioOverview = ({ portfolioId }: Props) => {
	const mainRef = useRef<HTMLDivElement | null>(null);

	const { filters } = usePortfolioFilters();

	const { data: portfolio, isFetching } = usePortfolioByIdQuery({
		id: portfolioId,
		filters,
	});

	if (!portfolio || isFetching)
		return (
			<Root>
				<DashboardCardLoadingContainer>
					<Spinner />
				</DashboardCardLoadingContainer>
			</Root>
		);

	const title = `portfolio overview`;

	const downloadBannerId = `portfolio-${title.replaceAll(' ', '_')}`;

	return (
		<Root ref={mainRef}>
			<Heading>
				<TitleContainer>
					<Title>{title}</Title>
					<PortfolioHelpIcon tooltip={TOOLTIP_TEXT} />
				</TitleContainer>
				<DownloadButton
					downloadRef={mainRef}
					filename={title}
					bannerId={downloadBannerId}
				/>
			</Heading>
			<MainContent>
				<MainOverview portfolio={portfolio} />
				<PortfolioMap portfolioId={portfolioId} />
			</MainContent>
			<Averages portfolio={portfolio} />
			<DownloadBranding bannerId={downloadBannerId} />
		</Root>
	);
};

const TOOLTIP_TEXT = (
	<>
		<Spacer>
			The Portfolio Overview tool gives you insights and key statistics about
			your selected properties.
		</Spacer>
		<div>Metrics include only active leases from the selected properties.</div>
	</>
);

const Root = styled.div`
	background: ${(p) => p.theme.colors.white.white};
	border: 1px solid ${(p) => p.theme.colors.neutral.n20};
	border-radius: 0.125rem;
	padding: 1rem;
	margin: 0 1.5rem;
	margin-bottom: 1.5rem;
	* {
		box-sizing: border-box;
	}
`;

const Heading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
	flex-wrap: wrap;
	gap: 0.5rem;
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	max-width: 100%;
`;

const Title = styled.div`
	font-size: 1rem;
	font-weight: 500;
	text-transform: uppercase;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	min-width: 100px;
`;

const MainContent = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 400px;
	gap: 1rem;
	margin-bottom: 1rem;
	@media (min-width: 1536px) {
		grid-template-columns: 1fr 4fr;
		grid-template-rows: auto;
	}
`;
