import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Portfolio } from 'api';
import { useState } from 'react';
import styled from 'styled-components';
import IconChevronDown from '../../ui/svg_icons/arrow_bottom.svg';
import { CopyPortfolioDialog } from './CopyPortfolioDialog';
import { DeletePortfolioDialog } from './DeletePortfolioDialog';
import { PortfolioOptionsMenu } from './PortfolioOptionsMenu';
import { UpdatePortfolioDialog } from './UpdatePortfolioDialog';

type Props = {
	portfolio: Portfolio;
	dashboardElement?: HTMLElement | null;
};

export const PortfolioOptionsButton = ({
	portfolio,
	dashboardElement,
}: Props) => {
	const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
	const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	return (
		<>
			<DropdownMenu.Root>
				<DropdownMenu.Trigger asChild>
					<Button data-html2canvas-ignore>
						<IconChevronDown fill="#fff" />
						<div>Options</div>
					</Button>
				</DropdownMenu.Trigger>
				<PortfolioOptionsMenu
					portfolio={portfolio}
					dashboardElement={dashboardElement}
					openUpdateDialog={() => setIsUpdateDialogOpen(true)}
					openCopyDialog={() => setIsCopyDialogOpen(true)}
					openDeleteDialog={() => setIsDeleteDialogOpen(true)}
				/>
			</DropdownMenu.Root>
			{isUpdateDialogOpen && (
				<UpdatePortfolioDialog
					portfolio={portfolio}
					closeDialog={() => setIsUpdateDialogOpen(false)}
				/>
			)}
			{isCopyDialogOpen && (
				<CopyPortfolioDialog
					portfolio={portfolio}
					closeDialog={() => setIsCopyDialogOpen(false)}
				/>
			)}
			{isDeleteDialogOpen && (
				<DeletePortfolioDialog
					portfolioId={portfolio.id}
					closeDialog={() => setIsDeleteDialogOpen(false)}
				/>
			)}
		</>
	);
};

const Button = styled.button`
	all: unset;
	box-sizing: border-box;
	height: 42px;
	background-color: ${(p) => p.theme.colors.neutral.n300};
	color: ${(p) => p.theme.colors.white.white};
	padding: 0.5rem 1rem;
	font-size: 14px;
	font-weight: 300;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	text-transform: uppercase;
	border-radius: 0.125rem;
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
	cursor: pointer;
	&:hover,
	&:active {
		background-color: ${(p) => p.theme.colors.neutral.n100};
	}
`;
