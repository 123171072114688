import { DATE_FORMATS } from 'constants/dateFormats';
import dayjs from 'dayjs';
import {
	MarketTrendData,
	MarketTrendFilters,
} from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { FiltersState } from '../PortfolioFiltersProvider';

export type PortfolioMarketTrendsFetchProps = {
	portfolioId: number;
	marketTrendFilters: MarketTrendFilters;
	filters: FiltersState;
	buildingClassIds: number[];
	propertyTypeIds: number[];
};

export const getDateForTimeSpan = (years: number) =>
	dayjs().subtract(years, 'year').format(DATE_FORMATS['YYYY-MM-DD']);

export const formatMarketTrendFilters = ({
	portfolioId,
	marketTrendFilters,
	filters,
	buildingClassIds,
	propertyTypeIds,
}: PortfolioMarketTrendsFetchProps) => {
	const baseBody = {
		properties: [
			marketTrendFilters.metric,
			'executionDate',
			'transactionSize',
			'id',
		],
		fullDataThreshold: 50,
		quartileThreshold: 10e9,
		// quartileThreshold: 10000000000,
		trendMonths: marketTrendFilters.movAverage,
	};
	const baseFilter = [
		{
			property: 'buildingPropertyTypeId',
			value: propertyTypeIds.length ? propertyTypeIds : [-1],
		},
		{
			property: 'buildingClassId',
			value: buildingClassIds.length ? buildingClassIds : [-1],
		},
		{
			property: 'marketId',
			value: [marketTrendFilters.marketId],
		},
		{
			property: 'outlier',
			value: false,
		},
		{
			property: 'hidden',
			value: false,
		},
	];

	const portfolioFilter = {
		property: 'portfolioId',
		value: portfolioId,
	};
	const leaseExpirationFilter = filters.leaseExpirationsMonths
		? [
				{
					property: 'expirationDate',
					value: {
						from: dayjs()
							.subtract(filters.leaseExpirationsMonths, 'month')
							.format(DATE_FORMATS['MM/DD/YYYY']),
						allowFallback: true,
					},
				},
		  ]
		: [];

	return {
		portfolioQuery: {
			...baseBody,
			filter: [portfolioFilter, ...leaseExpirationFilter, ...baseFilter],
		},
		marketQuery: {
			...baseBody,
			filter: [...leaseExpirationFilter, ...baseFilter],
		},
	};
};

export const formatDate = (date: Date, shouldFormatDateAsYear: boolean) =>
	dayjs(date).format(shouldFormatDateAsYear ? 'YYYY' : "MMM 'YY");

export const getChartData = (arr: MarketTrendData[], timeSpanDate: string) =>
	arr
		.filter(({ date }) => date >= timeSpanDate)
		.map(({ date, value }) => ({ x: new Date(date), y: value }));
