import React, { useMemo } from 'react';

import styles from '../../repository.less';

import ThreeDot from '../../../../../ui/svg_icons/three-dot.svg';

import ProjectMenu, { ProjectMenuProps } from './ProjectMenu';
import SortMenu from './SortMenu';

import newProject from '../Modals/NewProject';

import { User } from '@compstak/common';
import dayjs from 'dayjs';
import { DATE_FORMATS } from 'constants/dateFormats';
import { Chart, Project } from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';
import { MenuActions } from 'Singletons/Menu/actions';
import { ModalActions } from 'Singletons/Modal/actions';
import { AnalyticsProjectActions } from '../../actions';
import SidebarNavigationButtons from '../../../Components/Buttons/SidebarNavigationButtons';

type ProjectSortFunc = (project1: Project, project2: Project) => number;

type Props = {
	chartDraft: Chart;
	chart: Chart;
	redirect: (url: string) => void;
	markets: MarketsState;
	project: Project;
	analyticsProjects: Project[];
	menuActions: MenuActions;
	modalActions: ModalActions;
	analyticsProjectActions: AnalyticsProjectActions;
	user: User;
	params: {
		projectId?: string;
	};
};

class ProjectListItem extends React.Component<
	Pick<
		Props,
		| 'analyticsProjectActions'
		| 'markets'
		| 'menuActions'
		| 'modalActions'
		| 'project'
		| 'redirect'
		| 'user'
	> & {
		selected: boolean;
	}
> {
	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	goToProject = (event) => {
		if (!event.isDefaultPrevented()) {
			this.props.redirect(`/analytics/projects/${this.props.project.id}`);
		}
	};

	showSidebarMenu = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.preventDefault();
		this.props.menuActions.showMenu<ProjectMenuProps>(
			ProjectMenu,
			event.currentTarget,
			'onright',
			// @ts-expect-error TS2345: Argument of type 'Readonly<Pic...
			this.props
		);
	};

	render() {
		const selectedClass = this.props.selected ? styles.selected : '';
		const threeDotsEl = this.props.project.canDelete ? (
			<span onClick={this.showSidebarMenu} className={styles.threeDots}>
				<ThreeDot width={4} height={16} />
			</span>
		) : null;

		return (
			<div
				className={`${styles.projectListItem} ${selectedClass}`}
				onClick={this.goToProject}
			>
				<span className={styles.projectInfo}>
					<div className={styles.infoTop}>
						<span className={styles.projectName}>
							{this.props.project.name}
						</span>
						<span className={styles.chartCount}>
							({this.props.project.charts.length} Charts)
						</span>
					</div>
					<div className={styles.projectDateModified}>
						Date Modified:{' '}
						{/* @ts-expect-error TS2551: Property 'lastUpdated' does no... */}
						{dayjs(this.props.project.lastUpdated).format(
							DATE_FORMATS['MMM D YYYY']
						)}
					</div>
				</span>
				{threeDotsEl}
			</div>
		);
	}
}

type ChartRepoSidebarState = { sortBy: string };

export default class ChartRepoSidebar extends React.Component<
	Props,
	ChartRepoSidebarState
> {
	state = {
		sortBy: DEFAULT_PROJECT_SORT,
	};

	goToChartbuilder = () => {
		this.props.redirect('/analytics');
	};

	goBack = () => {
		this.props.redirect('/analytics/projects');
	};

	addProject = () => {
		this.props.modalActions.showModal(newProject, this.props);
	};

	setSortBy = (sortBy: SortMethodName) => {
		this.setState({ sortBy });
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	showSortMenu = (event) => {
		this.props.menuActions.showMenu(
			SortMenu,
			event.currentTarget,
			'below-onleft',
			{
				...this.props,
				sortBy: this.state.sortBy,
				setSortBy: this.setSortBy,
			}
		);
	};

	render() {
		const { sortByCallback, sortByText } = sortMethods[this.state.sortBy];

		return (
			<div className={styles.chartSidebar}>
				<div className={styles.buttonContainer}>
					<span className={styles.sortButton}>
						<span className={styles.sortBy}>Sort by {sortByText}</span>
						<span className={styles.downContainer} onClick={this.showSortMenu}>
							<div className={styles.down} />
						</span>
					</span>
					<span className={styles.plus} onClick={this.addProject}>
						<div>+</div>
					</span>
				</div>
				<ProjectItems {...this.props} sortByCallback={sortByCallback} />
				<SidebarNavigationButtons onClickChartbuilder={this.goToChartbuilder} />
			</div>
		);
	}
}

function ProjectItems(
	props: Pick<
		Props,
		| 'analyticsProjectActions'
		| 'analyticsProjects'
		| 'markets'
		| 'menuActions'
		| 'modalActions'
		| 'project'
		| 'redirect'
		| 'user'
		| 'params'
	> & {
		sortByCallback: ProjectSortFunc;
	}
) {
	const sortedProjects = useMemo(() => {
		return props.analyticsProjects.sort(props.sortByCallback);
	}, [props.analyticsProjects, props.sortByCallback]);

	return (
		<div className={styles.projectList}>
			{sortedProjects.map((project) => {
				return (
					<ProjectListItem
						{...props}
						project={project}
						selected={
							props.params?.projectId
								? parseInt(props.params.projectId) === project.id
								: false
						}
						key={project.id}
					/>
				);
			})}
		</div>
	);
}

const sortByDates: ProjectSortFunc = (project1, project2) => {
	// @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
	return new Date(project2.lastUpdated) - new Date(project1.lastUpdated);
};

const sortByName: ProjectSortFunc = (project1, project2) => {
	return project1.name.localeCompare(project2.name);
};

const sortByNumberOfCharts: ProjectSortFunc = (project1, project2) => {
	return project2.charts.length - project1.charts.length;
};

enum SortMethodName {
	NAME = 'name',
	NUMBER_OF_CHARTS = 'numberOfCharts',
	DATE_MODIFIED = 'dateModified',
}

export const sortMethods = {
	[SortMethodName.NAME]: {
		sortByCallback: sortByName,
		sortByText: 'Name',
	},
	[SortMethodName.NUMBER_OF_CHARTS]: {
		sortByCallback: sortByNumberOfCharts,
		sortByText: 'No. Of Charts',
	},
	[SortMethodName.DATE_MODIFIED]: {
		sortByCallback: sortByDates,
		sortByText: 'Date Modified',
	},
};

export const DEFAULT_PROJECT_SORT = SortMethodName.DATE_MODIFIED;
