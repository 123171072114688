import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { FiltersObject } from 'models/filters/types';
import { CompType } from 'types';
import { SearchToolbarButtons } from './SearchToolbarButtons';
import Stats from 'Components/Stats';
import { MapResultsButton } from '../MapResultsButton';
import { LeagueTablesNavButton } from './LeagueTablesNavButton';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type SearchToolbarV2_ListToolbarProps = {
	compType: CompType;
	filters: FiltersObject;
	params: SearchLayoutRouteParams;
};

export const SearchToolbarV2_ListToolbar = ({
	compType,
	params,
}: SearchToolbarV2_ListToolbarProps) => {
	const { leagueTables } = useFeatureFlags();

	return (
		<>
			<SearchToolbarButtons compType={compType} params={params} />
			<ul className="toolbar_controls_right">
				<li className="search_stats_container js-search_stats_container">
					<Stats compType={compType} view={params.view} />
				</li>
				<li className="search_results_switch js-search_results-switch">
					<MapResultsButton compType={compType} />
				</li>
				{leagueTables && compType === 'sale' && (
					<li className="search_results_switch js-search_results-switch">
						<LeagueTablesNavButton
							compType={compType}
							isActive={params.view === 'leagueTables'}
						/>
					</li>
				)}
			</ul>
		</>
	);
};
