import styled from 'styled-components';
import { defaultTheme, LabelWrapStyled, Button } from '@compstak/ui-kit';

export const Wrap = styled.div`
	padding: 50px;
	background-color: ${defaultTheme.colors.neutral.n0};
	border: 1px solid ${defaultTheme.colors.neutral.n200};
	border-radius: 4px;
	width: 776px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Title = styled.h3`
	font-weight: 500;
	font-size: 18px;
	text-transform: uppercase;
	color: ${defaultTheme.colors.neutral.n400};
	margin-bottom: 32px;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 450px;
	${LabelWrapStyled} {
		margin-bottom: 24px;
	}
`;

export const Footer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 24px;

	button:not(:last-child) {
		margin-right: 45px;
	}
`;

export const CreateButton = styled(Button)`
	&:disabled {
		background-color: ${(props) => props.theme.colors.blue.blue500};
	}
`;
