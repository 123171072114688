import React from 'react';
import button from 'ui/styles/button.less';

import modalStyles from '../../../Components/Modals/analytics-modals.less';
import Modal from 'Singletons/Modal';

import saveModal from 'Pages/Analytics/Builder/Components/Modals/Save';
import { Chart, Project } from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';

type Props = {
	chartBuilderActions: {
		resetChartBuilder: (chart: Chart, markets: MarketsState) => void;
	};
	chart: Chart;
	newChart: Chart;
	redirect: (url: string) => void;
	markets: MarketsState;
	modalActions: {
		pushModal: Function;
	};
	project: Project;
};

const SaveBeforeOpening: React.FC<Props> = (props) => {
	const resetAndContinue = () => {
		props.chartBuilderActions.resetChartBuilder(props.newChart, props.markets);
		props.redirect('/analytics');
	};

	const showSaveModal = () => {
		props.modalActions.pushModal(saveModal, {
			...props,
			onSave: resetAndContinue,
		});
	};

	return (
		<div className={`${modalStyles.container} ${modalStyles.importDataSet}`}>
			<h3>Chart in Progress</h3>
			<div className={modalStyles.message}>
				You currently have an Analytics chart in progress.
				<br />
				<br />
				Would you like to save your changes to your current analytics chart?
			</div>
			<div className={modalStyles.buttonContainer}>
				<span
					data-close-button
					className={`${button.darkBlue}`}
					onClick={() => {
						// @ts-expect-error TS2339: Property 'popModal' does not e...
						props.modalActions.popModal();
					}}
				>
					Return to Projects
				</span>
				<span className={`${button.darkBlue}`} onClick={resetAndContinue}>
					Discard
				</span>
				<span className={`${button.blue}`} onClick={showSaveModal}>
					Save
				</span>
			</div>
		</div>
	);
};

const modalId = 'analytics-save-before-opening';

Modal.addComponent({
	id: modalId,
	Component: SaveBeforeOpening,
});

export default modalId;
