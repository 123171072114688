import { DataProviderLogoBase, Flex } from '@compstak/ui-kit';
import { FilterSpacer } from 'Components/Filters/Base/Filter/FilterSpacer';
import React from 'react';
import styled from 'styled-components';
import { AddressFilter } from 'Components/Filters/Fields/AddressFilter/AddressFilter';
import { BuyerFilter } from 'Components/Filters/Fields/BuyerFilter/BuyerFilter';
import { SellerFilter } from 'Components/Filters/Fields/SellerFilter/SellerFilter';
import { FilterDescription } from 'Components/Filters/Base/Filter/FilterDescription';
import { SpaceSubtypeFilter } from 'Components/Filters/Fields/AggregationFilter/SpaceSubtypeFilter';
import { CityFilter } from 'Components/Filters/Fields/CityFilter/CityFilter';
import { ZipFilter } from 'Components/Filters/Fields/ZipFilter/ZipFilter';
import { BuildingClassFilter } from 'Components/Filters/Fields/BuildingClassFilter/BuildingClassFilter';
import { BuildingSizeFilter } from 'Components/Filters/Fields/BuildingSizeFilter/BuildingSizeFilter';
import { BuildingFloorsCount } from 'Components/Filters/Fields/BuildingFloorsCount/BuildingFloorsCount';
import { YearBuilt } from 'Components/Filters/Fields/YearBuilt/YearBuilt';
import { FloorsFilter } from 'Components/Filters/Fields/FloorsFilter/FloorsFilter';
import { BuildingNameFilter } from 'Components/Filters/Fields/BuildingNameFilter/BuildingNameFilter';
import { TransactionSizeFilter } from 'Components/Filters/Fields/TransactionSizeFilter/TransactionSizeFilter';
import { TransactionTypeFilter } from 'Components/Filters/Fields/TransactionTypeFilter/TransactionTypeFilter';
import { StartingRentFilter } from 'Components/Filters/Fields/StartingRentFilter/StartingRentFilter';
import { EffectiveRentFilter } from 'Components/Filters/Fields/EffectiveRentFilter/EffectiveRentFilter';
import { LeaseTypeFilter } from 'Components/Filters/Fields/LeaseTypeFilter/LeaseTypeFilter';
import { SubleaseFilter } from 'Components/Filters/Fields/SubleaseFilter/SubleaseFilter';
import { YearRenovatedFilter } from 'Components/Filters/Fields/YearRenovatedFilter/YearRenovatedFilter';
import { LeaseTermFilter } from 'Components/Filters/Fields/LeaseTermFilter/LeaseTermFilter';
import { ExecutionDateFilter } from 'Components/Filters/Fields/ExecutionDateFilter/ExecutionDateFilter';
import { ExpirationDateFilter } from 'Components/Filters/Fields/ExpirationDateFilter/ExpirationDateFilter';
import { BrokerageFilter } from 'Components/Filters/Fields/BrokerageFilter/BrokerageFilter';
import { PropertySubtypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertySubtypeFilter';
import { TenantFilter } from 'Components/Filters/Fields/TenantFilter/TenantFilter';
import { TenantIndustryFilter } from 'Components/Filters/Fields/TenantIndustryFilter/TenantIndustryFilter';
import { TenantRevenueFilter } from 'Components/Filters/Fields/TenantRevenueFilter/TenantRevenueFilter';
import { TenantEmployeesFilter } from 'Components/Filters/Fields/TenantEmployeesFilter/TenantEmployeesFilter';
import { TenantOwnershipFilter } from 'Components/Filters/Fields/TenantOwnershipFilter/TenantOwnershipFilter';
import { TenantStatusFilter } from 'Components/Filters/Fields/TenantStatusFilter/TenantStatusFilter';
import { LandlordFilter } from 'Components/Filters/Fields/LandlordFilter/LandlordFilter';
import { DateCreatedFilter } from 'Components/Filters/Fields/DateCreatedFilter/DateCreatedFilter';
import { LastSalePriceFilter } from 'Components/Filters/Fields/LastSalePriceFilter/LastSalePriceFilter';
import { MarketStartingRentFilter } from 'Components/Filters/Fields/MarketStartingRentFilter/MarketStartingRentFilter';
import { BuildingSqFtExpiringInTwelveMonthsFilter } from 'Components/Filters/Fields/BuildingSqFtExpiringInTwelveMonthsFilter/BuildingSqFtExpiringInTwelveMonthsFilter';
import { AverageTransactionSizeFilter } from 'Components/Filters/Fields/AverageTransactionSizeFilter/AverageTransactionSizeFilter';
import { PropertyStatusFilter } from 'Components/Filters/Fields/PropertyStatusFilter/PropertyStatusFilter';
import { TotalUnitsFilter } from 'Components/Filters/Fields/TotalUnitsFilter/TotalUnitsFilter';
import { CurrentOccupancyRateFilter } from 'Components/Filters/Fields/CurrentOccupancyRateFilter/CurrentOccupancyRateFilter';
import { AssetClassFilter } from 'Components/Filters/Fields/AssetClassFilter/AssetClassFilter';
import { PortfolioFilter } from 'Components/Filters/Fields/PortfolioFilter/PortfolioFilter';
import { SalePricePsfFilter } from 'Components/Filters/Fields/SalePricePsfFilter/SalePricePsfFilter';
import { SalePriceFilter } from 'Components/Filters/Fields/SalePriceFilter/SalePriceFilter';
import { ManagementCompanyFilter } from 'Components/Filters/Fields/ManagementCompanyFilter/ManagementCompanyFilter';
import { SaleDateFilter } from 'Components/Filters/Fields/SaleDateFilter/SaleDateFilter';
import { InterestTypeFilter } from 'Components/Filters/Fields/InterestTypeFilter/InterestTypeFilter';
import { PropertyMarketEffectiveRentFilter } from 'Components/Filters/Fields/PropertyMarketEffectiveRentFilter/PropertyMarketEffectiveRentFilter';
import { DataSourceFilter } from 'Components/Filters/Fields/DataSourceFilter/DataSourceFilter';
import { StartingRentPerSqfFilter } from 'Components/Filters/Fields/StartingRentPerSqfFilter/StartingRentPerSqfFilter';
import { EffectiveRentPerSqfFilter } from 'Components/Filters/Fields/EffectiveRentPerSqfFilter/EffectiveRentPerSqfFilter';

export function UndesirableResults() {
	return (
		<FilterDescription>
			Note: using this filter may create undesirable results.
		</FilterDescription>
	);
}

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export function ExpandedFiltersLeases(props) {
	return (
		<ul style={props.style} className={`search_sidebar_filters`}>
			<AddressFilter />
			<CityFilter />
			<ZipFilter />

			<FilterSpacer />

			<SpaceSubtypeFilter />

			<BuildingClassFilter />
			<BuildingSizeFilter />
			<BuildingFloorsCount />

			<FilterSpacer />

			<StartingRentFilter />
			<EffectiveRentFilter />
			<LeaseTypeFilter />

			<FilterSpacer />

			<TransactionSizeFilter />
			<ExecutionDateFilter>
				<UndesirableResults />
			</ExecutionDateFilter>

			<TransactionTypeFilter />

			<FilterSpacer />

			<TenantFilter />
			<TenantIndustryFilter />
			<React.Fragment>
				<TenantRevenueFilter />
				<TenantEmployeesFilter />
				<TenantOwnershipFilter />
				<TenantStatusFilter />
				<FilterSpacer />
			</React.Fragment>
			<SubleaseFilter />

			<ExpirationDateFilter>
				<UndesirableResults />
			</ExpirationDateFilter>

			<BuildingNameFilter />
			<YearBuilt />
			<YearRenovatedFilter />
			<LandlordFilter />
			<BrokerageFilter />
			<FloorsFilter />
			<LeaseTermFilter />
			<DateCreatedFilter>
				<UndesirableResults />
			</DateCreatedFilter>
		</ul>
	);
}

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export function ExpandedFiltersMufa(props) {
	return (
		<ul style={props.style} className={`search_sidebar_filters`}>
			<FiltersSectionTitle>PROPERTY</FiltersSectionTitle>

			<AddressFilter />
			<CityFilter />
			<ZipFilter />

			<FilterSpacer />

			<BuildingNameFilter />
			<YearBuilt />
			<PropertySubtypeFilter />
			<BuildingClassFilter />

			<BuildingSizeFilter />
			<YearRenovatedFilter />
			<BuildingFloorsCount />
			<LastSalePriceFilter />
			<LandlordFilter />

			<FilterSpacer />

			<BuildingSqFtExpiringInTwelveMonthsFilter />
			<AverageTransactionSizeFilter />
			<MarketStartingRentFilter />

			<FilterSpacer />

			<Flex
				alignItems="baseline"
				style={{ margin: FILTERS_SECTION_TITLE_MARGIN }}
			>
				<FiltersSectionTitle margin="0px">MULTIFAMILY</FiltersSectionTitle>
				<DataProviderLogoBase
					baseStyles={{ alignItems: 'baseline' }}
					logoImageStyles={{ height: 9 }}
				/>
			</Flex>

			<PropertyStatusFilter />
			<TotalUnitsFilter />

			<FilterSpacer />

			<CurrentOccupancyRateFilter />
			<StartingRentPerSqfFilter />
			<EffectiveRentPerSqfFilter />

			<FilterSpacer />

			<AssetClassFilter />

			<ManagementCompanyFilter />
		</ul>
	);
}

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export function ExpandedFiltersSales(props) {
	return (
		<ul style={props.style} className={`search_sidebar_filters`}>
			<AddressFilter />
			<CityFilter />
			<ZipFilter />
			<FilterSpacer />
			<BuildingNameFilter />
			<YearBuilt />
			<BuildingClassFilter />
			<BuildingSizeFilter />
			<BuildingFloorsCount />
			<FilterSpacer />
			<MarketStartingRentFilter />
			<PropertyMarketEffectiveRentFilter />
			<AverageTransactionSizeFilter />
			<FilterSpacer />
			<PortfolioFilter />
			<DataSourceFilter />
			<SaleDateFilter />
			<TransactionSizeFilter />
			<SalePriceFilter />
			<SalePricePsfFilter />
			<BuyerFilter />
			<SellerFilter />
			<InterestTypeFilter />
			<DateCreatedFilter />
		</ul>
	);
}

const FILTERS_SECTION_TITLE_MARGIN = '16px 18px 8px';

const FiltersSectionTitle = styled.h3<{ margin?: string }>`
	margin: ${(props) => props.margin ?? FILTERS_SECTION_TITLE_MARGIN};
	line-height: 18px;
	font-size: 12px;
	color: ${(props) => props.theme.colors.blue.blue400};
`;
