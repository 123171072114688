import { Text, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

type PdfSectionBoxProps = {
	title: string;
	children: ReactNode;
};

export const PdfSectionBox = ({ title, children }: PdfSectionBoxProps) => {
	const theme = useTheme();

	return (
		<View
			style={{
				border: `1px solid ${theme.colors.neutral.n40}`,
				padding: 12,
			}}
		>
			<Text
				style={{
					fontSize: 12,
					textTransform: 'uppercase',
					marginBottom: 4,
				}}
			>
				{title}
			</Text>
			{children}
		</View>
	);
};

type PdfSectionDescriptionTextProps = {
	children: ReactNode;
};

export const PdfSectionDescriptionText = ({
	children,
}: PdfSectionDescriptionTextProps) => {
	const theme = useTheme();

	return (
		<View
			style={{
				backgroundColor: theme.colors.neutral.n20,
				padding: 9,
				borderRadius: 3,
			}}
		>
			<Text
				style={{
					fontSize: 12,
				}}
			>
				{children}
			</Text>
		</View>
	);
};
