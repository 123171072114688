import { Spinner, Tabs as UIKitTabs } from '@compstak/ui-kit';
import {
	SearchPropertiesNavHeader,
	useSearchPropertiesNavHeaderContext,
} from 'Components';
import { usePropertyById } from 'api';
import { MQB } from 'constants/mediaQueryBreakpoints';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { routes } from 'router';
import styled from 'styled-components';
import { APP_HEADER_HEIGHT } from '../../constants';
import { ExportButton } from './ExportPdf';
import { MainInfo, PropertyTitle } from './MainInfo';
import { PropertyLeaseSnapshot } from './PropertyLeaseSnapshot';
import { LeaseProvider } from './LeasesTab/LeaseProvider';
import { SubmarketTrendsProvider } from './SubmarketTrendsTab/SubmarketTrendsProvider';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { PropertyPageTab } from './types';
import { CompstakEstimates } from './CompstakEstimates';
import { TabsContent, TabsList, useTabContext } from './Tabs';
import { useCompstakEstimatesData } from './hooks/useCompstakEstimatesData';

export const PropertyPage = () => {
	const params = routes.propertyById.useParams();
	const location = useLocation();

	const { tab, setTab, scrollToTabs } = useTabContext();

	useEffect(() => {
		if (location.state?.scrollToLeases) {
			setTab(PropertyPageTab.LEASES);
			scrollToTabs();
		}
	}, [location.state]);

	const { data: property, isLoading: isLoadingProperty } = usePropertyById({
		propertyId: params.propertyId,
	});

	const { getIsNavHeaderActive } = useSearchPropertiesNavHeaderContext();

	const isNavHeaderActive = getIsNavHeaderActive();

	const flags = useFeatureFlags();

	const { showCompstakEstimates, startingRent, effectiveRent } =
		useCompstakEstimatesData();

	return (
		<Container>
			{isNavHeaderActive && <SearchPropertiesNavHeader />}
			<Content>
				{!property || isLoadingProperty ? (
					<SpinnerContainer>
						<Spinner size="xl" />
					</SpinnerContainer>
				) : (
					<>
						<SubmarketTrendsProvider propertyId={property.id}>
							<LeaseProvider>
								<UIKitTabs
									value={tab}
									onValueChange={(value) => {
										setTab(value as PropertyPageTab);
									}}
								>
									<TopContainer>
										<PropertyTitle property={property} />
										{flags.PropertyPageExport && (
											<ExportButtonContainer>
												<ExportButton />
											</ExportButtonContainer>
										)}
									</TopContainer>
									<TopRow>
										<MainInfoContainer
											isShowingEstimates={showCompstakEstimates}
										>
											<MainInfo />
											{showCompstakEstimates && <GreyLine />}
											<TabsList />
										</MainInfoContainer>
										<RightContainer>
											<PropertyLeaseSnapshot />
											{showCompstakEstimates && (
												<CompstakEstimates
													startingRent={startingRent}
													effectiveRent={effectiveRent}
												/>
											)}
										</RightContainer>
									</TopRow>
									<TabsContent />
								</UIKitTabs>
							</LeaseProvider>
						</SubmarketTrendsProvider>
					</>
				)}
			</Content>
		</Container>
	);
};

const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100vh - ${APP_HEADER_HEIGHT}px);
	background-color: ${(p) => p.theme.colors.white.white};
	overflow-y: scroll;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	color: ${(p) => p.theme.colors.gray.gray700};
	* {
		box-sizing: border-box;
	}
`;

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div`
	padding: 1rem 1.5rem;
`;

const TopContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const TopRow = styled.div`
	display: grid;
	grid-template-columns: 3fr 1fr;
	gap: 1.5rem;
	min-height: 400px;
	@media (min-width: ${MQB.D_1920}px) {
		gap: 2rem;
	}
`;

const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-bottom: 1rem;
`;

const ExportButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const MainInfoContainer = styled.div<{ isShowingEstimates: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: ${({ isShowingEstimates }) => (isShowingEstimates ? '1.5rem' : '3rem')};
`;

const GreyLine = styled.div`
	border-top: 1px solid ${({ theme }) => theme.colors.neutral.n30};
`;
