import { closeUpgradeModal } from 'actions/filters';
import { createSearch, setMyComps } from 'actions/search';
import { MarketUpgradeModal } from 'Components/Modals/UpgradeModal/MarketUpgradeModal';
import { MultiMarketUpgradeModal } from 'Components/Modals/UpgradeModal/MultiMarketUpgradeModal';
import { SearchLayoutCommonProps } from 'exchange/ExchangeHome';
import { DISABLE_FILTERS_RESET } from 'middleware/filtersFromRoutes';
import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { filtersToQueryString } from 'models/filters/util/urls';
import { LeagueTablesView } from 'Pages/LeagueTables/LeagueTablesView';
import MapComponent from 'Pages/Search/Map';
import { resetSearch } from 'Pages/Search/Map/actions';
import {
	MultiSelectProvider,
	useMultiSelect,
} from 'Pages/Search/MultiSelectProvider';
import { NewSearchTableLayout } from 'Pages/Search/NewSearchTableLayout';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { AppState } from 'reducers/root';
import { SearchPageParams } from 'router';
import { CompType } from 'types';
import layoutStyles from 'ui/styles/layout.less';
import { useIsMarketsAccessible } from 'util/marketAccessUtils';
import { useAppSelector } from 'util/useAppSelector';
import { HomeSidebar } from '../Pages/Home/Sidebar';
import { HomeToolbar } from '../Pages/Home/Toolbar';
import { SearchToolbar } from '../Pages/Search/SearchToolbar';
import SearchSidebar from '../Pages/Search/Sidebar';
import { Table } from '../Pages/Search/Table';
import { usePrevious } from '../util/hooks';
import { SearchPageProvider, useSearchPageContext } from './SearchProvider';
import './styles/searchLayout.nomodule.less';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useNavigate } from 'react-router';
import { useFilters } from 'reducers/filtersReducer';
import { SearchToolbarV2 } from 'Pages/Search/SearchToolbar/SearchToolbarV2';
import { FilterFieldContextProvider } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { Modal } from 'Components/Modals/common/UI';

export type SearchLayoutRouteParams = Partial<SearchPageParams>;

export type SearchLayoutProps =
	SearchLayoutCommonProps<SearchLayoutRouteParams> &
		ReturnType<typeof mapStoreToProps> & {
			filters: FiltersObject;
			onFiltersChange: (newFilters: Partial<FiltersObject>) => void;
		};

const InternalSearchLayout = (props: SearchLayoutProps) => {
	const [sideBarState, setSideBarState] = useState<'open' | 'closed'>('closed');
	const showUpgradeModal = useAppSelector((s) => s.filtersV2.showUpgradeModal);
	const [insightsAreExpanded, setInsightsAreExpanded] = useState(true);

	const { leagueTables, sidebarRevampFF, SearchTableRefactor } =
		useFeatureFlags();
	const prevIsMyComps = usePrevious(props.isMyComps);
	const { searchState, resetSearchState } = useSearchPageContext();

	const compTab = getCompTab(window.location.pathname);
	const prevCompTab = usePrevious(compTab);

	const isHome = props.route.isHome || sidebarRevampFF;
	const isSearchView =
		props.route.isSearch ||
		searchState.mapAnalyticsType != null ||
		sidebarRevampFF;
	const syncFiltersWithURL = !props.route.isHome || sidebarRevampFF;

	const { filters, onFiltersChange } = props;

	const { resetMultiSelect } = useMultiSelect();

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.location.query.isMyComps !== undefined) {
			dispatch(setMyComps(true));
		}
	}, []);

	useEffect(() => {
		if (isHome && props.isMyComps) {
			dispatch(setMyComps(false));
		} else if (
			props.location.query.isMyComps !== undefined &&
			prevIsMyComps === false
		) {
			dispatch(setMyComps(true));
		}
	}, [dispatch, isHome, prevIsMyComps, props.isMyComps, props.location]);

	const navigate = useNavigate();

	useEffect(() => {
		// sync URL with the filter changes
		if (syncFiltersWithURL) {
			navigate(
				{
					pathname: props.location.pathname,
					search: '?' + filtersToQueryString(filters),
				},
				{ replace: true, state: { [DISABLE_FILTERS_RESET]: true } }
			);
		}
	}, [filters, syncFiltersWithURL, navigate, props.location]);

	// reset multi-select, reset search state, saved search (except first render), selection (except first render) on main tab change
	useEffect(() => {
		if (compTab && compTab !== prevCompTab) {
			// remove current saved search if compType actually switched from non-nullish value (done in AP-10784)
			const resetCurrentSavedSearch = !!prevCompTab;
			const resetSelection = !!prevCompTab;
			dispatch(
				resetSearch({
					resetCurrentSavedSearch,
					resetFilters: false,
					resetSelection,
				})
			);
			resetMultiSelect();
			resetSearchState();
		}
	}, [
		compTab,
		prevCompTab,
		dispatch,
		resetMultiSelect,
		props.route.compType,
		resetSearchState,
	]);

	const contentIfUserHasAccess = () => {
		const { route, params } = props;

		const showTable = route.isSearch && params.view === 'list';

		const showLeagueTable =
			route.isSearch && leagueTables && params.view === 'leagueTables';

		const suggestionId =
			(props.location.state && props.location.state.suggestionId) ||
			props.suggestionId ||
			null;

		return (
			<div className="search_inner_content">
				<FilterChangeEffect
					compType={props.route.compType}
					filters={filters}
					suggestionId={suggestionId}
				/>
				<div className="search_content_toolbar">
					<TransitionGroup>
						<CSSTransition
							key={isSearchView ? 'search-toolbar' : 'home-toolbar'}
							classNames="layout-toolbar-transition"
							timeout={300}
						>
							{sidebarRevampFF ? (
								<SearchToolbarV2
									compType={route.compType}
									params={params}
									filters={filters}
								/>
							) : isSearchView ? (
								<SearchToolbar compType={route.compType} params={params} />
							) : (
								<HomeToolbar
									params={params}
									compType={route.compType}
									filters={filters}
								/>
							)}
						</CSSTransition>
					</TransitionGroup>
				</div>
				<div className="search_content_results">
					<MapComponent
						compType={route.compType}
						setSideBarState={() =>
							setSideBarState((prev) => (prev === 'open' ? 'closed' : 'open'))
						}
						params={params}
					/>
					{SearchTableRefactor ? (
						<>
							{showTable && (
								<NewSearchTableLayout
									compTab={compTab ?? prevCompTab!}
									insightsAreExpanded={insightsAreExpanded}
									toggleInsightsAreExpanded={() =>
										setInsightsAreExpanded((prev) => !prev)
									}
								/>
							)}
							{showLeagueTable && (
								<LeagueTablesView compType={route.compType} />
							)}
						</>
					) : (
						<TransitionGroup>
							{showLeagueTable && (
								<CSSTransition
									key="leagueTables"
									classNames="layout-results-transition"
									timeout={300}
								>
									<LeagueTablesView compType={route.compType} />
								</CSSTransition>
							)}
							{showTable ? (
								<CSSTransition
									key="table"
									classNames="layout-results-transition"
									timeout={300}
								>
									<Table
										key="table"
										compType={route.compType}
										filters={filters}
										onFilterChange={onFiltersChange}
										insightsAreExpanded={insightsAreExpanded}
										toggleInsightsAreExpanded={() =>
											setInsightsAreExpanded((prev) => !prev)
										}
									/>
								</CSSTransition>
							) : (
								<CSSTransition
									key="nothing"
									classNames="layout-results-transition"
									timeout={300}
								>
									<span />
								</CSSTransition>
							)}
						</TransitionGroup>
					)}
				</div>
			</div>
		);
	};

	const hasNoPermissionAtAll = !useIsMarketsAccessible({
		compType: props.route.compType,
		marketIds: getFiltersMarkets(filters).map(({ id }) => id),
	});

	if (!filters || !props.user) {
		return <div />;
	}

	const userType = props.appConfig.isEnterprise ? 'enterprise' : 'exchange';

	const commonSideBarProps = {
		filters,
		compType: props.route.compType,
		onFilterChange: onFiltersChange,
		onNewSearchClick: () => {
			dispatch(resetSearch({ resetFilters: true }));
			resetMultiSelect();
			resetSearchState();
		},
		user: props.user,
		params: props.params,
		search: props.search,
		selection: props.selection,
	};

	const onUpgradeModalClose = () => dispatch(closeUpgradeModal('main'));

	return (
		<section
			className={`${layoutStyles.contentWithSidebar}`}
			key="search-layout"
		>
			<div
				className={
					layoutStyles.sidebar +
					' ' +
					(isSearchView ? 'search_sidebar' : 'home-sidebar') +
					' ' +
					userType
				}
				data-sidebar-state={sideBarState}
			>
				{isSearchView ? (
					<SearchSidebar {...commonSideBarProps} />
				) : (
					<HomeSidebar {...commonSideBarProps} />
				)}
			</div>
			<div className={layoutStyles.content + ' search_content'}>
				{hasNoPermissionAtAll ? (
					<MarketUpgradeModal inline market={getFiltersMarkets(filters)[0]} />
				) : (
					contentIfUserHasAccess()
				)}
			</div>
			{!hasNoPermissionAtAll && showUpgradeModal.main && (
				<Modal onClose={onUpgradeModalClose}>
					<MultiMarketUpgradeModal
						compType={props.route.compType}
						selectedMarkets={showUpgradeModal.main.map(({ id }) => id)}
						onClose={onUpgradeModalClose}
					/>
				</Modal>
			)}
		</section>
	);
};

export const SearchLayout = (props: SearchLayoutProps) => {
	const [filters, setFilters] = useFilters();
	return (
		<MultiSelectProvider>
			<SearchPageProvider>
				<FilterFieldContextProvider
					compType={props.route.compType}
					filters={filters}
					onFilterChange={setFilters}
				>
					<InternalSearchLayout
						{...props}
						filters={filters}
						onFiltersChange={setFilters}
					/>
				</FilterFieldContextProvider>
			</SearchPageProvider>
		</MultiSelectProvider>
	);
};

function mapStoreToProps(store: AppState) {
	return {
		appConfig: store.appConfig,
		isMyComps: store.searchReducer.isMyComps,
		search: store.searchReducer.search,
		selection: store.selection.list,
		suggestionId: store.searchReducer.suggestionId,
		user: store.user,
	};
}

export default connect(mapStoreToProps)(SearchLayout);

export const FilterChangeEffect = ({
	compType,
	filters,
	suggestionId,
}: {
	compType: CompType;
	filters: FiltersObject;
	suggestionId: string;
}) => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(
			createSearch({
				compType,
				filters,
				suggestionId,
			})
		);
		// do not add `compType` into the effect
		// since it produces unnecessary request on tab change
		// while having a proper request on actual filters change
	}, [filters, suggestionId, dispatch]);

	return null;
};

const getCompTab = (pathname: string) => {
	if (pathname.startsWith('/search/leases') || pathname.startsWith('/home'))
		return 'leases';
	if (pathname.startsWith('/search/sales')) return 'sales';
	if (pathname.startsWith('/search/properties')) return 'properties';
	return null;
};
