import { radiusExpanderSearch } from 'actions/search';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useFilters } from 'reducers/filtersReducer';
import { CompType, PropertyComp } from 'types/comp';
import { CompPopupActionButton } from './UI';

type Props = {
	compType: CompType;
	property: PropertyComp;
	closePopup: () => void;
	['data-qa-id']: string;
	children: React.ReactNode;
};

export const NearbyCompsButton = ({
	property,
	compType,
	closePopup,
	children,
	...props
}: Props) => {
	const [filters] = useFilters();
	const dispatch = useDispatch();

	const onClickNearbyLeaseComps = async () => {
		dispatch(
			radiusExpanderSearch({
				compType,
				filters,
				lat: property.geoPoint.lat,
				lon: property.geoPoint.lon,
			})
		);
		closePopup();
	};

	return (
		<CompPopupActionButton
			onClick={onClickNearbyLeaseComps}
			data-qa-id={props['data-qa-id']}
		>
			{children}
		</CompPopupActionButton>
	);
};
