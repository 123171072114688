import React from 'react';
import {
	VictoryAxis,
	VictoryChart,
	VictoryLabel,
	VictoryLine,
	VictoryTooltip,
	VictoryVoronoiContainer,
} from 'victory';

import { MarketTrendChartFlyout } from './MarketTrendChartFlyout';

import { MarketTrendMetricFilter } from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { Colors } from '..';

import { Styled } from './styled';

import { formatDate } from '../util';

type MarketTrendChartData = { x: Date; y: number };

type MarketTrendChartProps = {
	data: {
		portfolio: MarketTrendChartData[];
		market: MarketTrendChartData[];
	};
	noData: boolean;
	loading: boolean;
	metricFilter: MarketTrendMetricFilter;
	marketName: string;
};

export const MarketTrendChartComponent = ({
	data,
	noData,
	loading,
	metricFilter,
	marketName,
}: MarketTrendChartProps) => {
	const axisStyle = {
		fontSize: 11,
		fontFamily: 'Gotham, sans-serif',
		fontWeight: 500,
		opacity: noData ? 0 : 1,
	};

	const shouldFormatDateAsYear = React.useMemo(() => {
		const dates = data.portfolio.map(({ x }) => x.getFullYear());
		const firstYear = Math.min(...dates);
		const lastYear = Math.max(...dates);

		return lastYear - firstYear > 3;
	}, [data.portfolio]);

	const chartWidth = 780;

	return (
		<Styled.Container>
			<VictoryChart
				domainPadding={5}
				height={320}
				width={chartWidth}
				scale={{ x: 'time' }}
				containerComponent={
					<VictoryVoronoiContainer
						voronoiDimension="x"
						labels={() => ' '}
						labelComponent={
							<VictoryTooltip
								flyoutComponent={
									<MarketTrendChartFlyout
										width={220}
										metricFilter={metricFilter}
										marketName={marketName}
										chartWidth={chartWidth}
									/>
								}
							/>
						}
					/>
				}
			>
				<VictoryAxis
					tickLabelComponent={<VictoryLabel style={axisStyle} />}
					tickCount={6}
					tickFormat={(d: Date) => formatDate(d, shouldFormatDateAsYear)}
					style={{
						axis: { stroke: 'none' },
					}}
				/>
				<VictoryAxis
					dependentAxis
					tickLabelComponent={<VictoryLabel style={axisStyle} />}
					tickFormat={(m: number) => {
						return m > 0 ? `$${m}` : m;
					}}
					style={{
						axis: { stroke: 'none' },
						grid: { stroke: noData ? 'none' : '#F1F2F5' },
					}}
				/>
				<VictoryLine
					data={data.portfolio}
					style={{ data: { stroke: Colors.PORTFOLIO } }}
				/>
				<VictoryLine
					data={data.market}
					style={{ data: { stroke: Colors.MARKET } }}
				/>
				<NoDataRender noData={noData} loading={loading} />
			</VictoryChart>
		</Styled.Container>
	);
};

const noRenderTitleStyle = {
	fontSize: 16,
	fontFamily: 'Gotham, sans-serif',
	fontWeight: 500,
};
const noRenderSubtitleStyle = {
	fontSize: 14,
	fontFamily: 'Gotham, sans-serif',
};

// @ts-expect-error TS7031: Binding element 'noData' impli...
const NoDataRender = ({ noData, loading }) => {
	return noData ? (
		<>
			<VictoryLabel
				x={loading ? 358 : 323}
				y={loading ? 180 : 113}
				text={loading ? 'Loading' : 'No Data Available'}
				style={noRenderTitleStyle}
			/>
			<VictoryLabel
				x={loading ? 350 : 308}
				y={loading ? 215 : 148}
				text={loading ? 'Please wait' : 'Try adjusting your filters.'}
				style={noRenderSubtitleStyle}
			/>
		</>
	) : null;
};
