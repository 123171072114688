import { Pending as PendingOriginal } from '@compstak/ui-kit';
import { useApn } from 'hooks/useApn';
import { ComponentProps } from 'react';
import styled from 'styled-components';
import { Comp } from 'types';
import { AssociatedApns } from './AssociatedApns';

type ApnProps = {
	className?: string;
	comp: Comp;
	pendingProps?: ComponentProps<typeof PendingOriginal>;
};

export const Apn = ({ className, comp, pendingProps }: ApnProps) => {
	const { data: apn, isFetching, isEnabled } = useApn({ comp });

	if (!isEnabled) return null;

	return (
		<ApnContainer className={className}>
			APN:{' '}
			{isFetching ? (
				<Pending margin="0" {...pendingProps} />
			) : (
				<span>
					{Array.isArray(apn) ? <AssociatedApns associatedApns={apn} /> : apn}
				</span>
			)}
		</ApnContainer>
	);
};

const ApnContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5em;
	${({ theme }) => theme.breakpoints.down('T_834')} {
		font-size: 0.8em;
	}
`;

const Pending = styled(PendingOriginal)`
	height: 1em;
	width: 7em;
`;
