import React, { useEffect, useMemo } from 'react';

import styles from '../../repository.less';

import Sidebar, { DEFAULT_PROJECT_SORT, sortMethods } from './Sidebar';
import ChartRepository from './Repository';
import { Chart, Project } from 'Pages/Analytics/analytics';
import { LocationDescriptor } from 'router/migration/types';
import { useMarkets } from 'hooks/useMarkets';

type Props = {
	chartDraft: Chart;
	chart: Chart;
	redirect: (url: LocationDescriptor) => void;
	analyticsProjects: Project[];
	params: {
		projectId: string;
	};
};

const ChartRepoLayout: React.FC<Props> = (props) => {
	const markets = useMarkets();

	const currentProject = useMemo(() => {
		return props.analyticsProjects.find(
			(project) => project.id === parseInt(props.params.projectId)
		);
	}, [props.analyticsProjects, props.params.projectId]);

	useEffect(() => {
		if (props.analyticsProjects.length && !currentProject) {
			const sortedProjects = props.analyticsProjects.sort(
				sortMethods[DEFAULT_PROJECT_SORT].sortByCallback
			);
			props.redirect(`/analytics/projects/${sortedProjects[0].id}`);
		}
	}, []);

	return (
		<div className={styles.layout}>
			{/* @ts-expect-error TS2769: No overload matches this call.... */}
			<Sidebar {...props} markets={markets} />
			{currentProject && (
				<ChartRepository
					{...props}
					project={currentProject}
					markets={markets}
				/>
			)}
		</div>
	);
};

export default ChartRepoLayout;
