import React, { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { validateEmail } from 'util/validate';
import Menu from 'Singletons/Menu';
import { Comp, CompType } from 'types';
import { shareAComp as shareALease } from 'actions/lease';
import { shareAComp as shareASale } from 'actions/sale';
import { hideMenu } from 'Singletons/Menu/actions';
import { Button as ButtonOriginal } from '@compstak/ui-kit';
import styled, { css } from 'styled-components';

export type ShareACompProps = {
	compType: CompType;
	comp: Comp;
};

const ShareAComp = ({ comp, compType }: ShareACompProps) => {
	const [email, setEmail] = useState<string>('');

	const isValid = validateEmail(email);

	const dispatch = useDispatch();

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		if (isValid) {
			dispatch(
				compType === 'sale'
					? shareASale(comp.id, email)
					: shareALease(comp.id, email)
			);
			setTimeout(() => {
				dispatch(hideMenu('share-a-comp'));
			});
		}
	};

	return (
		<Container>
			<Header>Share this comp with...</Header>
			<form onSubmit={handleSubmit} method="post">
				<InputWrapper isValid={isValid} hasContent={!!email}>
					<input
						data-qa-id="share-a-comp-email"
						type="text"
						name="email"
						onChange={({ target }) => setEmail(target.value)}
						onBlur={({ target }) => setEmail(target.value)}
					/>
					<label htmlFor="email">Enter Email Address</label>
				</InputWrapper>
				<Button disabled={!isValid} variant="primary" onClick={handleSubmit}>
					Send Comp
				</Button>
			</form>
		</Container>
	);
};

const Container = styled.div`
	font-size: 0.75rem;
	letter-spacing: 0.125em;
	line-height: 1.25em;
	color: ${({ theme }) => theme.colors.white.white};
	font-weight: 300;
	text-align: left;
	text-transform: uppercase;
	font-weight: 300;
`;

const Header = styled.div`
	margin: 0.25rem 0.25rem 0.5rem 0.25rem;
`;

const transformCss = css<{ isValid?: boolean; hasContent?: boolean }>`
	transform: scale(0.75) translate3d(-4px, -7px, 0);
	color: ${({ theme, isValid, hasContent }) =>
		isValid || !hasContent
			? theme.colors.blue.blue400
			: theme.colors.red.red500};
`;

const InputWrapper = styled.div<{ isValid?: boolean; hasContent?: boolean }>`
	position: relative;
	transform-style: preserve-3d;

	input[type='text'] {
		width: 230px;
		box-sizing: border-box;
		height: 2.5rem;
		line-height: 1em;
		padding: 14px 11px 0;
		font-weight: 400;
		+ label {
			position: absolute;
			top: 14px;
			left: 13px;
			font-weight: 400;
			font-size: 13px;
			color: ${({ theme }) => theme.colors.neutral.n85};
			line-height: 1em;
			transition:
				transform 0.2s ease-in-out,
				color 0.2s ease-in-out;
			transform-origin: top left;
			pointer-events: none;
			${({ hasContent }) => hasContent && transformCss}
		}
		&:focus {
			+ label {
				${transformCss}
			}
		}
	}
`;

const Button = styled(ButtonOriginal)`
	width: 100%;
`;

Menu.addComponent('share-a-comp', ShareAComp);
