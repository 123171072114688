import React, { CSSProperties, useEffect } from 'react';

// TODO: deprecated icon - use 'ui/svg_icons/help_v2.svg' instead
import Help from '../../../../ui/svg_icons/help.svg';

import { Market } from '@compstak/common';
import {
	MovingAverageSelector,
	TimespanSelector,
	GraphTypeSelector,
} from 'Components/Graphs/Selectors';
import { usePermissions } from 'Pages/Login/reducers';
import { Chart, DataSetType } from 'Pages/Analytics/analytics';

import styles from './styles/analytics.less';
import { selectorStyles } from 'util/reactSelectStyles';
import styled from 'styled-components';
import { CalculationOverviewLink } from 'ui/CalculationOverviewLink';

interface ToolbarProps {
	chart: Chart;
	market: Market;
	setChartAttribute: (arg0: string, arg1: any) => void;
}

const Toolbar = (props: ToolbarProps) => {
	const permissions = usePermissions();
	const hasMultiFamilyAccess = permissions[props.market.id]?.multifamilyAccess;

	const setMovingAverage = (newValue: any) => {
		props.setChartAttribute('trendMonths', newValue);
	};

	const setTimespan = (newValue: any) => {
		props.setChartAttribute('timespan', newValue);
	};

	const setGraphType = (newValue: any) => {
		props.setChartAttribute('chartType', newValue);
	};

	const hasMultiFamilyDatasets = props.chart.dataSets
		.filter((ds) => ds.isVisible)
		.some((ds) => ds.type === DataSetType.MUFA);

	useEffect(() => {
		if (hasMultiFamilyDatasets && props.chart.chartType === 'histogram') {
			setGraphType('line');
		}
	}, [hasMultiFamilyDatasets, props.chart.chartType, setGraphType]);

	if (
		props.chart.dataSets.every(
			(dataSet: { isVisible: boolean }) => !dataSet.isVisible
		)
	) {
		return <div />;
	}

	return (
		<ToolbarContainer>
			<ToolbarContainerItem>
				<div className={styles.graphTypeSelector}>
					<span className={styles.label}>Graph Type</span>
					<GraphTypeSelector
						// @ts-expect-error ts-migrate(2769) FIXME: Property 'value' does not exist on type 'Intrinsic... Remove this comment to see the full error message
						value={props.chart.chartType}
						onChange={setGraphType}
						hasMultiFamilyDatasets={hasMultiFamilyDatasets}
					/>
				</div>
				<div className={styles.timespanSelector}>
					<span className={styles.label}>Timespan</span>
					<TimespanSelector
						value={props.chart.timespan}
						onChange={setTimespan}
						styles={{
							...selectorStyles,
							singleValue: (oldStyles: any) => ({
								...oldStyles,
								fontWeight: 500,
							}),
						}}
					/>
				</div>
				<div className={styles.movingAverageSelector}>
					<span className={styles.label}>
						{hasMultiFamilyAccess ? 'Commercial Mov. Avg.' : 'Mov. Average'}
						{hasMultiFamilyAccess && (
							<Help
								height={10}
								width={10}
								data-tooltip="Moving Average Updates are only supported for Compstak Commercial Lease and Sales Comp Data"
								data-tooltip-position="onright"
							/>
						)}
					</span>
					<MovingAverageSelector
						value={props.chart.trendMonths}
						onChange={setMovingAverage}
						styles={{
							...selectorStyles,
							singleValue: (oldStyles: CSSProperties) => ({
								...oldStyles,
								fontWeight: 500,
							}),
						}}
						data-qa-id="chartbuilder-moving-average"
					/>
				</div>
			</ToolbarContainerItem>
			<ToolbarContainerItem>
				<CalculationOverviewLink context="charts" />
			</ToolbarContainerItem>
		</ToolbarContainer>
	);
};

const ToolbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1rem;
	gap: 1rem;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;

const ToolbarContainerItem = styled.div`
	display: flex;
	flex-shrink: 0;
`;

export default Toolbar;
