import { Spinner } from '@compstak/ui-kit';
import { usePropertyByIdQuery } from 'api';
import { AddToPortfolioButton } from './AddToPortfolioButton';
import { GenerateCompSetButton } from './GenerateCompSetButton';
import { PropertyDetails } from './PropertyDetails';
import { PropertyPageLink } from './PropertyPageLink';
import { PropertyPopupHeader } from './PropertyPopupHeader';
import { CompPopupActions, CompPopupRoot, CompPopupRootLoading } from './UI';
import { useShouldShowPortfolioButton } from './useShouldShowPortfolioButton';

type Props = {
	propertyId: number;
	closePopup: () => void;
};

export const PropertyPopup = ({ propertyId, closePopup }: Props) => {
	const { data: property } = usePropertyByIdQuery({ id: propertyId });

	const showPortfolioButton = useShouldShowPortfolioButton();

	if (!property) {
		return (
			<CompPopupRootLoading>
				<Spinner size="l" />
			</CompPopupRootLoading>
		);
	}

	return (
		<CompPopupRoot>
			<PropertyPopupHeader property={property} closePopup={closePopup} />
			<PropertyDetails property={property} />
			<CompPopupActions>
				<PropertyPageLink propertyId={property.id} className="single-child">
					View Property Report
				</PropertyPageLink>
				{showPortfolioButton && <AddToPortfolioButton property={property} />}
				<GenerateCompSetButton property={property} closePopup={closePopup} />
			</CompPopupActions>
		</CompPopupRoot>
	);
};
