import { SimpleTable } from '@compstak/ui-kit';
import { useIsExchange } from 'hooks';
import { memo, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { SalesComp } from 'types';
import { useAppSelector } from 'util/useAppSelector';
import {
	SeeAllLink,
	TabContentContainer,
	TabContentHeader,
	TabContentTitle,
} from '../../Components';
import { useColumns } from './columns';
import {
	useBodyCellProps,
	useCurrentlyActiveLeases,
	useLeasesActiveAtTimeOfSale,
	useLeaseSnapshotData,
} from './hooks';

type LeaseSnapshotProps = {
	data: SalesComp;
};

export const LeaseSnapshot = memo<LeaseSnapshotProps>(({ data }) => {
	const { market, saleDate, portfolio } = data;
	const { propertyId, buildingAddressAndCity: propertyAddress } = portfolio[0];

	const {
		data: leaseSnapshotData,
		isLoading,
		refetch,
	} = useLeaseSnapshotData({
		addresses: [propertyAddress],
		market,
		sortBy: 'executionQuarter',
	});

	const leasesActiveAtTimeOfSale = useLeasesActiveAtTimeOfSale({
		leaseSnapshotData,
		saleDate,
	});

	const currentlyActiveLeases = useCurrentlyActiveLeases(
		leaseSnapshotData?.comps ?? []
	);

	const seeAllLeasesLink = useMemo(() => {
		if (!leaseSnapshotData?.comps?.length) return null;
		return (
			<SeeAllLink
				to={`/property/${propertyId}`}
				state={{ scrollToLeases: true }}
				data-qa-id="SeeAllSalesLink"
			>
				{viewAllLinkText}
			</SeeAllLink>
		);
	}, [leaseSnapshotData, propertyId]);

	const unlockedComps = useAppSelector((s) => s.unlockV2.unlockedComps);

	useEffect(() => {
		if (Object.keys(unlockedComps).length > 0) {
			refetch();
		}
	}, [unlockedComps]);

	const bodyCellProps = useBodyCellProps();
	const isExchange = useIsExchange();
	const columns = useColumns();

	const commonTableProps = {
		isLoading,
		columns,
		scrollContainerProps: {
			style: {
				maxHeight: 400,
			},
		},
		isBodyHoverable: true,
		getBodyCellProps: bodyCellProps,
	};

	return (
		<div>
			<TabContentContainer>
				<TabContentHeader>
					<TabContentTitle>
						{isExchange ? 'Unlocked' : ''} Active Leases at Time of Sale
					</TabContentTitle>
					{seeAllLeasesLink}
				</TabContentHeader>
				<SimpleTable
					id="activeLeasesTimeOfSaleTable"
					rows={leasesActiveAtTimeOfSale}
					{...commonTableProps}
				/>
			</TabContentContainer>
			<StyledTabContentContainer>
				<TabContentHeader>
					<TabContentTitle>Currently Active Leases</TabContentTitle>
					{seeAllLeasesLink}
				</TabContentHeader>
				<SimpleTable
					id="currentlyActiveLeasesTable"
					rows={currentlyActiveLeases}
					{...commonTableProps}
				/>
			</StyledTabContentContainer>
			<StyledTabContentContainer>
				<TabContentHeader>
					<TabContentTitle>All Leases</TabContentTitle>
					{seeAllLeasesLink}
				</TabContentHeader>
				<SimpleTable
					id="activeLeasesTable"
					rows={leaseSnapshotData?.comps ?? []}
					{...commonTableProps}
				/>
			</StyledTabContentContainer>
		</div>
	);
});

LeaseSnapshot.displayName = 'LeaseSnapshot';

const StyledTabContentContainer = styled(TabContentContainer)`
	margin-top: 8px;
	margin-bottom: 32px;
`;

const viewAllLinkText = 'view full lease history';
