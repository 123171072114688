import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { FiltersObject } from 'models/filters/types';
import { CompType } from 'types';
import { SearchToolbarV2_ListToolbar } from './SearchToolbarV2_ListToolbar';
import { SearchToolbarV2_MapToolbar } from './SearchToolbarV2_MapToolbar';
import './toolbar.nomodule.less';

type SearchToolbarV2Props = {
	compType: CompType;
	filters: FiltersObject;
	params: SearchLayoutRouteParams;
};

export const SearchToolbarV2 = ({
	compType,
	filters,
	params,
}: SearchToolbarV2Props) => {
	const isListToolbar =
		params.view === 'list' || params.view === 'leagueTables';
	const isMapToolbar = !isListToolbar;
	return (
		<div className={'search_content_toolbar ' + params.view}>
			{isMapToolbar && (
				<SearchToolbarV2_MapToolbar
					compType={compType}
					filters={filters}
					params={params}
				/>
			)}
			{isListToolbar && (
				<SearchToolbarV2_ListToolbar
					compType={compType}
					filters={filters}
					params={params}
				/>
			)}
		</div>
	);
};
