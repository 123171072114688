import { usePermissions, useUser } from 'Pages/Login/reducers';
import { FilterSpacer } from 'Components/Filters/Base/Filter/FilterSpacer';
import { useShouldHideMyCompsForExchangePlus } from './FilterFields';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useTopTenantIndustriesQuery } from 'api/topTenantIndustries/useTopTenantIndustriesQuery';
import { useCallback } from 'react';
import { FilterFieldButton } from 'Components/Filters/Base/Filter/FilterFieldButton';
import { AddressFilter } from 'Components/Filters/Fields/AddressFilter/AddressFilter';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { SpaceTypeFilter } from 'Components/Filters/Fields/AggregationFilter/SpaceTypeFilter';
import { PropertyTypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertyTypeFilter';
import { PropertySubtypeFilter } from 'Components/Filters/Fields/AggregationFilter/PropertySubtypeFilter';
import { CountyAPNFilter } from 'Components/Filters/Fields/CountyAPNFilter/CountyAPNFilter';
import { SpaceSubtypeFilter } from 'Components/Filters/Fields/AggregationFilter/SpaceSubtypeFilter';
import { CityFilter } from 'Components/Filters/Fields/CityFilter/CityFilter';
import { ZipFilter } from 'Components/Filters/Fields/ZipFilter/ZipFilter';
import { BuildingClassFilter } from 'Components/Filters/Fields/BuildingClassFilter/BuildingClassFilter';
import { BuildingSizeFilter } from 'Components/Filters/Fields/BuildingSizeFilter/BuildingSizeFilter';
import { BuildingFloorsCount } from 'Components/Filters/Fields/BuildingFloorsCount/BuildingFloorsCount';
import { YearBuilt } from 'Components/Filters/Fields/YearBuilt/YearBuilt';
import { FloorsFilter } from 'Components/Filters/Fields/FloorsFilter/FloorsFilter';
import { BuildingNameFilter } from 'Components/Filters/Fields/BuildingNameFilter/BuildingNameFilter';
import { TransactionSizeFilter } from 'Components/Filters/Fields/TransactionSizeFilter/TransactionSizeFilter';
import { TransactionTypeFilter } from 'Components/Filters/Fields/TransactionTypeFilter/TransactionTypeFilter';
import { StartingRentFilter } from 'Components/Filters/Fields/StartingRentFilter/StartingRentFilter';
import { EffectiveRentFilter } from 'Components/Filters/Fields/EffectiveRentFilter/EffectiveRentFilter';
import { LeaseTypeFilter } from 'Components/Filters/Fields/LeaseTypeFilter/LeaseTypeFilter';
import { SubleaseFilter } from 'Components/Filters/Fields/SubleaseFilter/SubleaseFilter';
import { YearRenovatedFilter } from 'Components/Filters/Fields/YearRenovatedFilter/YearRenovatedFilter';
import { LeaseTermFilter } from 'Components/Filters/Fields/LeaseTermFilter/LeaseTermFilter';
import { ExecutionDateFilter } from 'Components/Filters/Fields/ExecutionDateFilter/ExecutionDateFilter';
import { ExpirationDateFilter } from 'Components/Filters/Fields/ExpirationDateFilter/ExpirationDateFilter';
import { BrokerageFilter } from 'Components/Filters/Fields/BrokerageFilter/BrokerageFilter';
import { TenantFilter } from 'Components/Filters/Fields/TenantFilter/TenantFilter';
import { TenantIndustryFilter } from 'Components/Filters/Fields/TenantIndustryFilter/TenantIndustryFilter';
import { TenantRevenueFilter } from 'Components/Filters/Fields/TenantRevenueFilter/TenantRevenueFilter';
import { TenantEmployeesFilter } from 'Components/Filters/Fields/TenantEmployeesFilter/TenantEmployeesFilter';
import { TenantOwnershipFilter } from 'Components/Filters/Fields/TenantOwnershipFilter/TenantOwnershipFilter';
import { TenantTickerFilter } from 'Components/Filters/Fields/TenantTickerFilter/TenantTickerFilter';
import { TenantStatusFilter } from 'Components/Filters/Fields/TenantStatusFilter/TenantStatusFilter';
import { LandlordFilter } from 'Components/Filters/Fields/LandlordFilter/LandlordFilter';
import { DateCreatedFilter } from 'Components/Filters/Fields/DateCreatedFilter/DateCreatedFilter';
import { HiddenFilter } from 'Components/Filters/Fields/HiddenFilter/HiddenFilter';
import { PartialFilter } from 'Components/Filters/Fields/PartialFilter/PartialFilter';
import { MyCompsFilter } from 'Components/Filters/Fields/MyCompsFilter/MyCompsFilter';

export const LeaseFilterFields = () => {
	const user = useUser();
	const permissions = usePermissions();
	const { filters, onFilterChange } = useFilterFieldContext();
	const {
		data: topTenantIndustriesData,
		isLoading: isLoadingTopTenantIndustriesData,
	} = useTopTenantIndustriesQuery({
		filters: { markets: getFiltersMarkets(filters) },
	});
	const shouldHideMyCompsFilter = useShouldHideMyCompsForExchangePlus();

	let i = 0;

	const handleTopInMarket = useCallback(() => {
		if (!topTenantIndustriesData) {
			return;
		}

		onFilterChange({
			tenantIndustry: topTenantIndustriesData.map(
				(tenantIndustry) => tenantIndustry.industry
			),
		});
	}, [topTenantIndustriesData, onFilterChange]);

	const insideViewFields = [
		<TenantRevenueFilter key={++i} />,
		<TenantEmployeesFilter key={++i} />,
		<TenantOwnershipFilter key={++i}>
			{filters.insideviewOwnershipId === 5 && <TenantTickerFilter />}
		</TenantOwnershipFilter>,

		<TenantStatusFilter key={++i} />,
		<FilterSpacer key={++i} />,
	];

	let fields = [
		<AddressFilter key={++i} />,
		<CityFilter key={++i} />,
		<ZipFilter key={++i} />,
		<CountyAPNFilter key={++i} />,

		<FilterSpacer key={++i} />,

		<SpaceTypeFilter key={++i}>
			<SpaceSubtypeFilter />
		</SpaceTypeFilter>,
		<PropertyTypeFilter key={++i}>
			<PropertySubtypeFilter />
		</PropertyTypeFilter>,

		<BuildingClassFilter key={++i} />,
		<BuildingSizeFilter key={++i} />,
		<BuildingFloorsCount key={++i} />,
		<FilterSpacer key={++i} />,

		<StartingRentFilter key={++i} />,
		<EffectiveRentFilter key={++i} />,
		<LeaseTypeFilter key={++i} />,

		<FilterSpacer key={++i} />,

		<TransactionSizeFilter key={++i} />,
		<ExecutionDateFilter key={++i} />,
		<TransactionTypeFilter key={++i} />,

		<FilterSpacer key={++i} />,

		<TenantFilter key={++i} />,
		<TenantIndustryFilter key={++i}>
			<FilterFieldButton
				disabled={isLoadingTopTenantIndustriesData}
				onClick={handleTopInMarket}
			>
				Select Top 10 in Market
			</FilterFieldButton>
		</TenantIndustryFilter>,
		...insideViewFields,
		<SubleaseFilter key={++i} />,
	].filter(Boolean);

	const lastFields = [
		<ExpirationDateFilter key={++i} />,
		<BuildingNameFilter key={++i} />,
		<YearBuilt key={++i} />,
		<YearRenovatedFilter key={++i} />,
		<LandlordFilter key={++i} />,
		<BrokerageFilter key={++i} />,
		<FloorsFilter key={++i} />,
		<LeaseTermFilter key={++i} />,
		<DateCreatedFilter key={++i} />,
	];

	const adminFields = [<HiddenFilter key={++i} />, <PartialFilter key={++i} />];

	if (
		!shouldHideMyCompsFilter &&
		getFiltersMarkets(filters).some(
			(market) => !permissions?.[market.id]?.ownsAllLeaseComps
		)
	) {
		fields = fields.concat([<MyCompsFilter key={++i} />], lastFields);
	} else {
		fields = fields.concat(lastFields);
	}

	if (user?.userType === 'admin') {
		fields = fields.concat(adminFields);
	}

	return <>{fields}</>;
};
