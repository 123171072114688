import { Button } from '@compstak/ui-kit';
import { Portfolio } from 'api';
import { Link } from 'router';
import { routes } from 'router';
import styled from 'styled-components';
import IconSearch from '../ui/svg_icons/search.svg';
import { PortfolioOptionsButton } from './PortfolioOptions';

type Props = {
	portfolio?: Portfolio;
};

export const EmptyPortfolio = ({ portfolio }: Props) => {
	return (
		<Container>
			<GrayLabel>GETTING STARTED</GrayLabel>
			<Body>
				<Title>How to get started with CompStak Portfolios</Title>
				<Text>
					Organizing properties into portfolios can be a useful way to view and
					analyze property data. To get started, simply search for properties to
					add or import your data.
				</Text>
			</Body>
			<ButtonsContainer>
				<Link to={routes.searchPropertiesHome.path}>
					<StyledButton
						size="l"
						icon={<SearchStyled width={19} height={19} />}
						data-qa-id="pa-search-property-btn"
					>
						SEARCH FOR PROPERTIES
					</StyledButton>
				</Link>
				{portfolio && <PortfolioOptionsButton portfolio={portfolio} />}
			</ButtonsContainer>
		</Container>
	);
};

export const Container = styled.div`
	max-width: 600px;
	padding: 3rem;
`;

const GrayLabel = styled.div`
	font-size: 0.75rem;
	color: ${(p) => p.theme.colors.neutral.n80};
`;

const Body = styled.div`
	padding-top: 1rem;
	padding-bottom: 1.5rem;
`;

const Title = styled.div`
	font-size: 1.75rem;
	color: ${(p) => p.theme.colors.gray.gray700};
	font-weight: 300;
	line-height: 1.5;
	margin-bottom: 1.25rem;
	letter-spacing: 0.05em;
`;

const Text = styled.div`
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.05em;
	font-weight: 300;
	color: ${(p) => p.theme.colors.gray.gray700};
`;

const SearchStyled = styled(IconSearch)`
	fill: ${({ theme }) => theme.colors.white.white};
`;

const StyledButton = styled(Button)`
	gap: 0.5rem;
`;

const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;
