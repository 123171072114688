import React from 'react';
import isEqual from 'lodash/isEqual';

import withProjects from '../../Repository/withProjects';

import withModal from 'Singletons/Modal/withModal';
import saveModal from './Modals/Save';

import button from 'ui/styles/button.less';
import spinner from 'ui/styles/spinner.less';
import { Chart } from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';

type Props = {
	chart: Chart;
	chartDraft: Chart;
	markets: MarketsState;
};

export default withModal(
	withProjects(
		class SaveButton extends React.Component<Props> {
			getOriginalChart = () => {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjects' does not exist on typ... Remove this comment to see the full error message
				const originalChart = this.props.analyticsProjects
					// @ts-expect-error TS7006: Parameter 'project' implicitly...
					.map((project) => project.charts)
					.flat()
					// @ts-expect-error TS7006: Parameter 'chart' implicitly h...
					.find((chart) => chart.id === this.props.chartDraft.originalChartId);

				const { dataSets, trendMonths, timespan, title, chartType, id } =
					originalChart || {};

				// transform original chart to match this.props.chart dataset
				return originalChart == null
					? null
					: {
							dataSets,
							chartType,
							originalChartId: id,
							timespan,
							trendMonths,
							title,
							// @ts-expect-error TS2339: Property 'userId' does not exi...
							userId: this.props.chartDraft.userId,
						};
			};

			hasDuplicateTitle = () => {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjects' does not exist on typ... Remove this comment to see the full error message
				const currentProject = this.props.analyticsProjects.find((project) =>
					project.charts.some(
						// @ts-expect-error TS7006: Parameter 'chart' implicitly h...
						(chart) => chart.id === this.props.chartDraft.originalChartId
					)
				);
				const originalChart = this.getOriginalChart();

				return (
					currentProject != null &&
					originalChart != null &&
					this.props.chartDraft.title !== originalChart.title &&
					currentProject.charts.some(
						// @ts-expect-error TS7006: Parameter 'chart' implicitly h...
						(chart) => chart.title === this.props.chartDraft.title
					)
				);
			};

			handleClick = () => {
				const originalChart = this.getOriginalChart();
				if (!isEqual(this.props.chart, originalChart)) {
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
					this.props.modalActions.pushModal(saveModal, {
						hasDuplicateTitle: this.hasDuplicateTitle(),
						chartDraft: this.props.chartDraft,
						markets: this.props.markets,
					});
				}
			};

			render() {
				const originalChart = this.getOriginalChart();
				const saveButtonClass = isEqual(this.props.chart, originalChart)
					? button.disabled
					: button.blue;
				return (
					<div className={saveButtonClass} onClick={this.handleClick}>
						Save
					</div>
				);
			}
		},
		// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
		() => (
			<div className={button.blue}>
				<div className={spinner.spinner} />
			</div>
		)
	)
);
