import {
	SearchTable,
	SearchTableExportButton,
	SearchTableProps,
} from 'Components/SearchTable';
import { SearchTableUnlockButton } from 'Components/SearchTable/UnlockButton';
import { useSearchTableRows } from 'Pages/Search/useSearchTableRows';
import {
	LeaseAndSaleAveragesResponse,
	SearchLeasesResponse,
	useSearchInfiniteParams,
	useSearchLeasesInfiniteQuery,
	useSearchTableAverages,
} from 'api';
import { useLeasesSalesAveragesFormatting } from 'hooks/useLeasesSalesAveragesFormatting';
import { useSearchTableSelection } from 'hooks/useSearchTableSelection';
import { filtersToQueryString } from 'models/filters/util';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { useCallback } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { routes } from 'router/routes';
import { LeaseComp } from 'types';

type Props = Partial<SearchTableProps<LeaseComp>>;

export const LeasesSearchTable = (props: Props) => {
	const [filters, setFilters] = useFilters('exchange-dashboard');

	const params = useSearchInfiniteParams({ filters });

	const {
		data: leasesData,
		isFetching,
		fetchNextPage,
		hasNextPage,
	} = useSearchLeasesInfiniteQuery(params);

	const totalCount = leasesData?.pages[0].totalCount ?? 0;
	const searchAverages = leasesData?.pages[0].averages;

	const rows = useSearchTableRows<LeaseComp>({
		data: leasesData,
		getRows: useCallback((page: SearchLeasesResponse) => page.comps, []),
		isFetching,
	});

	const { data: averages } = useSearchTableAverages({
		compType: 'lease',
		searchAverages,
		filters,
	});

	const averagesFormatting = useLeasesSalesAveragesFormatting({
		averages: averages as LeaseAndSaleAveragesResponse,
		filters,
	});

	const selectionProps = useSearchTableSelection({
		compType: 'lease',
		totalCount,
		filters,
	});

	return (
		<SearchTable
			{...props}
			{...selectionProps}
			sortName={filters.sortField}
			sortDirection={filters.sortDirection}
			onSort={(name, dir) => {
				setFilters(
					mergeFilters(filters, {
						// @ts-expect-error filters
						sortField: name,
						sortDirection: dir,
					})
				);
			}}
			rows={rows}
			averages={averagesFormatting}
			getRowId={(row) => row.id}
			noData={!isFetching && totalCount === 0}
			onLoadMore={() => {
				if (hasNextPage && !isFetching) {
					fetchNextPage();
				}
			}}
			columnPreferencesType="lease"
			renderActionButton={(row) => {
				if (row.own) {
					return <SearchTableExportButton compType="lease" row={row} />;
				} else {
					return <SearchTableUnlockButton type="lease" row={row} />;
				}
			}}
			getBodyCellProps={(row, rowIndex) => ({
				to: routes.leaseById.toHref({ id: row.id }),
				state: {
					index: rowIndex,
					queryString: filtersToQueryString(filters),
				},
			})}
		/>
	);
};
