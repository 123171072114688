import constate from 'constate';
import { RouterLocation } from 'types';
import { useSelectionState } from 'utils/useSelectionState';
import { useListViewLeases } from './useListViewLeases';

type Props = {
	portfolioId: number;
	location: RouterLocation;
};

export const [LeasesSelectionProvider, useLeasesSelectionContext] = constate(
	({ portfolioId, location }: Props) => {
		const { data: leases } = useListViewLeases({
			portfolioId,
			location,
		});

		return useSelectionState(leases);
	}
);
