import React from 'react';
import Menu from 'Singletons/Menu';
import { MenuContainer } from '../styles/PortfolioUI';
import { ChartOption } from 'PortfolioAnalytics/Charts/ChartSelect';

const PortfolioChartSelectMenu = (props: {
	chartOptions: ChartOption[];
	setSelectedChart: React.Dispatch<React.SetStateAction<ChartOption>>;
}) => {
	return (
		<MenuContainer width="250px" minHeight="40px">
			<ul>
				{props.chartOptions.map((chartOption) => (
					<li
						key={chartOption.type}
						onClick={() => props.setSelectedChart(chartOption)}
					>
						{chartOption.value}
					</li>
				))}
			</ul>
		</MenuContainer>
	);
};

Menu.addComponent('portfolio-chart-select-menu', PortfolioChartSelectMenu);

export default 'portfolio-chart-select-menu';
