import React, { PropsWithChildren } from 'react';
import ReactTimeout from 'react-timeout';
import styled from 'styled-components';

import { Spinner } from '@compstak/ui-kit';
import { connect } from 'react-redux';
import IconCross from '../../../ui/svg_icons/cross.svg';
import { MAP_CONTROLS, searchActions } from 'actions/search';

import pluralizeCompType from 'util/pluralizeCompType';
import { AppDispatch } from 'store';
import { ActiveMapControl, CompType } from 'types';
import { FiltersObject } from 'models/filters/types';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { filtersToQueryString } from 'models/filters/util/urls';
import actionWrapper from 'util/actionWrapper';
import { push } from 'router';

export type MapControlProps = PropsWithChildren<{
	active: boolean;
	alwaysActive?: boolean;
	id: ActiveMapControl;
	compType: CompType;
	icon: React.ElementType;
	loading?: boolean;
	params?: SearchLayoutRouteParams;
	filters: FiltersObject;
	label: string;
	onFilterChange: (newFilters: Partial<FiltersObject>) => void;
}> &
	ReturnType<typeof mapDispatchToProps>;

type State = { glow: boolean };

// TODO to remove once sidebarRevampFF is on (don't forget about css)
export class MapControl extends React.Component<MapControlProps, State> {
	constructor(props: MapControlProps) {
		super(props);
		this.state = {
			glow: false,
		};
	}

	selectMapControl = () => {
		this.props.searchActions.selectMapControl(this.props.id);
		if (this.props.params?.view === 'list') {
			this.props.redirect(
				'/search/' +
					pluralizeCompType(this.props.compType, false) +
					'/map?' +
					filtersToQueryString(this.props.filters)
			);
		}
	};

	triggerGlow() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'glowTimeout' does not exist on type 'Map... Remove this comment to see the full error message
		this.glowTimeout && window.clearTimeout(this.glowTimeout);
		this.setState({ glow: true });
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'setTimeout' does not exist on type 'Read... Remove this comment to see the full error message
		this.props.setTimeout(() => {
			this.setState({ glow: false });
		}, 2000);
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	deselectMapControl = (event) => {
		event.stopPropagation();
		this.props.searchActions.deselectMapControl();
	};

	componentWillReceiveProps() {
		if (this.props.id === MAP_CONTROLS.SEARCH_WITHIN_VIEW) {
			this.triggerGlow();
		}
	}

	render() {
		let className = 'sidebar-control';
		const mapControlType = this.props.label.split(' ').join('-').toLowerCase();

		if (this.props.active) {
			className += ' sidebar-control-active';
			if (this.state.glow) {
				className += ' glow';
			}
			return (
				<li>
					<div
						onClick={this.selectMapControl}
						className={className}
						data-map-control-type={mapControlType}
					>
						<span className="sidebar-control-label">{this.props.label}</span>
						{this.props.loading ? (
							<StyledSpinner size="m" />
						) : (
							!this.props.alwaysActive && (
								<span
									key={'close-' + this.props.active}
									onClick={this.deselectMapControl}
									className="sidebar-control-close"
								>
									<IconCross />
								</span>
							)
						)}
					</div>
					{this.props.children}
				</li>
			);
		} else {
			const Icon = this.props.icon;
			return (
				<li>
					<div
						onClick={this.selectMapControl}
						className={className}
						data-map-control-type={mapControlType}
					>
						<span className="sidebar-control-label">{this.props.label}</span>
						<span key={'icon-' + this.props.active}>
							<Icon className="sidebar-control-icon" />
						</span>
					</div>
				</li>
			);
		}
	}
}

function mapDispatchToProps(dispatch: AppDispatch) {
	return {
		redirect: push,
		...actionWrapper(
			{
				searchActions,
			},
			dispatch
		),
	};
}

export default connect(null, mapDispatchToProps)(ReactTimeout(MapControl));

const StyledSpinner = styled(Spinner)`
	position: absolute;
	right: 12px;
	top: 12px;
`;
