import React from 'react';
import { VictoryTooltipProps } from 'victory';

import { metricDropdownData } from '../Filters';

import { MarketTrendMetricFilter } from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { Colors } from '..';

import { Styled } from './styled';

import { formatDate } from '../util';
import money from 'ui/util/money';

type MarketTrendChartFlyoutProps = VictoryTooltipProps & {
	width: number;
	metricFilter: MarketTrendMetricFilter;
	marketName: string;
	chartWidth: number;
};

export const MarketTrendChartFlyout = ({
	x,
	activePoints,
	width,
	metricFilter,
	marketName,
	chartWidth,
}: MarketTrendChartFlyoutProps) => {
	if (!activePoints || !activePoints[0] || !activePoints[1] || !x) return null;

	const xValue = activePoints[0].x;
	const yValueData = {
		portfolio: activePoints[0].y,
		market: activePoints[1].y,
	};
	const metricType = metricDropdownData.find((m) => m.id === metricFilter)
		?.value;

	const showRightTooltipBar = x > chartWidth / 2;

	const tooltipBarWidth = 5;
	const translateX = showRightTooltipBar
		? x + tooltipBarWidth - width
		: x - tooltipBarWidth;

	return (
		<g transform={`translate(${translateX}, ${50})`}>
			<foreignObject width={width} height={220}>
				<Styled.CustomTooltipContainer>
					<Styled.CustomTooltipBar hide={showRightTooltipBar}>
						<div />
					</Styled.CustomTooltipBar>
					<Styled.CustomTooltipInfoBox>
						<Styled.CustomTooltipInfoBoxHeading>
							<div>{formatDate(xValue, false)}</div>
							<div>{`${metricType} ($/PSF)`}</div>
						</Styled.CustomTooltipInfoBoxHeading>
						<Styled.CustomTooltipInfoBoxData>
							<div>
								<Styled.CustomTooltipInfoBoxIndicator color={Colors.MARKET} />
								<Styled.CustomTooltipInfoBoxName>
									{`Market - ${marketName}`}
								</Styled.CustomTooltipInfoBoxName>
							</div>
							<div>
								<span>{money(yValueData.market, 'USD', 2, false)}</span>
							</div>
						</Styled.CustomTooltipInfoBoxData>
						<Styled.CustomTooltipInfoBoxData>
							<div>
								<Styled.CustomTooltipInfoBoxIndicator
									color={Colors.PORTFOLIO}
								/>
								<Styled.CustomTooltipInfoBoxName>
									{marketName}
								</Styled.CustomTooltipInfoBoxName>
							</div>
							<div>
								<span>{money(yValueData.portfolio, 'USD', 2, false)}</span>
							</div>
						</Styled.CustomTooltipInfoBoxData>
					</Styled.CustomTooltipInfoBox>
					<Styled.CustomTooltipBar
						hide={!showRightTooltipBar}
						right={showRightTooltipBar}
					>
						<div />
					</Styled.CustomTooltipBar>
				</Styled.CustomTooltipContainer>
			</foreignObject>
		</g>
	);
};
