import { Chart, DataSet, DataSetType, Shape } from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';
import React, { useCallback, useMemo } from 'react';
import { ChartBuilderActions } from '../actions';
import { DEFAULT_CHART } from '../chartBuilderConstants';
import { DataSetColor, getColorForDataSetIndex } from '../colors';

type Props = {
	chartDraft: Chart;
	colors: DataSetColor[];
	markets: MarketsState;
	chartBuilderActions: ChartBuilderActions;
};

/**
 * Sorting shapes because we need new order: commercial(leases), sales, mufa.
 * AP-9537
 */
function compareShapes(a: DataSet, b: DataSet): number {
	if (!a.type || !b.type) return 0;

	const order = [DataSetType.COMMERCIAL, DataSetType.SALES, DataSetType.MUFA];

	const indexA = order.indexOf(a.type);
	const indexB = order.indexOf(b.type);

	return indexA - indexB;
}

function createShapes(chartDraft: Chart, colors: string[]): Shape[] {
	return chartDraft.dataSets.sort(compareShapes).map((dataset, idx) => ({
		trendMonths: chartDraft.trendMonths,
		filters: dataset.filters,
		excludedCompIds: dataset.excludedCompIds,
		x: 'executionDate',
		y: dataset.series,
		z: 'transactionSize',
		color: getColorForDataSetIndex(idx, colors),
		name: dataset.name,
		points: 50,
		dataSetType: dataset.type ?? DataSetType.COMMERCIAL,
	}));
}

// TODO: This whole InsightsLayout component can be removed and it's logic
// can be moved to the AnalyticsLayout component.
export default (Component: React.ComponentType<any>) =>
	function InsightsLayout(props: Props) {
		const chartDraft = useMemo(
			() =>
				props.chartDraft ?? {
					...DEFAULT_CHART,
					dataSets: [],
				},
			[props.chartDraft]
		);

		const { chartBuilderActions, markets } = props;

		const setChartAttribute = useCallback(
			// @ts-expect-error TS7006: Parameter 'attribute' implicit...
			(attribute, newValue) => {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				if (chartDraft[attribute] === newValue) {
					return;
				}
				const newChartDraft = {
					...chartDraft,
					[attribute]: newValue,
				};
				chartBuilderActions.updateChartDraft(newChartDraft, markets);
			},
			[chartDraft, chartBuilderActions, markets]
		);

		const shapes = useMemo(
			() => createShapes(chartDraft, props.colors),
			[chartDraft, props.colors]
		);

		return (
			<Component
				options={{
					chartDraft,
					shapes,
				}}
				setChartAttribute={setChartAttribute}
				shapes={shapes}
				{...props}
				chartDraft={chartDraft}
			/>
		);
	};
