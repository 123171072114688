import React from 'react';

import Modal from 'Singletons/Modal';
import withChartBuilder from 'Pages/Analytics/Builder/withChartBuilder';
import withProjects from 'Pages/Analytics/Repository/withProjects';

import { OverwriteWarning, SaveChartForm, SavingInProgress } from './Screens';

import dayjs from 'dayjs';
import { MarketsState } from 'Pages/Login/reducers';
import { Chart } from 'Pages/Analytics/analytics';
import { AnalyticsProjectActions } from 'Pages/Analytics/Repository/actions';
import { ChartBuilderActions } from 'Pages/Analytics/Builder/actions';

type Props = {
	chart: Chart;
	chartDraft: Chart | null;
	markets: MarketsState;
	analyticsLastUpdated: string;
	analyticsProjectActions: AnalyticsProjectActions;
	chartBuilderActions: ChartBuilderActions;
};

type State = {
	useSaveAsForExistingChart: boolean;
	lastUpdated: string;
	saveWasSuccessful: boolean;
	savedChartName: string | null;
	savedChartProject: Chart | null;
	isSaving: boolean;
};

export class SaveModal extends React.Component<Props, State> {
	// @ts-expect-error TS2416: Property 'state' in type 'Save...
	state = {
		useSaveAsForExistingChart: false,
		lastUpdated: this.props.analyticsLastUpdated || dayjs(),
		saveWasSuccessful: false,
		savedChartName: null,
		savedChartProject: null,
		isSaving: false,
	};

	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		if (nextProps.analyticsLastUpdated > prevState.lastUpdated) {
			return {
				saveWasSuccessful: true,
			};
		}
		return null;
	}

	componentDidUpdate(oldProps: Props, oldState: State) {
		if (this.state.saveWasSuccessful && !oldState.saveWasSuccessful) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.modalActions.popModal();
		}
		if (
			this.state.saveWasSuccessful &&
			!oldState.saveWasSuccessful &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onSave' does not exist on type 'Readonly... Remove this comment to see the full error message
			this.props.onSave
		) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onSave' does not exist on type 'Readonly... Remove this comment to see the full error message
			this.props.onSave();
		}
	}

	handlePlainSave = () => {
		const {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'hasDuplicateTitle' does not exist on typ... Remove this comment to see the full error message
			hasDuplicateTitle,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjects' does not exist on typ... Remove this comment to see the full error message
			analyticsProjects,
			analyticsProjectActions,
			chartDraft,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
			modalActions,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onSave' does not exist on type 'Readonly... Remove this comment to see the full error message
			onSave,
			markets,
		} = this.props;

		if (!hasDuplicateTitle && chartDraft) {
			// @ts-expect-error TS7006: Parameter 'p' implicitly has a...
			const project = analyticsProjects.find((p) => {
				return p.charts.find(
					// @ts-expect-error TS7006: Parameter 'chart' implicitly h...
					(chart) => chart.id === chartDraft.originalChartId
				);
			});

			console.assert(project, 'project should always exist?!');

			this.setState({
				// @ts-expect-error TS2322: Type 'string | undefined' is n...
				savedChartName: chartDraft.title,
				savedChartProject: project.name,
			});

			const newChart = {
				...chartDraft,
				id: chartDraft.originalChartId,
				projectId: project.id,
				originalChartId: null,
			};
			analyticsProjectActions.saveChart(newChart, markets);
			this.setState({ isSaving: true });
			modalActions.popModal();
			if (onSave) {
				onSave();
			}
		}
	};

	// @ts-expect-error TS7006: Parameter 'draft' implicitly h...
	handleSaveAs = (draft, projectName) => {
		const newChart = {
			...draft,
			id: null,
			originalChartId: null,
		};

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjects' does not exist on typ... Remove this comment to see the full error message
		const project = this.props.analyticsProjects.find(
			// @ts-expect-error TS7006: Parameter 'p' implicitly has a...
			(p) => p.name === projectName
		);

		this.setState({
			savedChartName: newChart.title,
			savedChartProject: project || { name: projectName },
		});
		this.props.chartBuilderActions.updateChartDraft(
			// @ts-expect-error TS2345: Argument of type '{ title: any...
			{
				...this.props.chartDraft,
				title: newChart.title,
			},
			this.props.markets
		);
		if (project) {
			newChart.projectId = project.id;
			this.props.analyticsProjectActions.saveChart(
				newChart,
				this.props.markets
			);
		} else {
			this.props.analyticsProjectActions.createNewProjectWithChart(
				projectName,
				newChart,
				this.props.markets
			);
		}
	};

	setSaveAs = () => {
		this.setState({
			useSaveAsForExistingChart: true,
		});
	};

	render() {
		const isExistingChart = !!this.props.chartDraft?.originalChartId;

		if (this.state.isSaving) {
			return <SavingInProgress />;
		}

		if (isExistingChart && !this.state.useSaveAsForExistingChart) {
			return (
				<OverwriteWarning
					// @ts-expect-error ts-migrate(2769) FIXME: Property 'errorMessage' does not exist on type 'In... Remove this comment to see the full error message
					errorMessage={
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'hasDuplicateTitle' does not exist on typ... Remove this comment to see the full error message
						this.props.hasDuplicateTitle
							? 'A chart with this name already exists in this project'
							: null
					}
					onSave={this.handlePlainSave}
					onSaveAs={this.setSaveAs}
				/>
			);
		}

		return <SaveChartForm {...this.props} onSave={this.handleSaveAs} />;
	}
}

const modalName = 'analytics-save-chart-dialog';

Modal.addComponent({
	id: modalName,
	// @ts-expect-error TS2345: Argument of type 'typeof SaveM...
	Component: withProjects(withChartBuilder(SaveModal)),
});

export default modalName;
