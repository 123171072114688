import { MAP_CONTROLS } from 'actions/search';
import { MapFilterV2Base } from './MapFilterV2Base';
import IconRadiusMapFilter from 'ui/svg_icons/map-radius-icon.svg';
import { RadiusFilter } from '../RadiusFilter/RadiusFilter';

export const RadiusMapFilterV2 = () => {
	return (
		<MapFilterV2Base
			Icon={IconRadiusMapFilter}
			key={MAP_CONTROLS.RADIUS}
			label="Radius Filter"
			mapControl={MAP_CONTROLS.RADIUS}
		>
			{(childrenProps) => <RadiusFilter {...childrenProps} />}
		</MapFilterV2Base>
	);
};
