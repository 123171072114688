import React, { ChangeEvent, useState } from 'react';
import { validateEmail } from 'util/validate';
import './invite-a-colleague.nomodule.less';
import { checkStringLength } from 'util/checkStringLength';
import { emailConstants } from '@compstak/common';
import { useReferColleguesV2Mutation } from 'api/referrals/useReferColleguesV2Mutation';
import { useModal } from 'providers/ModalProvider';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from '../common/UI';

export const InviteACollegueModal = () => {
	const [email, setEmail] = useState('');
	const [emailClass, setEmailClass] = useState('');
	const [firstName, setFirstName] = useState('');
	const [firstNameClass, setFirstNameClass] = useState('');
	const [lastName, setLastName] = useState('');
	const [lastNameClass, setLastNameClass] = useState('');
	const [phone, setPhone] = useState('');
	const [phoneClass, setPhoneClass] = useState('is-ok-anyway');
	const [badSubmitClass, setBadSubmitClass] = useState('');
	const [thankYou, setThankYou] = useState(false);

	const { closeModal } = useModal();

	const {
		mutate: referCollegues,
		error,
		isLoading,
	} = useReferColleguesV2Mutation({
		onSuccess: () => {
			setThankYou(true);
		},
	});

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		let isValid;

		if (name === 'email') {
			isValid = validateEmail(value) && checkStringLength(value, 256);
		} else if (name === 'phone') {
			isValid = checkStringLength(value, 128);
		} else {
			isValid = checkStringLength(value);
		}

		if (isValid && value) {
			if (name === 'email') setEmailClass('has-content');
			if (name === 'phone') setPhoneClass('has-content');
			if (name === 'firstName') setFirstNameClass('has-content');
			if (name === 'lastName') setLastNameClass('has-content');
		} else if (!isValid) {
			if (name === 'email') setEmailClass('invalid');
			if (name === 'phone') setPhoneClass('invalid');
			if (name === 'firstName') setFirstNameClass('invalid');
			if (name === 'lastName') setLastNameClass('invalid');
		} else {
			if (name === 'email') setEmailClass('is-ok-anyway');
			if (name === 'phone') setPhoneClass('is-ok-anyway');
			if (name === 'firstName') setFirstNameClass('is-ok-anyway');
			if (name === 'lastName') setLastNameClass('is-ok-anyway');
		}

		if (name === 'email') setEmail(value);
		if (name === 'phone') setPhone(value);
		if (name === 'firstName') setFirstName(value);
		if (name === 'lastName') setLastName(value);
	};

	const handleSubmit = () => {
		if (validateEmail(email) && firstName && lastName) {
			referCollegues([
				{
					firstName,
					lastName,
					email,
					...(phone && { phone }),
				},
			]);
		} else {
			setBadSubmitClass('flash-invalid');
			setTimeout(() => {
				setBadSubmitClass('');
			}, 750);
		}
	};

	if (error) {
		return (
			<div className={`invite-a-colleague ${badSubmitClass}`}>
				<ModalTitle>Server Error</ModalTitle>
				<ModalParagraph>
					Request cannot be processed at the moment.
				</ModalParagraph>
				<ModalButtons>
					<ModalButton onClick={closeModal} variant="primary">
						Return to Search
					</ModalButton>
				</ModalButtons>
			</div>
		);
	}

	if (thankYou) {
		return (
			<div className={`invite-a-colleague ${badSubmitClass}`}>
				<ModalTitle>Thanks for your referral!</ModalTitle>
				<ModalParagraph>Have more colleagues to refer?</ModalParagraph>
				<ModalParagraph>
					Contact{' '}
					<a href={`mailto:${emailConstants.clientSuccess}`}>
						{emailConstants.clientSuccess}
					</a>
				</ModalParagraph>
				<ModalButtons>
					<ModalButton variant="primary" onClick={closeModal}>
						Return to Search
					</ModalButton>
				</ModalButtons>
			</div>
		);
	}

	return (
		<div className={`invite-a-colleague ${badSubmitClass}`}>
			<ModalTitle>Give your colleague a CompStak trial—free!</ModalTitle>
			<div className={`input-wrap ${firstNameClass}`}>
				<input
					type="text"
					name="firstName"
					value={firstName}
					onChange={handleChange}
					onBlur={handleChange}
				/>
				<label>First Name</label>
			</div>
			<div className={`input-wrap ${lastNameClass}`}>
				<input
					type="text"
					name="lastName"
					value={lastName}
					onChange={handleChange}
					onBlur={handleChange}
				/>
				<label>Last Name</label>
			</div>
			<div className={`input-wrap ${emailClass}`}>
				<input
					type="text"
					name="email"
					value={email}
					onChange={handleChange}
					onBlur={handleChange}
				/>
				<label>Email</label>
			</div>
			<div className={`input-wrap ${phoneClass}`}>
				<input
					type="text"
					name="phone"
					value={phone}
					onChange={handleChange}
					onBlur={handleChange}
				/>
				<label>Phone Number (optional)</label>
			</div>
			<ModalButtons>
				<ModalButton
					variant="primary"
					onClick={handleSubmit}
					isLoading={isLoading}
				>
					Gift a free trial!
				</ModalButton>
			</ModalButtons>
		</div>
	);
};
