import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';
// TODO: deprecated icon - use 'ui/svg_icons/help_v2.svg' instead
import Help from '../../ui/svg_icons/help.svg';
import {
	Row,
	Heading,
	TabCardItem,
} from 'PortfolioAnalytics/styles/PortfolioUI';

import { showMenu } from 'Singletons/Menu/actions';
import PortfolioChartSelectMenu from 'PortfolioAnalytics/Singletons/PortfolioChartSelectMenu';

import DownloadButton from 'PortfolioAnalytics/Singletons/DownloadButton';
import { DownloadBranding } from 'PortfolioAnalytics/Singletons/DownloadBranding';

export type ChartType = 'sf' | 'leaseAmount' | 'leaseCount';
export const CHART_TYPES: ChartType[] = ['sf', 'leaseAmount', 'leaseCount'];

const CHART_OPTIONS: ChartOption[] = [
	{ type: 'sf', value: 'sf' },
	{ type: 'leaseAmount', value: 'lease amount' },
	{ type: 'leaseCount', value: 'lease count' },
];

export interface ChartOption {
	type: ChartType;
	value: string;
}
type ChartBox = {
	chartName: string;
	children?:
		| ((selectedChart: ChartOption) => React.ReactNode)
		| React.ReactNode;
};

export const ChartBox = memo((props: ChartBox) => {
	const chartRef = React.useRef<null | HTMLDivElement>(null);
	const [selectedChart, setSelectedChart] = React.useState<ChartOption>(
		CHART_OPTIONS[0]
	);
	const fullChartName = `${props.chartName} ${
		selectedChart?.value ?? ''
	}`.trim();

	const downloadBannerId = fullChartName.replaceAll(' ', '');

	return (
		<TabCardItem ref={chartRef}>
			<Row>
				{selectedChart == null ? (
					<Heading>{props.chartName}</Heading>
				) : (
					<Heading>
						<div>{props.chartName} By</div>
						<ChartSelectDropdown
							chartOptions={CHART_OPTIONS}
							selectedChart={selectedChart}
							setSelectedChart={setSelectedChart}
						/>
						{selectedChart.value === 'lease amount' && (
							<span
								data-tooltip="Lease amount is the annualized current rent x the lease transaction square footage"
								data-tooltip-position="above-onleft"
								data-html2canvas-ignore
							>
								<Help height={16} width={16} />
							</span>
						)}
					</Heading>
				)}
				<DownloadButton
					downloadRef={chartRef}
					filename={fullChartName}
					bannerId={downloadBannerId}
					data-qa-id="download-button-pa-portfolio-charts"
				/>
			</Row>
			{typeof props.children === 'function'
				? props.children(selectedChart)
				: props.children}
			<DownloadBranding bannerId={downloadBannerId} />
		</TabCardItem>
	);
});

ChartBox.displayName = 'ChartBox';

export const ChartWarning = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;
	min-height: 200px;
	height: 100%;
	width: 100%;
`;

type ChartSelectDropdown = {
	chartOptions: ChartOption[];
	selectedChart: ChartOption;
	setSelectedChart: React.Dispatch<React.SetStateAction<ChartOption>>;
};

const ChartSelectDropdown: React.FC<ChartSelectDropdown> = (props) => {
	const dispatch = useDispatch();

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	const showChartSelectMenu = (event) => {
		dispatch(
			showMenu(PortfolioChartSelectMenu, event.currentTarget, 'below', {
				chartOptions: props.chartOptions,
				setSelectedChart: props.setSelectedChart,
			})
		);
	};

	return (
		<SelectContainer onClick={showChartSelectMenu}>
			{props.selectedChart.value}&nbsp;
			<svg
				width="10"
				height="7"
				viewBox="0 0 10 7"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M4.98037 6.48783C4.84938 6.48859 4.71954 6.46348 4.59828 6.41396C4.47702 6.36443 4.36673 6.29145 4.27373 6.19921L0.292697 2.21817C0.199901 2.12538 0.126291 2.01521 0.0760699 1.89397C0.0258488 1.77272 1.95554e-09 1.64277 0 1.51154C-1.95554e-09 1.3803 0.0258488 1.25035 0.0760699 1.12911C0.126291 1.00787 0.199901 0.8977 0.292697 0.804904C0.385494 0.712107 0.495659 0.638497 0.616904 0.588276C0.738148 0.538055 0.868097 0.512207 0.999331 0.512207C1.13057 0.512207 1.26051 0.538055 1.38176 0.588276C1.503 0.638497 1.61317 0.712107 1.70597 0.804904L4.98037 4.09921L8.26472 0.934288C8.35628 0.832483 8.46785 0.750664 8.59247 0.693948C8.71709 0.637232 8.85207 0.60684 8.98897 0.604671C9.12587 0.602502 9.26175 0.628603 9.38811 0.681342C9.51446 0.734082 9.62857 0.812326 9.7233 0.91118C9.81804 1.01003 9.89136 1.12737 9.93868 1.25585C9.986 1.38433 10.0063 1.5212 9.9983 1.65788C9.99031 1.79457 9.95421 1.92813 9.89224 2.05023C9.83028 2.17232 9.74379 2.28032 9.63818 2.36746L5.65714 6.20916C5.47514 6.38464 5.23317 6.48427 4.98037 6.48783Z"
					fill="#4075FF"
				/>
			</svg>
		</SelectContainer>
	);
};

const SelectContainer = styled.div`
	align-items: center;
	color: #4075ff;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font-family: Gotham;
	font-size: 16px;
	font-weight: 500;
	text-decoration: underline;
	text-transform: uppercase;
`;

export type TotalValue = {
	activeLeaseCount: number;
	activeLeaseAmount: number;
	totalSqFt: number;
};

export const getTotalValues = (groups: TotalValue[]) => {
	return groups.reduce(
		(total, p) => {
			total.activeLeaseCount += p.activeLeaseCount;
			total.activeLeaseAmount += p.activeLeaseAmount;
			total.totalSqFt += p.totalSqFt;
			return total;
		},
		{
			activeLeaseCount: 0,
			activeLeaseAmount: 0,
			totalSqFt: 0,
		}
	);
};
