import constate from 'constate';
import { useMemo, useState } from 'react';
import { routes } from 'router';
import { usePropertyLeasesQuery } from 'api';
import {
	usePropertyLeasesOptionsState,
	usePropertyLeasesActive,
	usePropertyLeasesFiltered,
	usePropertyLeasesBySpaceType,
} from '../hooks/propertyLeases';

export const [LeaseProvider, useLeaseContext] = constate(() => {
	const {
		checkedSpaceType,
		setCheckedSpaceType,
		checkedStatus,
		setCheckedStatus,
	} = usePropertyLeasesOptionsState();

	const params = routes.propertyById.useParams();
	const { data: leasesData } = usePropertyLeasesQuery({
		propertyId: params.propertyId,
	});

	const leasesComps = leasesData?.comps ?? [];
	const ownedLeases = useMemo(
		() => leasesData?.comps.filter((comp) => comp.own) ?? [],
		[leasesData?.comps]
	);

	const [tab, setTab] = useState(LeasesTabEnum.ALL_LEASES);

	const leasesTab =
		tab === LeasesTabEnum.ALL_LEASES ? leasesComps : ownedLeases;

	const leasesActive = usePropertyLeasesActive({
		leases: leasesTab,
	});

	const leasesByStatus =
		checkedStatus === 'Only Active Leases' ? leasesActive : leasesTab;

	const leasesGrouppedBySpaceType = usePropertyLeasesBySpaceType({
		leases: leasesComps,
	});

	const filteredLeases = usePropertyLeasesFiltered({
		leases: leasesByStatus,
		checkedSpaceType,
		checkedStatus,
	});

	return {
		tab,
		setTab,
		ownedLeases,
		leases: leasesComps,
		filteredLeases,
		leasesByStatus,
		leasesGrouppedBySpaceType,
		checkedSpaceType,
		setCheckedSpaceType,
		checkedStatus,
		setCheckedStatus,
		leasesActive,
	};
});

export enum LeasesTabEnum {
	UNLOCKED_LEASES = 'UNLOCKED_LEASES',
	ALL_LEASES = 'ALL_LEASES',
}
