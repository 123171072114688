import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import 'Components/Filters/styles/filters.nomodule.less';
import { useListSearchInput } from 'Components/ListSearchInput/ListSearchInput';
import { useEffect } from 'react';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { MapFiltersV2 } from 'Components/Filters/Fields/MapFilter/MapFiltersV2';
import { LeaseFilterFieldsV2 } from './LeaseFilterFieldsV2';
import { SaleFilterFieldsV2 } from './SaleFilterFieldsV2';
import styled from 'styled-components';
import { PropertyFilterFieldsV2 } from './PropertyFilterFieldsV2';

type FilterFieldsV2Props = {
	params: SearchLayoutRouteParams;
};

export const FilterFieldsV2 = ({ params }: FilterFieldsV2Props) => {
	const {
		element: searchInput,
		searchTerm: searchInputSearchTerm,
		reset: resetSearch,
	} = useListSearchInput({
		inputDataQaId: 'filterFieldsSearchInputTestId',
		inputPlaceholder: 'Search for filters',
	});

	const { compType, setSearchTerm, categoryVisibility } =
		useFilterFieldContext();

	useEffect(() => {
		resetSearch();
	}, [compType, resetSearch]);

	useEffect(() => {
		setSearchTerm(searchInputSearchTerm);
	}, [searchInputSearchTerm, setSearchTerm]);

	return (
		<ul className="search_sidebar_filters">
			<SearchInputContainer>{searchInput}</SearchInputContainer>

			<MapFiltersV2 params={params} key={compType} />

			{compType === 'lease' && <LeaseFilterFieldsV2 />}
			{compType === 'sale' && <SaleFilterFieldsV2 />}
			{compType === 'property' && <PropertyFilterFieldsV2 />}

			{categoryVisibility?.[compType] &&
				Object.values(categoryVisibility[compType]).every(
					(isVisible) => !isVisible
				) && (
					<NoFiltersContainer>
						<StyledSpan>No filters found</StyledSpan>
						<p>There are no filters that match your search criteria.</p>
					</NoFiltersContainer>
				)}
		</ul>
	);
};

const SearchInputContainer = styled.div`
	position: sticky;
	top: 7.25rem;
	z-index: ${(p) => p.theme.zIndex.menu};
`;

const NoFiltersContainer = styled.div`
	max-width: 220px;
	text-align: center;
	margin: 0 auto;
`;

const StyledSpan = styled.span`
	padding: 0.5rem 1rem;
	margin-top: 2rem;
	display: block;

	font-weight: 700;
	font-size: 1rem;
`;
