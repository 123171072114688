import {
	Data,
	IntermittentStyledTD,
	KeyValueTable,
	Value,
} from '@compstak/ui-kit';
import { useExportPdfStateContext } from '../ExportPdfStateProvider';
import {
	EditableLeaseSnapshotField,
	editableLeaseSnapshotFieldKeys,
} from './useEditableLeaseSnapshot';
import { HideableLabel } from '../HideableLabel';
import { OverviewKeyValueTableInput, OverviewKeyValueTableLabel } from './UI';
import {
	AttributesTableHeader,
	AttributesTableWrapper,
} from 'Pages/PropertyPageV3/LoansTab/UI';
import styled from 'styled-components';

export const EditableLeaseSnapshot = () => {
	const { editableLeaseSnapshotFields, setEditableLeaseSnapshotFields } =
		useExportPdfStateContext();

	if (!editableLeaseSnapshotFields.showLeaseSnapshot) return null;

	const sectionsData: Data[] = editableLeaseSnapshotFieldKeys.map(
		(attribute) => {
			const isVisible = editableLeaseSnapshotFields.visibleFields[attribute];
			const label = (
				<HideableLabel
					isVisible={isVisible}
					onToggle={() => {
						setEditableLeaseSnapshotFields((s) => ({
							...s,
							visibleFields: {
								...s.visibleFields,
								[attribute]: !s.visibleFields[attribute],
							},
						}));
					}}
				>
					<OverviewKeyValueTableLabel>
						{leaseSnapshotLabels[attribute]}
					</OverviewKeyValueTableLabel>
				</HideableLabel>
			);
			const value = (
				<OverviewKeyValueTableInput
					isVisible={isVisible}
					value={editableLeaseSnapshotFields[attribute]}
					onChange={(e) => {
						setEditableLeaseSnapshotFields((s) => ({
							...s,
							[attribute]: e.target.value,
						}));
					}}
				/>
			);
			return [label, value];
		}
	);

	const sections: Data[][] = [];
	for (let i = 0; i < sectionsData.length; i = i + ROW_COUNT) {
		sections.push(sectionsData.slice(i, i + ROW_COUNT));
	}

	return (
		<div>
			<AttributesTableHeader>Property Lease Snapshot</AttributesTableHeader>
			<AttributesTableWrapper>
				{sections.map((section, index) => {
					return (
						<StyledKeyValueTable
							key={`section${index}`}
							data={section}
							columns={1}
						/>
					);
				})}
			</AttributesTableWrapper>
		</div>
	);
};

const ROW_COUNT = 3;

export const leaseSnapshotLabels: Record<EditableLeaseSnapshotField, string> = {
	activeLeaseCount: 'Active Leases',
	avgLeaseTerm: 'Average Lease Term',
	avgCurrentRent: 'Average In-Place Rent',
	propertyLeasesExpiringInTwelveMonths: 'Leases Expiring in the next 12 Months',
	propertyLeasesExpiringInFiveYears: 'Leases Expiring in the next 5 Years',
	tenants: 'Major Tenants',
};

export const StyledKeyValueTable = styled(KeyValueTable)`
	${Value} {
		margin-left: 8px;
		width: 100%;
	}
	${IntermittentStyledTD} {
		div {
			font-size: 14px;
		}
	}
	svg {
		height: 16px;
		width: 16px;
	}
`;
