import { FiltersObject } from 'models/filters/types';
import { ReactElement, ReactNode, SVGProps, useState } from 'react';
import { ActiveMapControl, CompType } from 'types';
import { useFilterFieldContext } from '../FilterFieldContextProvider';
import { useSearch } from 'Pages/Search/searchReducer';
import styled from 'styled-components';
import { trimString } from 'util/trimString';
import { useDispatch } from 'react-redux';
import { deselectMapControl, selectMapControl } from 'actions/search';
import IconCross from 'ui/svg_icons/cross.svg';
import { Spinner } from '@compstak/ui-kit';

type MapFilterBaseProps = {
	mapControl: ActiveMapControl;
	children?: (props: MapFilterBaseChildrenProps) => ReactNode;
	Icon: (props: SVGProps<SVGElement>) => ReactElement | null;
	label: string;
	/** to filter by other things rather than just `label` */
	labelSynonyms?: string[];
};

export type MapFilterBaseChildrenProps = {
	compType: CompType;
	filters: FiltersObject;
	isActive: boolean;
	onFilterChange: (changes: Partial<FiltersObject>) => void;
	setIsLoading: (isLoading: boolean) => void;
};

export const MapFilterV2Base = ({
	mapControl,
	children,
	Icon,
	label,
	labelSynonyms: _labelSynonyms,
}: MapFilterBaseProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const { activeMapControl } = useSearch();
	const { compType, filters, onFilterChange, searchTerm } =
		useFilterFieldContext();
	const isActive = activeMapControl === mapControl;
	const labelSynonyms = [label, ...(_labelSynonyms ?? [])];
	const isHidden =
		!!searchTerm &&
		!labelSynonyms.some((synonym) =>
			trimString(synonym).toLowerCase().includes(searchTerm)
		);

	const dispatch = useDispatch();

	const childrenProps: MapFilterBaseChildrenProps = {
		compType,
		filters,
		isActive,
		onFilterChange,
		setIsLoading,
	};

	const PostIcon = isLoading ? (
		<Spinner size="s" />
	) : isActive ? (
		<IconCross />
	) : null;

	const onClick = () => {
		dispatch(isActive ? deselectMapControl() : selectMapControl(mapControl));
	};

	const isChildrenActive = isActive && !isHidden && !isLoading;

	return (
		<Container
			isActive={isActive}
			isHidden={isHidden}
			data-filter-is-visible={!isHidden}
			data-filter-is-set={isActive}
		>
			<Control onClick={onClick}>
				<Label>
					<Icon width={21} height={21} />
					<span>{label}</span>
				</Label>
				{PostIcon}
			</Control>
			{children && (
				<ChildrenContainer isChildrenActive={isChildrenActive}>
					{children?.(childrenProps)}
				</ChildrenContainer>
			)}
		</Container>
	);
};

const Container = styled.div<{ isActive: boolean; isHidden: boolean }>`
	display: ${({ isHidden }) => (isHidden ? 'none' : 'inherit')};
	flex-direction: column;
	color: ${({ isActive, theme }) =>
		isActive ? theme.colors.white.white : '#a8aab3'};
	background-color: ${({ isActive, theme }) =>
		isActive ? theme.colors.neutral.n500 : 'inherit'};
	cursor: pointer;
	&:hover {
		background-color: ${({ isActive, theme }) =>
			isActive ? theme.colors.neutral.n700 : theme.colors.neutral.n500};
	}
`;

const ChildrenContainer = styled.div<{ isChildrenActive: boolean }>`
	display: ${({ isChildrenActive }) => (isChildrenActive ? 'flex' : 'none')};
	padding: 0.5rem 20px;
`;

const Control = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 13px;
	letter-spacing: 0.025em;
	padding: 0.5rem 20px;
	user-select: none;
	> svg {
		path {
			fill: ${({ theme }) => theme.colors.white.white};
		}
	}
`;

const Label = styled.div`
	display: flex;
	gap: 0.5rem;
	> svg {
		path {
			fill: ${({ theme }) => theme.colors.blue.blue400};
		}
	}
`;
