import React from 'react';

import Modal from 'Singletons/Modal';
import { NewProjectSuccess } from './NewProjectSuccess';

import withProjects from '../../withProjects';

import styles from '../../repository.less';
import modalStyles from '../../../Components/Modals/analytics-modals.less';
import button from 'ui/styles/button.less';
import spinner from 'ui/styles/spinner.less';
import { Project } from 'Pages/Analytics/analytics';
import { ModalActions } from 'Singletons/Modal/actions';

type Props = {
	analyticsProjects: Project[];
	modalActions: Pick<ModalActions, 'hideModal'>;
	closeModal: (ev: React.MouseEvent) => void;
};

type State = {
	name: string;
	saving: boolean;
	newProject: Project | null;
	saveWasSuccessful: boolean;
	analyticsLastUpdated: string;
	errorMessage: string;
};

export const NewProject = withProjects(
	class NewProject extends React.Component<Props, State> {
		state = {
			name: '',
			saving: false,
			newProject: null,
			saveWasSuccessful: false,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsLastUpdated' does not exist on ... Remove this comment to see the full error message
			analyticsLastUpdated: this.props.analyticsLastUpdated,
			errorMessage: '',
		};

		// @ts-expect-error TS7006: Parameter 'newProps' implicitl...
		static getDerivedStateFromProps(newProps, state) {
			if (newProps.analyticsLastUpdated !== state.analyticsLastUpdated) {
				return {
					saveWasSuccessful: true,
				};
			}
			return null;
		}

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		updateName = (event) => {
			if (event.target.value.length <= 75) {
				this.setState({
					name: event.target.value,
					errorMessage: '',
				});
			}
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		handleKeyDown = (event) => {
			if (event.keyCode === 13) {
				event.preventDefault();
				this.saveProject();
			}
		};

		saveProject = () => {
			if (this.state.name) {
				const trimmedName = this.state.name.trim();
				if (this.props.analyticsProjects.find((p) => p.name === trimmedName)) {
					this.setState({
						errorMessage: `The project ${this.state.name} already exists. Enter a new name to continue.`,
					});
				} else {
					const newProject = {
						name: trimmedName,
						canDelete: true,
						charts: [],
					};
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjectActions' does not exist ... Remove this comment to see the full error message
					this.props.analyticsProjectActions.saveProject(newProject);
					this.setState({
						saving: true,
						// @ts-expect-error TS2739: Type '{ name: string; canDelet...
						newProject,
					});
				}
			}
		};

		render() {
			if (this.state.saveWasSuccessful) {
				if (!this.state.newProject) return;

				return (
					<NewProjectSuccess
						project={this.state.newProject}
						modalActions={this.props.modalActions}
					/>
				);
			}
			if (this.state.saving) {
				return <div className={spinner.large} />;
			}

			const buttonClass = this.state.name ? button.blue : button.disabled;
			return (
				<div className={modalStyles.container}>
					<h3>Create New Project</h3>
					<p>
						Enter a project name for the new (empty) project that you want to
						add to your repository
					</p>
					<form>
						<label className={styles.modalInputLabel}>
							Project Name
							<input
								type="text"
								value={this.state.name}
								onChange={this.updateName}
								onKeyDown={this.handleKeyDown}
								className={this.state.errorMessage ? styles.error : ''}
							/>
							<div className={styles.errorMessage}>
								{this.state.errorMessage}
							</div>
						</label>
						<div className={modalStyles.buttonContainer}>
							<div
								className={button.button}
								onClick={this.props.closeModal}
								data-close-button
							>
								Cancel
							</div>
							<div className={buttonClass} onClick={this.saveProject}>
								Create
							</div>
						</div>
					</form>
				</div>
			);
		}
	}
);

const name = 'analytics-new-project';

Modal.addComponent({
	id: name,
	Component: NewProject,
});

export default name;
