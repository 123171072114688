import { useEffect } from 'react';
import { routes, useNavigate } from 'router';
import { usePortfoliosSortedByMostRecent } from './usePortfoliosSortedByMostRecent';

type Props = {
	params: {
		portfolioId?: string | null;
	};
};

export const useRedirectPortfolio = ({ params }: Props) => {
	const navigate = useNavigate();

	const { sortedPortfolios, isFetching } = usePortfoliosSortedByMostRecent();

	useEffect(() => {
		if (sortedPortfolios.length === 0 || isFetching) return;

		const mostRecentPortfolio = sortedPortfolios[0];

		if (!mostRecentPortfolio) return;

		let shouldRedirect = false;

		if (params.portfolioId == null) {
			shouldRedirect = true;
		} else {
			const portfolioExists = sortedPortfolios.find(
				(p) => p.id === Number(params.portfolioId)
			);
			shouldRedirect = !portfolioExists;
		}

		if (shouldRedirect) {
			navigate(
				routes.portfolioByIdView.toHref({
					portfolioId: mostRecentPortfolio.id,
					viewType: 'dashboard',
				})
			);
		}
	}, [sortedPortfolios, isFetching, params.portfolioId, navigate]);
};
