import { useAppConfig, useUser } from 'Pages/Login/reducers';
import styled from 'styled-components';
import { useAppSelector } from 'util/useAppSelector';
import { SavedSearchesList } from './Components/SavedSearchesList';
import './styles/savedSearches.nomodule.less';

export default function SavedSearchesPage() {
	const appConfig = useAppConfig();
	const savedSearches = useAppSelector((s) => s.savedSearches);
	const user = useUser();

	return (
		<SavedSearchPage>
			<SavedSearchesList
				appConfig={appConfig}
				savedSearches={savedSearches}
				user={user}
			/>
		</SavedSearchPage>
	);
}

const SavedSearchPage = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${(props) => props.theme.colors.white.white};
	padding: 24px;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	min-height: 75vh;
`;
