import { useMemo } from 'react';
import { colors, typography } from '@compstak/ui-kit';
import styled from 'styled-components';
import withModal from 'Singletons/Modal/withModal';
import AddDataSet from './Modals/DataSets/AddDataSet';

import { DatasetV2 } from './DataSet/DatasetV2';

import 'Components/Filters/styles/filters.nomodule.less';

import './styles/override.nomodule.less';
import styles from './styles/analytics.less';
import button from 'ui/styles/button.less';
import { MarketsState, usePermissions } from 'Pages/Login/reducers';
import { Chart, DataSet, DataSetType } from 'Pages/Analytics/analytics';
import { ModalActions } from 'Singletons/Modal/actions';
import { isPermittedDataset } from '../chartBuilderHelpers';
import {
	MAX_DATASETS_ALLOWED,
	dataSetGroupHeadingMap,
} from '../chartBuilderConstants';
import { DataProviderLogoBase } from '@compstak/ui-kit';
import SidebarNavigationButtons from '../../Components/Buttons/SidebarNavigationButtons';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useModal } from 'providers/ModalProvider';
import { ClearAnalyticsModal } from './Modals/ClearAnalyticsModal';

type Props = {
	chart: Chart;
	chartDraft: Chart;
	markets: MarketsState;
	expandedDataSetIndex: number | null;
	modalActions: ModalActions;
};

const FiltersComponent = (props: Props) => {
	const featureFlags = useFeatureFlags();
	const permissions = usePermissions();

	const { openModal } = useModal();

	const openAddDataSetModal = () => {
		if (props.chartDraft.dataSets.length < MAX_DATASETS_ALLOWED) {
			props.modalActions.pushModal(AddDataSet, {
				...props,
				modalContainerStyle: { width: 'unset', maxWidth: 'unset' },
			});
		}
	};

	const openClearAnalyticsModal = () => {
		openModal({
			modalContent: <ClearAnalyticsModal />,
		});
	};

	const goToProjectRepo = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'replace' does not exist on type 'Readonl... Remove this comment to see the full error message
		props.replace('/analytics/projects');
	};

	const { leaseDataSets, salesDataSets, multifamilyDataSets } = useMemo(
		() => groupDataSets(props.chartDraft.dataSets, permissions),
		[props.chartDraft.dataSets, permissions]
	);

	// @ts-expect-error TS7006: Parameter 'dataSet' implicitly...
	const getIndex = (dataSet, i): number => {
		switch (dataSet.type) {
			case DataSetType.COMMERCIAL:
				return i;
			case DataSetType.SALES:
				return i + leaseDataSets.length;
			case DataSetType.MUFA:
				return i + leaseDataSets.length + salesDataSets.length;
		}
		return i;
	};

	const renderDataSetList = (dataSets: DataSet[]) => {
		return dataSets.map((dataSet, i) => {
			// TODO: Use a field other than index to mark the expanded dataset.
			// Using index means a different dataset can become expanded when
			// someone deletes a dataset in the chart.
			const index = getIndex(dataSet, i);
			const expanded = props.expandedDataSetIndex === index;

			return (
				// @ts-expect-error TS2740: Type '{ filters: FiltersObject...
				<DatasetV2
					// "key" issue is discussed with BE side, taking into account current requirements
					// about we do not create two datasets at once anymore (like usual + mufa before)
					// and duplicate feature creates "copy" postfix, we can safely use name as a key
					// we are "ok" with the editing case, where we won't be able to create the dataset
					// with the existing name, the BE task for long-term solution is created
					key={dataSet.name + index}
					index={index}
					{...props}
					filters={dataSet.filters}
					dataSet={dataSet}
					dataSets={availableDataSetGroups.flat()}
					market={dataSet.filters.market}
					expanded={expanded}
					featureFlags={featureFlags}
				/>
			);
		});
	};

	const availableDataSetGroups = [
		leaseDataSets,
		salesDataSets,
		multifamilyDataSets,
	].filter((group) => group.length);

	const isGroup = availableDataSetGroups.length > 1;

	let tooltipMessage;
	let dataSetLimitClass;
	if (props.chartDraft.dataSets.length >= MAX_DATASETS_ALLOWED) {
		tooltipMessage = 'Limit: 10 data sets';
		dataSetLimitClass = styles.dataSetLimit;
	} else {
		tooltipMessage = '';
		dataSetLimitClass = '';
	}

	return (
		<div className={styles.sidebar}>
			<div className={styles.sidebarHeader}>
				<span
					role="button"
					className={button.button}
					onClick={openClearAnalyticsModal}
				>
					New Chart
				</span>
				<span
					role="button"
					data-tooltip={tooltipMessage}
					className={`${button.button} ${dataSetLimitClass}`}
					onClick={openAddDataSetModal}
				>
					Add Data Set
				</span>
			</div>
			<div className={styles.datasetListsContainer}>
				{availableDataSetGroups.map((dataSetGroup) => {
					const dataSetType = dataSetGroup[0].type as DataSetType;
					return (
						<div key={dataSetType}>
							{isGroup && (
								<GroupTitleWrapper>
									<GroupTitle>{dataSetGroupHeadingMap[dataSetType]}</GroupTitle>
									{dataSetType === DataSetType.MUFA && (
										<DataProviderLogoBase labelStyles={{ display: 'none' }} />
									)}
								</GroupTitleWrapper>
							)}
							{renderDataSetList(dataSetGroup)}
						</div>
					);
				})}
			</div>
			<SidebarNavigationButtons onClickProjects={goToProjectRepo} />
		</div>
	);
};

// @ts-expect-error TS7006: Parameter 'permissions' implic...
export function groupDataSets(dataSets: DataSet[], permissions) {
	return dataSets
		.filter((dataSet) => isPermittedDataset(dataSet, permissions))
		.reduce(
			(acc, curr) => {
				const { type } = curr;
				if (type === DataSetType.COMMERCIAL) acc.leaseDataSets.push(curr);
				if (type === DataSetType.SALES) acc.salesDataSets.push(curr);
				if (type === DataSetType.MUFA) acc.multifamilyDataSets.push(curr);
				return acc;
			},
			{
				leaseDataSets: [],
				salesDataSets: [],
				multifamilyDataSets: [],
			} as { [key: string]: DataSet[] }
		);
}

export default withModal(FiltersComponent);

const GroupTitle = styled.h3`
	display: flex;
	align-items: center;

	font-family: ${typography.fontFamily.gotham};
	font-size: 11px;
	font-weight: 400;
	color: ${colors.blue.blue500};
	text-transform: uppercase;
`;

const GroupTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 25px 25px 5px 20px;
`;
