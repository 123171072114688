import { Spinner } from '@compstak/ui-kit';
import { useAppConfig } from 'Pages/Login/reducers';
import { usePropertyByIdQuery, usePropertyLeasesQuery } from 'api';
import { Link } from 'router';
import styled from 'styled-components';
import { AddToPortfolioButton } from './AddToPortfolioButton';
import { CompPopupHeader } from './CompPopupHeader';
import { GenerateCompSetButton } from './GenerateCompSetButton';
import { PropertyPageLink } from './PropertyPageLink';
import {
	CompPopupActionButton,
	CompPopupActions,
	CompPopupRoot,
	CompPopupRootLoading,
	StyledArrowRightIcon,
} from './UI';
import { useFiltersWithAddressString } from './useFiltersWithAddressString';
import { useShouldShowPortfolioButton } from './useShouldShowPortfolioButton';

import { NearbyCompsButton } from './NearbyCompsButton';

type Props = {
	propertyId: number;
	closePopup: () => void;
};

export const LeasePopup = ({ propertyId, closePopup }: Props) => {
	const { data: property } = usePropertyByIdQuery({
		id: propertyId,
	});

	const { data: propertyLeases } = usePropertyLeasesQuery({
		propertyId: property?.id,
	});

	const comps = propertyLeases?.comps;

	const appConfig = useAppConfig();

	const filtersWithAddressString = useFiltersWithAddressString({
		property,
	});

	const viewRefineLeasesUrl = `/search/leases/list?${filtersWithAddressString}`;

	const showPortfolioButton = useShouldShowPortfolioButton();

	if (!property || !comps)
		return (
			<CompPopupRootLoading>
				<Spinner size="l" />
			</CompPopupRootLoading>
		);

	return (
		<CompPopupRoot>
			<CompPopupHeader property={property} closePopup={closePopup} />
			<CompPopupActions>
				<Link to={viewRefineLeasesUrl} onClick={closePopup}>
					<CompPopupActionButton data-qa-id="view-refine-leases-btn">
						<StyledDiv />
						View & Refine Leases ({comps.length})
						<StyledArrowRightIcon />
					</CompPopupActionButton>
				</Link>
				<PropertyPageLink propertyId={property.id}>
					<StyledDiv />
					Detailed Property Info
					<StyledArrowRightIcon />
				</PropertyPageLink>
				<NearbyCompsButton
					property={property}
					closePopup={closePopup}
					compType="lease"
					data-qa-id="nearby-lease-comps-btn"
				>
					<StyledDiv />
					Nearby Lease Comps
					<StyledArrowRightIcon />
				</NearbyCompsButton>
				{showPortfolioButton && <AddToPortfolioButton property={property} />}
				{appConfig.isExchange && (
					<Link to={`/stacking-plan/${property.id}`}>
						<CompPopupActionButton
							data-qa-id="stacking-plan-btn"
							className="single-child"
						>
							Stacking Plan Builder
						</CompPopupActionButton>
					</Link>
				)}
				<GenerateCompSetButton property={property} closePopup={closePopup} />
			</CompPopupActions>
		</CompPopupRoot>
	);
};

const StyledDiv = styled.div`
	width: 9px;
`;
