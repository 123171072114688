// Used to get paths of ALL routes typesafely.

import { route } from 'router/route';
import { CompType } from 'types';

const leaseByIdRoute = route<{ id: number }>({
	path: '/comps/leases/:id',
	isModal: true,
});

const saleByIdRoute = route<{ id: number }>({
	path: '/comps/sales/:id',
});

const exportPdfLeasesRoute = route<
	ExportPDFLayoutRouteParams,
	ExportPDFLayoutRouteProps
>({
	path: '/export/leases/:compIds',
	isModal: true,
	isLegacyModal: false,
	data: {
		compType: 'lease',
	},
});

const exportPdfSalesRoute = route<
	ExportPDFLayoutRouteParams,
	ExportPDFLayoutRouteProps
>({
	path: '/export/sales/:compIds',
	isModal: true,
	isLegacyModal: false,
	data: {
		compType: 'sale',
	},
});

const stackingPlanRoute = route<{ propertyId: number }>({
	path: '/stacking-plan/:propertyId',
	data: {
		inOverlay: true,
	},
});

// Rendering of routes is separate ( to avoid circular reference in TS ).
export const routes = {
	login: route({
		path: '/login',
	}),
	forgotPassword: route({
		path: '/forgotPassword',
	}),
	resetPassword: route<{ uuid: string }>({
		path: '/resetPassword/:uuid',
	}),
	createAccount: route<{ uuid: string }>({
		path: '/createAccount/:uuid',
	}),
	home: route<void, SearchLayoutRouteProps>({
		path: '/home',
		data: {
			compType: 'lease',
			isHome: true,
		},
	}),
	searchLeasesHome: route<void, SearchLayoutRouteProps>({
		path: '/search/leases/home',
		data: {
			compType: 'lease',
			isHome: true,
		},
	}),
	searchSalesHome: route<void, SearchLayoutRouteProps>({
		path: '/search/sales/home',
		data: {
			compType: 'sale',
			isHome: true,
		},
	}),
	searchPropertiesHome: route<void, SearchLayoutRouteProps>({
		path: '/search/properties/home',
		data: {
			compType: 'property',
			isHome: true,
		},
	}),
	leaseById: leaseByIdRoute,
	saleById: saleByIdRoute,
	propertyById: route<{ propertyId: number }>({
		path: '/property/:propertyId',
		data: {
			inOverlay: true,
		},
	}),
	propertyLeaseById: route<{ id: number }>({
		path: `/property${leaseByIdRoute.path}`,
		isModal: true,
	}),
	settingsPage: route<{ page: SettingsPage }>({
		path: '/settings/:page',
	}),
	searchLeases: route<{ savedSearchId?: string }>({
		path: '/search/leases',
		data: {
			compType: 'lease',
		},
		toHref: ({ savedSearchId }) =>
			`/search/leases${savedSearchId ? `?savedSearchId=${savedSearchId}` : ''}`,
	}),
	searchSales: route<{ savedSearchId?: string }>({
		path: '/search/sales',
		data: {
			compType: 'sale',
		},
		toHref: ({ savedSearchId }) =>
			`/search/sales${savedSearchId ? `?savedSearchId=${savedSearchId}` : ''}`,
	}),
	searchProperties: route<{ savedSearchId?: string }>({
		path: '/search/properties',
		data: {
			compType: 'property',
		},
		toHref: ({ savedSearchId }) =>
			`/search/properties${savedSearchId ? `?savedSearchId=${savedSearchId}` : ''}`,
	}),
	searchLeasesByView: route<SearchPageParams, SearchLayoutRouteProps>({
		path: '/search/leases/:view',
		data: {
			compType: 'lease',
			isSearch: true,
		},
	}),
	searchSalesByView: route<SearchPageParams, SearchLayoutRouteProps>({
		path: '/search/sales/:view',
		data: {
			compType: 'sale',
			isSearch: true,
		},
	}),
	searchPropertiesByView: route<SearchPageParams, SearchLayoutRouteProps>({
		path: '/search/properties/:view',
		data: {
			compType: 'property',
			isSearch: true,
		},
	}),
	savedSearches: route({
		path: '/saved-searches',
		isModal: true,
	}),
	propertyByMarketAndAddress: route<{
		marketId: number;
		propertyAddress: string;
	}>({
		path: '/property/market/:marketId/:propertyAddress',
		data: {
			inOverlay: true,
		},
	}),
	exportPdfLeases: exportPdfLeasesRoute,
	exportPdfSales: exportPdfSalesRoute,
	propertyExportPdfLeases: {
		...exportPdfLeasesRoute,
		path: `/property${exportPdfLeasesRoute.path}`,
	},
	propertyExportPdfSales: {
		...exportPdfSalesRoute,
		path: `/property${exportPdfSalesRoute.path}`,
	},
	upload: route({
		path: '/upload',
		data: {
			inOverlay: true,
		},
	}),
	uploadSection: route<{ section: UploadSection }>({
		path: '/upload/:section',
		data: {
			inOverlay: true,
		},
	}),
	premierList: route({
		path: '/premierList',
	}),
	analytics: route({
		path: '/analytics',
	}),
	analyticsDashboard: route({
		path: '/analytics/dashboard',
	}),
	analyticsProjects: route({
		path: '/analytics/projects',
	}),
	analyticsProjectById: route<{ projectId: number }>({
		path: '/analytics/projects/:projectId',
	}),
	analyticsImport: route({
		path: '/analytics/import',
		isModal: true,
	}),
	portfolios: route({
		path: '/portfolios',
		data: {
			compType: 'portfolios',
		},
	}),
	portfolioByIdView: route<{
		portfolioId: number;
		viewType: PortfolioView;
	}>({
		path: '/portfolios/:portfolioId/:viewType',
		data: {
			compType: 'portfolios',
		},
	}),
	upgrade: route({
		path: '/upgrade',
	}),
	landingpagetoken: route<{ accessToken: string }>({
		path: '/landingpagetoken',
		toHref: ({ accessToken }) => `landingpagetoken?accessToken=${accessToken}`,
	}),
	firstComp: route({
		path: '/comps/leases/firstcomp',
		isModal: true,
		data: {
			order: 1,
		},
	}),
	promotions: route({
		path: '/promotions',
	}),
	promotionsReview: route({
		path: '/promotions/review',
	}),
	credits: route({
		path: '/credits',
	}),
	rewards: route({
		path: '/rewards',
	}),
	survey: route({
		path: '/survey',
	}),
	stackingPlan: stackingPlanRoute,
	stackingPlanByDisplayMode: route<{
		propertyId: number;
		displayMode: StackingPlanDisplayMode;
	}>({
		path: `${stackingPlanRoute.path}/:displayMode`,
		data: {
			inOverlay: true,
		},
	}),
} as const;

// convenient option to get routes by compType
export const SEARCH_BY_VIEW_ROUTES = {
	lease: routes.searchLeasesByView,
	leases: routes.searchLeasesByView,
	sale: routes.searchSalesByView,
	sales: routes.searchSalesByView,
	property: routes.searchPropertiesByView,
	properties: routes.searchPropertiesByView,
};

export const SEARCH_ROUTES = {
	lease: routes.searchLeases,
	leases: routes.searchLeases,
	sale: routes.searchSales,
	sales: routes.searchSales,
	property: routes.searchProperties,
	properties: routes.searchProperties,
};

export const EXPORT_PDF_ROUTES = {
	lease: exportPdfLeasesRoute,
	sale: exportPdfSalesRoute,
};

export type RouteKey = keyof typeof routes;

export type SearchLayoutRouteProps = {
	compType: CompType;
	isSearch?: boolean;
	isHome?: boolean;
};

export type SearchPageParams = { view: SearchPageView };

export type SearchPageView = 'map' | 'list' | 'leagueTables';

export type UploadSection = 'lease' | 'sale';

export type PortfolioView = 'map' | 'list' | 'dashboard';

export type SettingsPage = 'account' | 'email' | 'password';

export type StackingPlanDisplayMode = 'directory' | 'plan';

export type ExportPDFLayoutRouteProps = {
	compType: CompType;
};

export type ExportPDFLayoutRouteParams = { compIds: number[] };
