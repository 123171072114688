import { DataProviderLogo, SaveDataMenu, TooltipV2 } from '@compstak/ui-kit';
import { CompsetMap, CompsetTable } from 'Components';
import {
	CompsetResponse,
	CompsetType,
	MufaCompsetResponse,
	useCompsetType,
} from 'api';
import styled from 'styled-components';
import IconHelp from 'ui/svg_icons/help_v2.svg';
import {
	TabContentContainer,
	TabContentHeader,
	TabContentTitle,
	TableCellGapper,
} from '../../Components';
import { useExcelData } from '../../utils';
import { excelColumns } from './excelColumns';
import { excelMufaColumns } from './excelMufaColumns';
import { useCompsetTableRows } from 'Components';

type Props = {
	compsetResponse: CompsetResponse | MufaCompsetResponse;
	isFetching: boolean;
};

export const Compset = ({ compsetResponse }: Props) => {
	const { subjectProperty } = compsetResponse;

	const propertyAddress = subjectProperty.buildingAddress;

	const compsetType = useCompsetType({
		propertyId: compsetResponse.subjectProperty.id,
	});

	const { data: rows } = useCompsetTableRows({
		propertyId: compsetResponse.subjectProperty.id,
	});

	const excelData = useExcelData({
		rows,
		columns:
			compsetType === CompsetType.REALPAGE ? excelMufaColumns : excelColumns,
		extraData: { subjectProperty },
	});

	return (
		<TabContentContainer>
			<TabContentHeader>
				<TabContentTitle>
					<TableCellGapper>
						Competitive Set
						<TooltipV2 content="CompStak’s automatically generated competitive set based on nearby, similar properties.">
							<span>
								<IconHelp />
							</span>
						</TooltipV2>
					</TableCellGapper>
				</TabContentTitle>
				<KebabContainer>
					<SaveDataMenu
						elementId={CONTAINER_ID}
						downloadFileName={`${propertyAddress} Competitive Set`}
						widgetTitle="Title your Image"
						// @ts-expect-error legacy
						dataToCopy={excelData}
						ignoreElements={(el) => {
							if (el.classList.contains('mapboxgl-control-container'))
								return true;
							return false;
						}}
					/>
				</KebabContainer>
			</TabContentHeader>
			<div id={CONTAINER_ID} style={{ width: '100%' }}>
				<MapContainer>
					<CompsetMap propertyId={compsetResponse.subjectProperty.id} />
				</MapContainer>
				<CompsetTable propertyId={compsetResponse.subjectProperty.id} />
				{compsetType === CompsetType.REALPAGE && (
					<RealPageLogoContainer>
						<DataProviderLogo />
					</RealPageLogoContainer>
				)}
			</div>
		</TabContentContainer>
	);
};

const CONTAINER_ID = 'compset-container';

const KebabContainer = styled.div`
	margin-left: auto;
`;

const RealPageLogoContainer = styled.div`
	position: relative;
	padding-top: 16px;
	display: flex;
	justify-content: flex-end;

	& > div {
		position: static;
	}
`;

const MapContainer = styled.div`
	height: 350px;
`;
