import React from 'react';

import Modal from 'Singletons/Modal';

import modalStyles from '../../../Components/Modals/analytics-modals.less';
import button from 'ui/styles/button.less';
import { Project } from 'Pages/Analytics/analytics';
import { ModalActions } from 'Singletons/Modal/actions';
import { AnalyticsProjectActions } from '../../actions';

export type DeleteProjectModalProps = {
	analyticsProjectActions: Pick<AnalyticsProjectActions, 'deleteProject'>;
	project: Project;
	modalActions: Pick<ModalActions, 'hideModal'>;
	closeModal: (ev: React.MouseEvent) => void;
};

export const DeleteProject = (props: DeleteProjectModalProps) => {
	const deleteProject = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjectActions' does not exist ... Remove this comment to see the full error message
		props.analyticsProjectActions.deleteProject(props.project.id);
		props.modalActions.hideModal();
	};

	return (
		<div className={modalStyles.container}>
			<h3>Delete Project</h3>
			<p>
				You’re trying to delete the project: <b>{props.project.name}</b>. This
				action cannot be undone, are you sure you want to proceed?
			</p>
			<div className={modalStyles.buttonContainer}>
				<div
					className={button.button}
					onClick={props.closeModal}
					data-close-button
				>
					Cancel
				</div>
				<div className={button.red} onClick={deleteProject}>
					Delete
				</div>
			</div>
		</div>
	);
};

const name = 'analytics-delete-project';

Modal.addComponent({
	id: name,
	Component: DeleteProject,
});

export default name;
