import { exportImage } from '@compstak/ui-kit';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Portfolio } from 'api';

import { useNavigate } from 'router';
import {
	PortfolioDropdownMenuArrow,
	PortfolioDropdownMenuContent,
	PortfolioDropdownMenuItem,
} from '../UI';

type Props = {
	portfolio: Portfolio;
	dashboardElement?: HTMLElement | null;
	openUpdateDialog: () => void;
	openCopyDialog: () => void;
	openDeleteDialog: () => void;
};

export const PortfolioOptionsMenu = ({
	portfolio,
	dashboardElement,
	openUpdateDialog,
	openCopyDialog,
	openDeleteDialog,
}: Props) => {
	const navigate = useNavigate();

	const isDashboard = !!dashboardElement;

	const onClickExport = () => {
		if (!dashboardElement) return;

		exportImage({
			element: dashboardElement,
			filename: `Portfolio Analytics - ${portfolio.name}`,
			bannerId: 'PortfolioDashboard',
			ignoreElements: (node) => node.classList.contains('mapboxgl-ctrl'),
		});
	};

	return (
		<DropdownMenu.Portal>
			<PortfolioDropdownMenuContent>
				<PortfolioDropdownMenuArrow />

				<PortfolioDropdownMenuItem onClick={openUpdateDialog}>
					Edit name & description
				</PortfolioDropdownMenuItem>

				{SHOW_EXPORT_OPTION && isDashboard && (
					<PortfolioDropdownMenuItem onClick={onClickExport}>
						Export Dashboard
					</PortfolioDropdownMenuItem>
				)}
				<PortfolioDropdownMenuItem onClick={openCopyDialog}>
					Duplicate Portfolio
				</PortfolioDropdownMenuItem>
				<PortfolioDropdownMenuItem onClick={openDeleteDialog}>
					Delete Portfolio
				</PortfolioDropdownMenuItem>
				<PortfolioDropdownMenuItem
					onClick={() => {
						navigate('/search/properties/home');
					}}
				>
					Add property
				</PortfolioDropdownMenuItem>
				{isDashboard && (
					<PortfolioDropdownMenuItem
						onClick={() => {
							navigate(`/portfolios/${portfolio.id}/list`);
						}}
					>
						Remove Property
					</PortfolioDropdownMenuItem>
				)}
				{/* hidden temporarily because Analytics does not support multi market
		        ticket:https://compstak.atlassian.net/browse/AP-14373
		      <PortfolioDropdownMenuItem>Send Entire Portfolio to Analytics</PortfolioDropdownMenuItem> */}
			</PortfolioDropdownMenuContent>
		</DropdownMenu.Portal>
	);
};

// Temporary decision, ticket : https://compstak.atlassian.net/browse/AP-14672
const SHOW_EXPORT_OPTION = false;
