import styled from 'styled-components';
import OriginalSearchIcon from 'ui/svg_icons/search.svg';
import OriginalCrossIcon from 'ui/svg_icons/cross.svg';
import { useRef } from 'react';

type MapControlSearchInputProps = {
	placeholder: string;
	value: string;
	onChange: (input: string) => void;
};

// TODO to remove once sidebarRevampFF is on
export const MapControlSearchInput = ({
	placeholder,
	value,
	onChange,
}: MapControlSearchInputProps) => {
	const ref = useRef<HTMLInputElement>(null);

	return (
		<MapControlSearchInputContainer>
			<SearchIcon />
			<input
				autoFocus
				data-qa-id="market-list-search-input"
				ref={ref}
				type="text"
				onChange={(ev) => onChange(ev.target.value)}
				placeholder={placeholder}
				value={value}
			/>
			<CrossIcon
				hidden={!value.length}
				onClick={() => {
					onChange('');
					ref.current?.focus();
				}}
			/>
		</MapControlSearchInputContainer>
	);
};

const MapControlSearchInputContainer = styled.div`
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.gray.gray800};
	height: 3em;
	font-size: 12px;
	opacity: 0.5;
	padding: 0 1.2em;

	& svg {
		fill: ${({ theme }) => theme.colors.white.white};
	}

	& input,
	input:focus {
		border: none;
		padding: 0 0.5em;
		height: 100%;
		background-color: ${({ theme }) => theme.colors.gray.gray800};
		color: ${({ theme }) => theme.colors.white.white};
		margin: 0;
	}
`;

const SearchIcon = styled(OriginalSearchIcon)`
	width: 1.2em;
	height: 1.2em;
`;

const CrossIcon = styled(OriginalCrossIcon)<{ hidden: boolean }>`
	cursor: pointer;
	width: 0.6em;
	height: 0.6em;
	${({ hidden }) => hidden && 'visibility: hidden;'};
`;
