import { SearchLeasesResponse } from 'api';
import dayjs from 'dayjs';
import { useMemo } from 'react';

type Props = {
	leaseSnapshotData?: SearchLeasesResponse;
	saleDate?: string;
};

export const useLeasesActiveAtTimeOfSale = ({
	leaseSnapshotData,
	saleDate,
}: Props) => {
	return useMemo(() => {
		if (leaseSnapshotData) {
			if (!saleDate) return leaseSnapshotData.comps;
			return leaseSnapshotData.comps.filter((lease) => {
				if (
					lease.own &&
					(!lease.commencementDate ||
						dayjs(lease.commencementDate).isBefore(dayjs(saleDate))) &&
					(!lease.expirationDate ||
						dayjs(lease.expirationDate).isAfter(dayjs(saleDate)))
				) {
					return lease;
				}
			});
		} else return [];
	}, [saleDate, leaseSnapshotData]);
};
