import { useMemo } from 'react';
import { useSaleMarket } from 'Pages/SalesDetailsV2/useSaleMarket';
import { useCompsetAddresses, useLeasesInCompset } from 'api';
import {
	SeeAllLink,
	TabContentContainer,
	TabContentHeader,
	TabContentTitle,
} from '../../Components';
import { TabsProps } from '../../Tabs';
import { useCompAddressesSearchUrl } from '../../utils';
import { LeasesInCompsetTable } from './LeasesInCompsetTable';
import { useCurrentlyActiveLeases } from '../LeaseSnapshot/hooks';

type Props = Pick<TabsProps, 'data'>;

export const LeasesInCompset = ({ data }: Props) => {
	const propertyId = data.portfolio[0].propertyId;
	const market = useSaleMarket({ id: data.id });

	const addresses = useCompsetAddresses({ propertyId });

	const compsetLeasesUrl = useCompAddressesSearchUrl({
		compType: 'lease',
		addresses,
		market,
	});

	const { data: leasesInCompset, isLoading } = useLeasesInCompset({
		propertyId,
	});
	const leases = leasesInCompset?.comps ?? [];
	const currentlyActiveLeases = useCurrentlyActiveLeases(leases);

	const seeAllLeasesLink = useMemo(() => {
		if (!leasesInCompset) return null;
		return (
			<SeeAllLink to={compsetLeasesUrl} data-qa-id="seeAllLeasesLink">
				view all leases in this set
			</SeeAllLink>
		);
	}, [leasesInCompset, compsetLeasesUrl]);

	return (
		<>
			<TabContentContainer>
				<TabContentHeader>
					<TabContentTitle>
						Currently Active Leases in Competitive Set
					</TabContentTitle>
					{seeAllLeasesLink}
				</TabContentHeader>
				<LeasesInCompsetTable
					isLoading={isLoading}
					leases={currentlyActiveLeases}
					id="property-active-leases-in-compset"
				/>
			</TabContentContainer>
			<TabContentContainer>
				<TabContentHeader>
					<TabContentTitle>Leases in Competitive Set</TabContentTitle>
					{seeAllLeasesLink}
				</TabContentHeader>
				<LeasesInCompsetTable
					isLoading={isLoading}
					leases={leases}
					id="property-leases-in-compset"
				/>
			</TabContentContainer>
		</>
	);
};
