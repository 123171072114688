import React from 'react';

import Modal from 'Singletons/Modal';

import { validateEmail } from 'util/validate';

import styles from '../../repository.less';
import modalStyles from '../../../Components/Modals/analytics-modals.less';
import button from 'ui/styles/button.less';
import { Chart } from 'Pages/Analytics/analytics';
import { User } from '@compstak/common';

type Props = {
	chart: Chart;
	modalActions: {
		hideModal: Function;
		pushModal: Function;
	};
	user: User;
};

type State = { email: string; errorMessage: string };

export class ShareChart extends React.Component<Props, State> {
	state = {
		email: '',
		errorMessage: '',
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleKeyDown = (event) => {
		if (event.keyCode === 13) {
			event.preventDefault();
			this.share();
		}
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	updateEmail = (event) => {
		this.setState({
			email: event.target.value,
			errorMessage: '',
		});
	};

	// @ts-expect-error TS7006: Parameter 'email' implicitly h...
	domainsMatch(email) {
		const userEmail = this.props.user.email;
		const userDomain = userEmail.substr(userEmail.indexOf('@') + 1);
		const targetDomain = email.substr(email.indexOf('@') + 1);

		return (
			userDomain === targetDomain ||
			targetDomain.toLowerCase() === 'compstak.com' ||
			userDomain.toLowerCase() === 'compstak.com'
		);
	}

	share = () => {
		if (
			validateEmail(this.state.email) &&
			this.domainsMatch(this.state.email)
		) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjectActions' does not exist ... Remove this comment to see the full error message
			this.props.analyticsProjectActions.sendChart(
				this.props.chart.id,
				this.state.email
			);
			this.props.modalActions.hideModal();
		} else {
			this.setState({
				errorMessage:
					"Email domain must match your account's email domain (@compstak.com)",
			});
		}
	};

	render() {
		return (
			<div className={`${modalStyles.container} ${styles.shareModal}`}>
				<h3>Share Chart</h3>
				<p>
					Enter your colleague’s email below to send them:{' '}
					<b>{this.props.chart.title}</b>
				</p>
				<label className={styles.modalInputLabel}>
					Recipient’s Email Address
					<input
						type="text"
						value={this.state.email}
						placeholder="Email Address"
						onChange={this.updateEmail}
						onKeyDown={this.handleKeyDown}
						className={this.state.errorMessage ? styles.error : ''}
					/>
					<div className={styles.errorMessage}>{this.state.errorMessage}</div>
				</label>
				<div className={modalStyles.buttonContainer}>
					<div className={button.blue} onClick={this.share}>
						Share
					</div>
				</div>
			</div>
		);
	}
}

const name = 'analytics-share-chart';

Modal.addComponent({
	id: name,
	Component: ShareChart,
});

export default name;
