import { formatInteger } from 'format';
import { colors } from '@compstak/ui-kit';
import { PORTFOLIO_LIMIT } from 'api/portfolio/constants';

// queryParam constants
export const PROPERTY_TYPE_IDS = 'propertyTypeIds';
export const BUILDING_CLASS_IDS = 'buildingClassIds';
export const TENANT_NAMES = 'tenantNames';
export const TENANT_INDUSTRIES = 'tenantIndustries';
export const EXPIRATION_YEARS = 'expirationYears';
export const SPACE_TYPE_NAMES = 'spaceTypes';

export const LIST_SORT_ROW_HEIGHT = 30;

export const PORTFOLIO_LIMIT_EXCEEDED_MESSAGE = `Exceeded the limit of ${formatInteger(
	PORTFOLIO_LIMIT
)} properties.`;

export const PORTFOLIO_CHART_COLORS = Object.values(colors.dataSet);
