import { RouterLocation } from 'types';
import { replaceAmpersand } from 'util/replaceAmpersand';

export const getFilterQueryParam = (location: RouterLocation, name: string) => {
	try {
		return location.query?.[name]
			? JSON.parse(replaceAmpersand(location.query[name]))
			: null;
	} catch (err) {
		console.error(`Unable to parse ${name}`, err);
		return null;
	}
};
