import MapControls from 'Components/SearchSidebar/MapControls';
import { ExportButtons } from 'Components/sidebarWidgets';
import React, { memo, useMemo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import pluralizeCompType from 'util/pluralizeCompType';
import MultiMarketSelector from './MultiMarketSelector';

import { User } from '@compstak/common';
import { useMarketSelectorExpanded } from 'hooks/useMarketSelectorExpanded';
import {
	SearchLayoutProps,
	SearchLayoutRouteParams,
} from 'Layouts/SearchLayout';
import { FiltersObject } from 'models/filters/types';
import { getSetFilterKeys } from 'models/filters/util/getSetFilters';
import { filtersToQueryString } from 'models/filters/util/urls';
import { showSaveSearchMenu } from 'Pages/SavedSearches/actions';
import { CompType } from 'types';
import '../styles/sidebar.nomodule.less';
import { useDispatch } from 'react-redux';
import { usePermissions } from 'Pages/Login/reducers';

export interface HomeSideBarProps {
	compType: CompType;
	filters: FiltersObject;
	onFilterChange: (newFilters: Partial<FiltersObject>) => void;
	params: SearchLayoutRouteParams;
	search: SearchLayoutProps['search'];
	selection: SearchLayoutProps['selection'];
	onNewSearchClick: NoArgCallback;
	user: User;
}

export const HomeSidebar = memo((props: HomeSideBarProps) => {
	const marketSelectorExpanded = useMarketSelectorExpanded();
	const dispatch = useDispatch();
	const permissions = usePermissions();

	const onShowSaveSearchMenu = (event: React.MouseEvent) => {
		dispatch(
			showSaveSearchMenu(props.filters, event.target, true, props.compType)
		);
	};

	let href = '/search/leases/home';
	if (props.compType === 'sale') {
		href = '/search/sales/home';
	} else if (props.compType === 'property') {
		href = '/search/properties/home';
	}

	const sidebarClass = marketSelectorExpanded
		? 'sidebar-filters-hidden'
		: 'sidebar-filters-visible';

	const setFiltersCount = useMemo(() => {
		const {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			market,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			markets,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			radius,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			polygon,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			submarkets,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			opportunityZoneId,
			...filtersToCount
		} = props.filters;
		return getSetFilterKeys(filtersToCount).length;
	}, [props.filters]);

	return (
		<div>
			<MultiMarketSelector
				compType={props.compType}
				filters={props.filters}
				permissions={permissions}
				user={props.user}
				marketSelectorExpanded={marketSelectorExpanded}
			/>
			<TransitionGroup>
				<CSSTransition classNames="sidebar-content" timeout={500}>
					<>
						<div className={sidebarClass}>
							<a
								href={href}
								onClick={props.onNewSearchClick}
								className="button_button button_blue button_block button_large"
							>
								New Search
							</a>
							<MapControls
								compType={props.compType}
								filters={props.filters}
								onFilterChange={props.onFilterChange}
								params={props.params}
							/>
							<div className="sidebar-more-filters">
								<a
									data-qa-id="more-filters"
									className="more-filters-text"
									href={`/search/${pluralizeCompType(
										props.compType,
										false
									)}/map?${filtersToQueryString(props.filters)}`}
								>
									More Filters{setFiltersCount ? ` (${setFiltersCount})` : ''}
								</a>
							</div>
						</div>

						<ExportButtons
							data-qa-id="ExportButtons-1"
							compType={props.compType}
							filters={props.filters}
							selection={props.selection}
							showSaveSearchMenu={(ev) => onShowSaveSearchMenu(ev)}
						/>
					</>
				</CSSTransition>
			</TransitionGroup>
		</div>
	);
});

HomeSidebar.displayName = 'Sidebar';
