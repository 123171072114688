import { Button, Spinner, TooltipV2 } from '@compstak/ui-kit';
import { SortDirection, useAllPortfoliosQuery } from 'api';
import { Triangle } from 'Components';
import { useState } from 'react';
import { Link } from 'router';
import styled from 'styled-components';
import { DarkSidebar } from 'ui/UI';
import { APP_HEADER_HEIGHT } from '../../constants';
import {
	ListSortButton,
	ListSortButtonsContainer,
	ListSortContainer,
	ListSortRowInfoText,
} from '../UI';
import { NewPortfolioDialog } from './NewPortfolioDialog';
import { SortFieldButton } from './SortFieldButton';
import { SortField } from './types';
import { usePortfoliosWithAggregations } from './usePortfoliosWithAggregations';
import { useSortedPortfolios } from './useSortedPortfolios';

type Props = {
	portfolioId: number | undefined;
};

export const PortfolioSidebar = ({ portfolioId }: Props) => {
	const [sortField, setSortField] = useState<SortField>('dateCreated');
	const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

	const { data: portfolios, isFetching: isFetchingPortfolios } =
		useAllPortfoliosQuery();

	const portfoliosWithAggregates = usePortfoliosWithAggregations(portfolios);

	const sortedPortfolios = useSortedPortfolios({
		portfoliosWithAggregates,
		sortField,
		sortDirection,
	});

	const [isCreatePortfolioDialogOpen, setIsCreatePortfolioDialogOpen] =
		useState(false);

	return (
		<DarkSidebar sideBarState="open">
			<NewPortfolioButtonContainer>
				<NewPortfolioButton
					variant="primary"
					size="l"
					onClick={() => setIsCreatePortfolioDialogOpen(true)}
				>
					New portfolio
				</NewPortfolioButton>
				{isCreatePortfolioDialogOpen && (
					<NewPortfolioDialog
						closeDialog={() => setIsCreatePortfolioDialogOpen(false)}
					/>
				)}
			</NewPortfolioButtonContainer>
			{isFetchingPortfolios ? (
				<SpinnerContainer>
					<Spinner />
				</SpinnerContainer>
			) : (
				<>
					<ListSortContainer>
						<StyledListSortRowInfoText>
							{sortedPortfolios.length} portfolios
						</StyledListSortRowInfoText>
						<ListSortButtonsContainer>
							<SortFieldButton
								sortField={sortField}
								setSortField={setSortField}
							/>
							<ListSortButton
								onClick={() => {
									setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
								}}
							>
								<Triangle
									size={14}
									color="#fff"
									style={{
										transform:
											sortDirection === 'desc' ? 'rotate(180deg)' : undefined,
									}}
								/>
							</ListSortButton>
						</ListSortButtonsContainer>
					</ListSortContainer>
					<List>
						{sortedPortfolios.map((p) => {
							const isSelected = portfolioId === p.id;

							return (
								<ListItem key={p.id} isSelected={isSelected}>
									<StyledLink to={`/portfolios/${p.id}/dashboard`}>
										{isSelected && <SelectedListItemBar />}
										<ListItemContentContainer>
											<TooltipV2
												align="start"
												shouldShowArrow
												content={<div>{p.name}</div>}
												delayDuration={500}
											>
												<ListItemTitle>{p.name}</ListItemTitle>
											</TooltipV2>

											<ListItemInfo>{p.propertyCount} Properties</ListItemInfo>
										</ListItemContentContainer>
									</StyledLink>
								</ListItem>
							);
						})}
					</List>
				</>
			)}
		</DarkSidebar>
	);
};

const SORT_ROW_HEIGHT = 30;
const BUTTON_CONTAINER_HEIGHT = 80;

const NewPortfolioButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${BUTTON_CONTAINER_HEIGHT}px;
	padding: 0 1.25rem;
`;

const NewPortfolioButton = styled(Button)`
	width: 100%;
`;

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const StyledListSortRowInfoText = styled(ListSortRowInfoText)`
	color: ${(p) => p.theme.colors.neutral.n50};
`;

const List = styled.ul`
	width: 100%;
	overflow-y: auto;
	height: calc(
		100vh - ${APP_HEADER_HEIGHT + SORT_ROW_HEIGHT + BUTTON_CONTAINER_HEIGHT}px
	);
`;

const ListItem = styled.li<{ isSelected: boolean }>`
	cursor: pointer;
	background-color: ${(p) =>
		p.isSelected ? p.theme.colors.neutral.n300 : undefined};
	position: relative;
	border-bottom: 1px solid ${(p) => p.theme.colors.neutral.n300};
	&:hover {
		background-color: ${(p) => p.theme.colors.neutral.n300};
	}
`;

const ListItemContentContainer = styled.div`
	overflow: hidden;
`;

const SelectedListItemBar = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 6px;
	background-color: ${(p) => p.theme.colors.blue.blue500};
`;

const StyledLink = styled(Link)`
	all: unset;
	height: 70px;
	padding: 0 1.25rem;
	display: flex;
	align-items: center;
`;

const ListItemTitle = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 16px;
	font-weight: 300;
	color: ${(p) => p.theme.colors.white.white};
`;

const ListItemInfo = styled.div`
	font-size: 12px;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	text-transform: uppercase;
	color: ${(p) => p.theme.colors.neutral.n50};
`;
