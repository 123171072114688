import { DataSetType } from 'Pages/Analytics/analytics';
import {
	MufaPropertyAveragesResponse,
	useCompsetIds,
	usePropertyAverages,
	usePropertyById,
	usePropertyMarket,
} from 'api';
import { useMemo } from 'react';
import { routes } from 'router';
import { encodeQuery } from 'util/encodeQuery';

type Props = {
	propertyId: number;
};

export const useCompsetSendToAnalyticsUrl = ({ propertyId }: Props) => {
	const { data: compsetIds } = useCompsetIds({ propertyId });
	const { data: property } = usePropertyById({ propertyId });

	const { data: propertyAverages } = usePropertyAverages({
		ids: compsetIds,
		marketIds: property ? [property.marketId] : [],
		enabled: !!property,
	});

	const { data: market } = usePropertyMarket({ propertyId });

	return {
		data: useMemo(() => {
			if (!market || !propertyAverages || !compsetIds) return;

			const hasRpData =
				(propertyAverages as MufaPropertyAveragesResponse).withRpDataCount > 0;

			const query = encodeQuery({
				marketName: encodeURIComponent(market.name),
				propertyId: encodeURIComponent(JSON.stringify(compsetIds)),
				'dataset-types': encodeURIComponent(
					JSON.stringify([
						hasRpData ? DataSetType.MUFA : DataSetType.COMMERCIAL,
					])
				),
			});

			return `${routes.analyticsImport.toHref()}?series=${query.slice(1)}`;
		}, [propertyAverages, market, compsetIds]),
	};
};
