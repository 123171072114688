import React from 'react';

import Modal from 'Singletons/Modal';

import modalStyles from '../../../Components/Modals/analytics-modals.less';
import button from 'ui/styles/button.less';
import { Chart } from 'Pages/Analytics/analytics';

type Props = {
	analyticsProjectActions: {
		deleteChart: (chartId: number) => void;
	};
	chart: Chart;
	modalActions: {
		hideModal: Function;
	};
};

export const DeleteChart: React.FC<Props> = (props) => {
	const handleDelete = () => {
		if (props.chart.id) {
			props.analyticsProjectActions.deleteChart(props.chart.id);
		}
		props.modalActions.hideModal();
	};

	return (
		<div className={modalStyles.container}>
			<h3>Delete Chart</h3>
			<p>
				You’re trying to delete the chart: <b>{props.chart.title}</b>. This
				action cannot be undone, are you sure you want to proceed?
			</p>
			<div className={modalStyles.buttonContainer}>
				<div
					className={button.button}
					data-close-button
					onClick={() => {
						props.modalActions.hideModal();
					}}
				>
					Cancel
				</div>
				<div className={button.red} onClick={handleDelete}>
					Delete
				</div>
			</div>
		</div>
	);
};

const name = 'analytics-delete-chart';

Modal.addComponent({
	id: name,
	Component: DeleteChart,
});

export default name;
