import { AppState } from 'reducers/root';
import { getAttributeHash } from 'util/comp-format/src/format';

type SubmissionMap = Record<
	string,
	{
		// TODO: When the feature flag ffUploadCompPageRevamp24Q3 is live
		// only label, unit and postUnit will be needed. The rest of the fields
		// can be removed from the type.
		name: string;
		label: string;
		unit: string;
		preUnit: string;
		postUnit: string;
		value: string;
		errorMessage: string;
		empty: boolean;
		error: boolean;
		required: boolean;
	}
>;

export function getLeaseSubmissionMapFromAppStore(
	store: AppState
): SubmissionMap {
	const attributeHash = getAttributeHash('lease');
	const submissionKeys = Object.keys(store.uploads.leaseSubmission ?? {});
	const requiredSubmissionKeys = [
		'tenantName',
		'buildingAddress',
		'city',
		'state',
		'spaceType',
		'transactionSize',
		'startingRent',
		'leaseTerm',
		'expirationDate',
		'executionDate',
		'commencementDate',
	];
	const submissionMap = getSubMap({
		submissionKeys,
		attributeHash,
		requiredSubmissionKeys,
		compType: 'lease',
	});
	return submissionMap;
}

export function getSaleSubmissionMapFromAppStore(
	store: AppState
): SubmissionMap {
	const attributeHash = getAttributeHash('sale');
	const submissionKeys = Object.keys(store.uploads.saleSubmission ?? {}).filter(
		(key) => key !== 'additionalAddresses'
	);
	const requiredSubmissionKeys = [
		'buildingAddress',
		'city',
		'state',
		'buildingSize',
		'lotSize',
		'transactionSize',
		'totalSalePrice',
		'salePricePsf',
		'saleDate',
		'buyer',
		'seller',
		'recordedBuyer',
		'recordedSeller',
	];
	const submissionMap = getSubMap({
		submissionKeys,
		attributeHash,
		requiredSubmissionKeys,
		compType: 'sale',
	});
	return submissionMap;
}

function getSubMap({
	submissionKeys,
	attributeHash,
	requiredSubmissionKeys,
	compType,
}: {
	submissionKeys: string[];
	attributeHash: ReturnType<typeof getAttributeHash>;
	compType: 'lease' | 'sale';
	requiredSubmissionKeys: string[];
}): SubmissionMap {
	const submissionMap = {} as SubmissionMap;
	submissionKeys.forEach((key) => {
		// @ts-expect-error TS7015: Element implicitly has an 'any...
		const attrVal = attributeHash[key];
		if (attrVal == null) {
			const errorMsg =
				'Failed to get attributeHash[key] value for ' +
				`compType="${compType}" key="${key}"`;
			throw Error(errorMsg);
		}
		submissionMap[key] = {
			name: key,
			label: attrVal.displayName,
			unit: attrVal.preUnit || attrVal.postUnit,
			preUnit: attrVal.preUnit || '',
			postUnit: attrVal.postUnit || '',
			value: '',
			errorMessage: '',
			empty: true,
			error: false,
			required: requiredSubmissionKeys.includes(key),
		};
	});
	return submissionMap;
}
