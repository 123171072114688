import { useMemo } from 'react';
import { groupBy } from 'lodash';
import { Portfolio, PortfolioLeaseExpirationsBySpaceTypesByYears } from 'api';
import {
	Legend,
	LegendColor,
	LegendItem,
	LegendLabel,
	ChartContainer,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryContainer,
	VictoryLabel,
	VictoryStack,
} from 'victory';
import { ChartBox, ChartType } from './ChartSelect';
import {
	EXPIRATION_YEARS,
	SPACE_TYPE_NAMES,
} from 'PortfolioAnalytics/constants';
import { PORTFOLIO_CHART_COLORS } from '../constants';
import { DataNotAvailableMessage } from 'Components/DataNotAvailableMessage';
import { replaceAmpersand } from 'util/replaceAmpersand';
import styled from 'styled-components';

type Props = {
	portfolio: Portfolio;
	redirect: (path: string) => void;
};

const LEASE_EXPIRATION__BY_SPACE_TYPE_CHART_NAME =
	'Upcoming Lease Expirations By Year';
const NUMBER_OF_YEARS = 5;

export const LeaseExpirationsBySpaceTypeChart = ({
	portfolio,
	redirect,
}: Props) => {
	const { leaseExpirationsBySpaceTypesByYears } = portfolio;

	const chartData = useMemo(() => {
		if (!leaseExpirationsBySpaceTypesByYears) return [];
		const expirationsByValidYears =
			leaseExpirationsBySpaceTypesByYears.filter(filterValidYears);
		const expirationsGroupedBySpaceType = groupBy(
			expirationsByValidYears,
			(e) => e.spaceType
		);
		return Object.entries(expirationsGroupedBySpaceType);
	}, [leaseExpirationsBySpaceTypesByYears]);

	return (
		<ChartBox chartName={LEASE_EXPIRATION__BY_SPACE_TYPE_CHART_NAME}>
			{(selectedChart) => {
				if (!chartData.length) {
					return (
						<ChartContainer>
							<DataNotAvailableMessage
								chartName={`${LEASE_EXPIRATION__BY_SPACE_TYPE_CHART_NAME} by ${selectedChart.value}`}
							/>
						</ChartContainer>
					);
				}

				const chartKey = CHART_TYPE_TO_KEY[selectedChart.type];

				return (
					<Container>
						<VictoryChart
							width={700}
							height={400}
							padding={{ left: 90, top: 50, right: 100, bottom: 50 }}
							containerComponent={
								<VictoryContainer
									responsive={false}
									style={{ margin: '0 auto' }}
								/>
							}
						>
							<VictoryAxis
								dependentAxis
								offsetX={50}
								tickLabelComponent={
									<VictoryLabel renderInPortal style={{ fontSize: 10 }} />
								}
								style={{
									axis: { stroke: '#F0F0F0', strokeWidth: 2 },
									axisLabel: {
										fontSize: 20,
										color: '#A3A3A3',
										textOverflow: 'ellipsis',
										whiteSpace: 'no-wrap',
										overflow: 'hidden',
									},
									grid: { stroke: '#F0F0F0' },
									ticks: { stroke: '#F0F0F0' },
									tickLabels: {
										padding: 0,
										color: '#A3A3A3',
										fontSize: 10,
										lineHeight: 10,
									},
								}}
							/>
							<VictoryAxis
								padding={{ left: 90, top: 50, right: 10, bottom: 50 }}
								fixLabelOverlap
								tickLabelComponent={
									<VictoryLabel renderInPortal style={{ fontSize: 10 }} />
								}
								style={{
									axis: { stroke: '#F0F0F0', strokeWidth: 2 },
									axisLabel: { fontSize: 20, padding: 0, color: '#A3A3A3' },
									grid: { stroke: '#F0F0F0', border: '1px solid black' },
									ticks: { stroke: '#F0F0F0' },
									tickLabels: {
										padding: 5,
										color: '#A3A3A3',
										fontSize: 10,
										lineHeight: 10,
									},
								}}
							/>
							<VictoryStack
								colorScale={PORTFOLIO_CHART_COLORS}
								style={{
									parent: {
										height: 300,
									},
									data: {
										stroke: '#fff',
										strokeWidth: 2,
									},
									labels: {
										fontFamily: 'Roboto Mono',
										fontSize: 11,
									},
								}}
							>
								{chartData.map(([spaceTypeName, spaceTypeGroup]) => {
									const barData = spaceTypeGroup.map(
										(leaseExpirationByYear) => {
											return {
												x: leaseExpirationByYear.expirationYear.toString(),
												y: leaseExpirationByYear[chartKey],
												spaceType: spaceTypeName,
											};
										}
									);
									return (
										<VictoryBar
											key={spaceTypeName}
											barRatio={0.6}
											data={barData}
											style={{
												data: {
													cursor: 'pointer',
												},
											}}
											events={[
												{
													target: 'data',
													eventHandlers: {
														onClick: (e, clickedProps) => {
															redirect(
																`/portfolios/${
																	portfolio.id
																}/list?${EXPIRATION_YEARS}=[${
																	clickedProps.datum.x
																}]&${SPACE_TYPE_NAMES}=[${JSON.stringify(
																	replaceAmpersand(spaceTypeName)
																)}]`
															);
														},
													},
												},
											]}
										/>
									);
								})}
							</VictoryStack>
						</VictoryChart>
						<Legend>
							{chartData.map(([spaceType], i) => (
								<LegendItem key={i}>
									<LegendColor color={PORTFOLIO_CHART_COLORS[i]} />
									<LegendLabel>{spaceType}</LegendLabel>
								</LegendItem>
							))}
						</Legend>
					</Container>
				);
			}}
		</ChartBox>
	);
};

const Container = styled.div`
	margin: 0 auto;
	padding: 1rem;
	width: 100%;
	max-width: 700px;
`;

const filterValidYears = (
	leaseExpiration: PortfolioLeaseExpirationsBySpaceTypesByYears
) => {
	const currentYear = new Date().getFullYear();
	const validYears = Array.from(Array(NUMBER_OF_YEARS).keys()).map(
		(n) => currentYear + n
	);
	return validYears.includes(leaseExpiration.expirationYear);
};

const CHART_TYPE_TO_KEY: {
	[key in ChartType]: keyof PortfolioLeaseExpirationsBySpaceTypesByYears;
} = {
	leaseAmount: 'expiringLeaseAmount',
	leaseCount: 'expiringLeaseCount',
	sf: 'totalSqFt',
};
