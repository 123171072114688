import { deselectMapControl } from 'actions/search';
import MapControls from 'Components/SearchSidebar/MapControls';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { useSearch } from 'Pages/Search/searchReducer';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFilterFieldContext } from '../FilterFieldContextProvider';

type MapFilterProps = {
	params: SearchLayoutRouteParams;
};

// TODO to remove once sidebarRevampFF is on (don't forget about css)
export const MapFilter = ({ params }: MapFilterProps) => {
	const dispatch = useDispatch();
	const { activeMapControl } = useSearch();
	const { compType, filters, onFilterChange } = useFilterFieldContext();

	const [isChecked, setIsChecked] = useState(!!activeMapControl);

	const isActive = isChecked || !!activeMapControl;

	const toggleMapFilter = useCallback(
		(checked: boolean) => {
			if (!checked && activeMapControl) {
				dispatch(deselectMapControl());
			}
			setIsChecked(checked);
		},
		[activeMapControl, dispatch]
	);

	return (
		<div
			className={`map-controls-container ${
				isActive ? 'map-controls-expanded' : ''
			}`}
		>
			<div className="toggle-map-controls">
				<div className="filter_header">
					<input
						type="checkbox"
						id="map-filters"
						className="checkbox"
						checked={isActive}
						onChange={(ev) => toggleMapFilter(ev.target.checked)}
					/>
					<label
						className={`filter_title_text ${isActive ? 'filter_selected' : ''}`}
						htmlFor="map-filters"
					>
						<span>Map Filters</span>
					</label>
				</div>
			</div>
			{isActive && (
				<MapControls
					compType={compType}
					filters={filters}
					onFilterChange={onFilterChange}
					params={params}
				/>
			)}
		</div>
	);
};
