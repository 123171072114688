import { useReferenceDataQuery } from 'api';
import constate from 'constate';
import { formatCsv, formatDate } from 'format';
import html2canvas from 'html2canvas';
import { useCallback, useMemo, useState } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { useDashboardExportContext } from './DashboardExportProvider';
import { IMAGE_SECTIONS, ImageSectionKey } from './constants';

export const [ExportPdfProvider, useExportPdfState] = constate(() => {
	const [filters] = useFilters('exchange-dashboard');
	const { data: referenceData } = useReferenceDataQuery();
	const { selectedComponents } = useDashboardExportContext();

	const marketText = filters.market.displayName;
	const submarketText = filters.submarkets
		? formatCsv(filters.submarkets.map((s) => s.name))
		: 'All';
	const propertyTypesText = useMemo(() => {
		const allSelected =
			filters.buildingPropertyTypeId == null ||
			filters.buildingPropertyTypeId.length ===
				referenceData.propertyTypes.length;

		return allSelected
			? 'All'
			: filters.buildingPropertyTypeId
					?.map((id) => {
						if (id) {
							const propertyType = referenceData?.propertyTypes.find(
								(type) => type.id === id
							);
							return propertyType?.name;
						}
						return null;
					})
					.filter(Boolean)
					.join(', ') ?? 'All';
	}, [filters.buildingPropertyTypeId, referenceData]);
	const createdOnText = formatDate(new Date(), 'MMMM DD, YYYY');

	const [exportPdfState, setExportPdfState] = useState<ExportPdfState>({
		preparedFor: '',
		notes: '',
		logo: null,
		title: `Compstak Report - ${marketText} ${createdOnText}`,
	});

	const [imageUrls, setImageUrls] = useState<ImageUrlsState>({
		marketRentHeatMap: null,
		tenantIndustryDistribution: null,
		tenantIndustryMap: null,
	});

	const registerImage = useCallback(
		async (key: ImageSectionKey, el: HTMLElement | null) => {
			let url = null;
			if (el) {
				const canvas = await html2canvas(el, {
					ignoreElements: (el) => el.classList.contains('mapboxgl-ctrl-attrib'),
				});
				url = canvas.toDataURL();
			}
			
			setImageUrls((s) => ({
				...s,
				[key]: url,
			}));
		},
		[]
	);

	const areImagesReady = useMemo(() => {
		return IMAGE_SECTIONS.filter((key) =>
			selectedComponents.includes(key)
		).every((key) => !!imageUrls[key]);
	}, [selectedComponents, imageUrls]);

	return {
		exportPdfState,
		setExportPdfState,
		marketText,
		submarketText,
		propertyTypesText,
		createdOnText,
		registerImage,
		imageUrls,
		areImagesReady,
	};
});

export type ExportPdfState = {
	preparedFor: string;
	notes: string;
	logo: string | null;
	title: string;
};

export type ImageUrlsState = Record<ImageSectionKey, string | null>;
export type ImageElsState = Record<ImageSectionKey, HTMLElement | null>;
