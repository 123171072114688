import { Spinner } from '@compstak/ui-kit';
import { useEffect, useState } from 'react';
import {
	useGetPublicPromotions,
	useUserCanSeePromotions,
} from '../../api/promotions/promotionQueries';
import { useShouldRedirect } from '../../hooks/useShouldRedirect';
import { promotionListItemId } from './promotionsList';
import { PromotionsListAndFocusedPromo } from './promotionsListAndFocusedPromo';
import { Promotion } from './promotionTypes';
import { PromoSimpleWarning } from './promotionUtilComponents';

const FOCUSED_PROMO_QUERY_PARAM = 'promo-id';

export function PromotionsTabContentLoader() {
	const { data: promotions, isLoading, error } = useGetPublicPromotions();
	const canSeePromos = useUserCanSeePromotions();
	useShouldRedirect(!canSeePromos, '/');

	if (!canSeePromos) {
		return <PromoSimpleWarning>Redirecting...</PromoSimpleWarning>;
	}

	if (error) {
		return (
			<PromoSimpleWarning>
				<p>Error loading promotions.</p>
				<p>Please refresh this page or check back in a few minutes.</p>
			</PromoSimpleWarning>
		);
	}

	if (isLoading || !promotions) {
		return <Spinner isCentered />;
	}

	return <PromotionsTabContent {...{ promotions }} />;
}

function PromotionsTabContent({ promotions }: { promotions: Promotion[] }) {
	const [selectedPromoId, setSelectedPromoId] = useState<number | null>(
		getFocusedPromoIdFromUrlSearchParams(promotions)
	);

	useEffect(
		() => {
			const id = getFocusedPromoIdFromUrlSearchParams(promotions);
			if (!id) return;
			const el = document.getElementById(promotionListItemId(id));
			if (!el) return;
			el.scrollIntoView();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<PromotionsListAndFocusedPromo
			{...{ promotions, selectedPromoId }}
			onSelectPromo={(id) => {
				setSelectedPromoId(id);
				if (id == null) return;
				const newUrl = addQueryParamToUrl({
					url: window.location.href,
					paramName: FOCUSED_PROMO_QUERY_PARAM,
					paramValue: String(id),
				});
				window.history.replaceState({}, '', newUrl);
			}}
		/>
	);
}

function getFocusedPromoIdFromUrlSearchParams(
	promotions: Promotion[]
): number | null {
	const urlParams = new URLSearchParams(window.location.search);
	const myParam = urlParams.get(FOCUSED_PROMO_QUERY_PARAM);
	if (myParam && !isNaN(Number(myParam))) {
		const id = Number(myParam);
		if (promotions.some((promo) => promo.id === id)) {
			return id;
		}
	}
	return promotions?.[0]?.id ?? null;
}

function addQueryParamToUrl({
	url,
	paramName,
	paramValue,
}: {
	url: string;
	paramName: string;
	paramValue: string;
}): string {
	try {
		const newUrl = new URL(url);
		newUrl.searchParams.set(paramName, paramValue);
		return newUrl.toString();
	} catch (err) {
		console.error('Failed to parse URL', err, url);
		return url;
	}
}
