import { ExportButton } from '../../Components';
import styled from 'styled-components';
import { useLeasesSelectionContext } from './LeasesSelectionProvider';
import { usePropertiesSelectionContext } from './PropertiesSelectionProvider';

type Props = {
	type: 'lease' | 'property';
};

export const ListViewExportButton = ({ type }: Props) => {
	const { selectedRows: selectedProperties } = usePropertiesSelectionContext();
	const { selectedRows: selectedLeases } = useLeasesSelectionContext();

	return (
		<PortfolioExportButton
			className="button_dark_blue"
			selection={type === 'property' ? selectedProperties : selectedLeases}
			compType={type}
			menuSide="below-onright"
		/>
	);
};

const PortfolioExportButton = styled(ExportButton)`
	height: 42px;
	margin: 0;
`;
