import React from 'react';
import { showExportMenu } from 'actions/export';
import { useDispatch } from 'react-redux';
import { CompType } from 'types';
import IconExport from '../../ui/svg_icons/download_icon.svg';
import { useLayoutContext } from './LayoutProvider';
import { ActionButton } from './UI';

type Props = {
	compType: CompType;
	row: { id: number; marketId: number };
};

export const SearchTableExportButton = ({ compType, row }: Props) => {
	const dispatch = useDispatch();
	const { isCompact } = useLayoutContext();

	return (
		<ActionButton
			size="m"
			variant="primary"
			icon={<IconExport fill="#fff" />}
			data-qa-id="export-btn"
			onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				dispatch(
					// @ts-expect-error legacy types
					showExportMenu([row], compType, e.currentTarget, 'above', {
						singleComp: true,
					})
				);
			}}
		>
			{!isCompact && (
				<>
					<span>|</span>
					<span>Export</span>
				</>
			)}
		</ActionButton>
	);
};
