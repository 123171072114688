import { CheckedState } from '@radix-ui/react-checkbox';
import { MAP_CONTROLS } from 'actions/search';
import { useMarketToOpportunityZones } from 'api/opportunityZones/useMarketToOpportunityZones';
import { Checkbox } from 'Components/Checkbox/Checkbox';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMarkets } from 'hooks/useMarkets';
import React, { useMemo, useState } from 'react';
import { CompType } from 'types';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { getSearchTermsForMarket } from 'util/getSearchTerms';
import { trimString } from 'util/trimString';
import MapControl from './MapControl';
import {
	CollapseButton,
	CollapsedContainer,
	Container,
	IconArrowBottom,
	MapControlItem,
	ScrollContainer,
} from './MapControl.styles';
import { MapControlSearchInput } from './MapControlSearchInput';
import OpportunityZonesIcon from 'ui/svg_icons/opportunity_zones_icon.svg';
import { FilterChips } from 'Components/Filters/Base/Filter/FilterChips';

type OpportunityZonesMapControlProps = {
	active: boolean;
	compType: CompType;
	filters: FiltersObject;
	onFilterChange: (newFilters: Partial<FiltersObject>) => void;
	params?: SearchLayoutRouteParams;
};

const MAX_CHECKED_MARKETS_NUMBER = 15;

// TODO to remove once sidebarRevampFF is on (don't forget about css)
const OpportunityZonesMapControl = (Component: typeof MapControl) => {
	return function OpportunityZonesMapControlComponent({
		active,
		compType,
		filters,
		onFilterChange,
		params,
	}: OpportunityZonesMapControlProps) {
		const [searchTerm, setSearchTerm] = useState('');
		const trimmedSearchTerm = trimString(searchTerm).toLowerCase();
		const [isCollapsed, setIsCollapsed] = useState(false);

		const markets = useMarkets();
		const { isFetching, marketIdToOpportunityZones } =
			useMarketToOpportunityZones(getFiltersMarkets(filters), {
				enabled: active,
			});
		const filtersOpportunityZoneIds = useMemo(
			() => filters.opportunityZoneId ?? [],
			[filters.opportunityZoneId]
		);

		const marketNameToChecked = useMemo(() => {
			if (!marketIdToOpportunityZones) {
				return undefined;
			}

			let marketToOpportunityZones: Record<string, CheckedState> = {};

			const getIsChecked = (_marketId: number | string) => {
				const marketId = Number(_marketId);
				const marketOpportunityZoneIds = Object.values(
					marketIdToOpportunityZones[marketId]
				).map(
					(opportunityZoneFeature) => opportunityZoneFeature.properties.GEOID10
				);

				if (!filtersOpportunityZoneIds.length) {
					return false;
				}

				let isChecked: CheckedState = false;
				for (let i = 0; i < marketOpportunityZoneIds.length; i++) {
					const marketOpportunityZoneId = marketOpportunityZoneIds[i];
					if (filtersOpportunityZoneIds.includes(marketOpportunityZoneId)) {
						isChecked =
							i === marketOpportunityZoneIds.length - 1
								? true
								: 'indeterminate';
						continue;
					}

					if (isChecked === 'indeterminate') {
						break;
					}
				}
				return isChecked;
			};

			if (trimmedSearchTerm) {
				marketToOpportunityZones = Object.keys(
					marketIdToOpportunityZones
				).reduce<Record<string, CheckedState>>((acc, marketId) => {
					const market = markets[marketId];
					const marketDisplayName = market.displayName;

					const isMatchingMarket = getSearchTermsForMarket(
						market,
						false
					).includes(trimmedSearchTerm);

					if (isMatchingMarket) {
						acc[marketDisplayName] = getIsChecked(marketId);
					}

					return acc;
				}, {});
			} else {
				marketToOpportunityZones = Object.keys(
					marketIdToOpportunityZones
				).reduce<Record<string, CheckedState>>((acc, marketId) => {
					const marketDisplayName = markets[marketId].displayName;
					acc[marketDisplayName] = getIsChecked(marketId);
					return acc;
				}, {});
			}

			return Object.keys(marketToOpportunityZones)
				.sort()
				.reduce<Record<string, CheckedState>>((obj, sortedDisplayName) => {
					obj[sortedDisplayName] = marketToOpportunityZones[sortedDisplayName];
					return obj;
				}, {});
		}, [
			marketIdToOpportunityZones,
			trimmedSearchTerm,
			markets,
			filtersOpportunityZoneIds,
		]);

		const toggleOpportunityZones = (marketId: number, checked: boolean) => {
			const marketOpportunityZoneIds = Object.values(
				marketIdToOpportunityZones?.[marketId] ?? {}
			).map(
				(opportunityZoneFeature) => opportunityZoneFeature.properties.GEOID10
			);
			const opportunityZoneIds = checked
				? [...filtersOpportunityZoneIds, ...marketOpportunityZoneIds]
				: filtersOpportunityZoneIds.filter(
						(oppZoneId) => !marketOpportunityZoneIds.includes(oppZoneId)
					);

			onFilterChange({
				opportunityZoneId: opportunityZoneIds,
			});
		};

		const hasMultipleMarkets =
			Object.keys(marketIdToOpportunityZones ?? {}).length > 1;

		const exceedsMaxCheckedMarkets =
			Object.values(marketNameToChecked ?? {}).filter(
				(isChecked) => !!isChecked
			).length >= MAX_CHECKED_MARKETS_NUMBER;

		return (
			<Component
				active={active}
				compType={compType}
				filters={filters}
				icon={OpportunityZonesIcon}
				id={MAP_CONTROLS.OPPORTUNITY_ZONES}
				key={MAP_CONTROLS.OPPORTUNITY_ZONES}
				label="Opportunity Zones"
				loading={isFetching}
				onFilterChange={onFilterChange}
				params={params}
			>
				{marketNameToChecked && (
					<Container>
						{isCollapsed ? (
							<CollapsedContainer
								data-qa-id="expand-submarket-list-button"
								onClick={() => setIsCollapsed(false)}
							>
								<FilterChips
									chips={[
										`${abbreviateNumber(
											filtersOpportunityZoneIds.length
										)} Zone${
											filtersOpportunityZoneIds.length === 1 ? '' : 's'
										} Selected`,
									]}
								/>
								<IconArrowBottom />
							</CollapsedContainer>
						) : (
							<>
								{hasMultipleMarkets && (
									<MapControlSearchInput
										placeholder="Search by market"
										onChange={setSearchTerm}
										value={searchTerm}
									/>
								)}
								<ScrollContainer>
									{Object.keys(marketNameToChecked).map((marketName) => {
										const marketId = markets[marketName].id;
										const isChecked = marketNameToChecked[marketName];
										const isDisabled = !isChecked && exceedsMaxCheckedMarkets;

										return (
											<MapControlItem
												data-tooltip={
													isDisabled
														? `You cannot select more than ${MAX_CHECKED_MARKETS_NUMBER} markets for Opportunity Zones at this time`
														: undefined
												}
												key={marketId}
												isChecked={Boolean(isChecked)}
												isDisabled={isDisabled}
												onClick={() =>
													!isDisabled &&
													toggleOpportunityZones(
														marketId,
														!isChecked || isChecked === 'indeterminate'
													)
												}
											>
												<Checkbox
													disabled={isDisabled}
													name={`${marketId}_opportunityZone`}
													id={String(marketId)}
													value={marketId}
													checked={isChecked}
													data-qa-id="toggle-opportunityZone-checkbox"
												/>
												<label>{marketName}</label>
											</MapControlItem>
										);
									})}
								</ScrollContainer>
								<CollapseButton
									data-qa-id="submarkets-filter-collapse-button"
									onClick={() => setIsCollapsed(true)}
									variant="secondary"
								>
									Collapse
								</CollapseButton>
							</>
						)}
					</Container>
				)}
			</Component>
		);
	};
};

export default OpportunityZonesMapControl(MapControl);
