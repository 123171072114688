import React from 'react';
// @ts-expect-error TS7016: Could not find a declaration f...
import Creatable from 'react-select/lib/Creatable';

import withProjects from '../../../../Repository/withProjects';

import styles from './analytics-save-dialog.less';
import button from 'ui/styles/button.less';
import modalStyles from '../../styles/chart-builder-modals.less';
import { selectorStyles } from 'util/reactSelectStyles';
import { Chart } from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';
import { DEFAULT_NEW_CHART_TITLE } from 'Pages/Analytics/Builder/chartBuilderConstants';
import { Spinner } from '@compstak/ui-kit';
import { ModalActions } from 'Singletons/Modal/actions';

const SAVE_CHART_NAME_INPUT = 'save-chart-analytics-dialog-name';

type Props = {
	chart: Chart | null;
	chartDraft: Chart;
	markets: MarketsState;
	analyticsLastUpdated: string;
	modalActions: ModalActions;
};

type SaveChartFormWithoutLoaderState = any;

export class SaveChartFormWithoutLoader extends React.Component<
	Props,
	SaveChartFormWithoutLoaderState
> {
	constructor(props: Props) {
		super(props);
		// For the Creatable selector, all option values must be strings
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjects' does not exist on typ... Remove this comment to see the full error message
		const projectOptions = this.props.analyticsProjects.map((f) => ({
			value: f.name,
			label: f.name,
		}));

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjects' does not exist on typ... Remove this comment to see the full error message
		const currentProject = this.props.analyticsProjects.find((project) =>
			project.charts.find(
				// @ts-expect-error TS7006: Parameter 'chart' implicitly h...
				(chart) => chart.id === this.props.chartDraft?.originalChartId
			)
		);

		this.state = {
			projectError: null,
			titleError: null,
			chartTitle: this.props.chartDraft?.title ?? DEFAULT_NEW_CHART_TITLE,
			selectedProjectOption:
				currentProject &&
				// @ts-expect-error TS7006: Parameter 'o' implicitly has a...
				projectOptions.find((o) => o.value === currentProject.id.toString()),
			projectOptions,
		};
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleTitleChange = (event) => {
		const newState = {
			chartTitle: event.target.value,
			titleError: null,
		};

		this.setState(newState);
	};

	// @ts-expect-error TS7006: Parameter 'projectName' implic...
	dupeTitleExists = (projectName, title) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjects' does not exist on typ... Remove this comment to see the full error message
		const project = this.props.analyticsProjects.find(
			// @ts-expect-error TS7006: Parameter 'p' implicitly has a...
			(p) => p.name === projectName
		);
		if (project) {
			// @ts-expect-error TS7006: Parameter 'chart' implicitly h...
			if (project.charts.find((chart) => chart.title === title)) {
				return true;
			}
		}
		return false;
	};

	// @ts-expect-error TS7006: Parameter 'option' implicitly ...
	handleProjectChange = (option) => {
		const newState = {
			selectedProjectOption: option,
			projectError: null,
			titleError: null,
		};
		if (
			option &&
			option.value &&
			this.dupeTitleExists(option.value, this.state.chartTitle)
		) {
			// @ts-expect-error ts-migrate(2322) FIXME: Type '"There is already a chart with this name."' ... Remove this comment to see the full error message
			newState.titleError = 'There is already a chart with this name.';
		}
		this.setState(newState);
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleSubmit = (event) => {
		event.preventDefault();
		if (this.state.titleError || this.state.projectError) {
			return;
		}
		const newState = {
			titleError: null,
			projectError: null,
		};
		if (!this.state.chartTitle.trim()) {
			// @ts-expect-error ts-migrate(2322) FIXME: Type '"Please name the chart"' is not assignable t... Remove this comment to see the full error message
			newState.titleError = 'Please name the chart';
		}
		if (!this.state.selectedProjectOption) {
			// @ts-expect-error ts-migrate(2322) FIXME: Type '"Please select a project"' is not assignable... Remove this comment to see the full error message
			newState.projectError = 'Please select a project';
		}

		const projectName = this.state.selectedProjectOption.value;
		if (
			this.state.selectedProjectOption &&
			this.dupeTitleExists(projectName, this.state.chartTitle.trim())
		) {
			// @ts-expect-error ts-migrate(2322) FIXME: Type '"There is already a chart with this name."' ... Remove this comment to see the full error message
			newState.titleError = 'There is already a chart with this name.';
		}

		if (newState.titleError || newState.projectError) {
			this.setState(newState);
			return;
		}

		const chart = {
			...this.props.chartDraft,
			title: this.state.chartTitle.trim(),
		};
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'onSave' does not exist on type 'Readonly... Remove this comment to see the full error message
		this.props.onSave(chart, projectName);
	};

	render() {
		return (
			<div className={`${modalStyles.container} ${modalStyles.importDataSet}`}>
				<h3>Save In-Progress Chart</h3>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label htmlFor={SAVE_CHART_NAME_INPUT} className={styles.label}>
							Chart Name
						</label>
						<input
							id={SAVE_CHART_NAME_INPUT}
							type="text"
							className={this.state.titleError ? styles.error : styles.input}
							value={this.state.chartTitle}
							onChange={this.handleTitleChange}
						/>
						<div className={styles.errorMessage}>{this.state.titleError}</div>
						<span className={styles.label}>Select Project</span>
						<Creatable
							className={this.state.projectError ? styles.error : styles.input}
							onChange={this.handleProjectChange}
							options={this.state.projectOptions}
							value={this.state.selectedProjectOption}
							styles={{
								...selectorStyles,
								// @ts-expect-error TS7006: Parameter 'oldStyles' implicit...
								input: (oldStyles) => ({
									...oldStyles,
									input: {
										width: '260px !important',
										height: '100%',
										margin: '3px 0 0',
									},
								}),
							}}
							isClearable={true}
							classNamePrefix="dropdown-selector"
						/>
						<div className={styles.errorMessage}>{this.state.projectError}</div>
					</div>
					<div className={modalStyles.buttonContainer}>
						<a
							onClick={() => this.props.modalActions.popModal()}
							className={button.button}
							data-close-button={true}
						>
							Cancel
						</a>
						<button
							className={
								this.state.titleError || this.state.projectError
									? button.disabled
									: button.blue
							}
							onClick={this.handleSubmit}
						>
							Save & create new
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export const SaveChartForm = withProjects(SaveChartFormWithoutLoader);

export function SavingInProgress() {
	return (
		<div className={modalStyles.container}>
			<h3>Saving in Progress</h3>
			<Spinner />
		</div>
	);
}

type OverwriteWarningState = any;

export class OverwriteWarning extends React.Component<
	{},
	OverwriteWarningState
> {
	state = {
		showErrorMessage: false,
	};

	handleErrorState = () =>
		this.setState({
			showErrorMessage: true,
		});

	render() {
		const { showErrorMessage } = this.state;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'errorMessage' does not exist on type 'Re... Remove this comment to see the full error message
		const hasError = this.props.errorMessage != null;
		return (
			<div className={modalStyles.container}>
				<h3>Save Chart</h3>
				<p>
					You’re trying to save changes to a chart this already exists in the
					current project. Would you like to overwrite the existing chart or
					save a new, separate version?
				</p>
				<div className={modalStyles.buttonContainer}>
					<div
						className={showErrorMessage ? button.red : button.white}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'onSave' does not exist on type 'Readonly... Remove this comment to see the full error message
						onClick={hasError ? this.handleErrorState : this.props.onSave}
					>
						Save
					</div>
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'onSaveAs' does not exist on type 'Readon... Remove this comment to see the full error message */}
					<div className={button.blue} onClick={this.props.onSaveAs}>
						Save As
					</div>
				</div>
				{showErrorMessage && (
					<span className={modalStyles.alreadyExists}>
						{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'errorMessage' does not exist on type 'Re... Remove this comment to see the full error message */}
						{this.props.errorMessage}
					</span>
				)}
			</div>
		);
	}
}
