import React from 'react';
import Modal from 'Singletons/Modal';

import styles from '../styles/analytics.less';
import modalStyles from '../styles/chart-builder-modals.less';
import button from 'ui/styles/button.less';
import { Chart, DataSet, DataSetType } from 'Pages/Analytics/analytics';
import { MarketsState } from 'Pages/Login/reducers';
import {
	ATTRIBUTE_TO_PLOT_VALUES_COMMERCIAL_SPECIFIC,
	ATTRIBUTE_TO_PLOT_VALUES_MUFA_SPECIFIC,
	AttributeToPlotCommercialValues,
	AttributeToPlotMufaValues,
	ATTRIBUTE_TO_PLOT_VALUES_SALES_SPECIFIC,
} from '../../../../../Components/Graphs/Selectors';

type Props = {
	chartDraft: Chart;
	markets: MarketsState;
};

const changeSeriesOnlyForDataSetType = (
	dataSetType: DataSetType,
	newSeries: string
) => (dataSet: DataSet) => {
	if (dataSet.type === dataSetType) {
		return {
			...dataSet,
			series: newSeries,
		};
	}
	return dataSet;
};

// @ts-expect-error TS7006: Parameter 'dataSet' implicitly...
const updateIfNewSeriesOneOf = (dataSet, newSeries, optionAlternatives) => {
	if (optionAlternatives.includes(newSeries)) {
		const updatedDataSetIfCommercial = changeSeriesOnlyForDataSetType(
			DataSetType.COMMERCIAL,
			optionAlternatives[0]
		)(dataSet);

		return changeSeriesOnlyForDataSetType(
			DataSetType.MUFA,
			optionAlternatives[1]
		)(updatedDataSetIfCommercial);
	}
	return dataSet;
};

export class ApplyAll extends React.Component<Props> {
	applyAll = () => {
		const {
			chartDraft: { dataSets, ...otherChartDraftProps },
		} = this.props;
		// @ts-expect-error TS2339: Property 'data' does not exist...
		const newSeries = this.props.data.value.y;

		let resultDataSets: DataSet[];
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (ATTRIBUTE_TO_PLOT_VALUES_MUFA_SPECIFIC[newSeries]) {
			resultDataSets = dataSets.map(
				changeSeriesOnlyForDataSetType(DataSetType.MUFA, newSeries)
			);
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		} else if (ATTRIBUTE_TO_PLOT_VALUES_COMMERCIAL_SPECIFIC[newSeries]) {
			resultDataSets = dataSets.map(
				changeSeriesOnlyForDataSetType(DataSetType.COMMERCIAL, newSeries)
			);
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		} else if (ATTRIBUTE_TO_PLOT_VALUES_SALES_SPECIFIC[newSeries]) {
			resultDataSets = dataSets.map(
				changeSeriesOnlyForDataSetType(DataSetType.SALES, newSeries)
			);
		} else {
			resultDataSets = dataSets.map((dataSet) => {
				const maybeUpdatedDataSet = updateIfNewSeriesOneOf(dataSet, newSeries, [
					AttributeToPlotCommercialValues.STARTING_RENT,
					AttributeToPlotMufaValues.ASKING_RPSF,
				]);

				return updateIfNewSeriesOneOf(maybeUpdatedDataSet, newSeries, [
					AttributeToPlotCommercialValues.EFFECTIVE_RENT,
					AttributeToPlotMufaValues.EFFECTIVE_RPSF,
				]);
			});
		}

		const newChartDraft = {
			...otherChartDraftProps,
			dataSets: resultDataSets,
		};
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'chartBuilderActions' does not exist on t... Remove this comment to see the full error message
		this.props.chartBuilderActions.updateChartDraft(
			newChartDraft,
			this.props.markets
		);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
		this.props.modalActions.hideModal();
	};

	render() {
		return (
			<div className={`${modalStyles.container} ${modalStyles.applyAll}`}>
				<div className={modalStyles.message}>
					Are you sure you want to Apply this attribute to all data sets?
				</div>
				<div className={modalStyles.buttonContainer}>
					<span
						className={button.button}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
						onClick={this.props.modalActions.hideModal}
					>
						Cancel
					</span>
					<span className={button.blue} onClick={this.applyAll}>
						Apply All
					</span>
				</div>
			</div>
		);
	}
}

Modal.addComponent({
	id: 'analytics-apply-all',
	Component: ApplyAll,
	className: styles.analyticsModal,
});

export default 'analytics-apply-all';
