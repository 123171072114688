import React from 'react';
import dayjs from 'dayjs';

import DownArrow from '../../../../ui/svg_icons/arrow_bottom.svg';
import { emailConstants, phoneNumberConstants } from '@compstak/common';
import styled from 'styled-components';
import { SubmissionCategory, SubmissionInfo } from './submissionTypes';
import { useGetUserSubmissionDetails } from 'api/submissions/submissionQueries';
import { Flex, Spinner } from '@compstak/ui-kit';
import { CategoryPills } from './CategoryPills';

const COMPS_PER_PAGE = 10;

type Props = SubmissionInfo;

type State = { currentPage: number; opened: boolean };

export default class SubmissionDetails extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			currentPage: 1,
			opened: false,
		};
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	toggleDetailsOfSubmission(event) {
		event.preventDefault();
		this.setState((prevState) => ({
			opened: !prevState.opened,
			currentPage: 1,
		}));
	}

	showMore() {
		this.setState((prevState) => ({
			...prevState,
			currentPage: prevState.currentPage + 1,
		}));
	}

	submissionDetailsUi() {
		if (this.props.status === 'IN PROGRESS') {
			return (
				<div className="credits-submission-empty">
					<h3>We are still reviewing your comps</h3>
					<p>
						It may take 3-5 business days to review your submission. You will
						receive an email when the review is complete.
					</p>
					<NoCreditsContactLink />
				</div>
			);
		}
		return (
			<SubmissionDetailsSubRow
				submissionId={this.props.submission.id}
				currentPage={this.state.currentPage}
				showMore={() => this.showMore()}
			/>
		);
	}

	maxHeight() {
		if (!this.state.opened) {
			return 0;
		} else {
			return this.state.currentPage * COMPS_PER_PAGE * 32 + 10 + 15 + 73;
		}
	}

	getFixedFilename() {
		// regexp to detect UUID in file name
		const regexp =
			/-?[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/gi;

		const dateSubmitted = dayjs(this.props.submission.dateSubmitted);

		const originalFilename = this.props.submission.fileName;

		let fixedFilename;
		if (originalFilename) {
			// remove all UUIDs from the filename
			fixedFilename = originalFilename.replace(regexp, '');

			if (!fixedFilename || fixedFilename.length === 0) {
				fixedFilename = originalFilename;
			}

			// if after replacing UUID we have only extension - let show just extension in all upper case
			if (fixedFilename.startsWith('.')) {
				fixedFilename = `${fixedFilename
					.substr(1)
					.toUpperCase()} submitted on ${dateSubmitted.format('MM/DD/YY')}`;
			}
		} else {
			fixedFilename = `Submitted on ${dateSubmitted.format('MM/DD/YY')}`;
		}

		return fixedFilename;
	}

	render() {
		const dateSubmitted = dayjs(this.props.submission.dateSubmitted);

		return (
			<div>
				<div
					className={'credits-table-row ' + (this.state.opened ? ' open' : '')}
				>
					<div className="credits-cell col-4-12">
						<span className="ellipsis">{this.getFixedFilename()}</span>
					</div>
					<div className="credits-cell col-2-12">
						{dateSubmitted.format('MMMM D, YYYY')}
					</div>
					<div className="credits-cell col-2-12">{this.props.status}</div>
					<div className="credits-cell col-1-12 align-right">
						{this.props.status === 'COMPLETED'
							? this.props.versionsCount
							: false}
					</div>
					<div className="credits-cell col-1-12 align-right">
						{this.props.status === 'COMPLETED' ? this.props.points : false}
					</div>
					<div className="credits-cell col-2-12 align-right">
						<div
							onClick={(ev) => this.toggleDetailsOfSubmission(ev)}
							className="button_button button_small button_gray"
						>
							{(this.state.opened ? 'Hide' : 'See') + ' Details'}
						</div>
					</div>
				</div>

				<div
					className={'individual-comps ' + (this.state.opened ? 'open' : '')}
					style={{ maxHeight: this.maxHeight() + 'px' }}
				>
					{this.state.opened ? this.submissionDetailsUi() : false}
				</div>
			</div>
		);
	}
}

function SubmissionDetailsSubRow({
	submissionId,
	currentPage,
	showMore,
}: {
	submissionId: number;
	currentPage: number;
	showMore: () => void;
}) {
	const {
		data: submissionDetails,
		isLoading,
		isError,
	} = useGetUserSubmissionDetails(submissionId);

	if (isLoading || isError) {
		return (
			<Flex
				justifyContent="center"
				className="container"
				style={{ padding: 4 }}
			>
				{isError ? 'Failed to load submission details' : <Spinner />}
			</Flex>
		);
	}

	if (!Array.isArray(submissionDetails) || submissionDetails.length === 0) {
		return (
			<div className="credits-submission-empty">
				<h3>This submission doesn’t contain any relevant Comps</h3>
				<p>Usually this is because:</p>
				<ul>
					<li>
						This submission contains property listings, not lease or sales comps
					</li>
					<li>The addresses in the submission could not be verified</li>
				</ul>
				<NoCreditsContactLink />
			</div>
		);
	}
	return (
		<>
			<ol>
				{submissionDetails
					.slice(0, currentPage * COMPS_PER_PAGE)
					.map((comp, i) => (
						<li key={comp.id}>
							<div
								className={
									'credits-table-row ' +
									(isRecent(comp.category) ? 'recent' : '')
								}
							>
								<div className="credits-cell col-6-12">
									<div className="ellipsis">
										<span className="number">{i + 1}.</span>
										{comp.address}
									</div>
								</div>
								<div className="credits-cell col-3-12">
									<CategoryPills category={comp.category} />
								</div>
								<div className="credits-cell col-1-12 align-right">
									{comp.points}
								</div>
							</div>
						</li>
					))}
			</ol>
			{submissionDetails &&
			currentPage * COMPS_PER_PAGE < submissionDetails.length ? (
				<button
					className="container show-more-btn-row"
					onClick={showMore}
					data-qa-id="credit-ledger-show-more-comps-button"
				>
					<span className="show-more-btn-label">
						Show More <StyledDownArrow />
					</span>
				</button>
			) : (
				false
			)}
		</>
	);
}

function NoCreditsContactLink() {
	const contactPhoneDashed = phoneNumberConstants.phone1.dashed;
	const contactPhoneDotted = phoneNumberConstants.phone1.dotted;
	return (
		<p className="small">
			If you have any questions contact us at{' '}
			<a
				data-qa-id="credits-submission-empty-contact-email-link"
				href={`mailto:${emailConstants.help}`}
			>
				{emailConstants.help}
			</a>{' '}
			or{' '}
			<a
				data-qa-id="credits-submission-empty-contact-phone-link"
				href={`tel:${contactPhoneDashed}`}
			>
				{contactPhoneDotted}
			</a>
		</p>
	);
}

function isRecent(category: SubmissionCategory) {
	switch (category) {
		case 'BASIC':
		case 'PREMIUM':
			return true;
		default:
			return false;
	}
}

const StyledDownArrow = styled(DownArrow)`
	width: 12px;
	height: 12px;
	display: inline;
	fill: ${({ theme }) => theme.colors.neutral.n85};
`;
