import {
	PortfolioFormFields,
	PortfolioFormState,
	usePortfolioForm,
	UsePortfolioFormProps,
} from 'Components/PortfolioForm';
import {
	Modal,
	ModalButton,
	ModalButtons,
	ModalTitle,
} from 'Components/Modals/common/UI';

type Props = {
	title: string;
	closeDialog: () => void;
	isLoadingMutation: boolean;
	onSubmit: (values: PortfolioFormState) => void;
} & UsePortfolioFormProps;

export const PortfolioDialog = ({
	title,
	closeDialog,
	isLoadingMutation,
	onSubmit,
	...formProps
}: Props) => {
	const portfolioForm = usePortfolioForm(formProps);

	return (
		<Modal onClose={closeDialog}>
			<ModalTitle>{title}</ModalTitle>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					onSubmit(portfolioForm.trimmedState);
				}}
			>
				<PortfolioFormFields {...portfolioForm} />
				<ModalButtons>
					<ModalButton variant="secondary" onClick={closeDialog}>
						Cancel
					</ModalButton>
					<ModalButton
						type="submit"
						variant="primary"
						disabled={!portfolioForm.isValid || isLoadingMutation}
						isLoading={isLoadingMutation}
						style={{
							transition: 'none',
						}}
					>
						Done
					</ModalButton>
				</ModalButtons>
			</form>
		</Modal>
	);
};
