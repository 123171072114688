import { APIClientResponse } from '@compstak/common';
import { Button, Flex, Modal } from '@compstak/ui-kit';
import { MutateOptions } from '@tanstack/react-query';
import { SpaceTypeName } from 'api';
import { SummaryFeedbackUpdate } from 'api/marketSummary';
import { Checkbox } from 'Components';
import React from 'react';
import { useDispatch } from 'react-redux';
import { addFeedback } from 'Singletons/Feedback/actions';
import { FeedbackRequest, ThumbStatus } from 'types/marketSummary';
import { ModalContainer } from '../UI';

type Props = {
	feedbackRequest: FeedbackRequest;
	setFeedbackRequest: React.Dispatch<React.SetStateAction<FeedbackRequest>>;
	setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
	updateFeedbackMutation: (
		variables: SummaryFeedbackUpdate,
		options?:
			| MutateOptions<
					APIClientResponse<unknown>,
					unknown,
					SummaryFeedbackUpdate,
					void
			  >
			| undefined
	) => void;
	marketId: number;
	spaceType: SpaceTypeName;
	userId: number;
	thumbStatus: ThumbStatus;
};

export const MarketSummaryModal = ({
	feedbackRequest,
	setFeedbackRequest,
	setIsOpenModal,
	updateFeedbackMutation,
	marketId,
	spaceType,
	userId,
	thumbStatus,
}: Props) => {
	const handleCheckboxChange = (
		name: keyof Omit<FeedbackRequest, 'comments' | 'positive'>,
		checked: boolean
	) => {
		setFeedbackRequest((prevFeedbackData) => ({
			...prevFeedbackData,
			[name]: checked,
		}));
	};

	const isButtonDisabled = Object.entries(feedbackRequest)
		.filter(([key]) => key !== 'positive')
		.every(([, value]) => !value);

	const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setFeedbackRequest((prevFeedbackData) => ({
			...prevFeedbackData,
			[e.target.name]: e.target.value,
		}));
	};

	const feedbackOptions: {
		id: keyof Omit<FeedbackRequest, 'comments' | 'positive'>;
		label: string;
		checked: boolean | undefined;
	}[] = [
		{
			id: 'importantTopics',
			label: thumbStatus
				? 'The summary topics were important to me'
				: "The summary topics aren't important to me",
			checked: feedbackRequest?.importantTopics,
		},
		{
			id: 'informativeInsights',
			label: thumbStatus
				? 'The insights provided me with new information'
				: 'The insights did not provide me with new information',
			checked: feedbackRequest?.informativeInsights,
		},
		{
			id: 'helpfulInsights',
			label: thumbStatus
				? 'The insights provided would help me with my work'
				: 'The insights would not help me with my work',
			checked: feedbackRequest?.helpfulInsights,
		},
	];

	const question = thumbStatus
		? 'Which of these did you like about the market summary?'
		: 'Which of these did you not like about the market summary?';

	const dispatch = useDispatch();

	return (
		<Modal
			title="Thanks for your Feedback!"
			onClose={() => setIsOpenModal(false)}
		>
			<ModalContainer>
				<form>
					<p>
						Thanks for letting us know how you feel about our Market Summary
						beta. To make this feature more impactful, please answer the
						question below (optional):
					</p>

					<p>{question}</p>

					{feedbackOptions.map((option) => {
						return (
							<Flex
								alignItems="center"
								justifyContent="flex-start"
								gap="8px"
								key={option.id}
							>
								<Checkbox
									id={option.id}
									checked={option.checked}
									onCheckedChange={(checked) => {
										handleCheckboxChange(option.id, checked as boolean);
									}}
								/>
								<label className="Label" htmlFor={option.id}>
									{option.label}
								</label>
							</Flex>
						);
					})}

					<label className="textarea-label">
						Let us know if you have any additional thoughts or questions
					</label>
					<textarea
						placeholder="I also want to mention"
						name="comment"
						onChange={handleTextareaChange}
					></textarea>

					<Flex gap="16px" justifyContent="flex-end">
						<Button
							type="button"
							onClick={() => {
								dispatch(addFeedback('Thanks for your Feedback!'));

								updateFeedbackMutation({
									marketId,
									spaceType,
									userId,
									body: feedbackRequest as FeedbackRequest,
								});

								setFeedbackRequest({
									comments: '',
									helpfulInsights: false,
									importantTopics: false,
									positive: false,
									informativeInsights: false,
								});
								setIsOpenModal(false);
							}}
							disabled={isButtonDisabled}
						>
							Submit feedback
						</Button>
					</Flex>
				</form>
			</ModalContainer>
		</Modal>
	);
};

MarketSummaryModal.displayName = 'MarketSummaryModal';
