import {
	useQuery,
	useQueryClient,
	UseQueryOptions,
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import { filtersToServerJSON } from 'models/filters/util';
import { useCallback, useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { ServerFilterItem } from 'types/serverFilters';
import { SearchLeasesResponse, useSearchLeasesQuery } from 'api';
import { formatAverages } from 'services/util/formatAverages';

const EXCHANGE_DASHBOARD_AVERAGES_TIMELINE =
	'EXCHANGE_DASHBOARD_AVERAGES_TIMELINE';

type Timeline = 6 | 12 | 24;

export const useExchangeDashboardAverages = (
	options?: UseQueryOptions<SearchLeasesResponse>
) => {
	const queryClient = useQueryClient();

	const { data: timeline } = useQuery({
		queryKey: [EXCHANGE_DASHBOARD_AVERAGES_TIMELINE],
		initialData: 6 as Timeline,
		queryFn: () =>
			queryClient.getQueryData<Timeline>([
				EXCHANGE_DASHBOARD_AVERAGES_TIMELINE,
			]) ?? 6,
	});

	const setTimeline = useCallback(
		(newTimeline: Timeline) => {
			queryClient.setQueryData(
				[EXCHANGE_DASHBOARD_AVERAGES_TIMELINE],
				newTimeline
			);
		},
		[queryClient]
	);

	const [filters] = useFilters('exchange-dashboard');

	const filter = useMemo(() => {
		const min = dayjs().subtract(timeline, 'months').startOf('month');
		const max = dayjs().add(0, 'months');
		const executionDate: ServerFilterItem<'executionDate'> = {
			property: 'executionDate',
			value: {
				from: min.format('MM/DD/YYYY'),
				to: max.format('MM/DD/YYYY'),
			},
		};

		return [...filtersToServerJSON(filters), executionDate];
	}, [filters, timeline]);

	const { data, isFetching } = useSearchLeasesQuery(
		{
			filter,
			offset: 0,
			sort: 'executionQuarter',
			order: 'desc',
			limit: 1,
		},
		options
	);

	const averages = useMemo(() => {
		if (!data || !data.averages) {
			return;
		}

		return formatAverages(filters.market.monthly, data.averages);
	}, [data, filters.market]);

	return {
		averages,
		isFetching,
		timeline,
		setTimeline,
		type: 'averages' as const,
	};
};
