import React, { Component } from 'react';
import FormInput from './FormInput';

type Props = {
	options: ReadonlyArray<Option>;
	inputClass?: string;
	isDate?: boolean;
	label?: string;
	labelClass?: string;
	matchProp?: string;
	name?: string;
	onChange?: (name: string | undefined, value: string) => void;
	placeholder?: string;
	required?: boolean;
	type?: string;
	value?: string;
};

type State = {
	filteredOptions: ReadonlyArray<Option>;
	focusedOption: Option | Record<string, never>;
	isFocused: boolean;
	isOpen?: boolean;
	optionsExpanded: boolean;
	value: string | undefined;
};

type Option = { label: string; value: string };

export default class FormSelect extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			value: this.props.value,
			filteredOptions: this.props.options,
			optionsExpanded: false,
			isFocused: false,
			focusedOption: {},
		};
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevState.isFocused === true && this.state.isFocused === false) {
			this.setState({
				optionsExpanded: false,
			});
		}
	}

	componentWillUnmount() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'blurTimeout' does not exist on type 'For... Remove this comment to see the full error message
		clearTimeout(this.blurTimeout);
	}

	resetValue() {
		this.setValue(this.state.value);
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	clearValue(event) {
		if (event && event.type === 'mousedown' && event.button !== 0) {
			return;
		}
		this.setValue(null);
	}

	// @ts-expect-error TS7006: Parameter 'value' implicitly h...
	setValue(value) {
		this.setState({
			value: value,
		});

		if (this.props.onChange) {
			this.props.onChange(this.props.name, value);
		}
	}

	// @ts-expect-error TS7006: Parameter 'filterValue' implic...
	filterOptions(filterValue) {
		// @ts-expect-error TS7006: Parameter 'option' implicitly ...
		const filterOption = (option) => {
			return !filterValue
				? (this.props.matchProp !== 'label' &&
						option.value.toLowerCase().substr(0, filterValue.length) ===
							filterValue) ||
						(this.props.matchProp !== 'value' &&
							option.label.toLowerCase().substr(0, filterValue.length) ===
								filterValue)
				: (this.props.matchProp !== 'label' &&
						option.value.toLowerCase().indexOf(filterValue.toLowerCase()) >=
							0) ||
						(this.props.matchProp !== 'value' &&
							option.label.toLowerCase().indexOf(filterValue.toLowerCase()) >=
								0);
		};

		return this.props.options.filter(filterOption);
	}

	// @ts-expect-error TS7006: Parameter 'focusedOption' impl...
	selectOption(focusedOption) {
		const option = focusedOption || this.state.focusedOption;

		if (option) {
			this.setValue(option.value);
		}
		this.setState({
			optionsExpanded: false,
			focusedOption: option,
		});
	}

	// @ts-expect-error TS7006: Parameter 'option' implicitly ...
	focusOption(option) {
		this.setState({
			focusedOption: option,
		});
	}

	// @ts-expect-error TS7006: Parameter 'option' implicitly ...
	unfocusOption(option) {
		if (this.state.focusedOption === option) {
			this.setState({
				focusedOption: {},
			});
		}
	}

	// @ts-expect-error TS7006: Parameter 'direction' implicit...
	focusAdjacentOption(direction) {
		if (!this.state.optionsExpanded) {
			const index = direction === 'next' ? 0 : this.props.options.length - 1;
			this.setState({
				optionsExpanded: true,
				value: '',
				focusedOption: this.state.focusedOption || this.props.options[index],
			});

			return;
		}

		let focusedOption = this.state.focusedOption;

		if (focusedOption) {
			const index = this.state.filteredOptions.findIndex((option) => {
				return option.label === this.state.focusedOption.label;
			});
			if (direction === 'next') {
				if (index < this.state.filteredOptions.length - 1) {
					focusedOption = this.state.filteredOptions[index + 1];
				} else {
					focusedOption = this.state.filteredOptions[0];
				}
			}
			if (direction === 'previous') {
				if (index > 0) {
					focusedOption = this.state.filteredOptions[index - 1];
				} else {
					focusedOption =
						this.state.filteredOptions[this.state.filteredOptions.length - 1];
				}
			}
		} else {
			focusedOption = this.state.filteredOptions[0];
		}

		this.setState({
			focusedOption: focusedOption,
		});
	}

	focusNextOption() {
		this.focusAdjacentOption('next');
	}

	focusPreviousOption() {
		this.focusAdjacentOption('previous');
	}

	// @ts-expect-error TS7006: Parameter 'name' implicitly ha...
	handleInputChange(name, value) {
		const filteredOptions = this.filterOptions(value);
		const focusedOption = filteredOptions.includes(
			this.state.focusedOption as Option
		)
			? this.state.focusedOption
			: filteredOptions[0];

		this.setState({
			isOpen: true,
			filteredOptions: filteredOptions,
			focusedOption: focusedOption,
		});

		this.setValue(value);
	}

	handleInputFocus() {
		this.setState({
			isFocused: true,
			optionsExpanded: true,
		});
	}

	handleInputBlur() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'blurTimeout' does not exist on type 'For... Remove this comment to see the full error message
		this.blurTimeout = setTimeout(() => {
			this.setState({
				optionsExpanded: false,
				isFocused: false,
			});
		}, 250);
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleKeyDown(event) {
		switch (event.keyCode) {
			case 9: // tab
				if (
					event.shiftKey ||
					!this.state.optionsExpanded ||
					!this.state.focusedOption
				) {
					return;
				}
				// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
				this.selectOption();

				break;
			case 13: // enter
				// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
				this.selectOption();

				break;
			case 27: // escape
				if (this.state.isOpen) {
					this.resetValue();
				} else {
					this.clearValue(event);
				}
				break;
			case 38: // up
				this.focusPreviousOption();
				break;
			case 40: // down
				this.focusNextOption();
				break;
			default:
				return;
		}

		event.preventDefault();
		event.stopPropagation();
	}

	renderOptions() {
		const options = this.state.filteredOptions.map((option, index) => {
			let className = 'form-select-option';
			if (option.label === this.state.focusedOption.label) {
				className += ' ' + 'active';
			}
			return (
				<li
					className={className}
					key={this.props.name + '-' + index}
					data-value={option.value}
					onClick={this.selectOption.bind(this, option)}
				>
					{option.label}
				</li>
			);
		});
		return <ul className="form-select-options">{options}</ul>;
	}

	render() {
		let className = 'form-input form-select';
		if (this.props.inputClass) {
			className += ' ' + this.props.inputClass;
		}

		if (this.props.required) {
			className += ' ' + 'required';
		}

		const inputProps = {
			placeholder: this.props.placeholder,
			type: this.props.type,
			value: this.state.value,
			isDate: this.props.isDate,
			name: this.props.name,
		};

		return (
			<div className={className}>
				{this.props.label ? (
					<label className="label input_label">
						{this.props.label} {this.props.required ? '*' : false}
					</label>
				) : (
					false
				)}

				<div
					className="form-select-control"
					onKeyDown={this.handleKeyDown.bind(this)}
				>
					<FormInput
						defaultValue=""
						onChange={this.handleInputChange.bind(this)}
						onFocus={this.handleInputFocus.bind(this)}
						onBlur={this.handleInputBlur.bind(this)}
						// @ts-expect-error ts-migrate(2783) FIXME: 'value' is specified more than once, so this usage... Remove this comment to see the full error message
						value={this.state.value}
						allowHorizontalScrolling={false}
						{...inputProps}
					/>
					<div className="form-select-arrow" />
				</div>
				{this.state.optionsExpanded ? (
					<div ref="options">{this.renderOptions()}</div>
				) : (
					false
				)}
			</div>
		);
	}
}
